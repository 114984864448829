// This file contains helper functions for accessibility purposes

export const accessibleClick = (
  event: KeyboardEvent,
  callback: (e?: KeyboardEvent) => void
) => {
  // Add this to your keydown events on clickable elements and pass the callback (usually same function as onClick)
  if (event.code === 'Enter' || event.code === 'Space') {
    event.preventDefault(); // Prevents unintentional form submissions, page scrollings, the like
    callback(event);
  }
};
