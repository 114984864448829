import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {HintBox, InfoIcon, LoadingIndicator} from '@edume/magnificent';

import KnowledgeHubContentList from '../KnowledgeHubContentList';

import styles from './styles.module.scss';

export const ASSIGNED_CONTENT_DROPPABLE_ID = 'kh-assigned';

const KnowledgeHubAssignedContent = ({
  knowledgeHub,
  loadKnowledgeHubContent,
  contentList,
  removeContentFromKnowledgeHubSection,
  noContentMessage,
}) => {
  const intl = useIntl();
  useEffect(() => {
    if (!knowledgeHub.sections) {
      loadKnowledgeHubContent(knowledgeHub.id);
    }
  }, [knowledgeHub.id, knowledgeHub.sections, loadKnowledgeHubContent]);

  if (!knowledgeHub.sections) {
    return (
      <LoadingIndicator indicator='hexagons' containerStyle='centerFlex' />
    );
  }

  return (
    <div styleName='assigned-content'>
      <div styleName='hint-container'>
        <HintBox
          icon={<InfoIcon />}
          text={intl.formatMessage({
            id: 'KnowledgeHub.assignedContent.hintText',
          })}
        />
      </div>
      {noContentMessage}
      <KnowledgeHubContentList
        contentList={contentList}
        droppableId={ASSIGNED_CONTENT_DROPPABLE_ID}
        removeContent={removeContentFromKnowledgeHubSection}
        dataAutomation='assigned-content'
      />
    </div>
  );
};

KnowledgeHubAssignedContent.propTypes = {
  knowledgeHub: PropTypes.object.isRequired,
  loadKnowledgeHubContent: PropTypes.func.isRequired,
  contentList: PropTypes.array.isRequired,
  removeContentFromKnowledgeHubSection: PropTypes.func.isRequired,
  noContentMessage: PropTypes.node,
};

export default CSSModules(KnowledgeHubAssignedContent, styles);
