import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Button, CrossIcon, PlusIcon, TickIcon} from '@edume/magnificent';

import colourStyles from '../../../../../resources/styles/colours.module.scss';
import styles from '../styles.module.scss';

const SUCCESS_ICON_DURATION = 1000;

const RightPanel = ({
  removeContent,
  addContent,
  content,
  isAssignedContent,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (showSuccess) {
      timeoutId = setTimeout(
        () => setShowSuccess(false),
        SUCCESS_ICON_DURATION
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuccess]);

  const handleRemoveContent = () => {
    removeContent(content);
  };

  const handleAddContent = () => {
    setShowSuccess(true);
    addContent(content);
  };

  const isUnAssignedOrShowingSuccess =
    isAssignedContent === false || showSuccess;

  const renderActionButton = () => {
    if (isUnAssignedOrShowingSuccess && addContent) {
      if (showSuccess) {
        return (
          <div styleName='add-button success'>
            <Button
              type='custom'
              customColour={colourStyles.aqua700}
              customDarkColour={colourStyles.aqua700}
              customFontColour={colourStyles.white}
              customHoverFontColour={colourStyles.white}
              icon={<TickIcon />}
              dataAutomation='content-added-label'
              onClick={handleAddContent} // Use extracted function
            />
          </div>
        );
      } else {
        return (
          <div styleName='add-button'>
            <Button
              type='primary'
              icon={<PlusIcon />}
              dataAutomation='add-content-button'
              onClick={handleAddContent} //Use extracted function
            />
          </div>
        );
      }
    } else if (removeContent) {
      return (
        <div styleName='remove-button'>
          <Button
            type='custom'
            icon={<CrossIcon size='tiny' />}
            onClick={handleRemoveContent} // Use extracted function
            customColour={colourStyles.grey300}
            customDarkColour={colourStyles.grey700}
            customFontColour={colourStyles.white}
            customHoverFontColour={colourStyles.white}
            dataAutomation='remove-content-button'
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return <div styleName='right-panel'>{renderActionButton()}</div>;
};

RightPanel.propTypes = {
  removeContent: PropTypes.func,
  addContent: PropTypes.func,
  isAssignedContent: PropTypes.bool,
  content: PropTypes.object.isRequired,
};

export default CSSModules(RightPanel, styles, {
  allowMultiple: true,
});
