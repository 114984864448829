import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {appendParamsToUrl} from '@edume/bento/urlUtil';
import {Button, Heading, Text} from '@edume/magnificent';

import config from '../../../config';
import {generateAuthRequest} from '../../../utils/saml';

import styles from '../styles.module.scss';

const SSOLoginForm = ({onBackClick, userId, ssoConfig = {}}) => {
  const intl = useIntl();

  const {id, protocol, acsUrl, companyName, loginUrl, clientId, scope} =
    ssoConfig;

  if (!['saml', 'oauth'].includes(protocol)) {
    return null;
  }

  const ssoSignInHeader = intl.formatMessage({id: 'Login.ssoSignInHeader'});
  const ssoSignInMessage = intl.formatMessage(
    {id: 'Login.ssoSignInMessage'},
    {companyName}
  );
  const ssoRedirectMessage = intl.formatMessage(
    {id: 'Login.ssoRedirectMessage'},
    {companyName}
  );

  const buttonText = intl.formatMessage({id: 'Button.continue'});
  const backButtonText = intl.formatMessage({id: 'Button.back'});

  const onClick = () => {
    let url;
    if (protocol === 'saml') {
      const authRequest = generateAuthRequest(userId);
      url = appendParamsToUrl(acsUrl, `SAMLRequest=${authRequest}`);
    } else {
      const query = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        redirect_uri: config.ssoRedirectUri,
        state: id,
      });
      if (scope) {
        query.append('scope', scope);
      }
      url = `${loginUrl}?${query}`;
    }
    window.location.href = url;
  };

  return (
    <div styleName='login-form'>
      <Heading as='h2' size='sheep'>
        {ssoSignInHeader}
      </Heading>
      <div styleName='sso'>
        <Text size='m'>{ssoSignInMessage}</Text>
        <Text size='m'>{ssoRedirectMessage}</Text>
      </div>

      <div styleName='buttons sso-buttons'>
        <Button type='primary' fullWidth onClick={onClick} noMarginTop>
          {buttonText}
        </Button>
        {!!onBackClick && (
          <Button type='secondary' onClick={onBackClick} fullWidth noMarginTop>
            {backButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

SSOLoginForm.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  ssoConfig: PropTypes.object.isRequired,
};

export default CSSModules(SSOLoginForm, styles, {allowMultiple: true});
