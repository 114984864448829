import {fromJS} from 'immutable';

import {SELECT_GROUP} from '../people/peopleActionTypes';
import {EDIT_GROUP_SUCCESS} from '../team/teamActionTypes';
import {
  CREATE_GROUP_SEGMENT,
  CREATE_GROUP_SEGMENT_FAIL,
  CREATE_GROUP_SEGMENT_SUCCESS,
  EDIT_GROUP_SEGMENT,
  EDIT_GROUP_SEGMENT_FAIL,
  EDIT_GROUP_SEGMENT_SUCCESS,
  LOAD_GROUP_SEGMENTS,
  LOAD_GROUP_SEGMENTS_FAIL,
  LOAD_GROUP_SEGMENTS_SUCCESS,
  STOP_POLL_SEGMENT_STATUS,
  UPDATE_SEGMENT_STATUS,
} from './segmentActionTypes';

export const initialState = fromJS({
  loaded: false,
  loading: false,
  shouldPollSegmentStatus: true,
  segments: [],
});

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUP_SEGMENTS: {
      return state.set('loaded', false).set('loading', true);
    }
    case LOAD_GROUP_SEGMENTS_SUCCESS: {
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('segments', fromJS(action.payload.data));
    }

    case LOAD_GROUP_SEGMENTS_FAIL:
      return state.set('loading', false).set('loaded', false);

    case SELECT_GROUP:
      return initialState;

    case STOP_POLL_SEGMENT_STATUS:
      return state.set('shouldPollSegmentStatus', false);

    case CREATE_GROUP_SEGMENT:
    case EDIT_GROUP_SEGMENT:
      return state.set('loading', true).set('loaded', false);

    case CREATE_GROUP_SEGMENT_FAIL:
    case CREATE_GROUP_SEGMENT_SUCCESS:
    case EDIT_GROUP_SEGMENT_FAIL:
    case EDIT_GROUP_SEGMENT_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('shouldPollSegmentStatus', true);

    case UPDATE_SEGMENT_STATUS: {
      const segments = action.payload.data;
      return segments.reduce((updatedState, segment) => {
        const segmentIndex = updatedState
          .get('segments')
          .findIndex((s) => s.get('id') === segment.id);
        return segmentIndex > -1
          ? updatedState.updateIn(['segments', segmentIndex], (stateSegment) =>
              stateSegment
                .set('currentStep', segment.currentStep)
                .set('totalSteps', segment.totalSteps)
                .set('status', segment.status)
            )
          : updatedState;
      }, state);
    }
    case EDIT_GROUP_SUCCESS: {
      const {allUsersSegment} = action.meta;
      const {allUsersTeamName} = action.payload.data;

      if (!allUsersSegment) {
        return state;
      }

      const allUsersSegmentIndex = state
        .get('segments')
        .findIndex((segment) => segment.get('id') === allUsersSegment.id);

      return state.setIn(
        ['segments', allUsersSegmentIndex, 'name'],
        allUsersTeamName
      );
    }
    default:
      return state;
  }
};
