type APIReference = {
  paths: {
    [path: string]: {
      [method: string]: {
        security?: object;
      };
    };
  };
  components?: object;
};

export const patchHapiSwaggerOutput = (data: APIReference) => {
  // hapi-swagger incorrectly strips securitySchemes if deReference option is used
  data.components = {
    securitySchemes: {
      'api-key': {type: 'apiKey', name: 'x-api-key', in: 'header'},
    },
  };
};

export const docsUI = () => `<!doctype html>
<html>
  <head>
    <title>API Reference</title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <style>
      body { margin: 0; }
    </style>
  </head>
  <body>
    <script id="api-reference" data-url="/openapi.json"></script>
    <script>
      const configuration = {
        theme: 'solarized',
      }
      const apiReference = document.getElementById('api-reference')
      apiReference.dataset.configuration = JSON.stringify(configuration)
    </script>
    <script src="https://cdn.jsdelivr.net/npm/@scalar/api-reference"></script>
  </body>
</html>`;
