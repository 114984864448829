import React from 'react';

import PropTypes from 'prop-types';
import {FormattedRelativeTime} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

import {LegacyIntlHeading} from '../';

const RelativeTime = ({
  timestamp,
  style = 'long',
  colour = 'textPrimary',
  size = 'micro',
  weight = 'regular',
  nowTextKey = 'RelativeTime.updated.justNow',
  relativeTextKey = 'RelativeTime.updated.relative',
}) => {
  const timestampInSeconds = Math.round(
    (new Date() - new Date(timestamp)) / 1000
  );

  const justUpdated = (
    <LegacyIntlHeading
      size={size}
      colour={colour}
      weight={weight}
      textKey={nowTextKey}
    />
  );

  const relativeTime = (
    <div>
      <LegacyIntlHeading
        size={size}
        colour={colour}
        weight={weight}
        inline
        textKey={relativeTextKey}
      />
      <LegacyHeading size={size} colour={colour} weight={weight} inline>
        <FormattedRelativeTime
          value={-timestampInSeconds}
          numeric='auto'
          style={style}
          updateIntervalInSeconds={1}
        />
      </LegacyHeading>
    </div>
  );

  return timestampInSeconds < 60 ? justUpdated : relativeTime;
};

RelativeTime.propTypes = {
  timestamp: PropTypes.string.isRequired,
  style: PropTypes.oneOf(['long', 'short']),
  colour: PropTypes.oneOf(['textPrimary', 'white', 'grey700', 'grey300']),
  size: PropTypes.oneOf(['micro', 'mini-light', 'mini']),
  weight: PropTypes.oneOf(['regular', 'medium', 'bold']),
  nowTextKey: PropTypes.string,
  relativeTextKey: PropTypes.string,
};

export default RelativeTime;
