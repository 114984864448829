import React from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {HintBox} from '@edume/magnificent';

import {StyledModal} from '../../../containers/StyledModals';
import LegacyIntlHeading from '../LegacyIntlHeading';

const Dialog = ({
  titleKey,
  titleValue,
  descriptionKey,
  descriptionValue,
  hintBoxKey,
  hintBoxType,
  onCancel,
  onConfirm,
  cancelKey,
  confirmKey,
  disableConfirmButton,
  cancelDataAutomation,
  confirmDataAutomation,
  showIcon = true,
  confirmType = 'danger',
}) => {
  //TODO, when needed: make types of dialogs for confirm/danger/info - change icon, button type
  const intl = useIntl();

  const body = (
    <div>
      <LegacyIntlHeading
        size='micro'
        textKey={descriptionKey}
        values={descriptionValue}
      />
      {hintBoxKey && (
        <div style={{paddingTop: 15}}>
          <HintBox
            type={hintBoxType}
            text={intl.formatMessage({id: hintBoxKey})}
          />
        </div>
      )}
    </div>
  );

  const content = {
    title: intl.formatMessage({id: titleKey}, titleValue),
    iconUrl: showIcon && '/resources/img/38_alert.svg',
    body: body,
    buttons: [
      {
        type: 'secondary',
        onClick: onCancel,
        text: intl.formatMessage({id: cancelKey}),
        dataAutomation: cancelDataAutomation,
      },
      {
        type: confirmType,
        onClick: onConfirm,
        text: intl.formatMessage({id: confirmKey}),
        disabled: disableConfirmButton,
        dataAutomation: confirmDataAutomation,
      },
    ],
  };

  return <StyledModal onClose={onCancel} content={content} cancelFocus />;
};

Dialog.propTypes = {
  titleKey: PropTypes.string.isRequired,
  titleValue: PropTypes.object,
  descriptionKey: PropTypes.string.isRequired,
  descriptionValue: PropTypes.object,
  hintBoxKey: PropTypes.string,
  hintBoxType: PropTypes.oneOf(['info', 'warning', 'error']),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelKey: PropTypes.string.isRequired,
  confirmKey: PropTypes.string.isRequired,
  disableConfirmButton: PropTypes.bool,
  cancelDataAutomation: PropTypes.string,
  confirmDataAutomation: PropTypes.string,
  showIcon: PropTypes.bool,
  confirmType: PropTypes.oneOf(['danger', 'primary']),
};

export default Dialog;
