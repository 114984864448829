import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import TrialEndFooter from './TrialEndFooter';

import styles from './styles.module.scss';

const {version} = require('../../../package.json');

const connector = connect((state) => {
  const isAuthenticated =
    state.getIn(['auth', 'isAuthenticated']) &&
    state.getIn(['auth', 'activated']);
  const {isTrial, trialEnd} = state.getIn(['auth', 'company']);
  let hasTrialEnded = false;
  if (isTrial && trialEnd) {
    hasTrialEnded = moment(trialEnd).diff(moment(), 'days', true) < 0.0;
  }

  return {
    isAuthenticated,
    authValidated: state.getIn(['auth', 'authValidated']),
    isTrial: isTrial || false, //in case not loaded yet
    hasTrialEnded,
  };
});

const Footer = ({
  isAuthenticated,
  hasTrialEnded,
  showMinimal,
  darkBackground,
  match,
}) => {
  const getFooterContent = () => {
    const lines = <div styleName='coloured-line' />;

    if (hasTrialEnded) {
      return (
        <div styleName='footer-content'>
          <TrialEndFooter />
          {lines}
        </div>
      );
    }

    if (isAuthenticated || showMinimal) {
      return (
        <div styleName='footer-content'>
          <div styleName='edume-link-container'>
            <a
              styleName='edume-link'
              href='https://www.edume.com'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={`/resources/img/logo-edume-grayscale${
                  showMinimal ? '-light' : '-dark'
                }.svg`}
              />
            </a>
          </div>
          <div styleName={classNames('version', {minimal: showMinimal})}>
            <FormattedMessage id='Footer.version' values={{number: version}} />
          </div>
          {lines}
        </div>
      );
    }

    return (
      <div styleName='footer-content'>
        {lines}
        <div styleName='edume-link-container'>
          <a href='https://www.edume.com' target='_blank' rel='noreferrer'>
            <FormattedMessage id={'Login.edumeWebsite'} />
            <img
              styleName='icon'
              src={`/resources/img/24_right${
                darkBackground ? '_white' : '_purple'
              }.svg`}
            />
          </a>
        </div>
        <div styleName='edume-logo'>
          <img
            src={`/resources/img/logo-edume-full-colour${
              darkBackground ? '-white' : ''
            }.svg`}
          />
        </div>
        <div styleName='copyright'>
          <FormattedMessage id={'Login.copyright'} />
        </div>
      </div>
    );
  };

  // These routes coincide with pages with a sidebar
  // If this ever changes we'll need to amend the logic for language dropdown styling
  const hideFooterRoutes = ['/learning', '/guides', '/settings'];

  const isFooterHidden =
    match.path !== '/' && hideFooterRoutes.some((r) => r.includes(match.path));

  return (
    <footer styleName={classNames({'hide-footer': isFooterHidden})}>
      <div
        styleName={classNames('footer-content-container', {
          light: isAuthenticated || showMinimal || !darkBackground,
          dark: !isAuthenticated && !showMinimal && darkBackground,
        })}
      >
        {getFooterContent()}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  //UI is different once you're fully logged in
  darkBackground: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  hasTrialEnded: PropTypes.bool.isRequired,
  showMinimal: PropTypes.bool,
  match: PropTypes.object,
};

export default withRouter(
  connector(injectIntl(CSSModules(Footer, styles, {allowMultiple: true})))
);
