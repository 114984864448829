import React, {MouseEventHandler, ReactNode} from 'react';

import CSSModules from 'react-css-modules';

import Button, {ButtonTypes} from '../Button';
import Checkbox from '../Checkbox';
import Link from '../Link';
import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';

import styles from './styles.module.scss';

type CommonHintBoxTypes = {
  type?: 'info' | 'warning' | 'error';
  icon?: ReactNode;
  title?: string;
  text?: string;
  content?: ReactNode;
  compact?: boolean;
  leftText?: string;
  trianglePosition?: 'tl' | 'tr' | 'bl' | 'br' | 'l' | 'r';
  showCheckBox?: boolean;
  checkBoxDataAutomation?: string;
  buttonText?: string;
  buttonDarkIcon?: string;
  buttonLightIcon?: string;
  buttonLightIconComponent?: ReactNode;
  buttonDisabled?: boolean;
  buttonDataAutomation?: string;
  linkDataAutomation?: string;
  marginBottom?: boolean;
  reskinClassName?: string;
  columnLayout?: boolean;
  linkDisabled?: boolean;
};

type ConditionalLinkProps =
  | {
      showLink?: false;
      linkText?: never;
      linkHref?: never;
      linkAction?: never;
    }
  | {
      showLink: true;
      linkText: string;
      linkHref: string;
      linkAction?: never;
    }
  | {
      showLink: true;
      linkText: string;
      linkHref?: never;
      linkAction: MouseEventHandler<HTMLButtonElement>;
    };

type ConditionalButtonProps =
  | {
      showButton?: false;
      onButtonClick?: never;
      buttonType?: never;
    }
  | {
      showButton?: true;
      onButtonClick: () => void;
      buttonType: ButtonTypes;
    };

type ConditionalCheckboxProps =
  | {
      showCheckBox?: false;
      checkBoxText?: never;
      onCheck?: never;
      checked?: never;
    }
  | {
      showCheckBox?: true;
      checkBoxText: string;
      onCheck: () => void;
      checked: boolean;
    };

type HintBoxProps = CommonHintBoxTypes &
  ConditionalLinkProps &
  ConditionalButtonProps &
  ConditionalCheckboxProps & {
    textDataAutomation?: string;
  };

// eslint-disable-next-line complexity
const HintBox = (
  /* prettier-ignore */ {
    type = 'info', title, text, content, compact = false, icon, leftText, trianglePosition, showCheckBox,
    checkBoxText, onCheck, checked, checkBoxDataAutomation, showLink, linkText, linkHref, linkAction,
    linkDataAutomation, showButton, onButtonClick, buttonText, buttonDisabled, buttonDarkIcon,
    buttonLightIcon, buttonLightIconComponent, buttonType, buttonDataAutomation, marginBottom = false, reskinClassName,
    textDataAutomation, columnLayout, linkDisabled
  }: HintBoxProps
): JSX.Element => {
  const isSurveyHint = type.includes('survey');

  const iconContent = (icon || leftText) && (
    <div styleName='left'>
      {icon}
      {leftText && (
        <Text size='m' weight='medium'>
          {leftText}
        </Text>
      )}
    </div>
  );
  const titleHeading = title && (
    <Heading as='h6' size='snail' weight='medium'>
      {title}
    </Heading>
  );
  const textElement = text && (
    <Text size='s' dataAutomation={textDataAutomation}>
      {text}
    </Text>
  );

  let containerStyle = `container ${type}`;
  if (trianglePosition) {
    containerStyle += ' floating';
  }
  if (compact) {
    containerStyle += ' compact';
  }
  if (marginBottom) {
    containerStyle += ' marginBottom';
  }
  if (reskinClassName) {
    containerStyle += ` ${reskinClassName}`;
  }
  if (columnLayout) {
    containerStyle += ' column';
  }

  const conditionalWrapper = (children: ReactNode) =>
    isSurveyHint ? <div styleName='textIconWrapper'>{children}</div> : children;

  // probably a better way to do this, but TS doesn't realise linkHref and linkAction are mutually exclusive
  const linkProps = linkHref
    ? {href: linkHref}
    : {onClick: linkAction as MouseEventHandler<HTMLButtonElement>};

  return (
    <div styleName={containerStyle}>
      {conditionalWrapper(
        <React.Fragment>
          {!isSurveyHint && iconContent}
          <div styleName='center'>
            {titleHeading}
            {content || textElement}
          </div>
          {isSurveyHint && iconContent}
        </React.Fragment>
      )}
      {showCheckBox && (
        <div styleName='right'>
          <Checkbox
            checkboxKey='agree'
            onToggleCheck={onCheck}
            checked={checked}
            label={checkBoxText}
            dataAutomation={checkBoxDataAutomation}
          />
        </div>
      )}
      {showButton && (
        <div styleName='right button'>
          <Button
            type={buttonType}
            onClick={onButtonClick}
            noMarginTop={true}
            darkIconUrl={buttonDarkIcon}
            iconUrl={buttonLightIcon}
            icon={buttonLightIconComponent || null}
            disabled={buttonDisabled}
            noMarginLeft={true}
            dataAutomation={buttonDataAutomation}
          >
            {buttonText}
          </Button>
        </div>
      )}
      {showLink && (
        <div styleName='right link'>
          <Link
            text={linkText}
            dataAutomation={linkDataAutomation}
            disabled={linkDisabled}
            {...linkProps}
          />
        </div>
      )}
      {trianglePosition && (
        <div styleName={`triangle ${trianglePosition}`}>
          <div styleName='triangle-inner' />
        </div>
      )}
    </div>
  );
};

export default CSSModules(HintBox, styles, {allowMultiple: true});
