export class SSEClient {
  /** @type {EventSource} **/
  #eventSource;
  #config;
  #url;
  #onCloseHandler;
  #onMessageHandler;

  /**
   *
   * @param {string} url
   * @param {EventSourceInit} [config]
   */
  constructor(url, config) {
    this.#url = url;
    this.#config = {
      withCredentials: true,
      ...config,
    };
    this.#createEventSource(this.#url, this.#config);
  }

  #removeEventListeners() {
    this.#eventSource?.removeEventListener('close', this.#onClose);
    this.#eventSource?.removeEventListener('error', this.#onError);
    this.#eventSource?.removeEventListener('message', this.#onMessage);
  }

  /**
   * Remove event listeners and close the connection
   */
  close = () => {
    this.#removeEventListeners();
    this.#eventSource?.close();
  };

  #onClose = () => {
    this.#onCloseHandler?.();
    this.close();
  };

  #onError = () => {
    // retry after 5 seconds
    setTimeout(() => {
      this.close();
      this.#createEventSource(this.#url, this.#config);
    }, 5000);
  };

  #onMessage = (event) => {
    this.#onMessageHandler?.(JSON.parse(event.data));
  };

  #createEventSource(url, config) {
    this.#eventSource = new EventSource(url, config);
    this.#eventSource.addEventListener('close', this.#onClose);
    this.#eventSource.addEventListener('error', this.#onError);
    this.#eventSource.addEventListener('message', this.#onMessage);
  }

  set onClose(callback) {
    this.#onCloseHandler = callback;

    if (this.readyState === EventSource.CLOSED) {
      this.#onCloseHandler?.();
    }
  }

  set onMessage(callback) {
    this.#onMessageHandler = callback;
  }
}
