import React from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import {ProfileIcon} from '../icons';
import {isLightColour} from '../utils/colourUtil';

import colourStyles from '../../resources/styles/colours.module.scss';
import styles from './styles.module.scss';

type Name = string | undefined;

type AvatarProps = {
  name?: string;
  colour?: string;
  dataAutomation?: string;
  size?: 'default' | 'small' | 'large';
  withBorder?: boolean;
  isActive?: boolean;
};

const supportedCharacters =
  /^[\u0021-\u007a\u00c0-\u00ff\u0100-\u024f\u0370-\u03ff\u0400-\u04ff]/i;
const ignoredPunctuation =
  /[\u0021-\u002c\u002e-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007f]/g;

const checkInitialIsSupported = (initial: string) =>
  supportedCharacters.test(initial);

const checkInitials = (letters: Array<string>) => {
  for (let i = 0; i < letters.length; i++) {
    if (!checkInitialIsSupported(letters[i])) {
      return false;
    }
  }
  return letters.join('');
};

const getAvatarInitials = (name: Name) => {
  if (!name) {
    return false;
  }

  const nameParts = name
    .replaceAll(ignoredPunctuation, '')
    .split(/[\s-]/)
    .filter((item) => item !== '');

  if (nameParts.length === 0) {
    return false;
  }

  const initials: string[] = [];

  if (nameParts.length === 3) {
    nameParts.forEach((part) => {
      initials.push(part.substring(0, 1));
    });
  } else if (nameParts.length > 1) {
    initials.push(nameParts[0].substring(0, 1));
    initials.push(nameParts[nameParts.length - 1].substring(0, 1));
  } else {
    initials.push(nameParts[0].substring(0, 1));
  }

  return checkInitials(initials);
};

const getIconColourHex = (text: Name) => {
  if (!text) {
    return colourStyles.brandPrimaryDark;
  }

  const iconColours = [
    colourStyles.brandPrimary,
    colourStyles.aqua700,
    colourStyles.purple700,
    colourStyles.red700,
    colourStyles.blue700,
    colourStyles.brandPrimaryDark,
  ];
  const index = text.charCodeAt(text.length - 1) % iconColours.length;
  return iconColours[index];
};

const Avatar = ({
  colour,
  dataAutomation,
  name,
  size = 'default',
  withBorder,
  isActive = true,
}: AvatarProps): JSX.Element => {
  const iconText = getAvatarInitials(name);
  const avatarColour = !isActive
    ? colourStyles.grey300
    : colour || getIconColourHex(name);
  const textColour = isLightColour(avatarColour) ? 'textPrimary' : 'white';

  return (
    <div
      styleName={classNames('avatar', size, {
        'with-border': withBorder,
        'three-chars': iconText && iconText.length === 3,
      })}
      style={{backgroundColor: avatarColour}}
      data-automation={dataAutomation}
    >
      {iconText && isActive ? (
        <span styleName='avatar-text' style={{color: colourStyles[textColour]}}>
          {iconText}
        </span>
      ) : (
        <ProfileIcon colour={isActive ? textColour : 'textSecondary'} />
      )}
    </div>
  );
};
export default CSSModules(Avatar, styles, {allowMultiple: true});
