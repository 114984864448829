import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage} from 'react-intl';

import {Button, LegacyHeading, Link} from '@edume/magnificent';

import {LegacyIntlHeading} from '../core';

import styles from './styles.module.scss';

const BREAK_LINE = <br />;

const onReloadClick = () => {
  window.location.reload();
};

const addDivWrapper = (children, styleName) => (
  <div styleName={styleName}>{children}</div>
);

const getHeading = ({key, weight = 'regular', size = 'tiny'}) => (
  <LegacyIntlHeading {...{weight, size}} textKey={key} />
);

const getFormatted = ({key, colour = '', size = 'micro', ...rest}) => (
  <LegacyHeading {...{size, colour}}>
    <FormattedMessage id={key} values={rest} />
  </LegacyHeading>
);

const getTitle = (textKey) =>
  addDivWrapper(
    getHeading({
      key: textKey || 'Error.uncaught.title',
      weight: 'medium',
      size: 'small',
    }),
    'errorTitle'
  );

const getMessage = (textKey) =>
  addDivWrapper(
    getFormatted({key: textKey || 'Error.uncaught.message', br: BREAK_LINE}),
    'messageTextArea'
  );

const getButton = (onClickFunction) =>
  addDivWrapper(
    <Button onClick={onClickFunction} type='primary'>
      <FormattedMessage id='Error.uncaught.button' />
    </Button>,
    'retryButton'
  );

const getExtraInfo = () => {
  const supportEmail = 'support@edume.com';
  const email = (
    <Link
      linkSize='ant'
      linkColour='primary'
      text={supportEmail}
      href={`mailto:${supportEmail}`}
    />
  );

  return addDivWrapper(
    getFormatted({
      key: 'Error.uncaught.extraInfo',
      size: 'mini',
      colour: 'grey700',
      styleName: 'extraInfo',
      email,
    }),
    'extraInfo'
  );
};

const getErrorMessage = (eventId) => {
  if (eventId) {
    return getFormatted({
      key: 'Error.errorId',
      size: 'mini',
      colour: 'grey700',
      eventId,
    });
  }
  return null;
};

const Error = ({
  eventId = '',
  titleKey = '',
  messageKey = '',
  hasButton = true,
  hasExtraInfo = true,
}) => (
  <div styleName='contentWrapper'>
    <div styleName='content'>
      <img styleName='marginBottom' src='/resources/img/no_connection.svg' />
      {getTitle(titleKey)}
      {getMessage(messageKey)}
      {hasButton && getButton(onReloadClick)}
      {hasExtraInfo && getExtraInfo()}
      {eventId && getErrorMessage(eventId)}
    </div>
  </div>
);

Error.propTypes = {
  eventId: PropTypes.string,
  titleKey: PropTypes.string,
  messageKey: PropTypes.string,
  hasButton: PropTypes.bool,
  hasExtraInfo: PropTypes.bool,
};

export default CSSModules(Error, styles);
