export const GET_COURSE_SURVEYS = 'GET_COURSE_SURVEYS';
export const GET_COURSE_SURVEYS_SUCCESS = 'GET_COURSE_SURVEYS_SUCCESS';
export const GET_LESSON = 'GET_LESSON';
export const LOAD_LESSONS = 'LOAD_LESSONS';
export const START_EDITING_ACTIVITY = 'START_EDITING_ACTIVITY';
export const STOP_EDITING_ACTIVITY = 'STOP_EDITING_ACTIVITY';
export const START_EDITING_END = 'START_EDITING_END';
export const STOP_EDITING_END = 'STOP_EDITING_END';
export const STORE_LESSON_PREVIEW_TOKEN = 'STORE_LESSON_PREVIEW_TOKEN';
export const STORE_LESSON_PREVIEW_TOKEN_SUCCESS =
  'STORE_LESSON_PREVIEW_TOKEN_SUCCESS';
export const UPDATE_ESON = 'UPDATE_ESON';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_LESSON_FAIL = 'UPDATE_LESSON_FAIL';
export const UPDATE_LESSON_SUCCESS = 'UPDATE_LESSON_SUCCESS';
export const RESET_MODULE_ACTIVITY = 'RESET_MODULE_ACTIVITY';
export const VIEWED_PREVIEW_LINK = 'VIEWED_PREVIEW_LINK';
export const GET_EDURATING = 'GET_EDURATING';
export const GET_EDURATING_SUCCESS = 'GET_EDURATING_SUCCESS';
export const GET_EDURATING_FAIL = 'GET_EDURATING_FAIL';
export const GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT =
  'GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT';
export const GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_SUCCESS =
  'GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_SUCCESS';
export const GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_FAIL =
  'GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_FAIL';
export const GENERATE_LESSONS_FROM_TOPICS = 'GENERATE_LESSONS_FROM_TOPICS';
export const GENERATE_LESSONS_FROM_TOPICS_SUCCESS =
  'GENERATE_LESSONS_FROM_TOPICS_SUCCESS';
export const GENERATE_LESSONS_FROM_TOPICS_FAIL =
  'GENERATE_LESSONS_FROM_TOPICS_FAIL';
export const RESET_GENERATED_SUGGESTIONS = 'RESET_GENERATED_SUGGESTIONS';
