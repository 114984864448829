import React, {useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {Text} from '@edume/magnificent';

import styles from './styles.module.scss';

const ActivityCard = ({
  option,
  hasBackgroundStyles = true,
  titleSize = 'l',
  subtitleColour = 'textPrimary',
  hasPurpleHoverEffect = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    activityTitle,
    activitySubtitle,
    imageUrl,
    hoveredImageUrl,
    onSelect,
    dataAutomation,
    disabled,
    disabledDescription,
  } = option;

  return (
    <div data-tip={disabled} data-for={disabled ? 'disabledTooltip' : null}>
      <button
        styleName={classNames('option', {
          disabled,
          'background-styles': hasBackgroundStyles,
          'purple-hover-effect': hasPurpleHoverEffect,
        })}
        onClick={!disabled && onSelect}
        onMouseOver={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-automation={dataAutomation}
      >
        <img src={isHovered ? hoveredImageUrl : imageUrl} styleName='image' />
        <div styleName='headingsContainer'>
          <Text size={titleSize} weight='medium'>
            {activityTitle}
          </Text>
          <Text size='s' colour={subtitleColour}>
            {activitySubtitle}
          </Text>
        </div>
      </button>
      {disabled && (
        <ReactTooltip
          id='disabledTooltip'
          place='bottom'
          className='overlay-tooltip arrow-bottom'
        >
          <Text size='s' weight='medium' colour='white'>
            {disabledDescription}
          </Text>
        </ReactTooltip>
      )}
    </div>
  );
};

ActivityCard.propTypes = {
  option: PropTypes.object.isRequired,
  hasBackgroundStyles: PropTypes.bool,
  titleSize: PropTypes.oneOf(['l', 'm']),
  subtitleColour: PropTypes.oneOf(['textPrimary', 'grey700']),
  hasPurpleHoverEffect: PropTypes.bool,
};

export default injectIntl(
  CSSModules(ActivityCard, styles, {allowMultiple: true})
);
