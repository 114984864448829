import React from 'react';

import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import requiredIf from 'react-required-if';

import * as hints from '@edume/bento/hints';
import {HintBox} from '@edume/magnificent';

const Hint = ({
  hintId,
  prerequisite,
  hintContent,
  trianglePosition,
  icon,
  leftTextKey,
  visible,
  dismissHint,
  undismissPrerequisite,
  buttonKey,
  buttonDarkIcon,
  buttonLightIcon,
  linkTextKey,
  textValues,
  intl,
}) => {
  if (!visible) {
    return null;
  }

  const hint = hints[hintId];

  const titleText = intl.formatMessage({id: `Hints.${hint}.title`});
  const descriptionText =
    !hintContent &&
    intl.formatMessage({id: `Hints.${hint}.description`}, textValues);
  const buttonText =
    buttonKey && intl.formatMessage({id: `Button.${buttonKey}`});
  const leftText = leftTextKey && intl.formatMessage({id: leftTextKey});
  const linkText = linkTextKey && intl.formatMessage({id: linkTextKey});

  return (
    <HintBox
      title={titleText}
      text={descriptionText}
      content={hintContent}
      showButton
      onButtonClick={(event) => {
        event.stopPropagation();
        dismissHint(hintId, true);
      }}
      buttonText={buttonText}
      buttonDarkIcon={buttonDarkIcon}
      buttonLightIcon={buttonLightIcon}
      buttonType='thirdary'
      icon={leftText ? null : icon}
      leftText={leftText}
      trianglePosition={trianglePosition}
      showCheckBox={false}
      showLink={!!linkTextKey}
      linkText={linkText}
      linkAction={
        prerequisite ? undismissPrerequisite : () => dismissHint(hintId)
      }
      buttonDataAutomation={`hint-button-${hintId}`
        .toLowerCase()
        .replace(/[^a-zA-Z]/g, '-')}
    />
  );
};

Hint.propTypes = {
  hintId: PropTypes.string.isRequired,
  prerequisite: PropTypes.string,
  hintContent: PropTypes.element,
  trianglePosition: PropTypes.string.isRequired,
  icon: PropTypes.string,
  leftTextKey: PropTypes.string,
  visible: PropTypes.bool,
  dismissHint: PropTypes.func,
  undismissPrerequisite: requiredIf(
    PropTypes.func,
    (props) => props.prerequisite
  ),
  linkTextKey: PropTypes.string,
  buttonKey: PropTypes.string,
  buttonDarkIcon: PropTypes.string,
  buttonLightIcon: PropTypes.string,
  textValues: PropTypes.object,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Hint);
