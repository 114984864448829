import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Button} from '@edume/magnificent';

import {LegacyIntlHeading} from '../../core';

import styles from './styles.module.scss';

const TrialEndFooter = ({intl}) => {
  const keyPrefix = 'Footer.trialEnd.';

  const contactButtonText = intl.formatMessage({
    id: keyPrefix + 'contactButton',
  });

  return (
    <div styleName='trial-end-footer'>
      <div styleName='left-container'>
        <div styleName='title-container'>
          <LegacyIntlHeading size='small' textKey={keyPrefix + 'heading'} />
        </div>
        <LegacyIntlHeading size='mini' textKey={keyPrefix + 'body'} />
      </div>
      <div>
        <Button
          type='thirdary'
          onClick={() => {
            window.open('https://www.edume.com/book-a-meeting', '_blank');
          }}
          noMarginTop
        >
          {contactButtonText}
        </Button>
      </div>
    </div>
  );
};

TrialEndFooter.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(TrialEndFooter, styles));
