import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

const HintUnderlay = ({visible}) => visible && <div styleName='underlay' />;

HintUnderlay.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default CSSModules(HintUnderlay, styles, {allowMultiple: true});
