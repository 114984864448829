/* eslint-disable complexity */
import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
  AddFolderIcon,
  Button,
  DeleteIcon,
  Dropdown,
  Heading,
  IconButton,
  LoadingIndicator,
  PenIcon,
  Text,
} from '@edume/magnificent';

import {
  CONTENT_FILTERS,
  SIDEBAR_TABS,
} from '../../state/course/courseActionTypes';
import {goToKnowledgeHub} from '../../state/knowledgeHub/knowledgeHubActions';
import {getHasSingleV2KnowledgeHub} from '../../state/knowledgeHub/knowledgeHubSelector';
import {sortContentByCreationDate} from '../../utils/courseUtil';
import {injectFeatureFlags} from '../../utils/featureFlags';
import AddKnowledgeHubView from '../../views/KnowledgeHubs';
import CreateContentFlow from '../Content/CreateContentFlow';
import {Dialog} from '../core';
import Tabs from '../core/Tabs';
import CreateEditCourse from '../Course/CreateEditCourse';
import AddNewGuideSlidePanel from '../Guide/AddNewGuideSlidePanel';
import AddEditCollectionModal from './AddEditCollectionModal';
import ContentListSlidePanel from './ContentListSlidePanel';
import CourseList from './CourseList';
import KnowledgeHubList from './KnowledgeHubList';
import LearningTabHeader from './LearningTabHeader';
import Sidebar, {COLLECTION_CONTAINER_ID} from './Sidebar';

import styles from './styles.module.scss';

export const COLLECTION_PREFIX = `${SIDEBAR_TABS.COLLECTIONS}-`;

// Could be extended to different filter types if needed
const CONTENT_FILTER_FUNCTIONS = {
  [CONTENT_FILTERS.ALL]: () => true,
  [CONTENT_FILTERS.DRAFT]: (content) => !content.published,
  [CONTENT_FILTERS.PUBLISHED]: (content) => content.published,
};

// eslint-disable-next-line max-statements
const LearningTab = ({
  courses,
  featureFlags: {
    resourceLibraryTabEnabled,
    imageCourseCardEnabled,
    demoContentEnabled,
    aiGuideGenerationEnabled,
    contentCollectionsEnabled,
    aiContentHelperEnabled,
  },
  selectedLearningTabFilter,
  clearKnowledgeHubId,
  searchCourse,
  groups,
  teams,
  selectGroup,
  goToContent,
  copyContent,
  copyDemoCourseToCustomer,
  groupId,
  updateCourseDetails,
  editCourseInProgress,
  hasCourseDuplicationFailed,
  resourceLibraryCourses,
  demoContentCourses,
  adminLanguage,
  isSeamlessEnabled,
  knowledgeHubs,
  coursesLoaded,
  resourceLibraryCoursesLoaded,
  knowledgeHubsLoaded,
  demoContentCoursesLoaded,
  getResourceLibraryCourses,
  getDemoContentCourses,
  createErrorNotification,
  trackIdCopied,
  lastCopiedCourse,
  activeSidebarItem,
  setActiveSidebarItem,
  activeContentFilter,
  setActiveContentFilter,
  sendSetCourseCardDesignEvent,
  setIsUserAddingContent,
  isUserAddingContent,
  setIsUserAddingCourse,
  isUserAddingCourse,
  collections,
  createCollection,
  renameCollection,
  deleteCollection,
  updateContentInCollection,
  isKnowledgeHubsEnabled,
  guidesInLearningTabEnabled,
  setIsUserAddingGuide,
  isUserAddingGuide,
  intl,
}) => {
  const [searchResults, setSearchResults] = useState(null);
  const [courseSearchValue, setCourseSearchValue] = useState('');
  const [isImageCourseCardEnabled, setIsImageCourseCardEnabled] = useState(
    localStorage.getItem('isImageCourseCardEnabled')
      ? localStorage.getItem('isImageCourseCardEnabled') === 'true'
      : imageCourseCardEnabled
  );
  const [showKnowledgeHubModal, setShowKnowledgeHubModal] = useState(false);
  const [isAddEditCollectionModalVisible, setIsAddEditCollectionModalVisible] =
    useState(null);
  const [isDeleteCollectionModalVisible, setIsDeleteCollectionModalVisible] =
    useState(false);
  const [showContentListSlidePanel, setShowContentListSlidePanel] =
    useState(false);

  const dispatch = useDispatch();

  const isResourceLibraryTabActive =
    activeSidebarItem === SIDEBAR_TABS.RESOURCE_LIBRARY;
  const isKnowledgeHubsTabActive =
    activeSidebarItem === SIDEBAR_TABS.KNOWLEDGE_HUBS;
  const isDemoContentTabActive =
    activeSidebarItem === SIDEBAR_TABS.DEMO_CONTENT;
  const isCollectionsTabActive = activeSidebarItem.includes(COLLECTION_PREFIX);
  const isTemplatesTabActive = activeSidebarItem === SIDEBAR_TABS.TEMPLATES;
  const hasFiltersVisible =
    activeSidebarItem === SIDEBAR_TABS.STANDARD_CONTENT ||
    isCollectionsTabActive;

  useEffect(() => {
    if (!resourceLibraryCoursesLoaded && resourceLibraryTabEnabled) {
      getResourceLibraryCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceLibraryCoursesLoaded]);

  useEffect(() => {
    if (!demoContentCoursesLoaded && demoContentEnabled) {
      getDemoContentCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoContentCoursesLoaded]);

  const getCollection = () => {
    const activeCollectionId = +activeSidebarItem.substring(
      COLLECTION_PREFIX.length
    );
    return collections.find(
      (collection) => collection.id === activeCollectionId
    );
  };

  const getContent = (isSorted = true) => {
    const sortContent = (contentList) =>
      isSorted ? sortContentByCreationDate(contentList, 'title') : contentList;

    if (activeSidebarItem.includes(COLLECTION_PREFIX)) {
      const activeCollection = getCollection();
      const collectionCourses = courses.filter((course) =>
        activeCollection?.contentIds?.includes(course.id)
      );
      return sortContent(collectionCourses);
    }

    switch (activeSidebarItem) {
      case SIDEBAR_TABS.RESOURCE_LIBRARY:
        return resourceLibraryCourses;
      case SIDEBAR_TABS.DEMO_CONTENT:
        return demoContentCourses;
      case SIDEBAR_TABS.TEMPLATES:
        return sortContent(templateContent);
      case SIDEBAR_TABS.STANDARD_CONTENT: {
        return sortContent(nonTemplateContent);
      }
      default:
        return [];
    }
  };

  const nonTemplateContent = courses?.filter((course) => !course.isTemplate);
  const templateContent = courses?.filter((course) => course.isTemplate);

  const currentContent = searchResults ?? getContent();
  const contentToDisplay = !hasFiltersVisible
    ? currentContent
    : currentContent?.filter(CONTENT_FILTER_FUNCTIONS[activeContentFilter]);

  const updateTabCourseCounts = useCallback(
    () => ({
      [SIDEBAR_TABS.STANDARD_CONTENT]: nonTemplateContent?.length,
      [SIDEBAR_TABS.TEMPLATES]: templateContent?.length,
      [SIDEBAR_TABS.RESOURCE_LIBRARY]: resourceLibraryCourses?.length,
      [SIDEBAR_TABS.KNOWLEDGE_HUBS]: knowledgeHubs?.length,
      [SIDEBAR_TABS.DEMO_CONTENT]: demoContentCourses?.length,
      [CONTENT_FILTERS.ALL]: currentContent?.length,
      [CONTENT_FILTERS.DRAFT]: currentContent?.filter(
        (content) => !content.published
      )?.length,
      [CONTENT_FILTERS.PUBLISHED]: currentContent?.filter(
        (content) => content.published
      )?.length,
    }),
    [
      nonTemplateContent,
      templateContent?.length,
      demoContentCourses?.length,
      knowledgeHubs?.length,
      resourceLibraryCourses?.length,
      currentContent,
    ]
  );

  const tabCourseCounts = updateTabCourseCounts();

  const collectionItems = collections?.map((collection) => ({
    id: collection.id,
    name: collection.name,
    onClick: () => setActiveSidebarItem(`${COLLECTION_PREFIX}${collection.id}`),
    isActive: activeSidebarItem === `${COLLECTION_PREFIX}${collection.id}`,
  }));

  const creationSidebarItems = [
    {
      name: SIDEBAR_TABS.STANDARD_CONTENT,
      automationTag: 'all-content-tab',
      count: tabCourseCounts[SIDEBAR_TABS.STANDARD_CONTENT],
      onClick: () => {
        onSidebarItemClick(SIDEBAR_TABS.STANDARD_CONTENT);
      },
      isActive: activeSidebarItem === SIDEBAR_TABS.STANDARD_CONTENT,
    },
    {
      name: SIDEBAR_TABS.TEMPLATES,
      automationTag: 'templates-tab',
      count: tabCourseCounts[SIDEBAR_TABS.TEMPLATES],
      onClick: () => onSidebarItemClick(SIDEBAR_TABS.TEMPLATES),
      isActive: activeSidebarItem === SIDEBAR_TABS.TEMPLATES,
    },
  ];
  if (contentCollectionsEnabled) {
    creationSidebarItems.splice(1, 0, {
      name: SIDEBAR_TABS.COLLECTIONS,
      automationTag: 'collections-tab',
      onClick: () => null,
      children: collectionItems,
    });
  }
  if (resourceLibraryTabEnabled) {
    creationSidebarItems.push({
      name: SIDEBAR_TABS.RESOURCE_LIBRARY,
      automationTag: 'edume-resources-tab',
      count: tabCourseCounts[SIDEBAR_TABS.RESOURCE_LIBRARY],
      onClick: () => onSidebarItemClick(SIDEBAR_TABS.RESOURCE_LIBRARY),
      isActive: activeSidebarItem === SIDEBAR_TABS.RESOURCE_LIBRARY,
    });
  }
  if (demoContentEnabled) {
    creationSidebarItems.push({
      name: SIDEBAR_TABS.DEMO_CONTENT,
      automationTag: 'demo-content-tab',
      count: tabCourseCounts[SIDEBAR_TABS.DEMO_CONTENT],
      onClick: () => onSidebarItemClick(SIDEBAR_TABS.DEMO_CONTENT),
      isActive: activeSidebarItem === SIDEBAR_TABS.DEMO_CONTENT,
    });
  }

  const hasSingleV2KnowledgeHub = useSelector(getHasSingleV2KnowledgeHub);
  const curationSidebarItems = [
    {
      name: SIDEBAR_TABS.KNOWLEDGE_HUBS,
      automationTag: 'knowledge-hubs-tab',
      count: hasSingleV2KnowledgeHub
        ? null
        : tabCourseCounts[SIDEBAR_TABS.KNOWLEDGE_HUBS],
      onClick: () => dispatch(goToKnowledgeHub()),
      isActive: activeSidebarItem === SIDEBAR_TABS.KNOWLEDGE_HUBS,
    },
  ];

  const statusFilters = Object.keys(CONTENT_FILTER_FUNCTIONS).map((name) => ({
    name,
    title: `${intl.formatMessage({
      id: `LearningTab.${name}`,
    })}${coursesLoaded ? ` (${tabCourseCounts[name]})` : ''}`,
    dataAutomation: `content-status-filter-option`,
    callback: () => setActiveContentFilter(name),
  }));

  const onSidebarItemClick = (itemName) => {
    setCourseSearchValue('');
    setSearchResults(null);
    setActiveSidebarItem(itemName);

    selectedLearningTabFilter(itemName); // Amplitude event
  };

  const toggleKnowledgeHubModal = () => {
    setShowKnowledgeHubModal(!showKnowledgeHubModal);
    clearKnowledgeHubId();
  };

  const clearCourseSearch = () => {
    setSearchResults(null);
  };

  const openAddContentPanel = () => {
    setIsUserAddingContent(true);
    clearKnowledgeHubId();
  };

  const closeAddContentPanel = () => {
    setIsUserAddingContent(false);
  };

  const openAddCoursePanel = () => {
    setIsUserAddingCourse(true);
  };

  const closeAddCoursePanel = () => {
    setIsUserAddingCourse(false);
  };

  const openAddGuidePanel = () => {
    setIsUserAddingGuide(true);
  };

  const toggleImageCourseCard = (enabled) => {
    localStorage.setItem('isImageCourseCardEnabled', enabled);
    setIsImageCourseCardEnabled(enabled);

    // Amplitude event
    sendSetCourseCardDesignEvent({
      isImageCourseCardEnabled: enabled,
    });
  };

  const knowledgeHubCardProps = {
    teams,
    createErrorNotification,
    trackIdCopied,
    isSeamlessEnabled,
  };

  const listContent = isKnowledgeHubsTabActive ? (
    <KnowledgeHubList
      knowledgeHubs={knowledgeHubs}
      knowledgeHubCardProps={knowledgeHubCardProps}
      toggleKnowledgeHubModal={toggleKnowledgeHubModal}
      groups={groups}
      groupId={groupId}
    />
  ) : (
    <CourseList
      filteredCourses={contentToDisplay ?? []}
      courseSearchValue={courseSearchValue}
      setCourseSearchValue={setCourseSearchValue}
      setSearchResults={setSearchResults}
      searchCourse={searchCourse}
      groups={groups}
      groupId={groupId}
      selectGroup={selectGroup}
      updateCourseDetails={updateCourseDetails}
      hasTemplatesFilter={isTemplatesTabActive}
      openAddCoursePanel={openAddCoursePanel}
      goToContent={goToContent}
      copyContent={copyContent}
      copyDemoCourseToCustomer={copyDemoCourseToCustomer}
      editCourseInProgress={editCourseInProgress}
      adminLanguage={adminLanguage}
      hasCourseDuplicationFailed={hasCourseDuplicationFailed}
      isImageCourseCardEnabled={isImageCourseCardEnabled}
      isResourceLibraryTabActive={isResourceLibraryTabActive}
      isDemoContentTabActive={isDemoContentTabActive}
      lastCopiedCourse={lastCopiedCourse}
      isCollectionTabActive={isCollectionsTabActive}
      setShowContentListSlidePanel={() => setShowContentListSlidePanel(true)}
      openAddGuideModal={openAddGuidePanel}
      openAddCourseModal={openAddCoursePanel}
      guidesInLearningTabEnabled={guidesInLearningTabEnabled}
    />
  );

  const renderTabTitleContent = CSSModules(
    ({title, identifier}) => (
      <div styleName='mobile-tab-title'>
        <Heading as='h3' size='cat' weight='medium'>
          {title}
        </Heading>

        <Text
          size='m'
          colour='grey700'
          weight='medium'
          dataAutomation='active-tab-count'
        >
          {tabCourseCounts?.[identifier]}
        </Text>
      </div>
    ),
    styles
  );

  const renderAddEditCollectionModal = () => {
    if (isAddEditCollectionModalVisible) {
      const isEditing = isAddEditCollectionModalVisible === 'edit';
      const onSave = (newTitle) => {
        if (isEditing) {
          renameCollection(getCollection().id, newTitle);
        } else {
          createCollection(newTitle);
          // forwardRef doesn't work with react-css-modules, so using vanilla JS
          document.getElementById(COLLECTION_CONTAINER_ID)?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
        setIsAddEditCollectionModalVisible(null);
      };
      return (
        <AddEditCollectionModal
          onClose={() => {
            setIsAddEditCollectionModalVisible(null);
          }}
          onSave={onSave}
          isEditing={isEditing}
          collectionName={getCollection()?.name}
        />
      );
    }
    return <div />;
  };

  const renderSlidePanel = () => {
    if (isUserAddingContent) {
      return (
        <>
          <CreateContentFlow onClose={closeAddContentPanel} />
          <div />
        </>
      );
    }
    if (isUserAddingCourse) {
      return (
        <>
          <CreateEditCourse onClose={closeAddCoursePanel} />
          <div />
        </>
      );
    }
    if (isAddEditCollectionModalVisible) {
      return renderAddEditCollectionModal();
    }
    if (isDeleteCollectionModalVisible) {
      return (
        <Dialog
          titleKey='Learning.deleteCollection'
          descriptionKey='Learning.deleteCollectionDescription'
          onCancel={() => setIsDeleteCollectionModalVisible(false)}
          onConfirm={() => {
            deleteCollection(getCollection().id);
            setIsDeleteCollectionModalVisible(false);
            setActiveSidebarItem(SIDEBAR_TABS.STANDARD_CONTENT);
          }}
          cancelKey='Lesson.deleteDialog.cancel'
          confirmKey='Learning.deleteCollectionConfirm'
          disableConfirmButton={false}
          confirmDataAutomation='confirm-delete-collection-button'
          cancelDataAutomation='cancel-delete-collection-button'
          showIcon={false}
        />
      );
    }
    if (isUserAddingGuide) {
      return (
        <>
          <AddNewGuideSlidePanel
            onClosePopover={() => setIsUserAddingGuide(false)}
            aiGuideGenerationEnabled={aiGuideGenerationEnabled}
          />
          <div />
        </>
      );
    }
    if (showKnowledgeHubModal) {
      return (
        <>
          <AddKnowledgeHubView onClose={toggleKnowledgeHubModal} />
          <div />
        </>
      );
    }
    return <div />;
  };

  const renderMainPanelContent = () => {
    const renderEmptyMessage = (textKey) => (
      <div styleName='empty-message-container'>
        <Text size='s' dataAutomation='no-results-found-text'>
          {intl.formatMessage({
            id: textKey,
          })}
        </Text>
      </div>
    );

    if (courseSearchValue && contentToDisplay?.length === 0) {
      return renderEmptyMessage('SelectCourse.search.noResultsMessage');
    }

    if (
      activeSidebarItem.includes(COLLECTION_PREFIX) &&
      contentToDisplay.length === 0 &&
      currentContent?.length
    ) {
      // Only show this message if the collection contains content but the current status filter returns no results
      return renderEmptyMessage('Learning.collections.noResultsMessage');
    }

    return listContent;
  };

  const searchResultsHeadingText = intl.formatMessage({
    id: 'SelectGroup.search.resultsHeader',
  });

  const sharedViewToggleButtonProps = {
    iconAlt: '',
    type: 'custom',
    customColour: '#ffffff00',
    customDarkColour: '#ffffff00',
    noMarginTop: true,
    noMarginLeft: true,
    role: 'menuitem',
  };

  const sharedTooltipProps = {
    effect: 'solid',
    place: 'bottom',
    type: 'dark',
  };

  return (
    <div styleName='page-container'>
      {/* Courses Header */}
      <Heading as='h1' size='leopard' weight='medium'>
        {intl.formatMessage({id: 'Header.content'})}
      </Heading>
      <LearningTabHeader
        courses={courses}
        searchCourse={searchCourse}
        openAddContentPanel={openAddContentPanel}
        clearCourseSearch={clearCourseSearch}
        courseSearchValue={courseSearchValue}
        setCourseSearchValue={setCourseSearchValue}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        setActiveSidebarItem={setActiveSidebarItem}
        setActiveContentFilter={setActiveContentFilter}
        goToContent={goToContent}
        aiContentHelperEnabled={aiContentHelperEnabled}
      />

      {renderSlidePanel()}

      {/* Desktop sidebar */}
      <Sidebar
        creationSidebarItems={creationSidebarItems}
        curationSidebarItems={curationSidebarItems}
        setIsAddEditCollectionModalVisible={setIsAddEditCollectionModalVisible}
      />

      {/* Mobile sidebar */}
      <Tabs
        tabs={[
          ...creationSidebarItems.flatMap((item) => {
            if (item.children) {
              return item.children.map((child) => ({
                title: child.name,
                titleContent: renderTabTitleContent({
                  title: child.name,
                  identifier: `${COLLECTION_PREFIX}${child.id}`,
                }),
                onClick: child.onClick,
                dataAutomation: `${item.automationTag}-mobile`,
              }));
            }
            const title = intl.formatMessage({
              id: `Learning.sidebar.${item.name}`,
            });
            return {
              title,
              titleContent: renderTabTitleContent({
                title,
                identifier: item.name,
              }),
              onClick: item.onClick,
              dataAutomation: `${item.automationTag}-mobile`,
            };
          }),
          ...(isKnowledgeHubsEnabled
            ? curationSidebarItems.map((item) => {
                const title = intl.formatMessage({
                  id: `Learning.sidebar.${item.name}`,
                });
                return {
                  title,
                  titleContent: renderTabTitleContent({
                    title,
                    identifier: item.name,
                  }),
                  onClick: item.onClick,
                  dataAutomation: `${item.automationTag}-mobile`,
                };
              })
            : []),
        ]}
        isMobileMenuOnly
      />

      {/* Rendering course list */}
      <div
        styleName={`courses-list
        ${courseSearchValue ? ' search' : ''}`}
      >
        {(!contentToDisplay && !isKnowledgeHubsTabActive) ||
        (isResourceLibraryTabActive && !resourceLibraryCoursesLoaded) ||
        (isKnowledgeHubsTabActive && !knowledgeHubsLoaded) ||
        (isDemoContentTabActive && !demoContentCoursesLoaded) ? (
          <LoadingIndicator
            indicator='hexagons'
            containerStyle='centerAbsolute'
          />
        ) : (
          <>
            {!isKnowledgeHubsTabActive && (
              <div styleName='content-list-header'>
                {hasFiltersVisible && (
                  <Dropdown
                    type='thirdary'
                    selectedIndex={statusFilters.findIndex(
                      ({name}) => name === activeContentFilter
                    )}
                    iconPosition='end'
                    options={statusFilters}
                    hasPopoverBelow
                    dataAutomation='content-status-filter-dropdown'
                  />
                )}
                {isCollectionsTabActive && (
                  <div styleName='content-collections-header'>
                    <Heading
                      as='h2'
                      size='cat'
                      weight='medium'
                      dataAutomation='collection-title'
                    >
                      {getCollection().name}
                    </Heading>
                    <IconButton
                      icon={<AddFolderIcon colour='brandPrimary' />}
                      showBorder={false}
                      handleClick={() => setShowContentListSlidePanel(true)}
                      dataAutomation='manage-collection-button'
                    />
                    <IconButton
                      icon={<PenIcon colour='brandPrimary' />}
                      showBorder={false}
                      handleClick={() =>
                        setIsAddEditCollectionModalVisible('edit')
                      }
                      dataAutomation='rename-collection-button'
                    />
                    <IconButton
                      icon={<DeleteIcon colour='brandPrimary' />}
                      showBorder={false}
                      handleClick={() =>
                        setIsDeleteCollectionModalVisible(true)
                      }
                      dataAutomation='delete-collection-button'
                    />
                  </div>
                )}
                <div
                  styleName='image-course-card-toggle'
                  role='menu'
                  aria-label={intl.formatMessage({id: 'Learning.viewOptions'})}
                >
                  <span
                    data-tip
                    data-for='gridViewTooltip'
                    styleName='display-options-item'
                  >
                    <Button
                      {...sharedViewToggleButtonProps}
                      iconUrl={
                        isImageCourseCardEnabled
                          ? 'resources/img/20_courses_grid_view_blue.svg'
                          : 'resources/img/20_courses_grid_view_grey.svg'
                      }
                      onClick={() => toggleImageCourseCard(true)}
                      dataAutomation='image-course-card-view'
                      aria-disabled={isImageCourseCardEnabled}
                      aria-labelledby='gridViewTooltip'
                    />
                    <ReactTooltip
                      {...sharedTooltipProps}
                      id={'gridViewTooltip'}
                      className='overlay-tooltip'
                    >
                      {intl.formatMessage({
                        id: 'Learning.gridView',
                      })}
                    </ReactTooltip>
                  </span>
                  <span
                    data-tip
                    data-for='listViewTooltip'
                    styleName='display-options-item'
                  >
                    <Button
                      {...sharedViewToggleButtonProps}
                      iconUrl={
                        !isImageCourseCardEnabled
                          ? 'resources/img/20_courses_row_view_blue.svg'
                          : 'resources/img/20_courses_row_view_grey.svg'
                      }
                      onClick={() => toggleImageCourseCard(false)}
                      dataAutomation='row-course-card-view'
                      aria-disabled={!isImageCourseCardEnabled}
                      aria-labelledby='listViewTooltip'
                    />
                    <ReactTooltip
                      {...sharedTooltipProps}
                      id={'listViewTooltip'}
                      className='overlay-tooltip'
                    >
                      {intl.formatMessage({
                        id: 'Learning.listView',
                      })}
                    </ReactTooltip>
                  </span>
                </div>
              </div>
            )}

            {courseSearchValue && (
              <div styleName='search-header'>
                <Text size='s' dataAutomation='search-heading'>
                  {searchResultsHeadingText}
                </Text>
              </div>
            )}

            <div
              styleName={`course-list-inner${
                isKnowledgeHubsTabActive ? ' knowledge-hubs' : ''
              }`}
            >
              {renderMainPanelContent()}
            </div>
          </>
        )}

        {/* Course list slide panel (used by collections) */}
        {showContentListSlidePanel && (
          <ContentListSlidePanel
            contentList={nonTemplateContent}
            assignedContentIds={
              collections.find(
                (collection) => collection.id === getCollection()?.id
              )?.contentIds ?? []
            }
            addContent={(c) => {
              if (activeContentFilter !== CONTENT_FILTERS.ALL) {
                setActiveContentFilter(CONTENT_FILTERS.ALL);
              }
              updateContentInCollection({
                collectionId: getCollection()?.id,
                contentToAdd: {contentId: c.id, contentType: c.contentType},
              });
            }}
            removeContent={(c) => {
              updateContentInCollection({
                collectionId: getCollection()?.id,
                contentToRemove: {
                  contentId: c.id,
                  contentType: c.contentType,
                },
              });
            }}
            dataAutomation='slide-out-content-cards-container'
            setShowContentListSlidePanel={setShowContentListSlidePanel}
          />
        )}
      </div>
    </div>
  );
};

LearningTab.propTypes = {
  updateCourseDetails: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  selectGroup: PropTypes.func.isRequired,
  goToContent: PropTypes.func.isRequired,
  copyContent: PropTypes.func.isRequired,
  copyDemoCourseToCustomer: PropTypes.func.isRequired,
  groupId: PropTypes.number,
  courses: PropTypes.array.isRequired,
  searchCourse: PropTypes.func,
  getResourceLibraryCourses: PropTypes.func,
  coursesLoaded: PropTypes.bool.isRequired,
  resourceLibraryCourses: PropTypes.array.isRequired,
  resourceLibraryCoursesLoaded: PropTypes.bool.isRequired,
  demoContentCourses: PropTypes.array.isRequired,
  getDemoContentCourses: PropTypes.func.isRequired,
  demoContentCoursesLoaded: PropTypes.bool.isRequired,
  addKnowledgeHub: PropTypes.func.isRequired,
  clearKnowledgeHubId: PropTypes.func.isRequired,
  selectedLearningTabFilter: PropTypes.func.isRequired,
  lastCopiedCourse: PropTypes.object,
  setActiveSidebarItem: PropTypes.func.isRequired,
  setActiveContentFilter: PropTypes.func.isRequired,
  sendSetCourseCardDesignEvent: PropTypes.func.isRequired,
  editCourseInProgress: PropTypes.bool.isRequired,
  hasCourseDuplicationFailed: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  featureFlags: PropTypes.object.isRequired,
  adminLanguage: PropTypes.string.isRequired,
  isSeamlessEnabled: PropTypes.bool.isRequired,
  knowledgeHubsLoaded: PropTypes.bool.isRequired,
  knowledgeHubs: PropTypes.array.isRequired,
  knowledgeHubId: PropTypes.number,
  createErrorNotification: PropTypes.func.isRequired,
  trackIdCopied: PropTypes.func.isRequired,
  activeSidebarItem: PropTypes.number.isRequired,
  activeContentFilter: PropTypes.number.isRequired,
  setIsUserAddingContent: PropTypes.func.isRequired,
  isUserAddingContent: PropTypes.bool.isRequired,
  setIsUserAddingCourse: PropTypes.func.isRequired,
  isUserAddingCourse: PropTypes.bool.isRequired,
  setIsUserAddingGuide: PropTypes.func.isRequired,
  isUserAddingGuide: PropTypes.bool.isRequired,
  collections: PropTypes.array.isRequired,
  createCollection: PropTypes.func.isRequired,
  renameCollection: PropTypes.func.isRequired,
  deleteCollection: PropTypes.func.isRequired,
  updateContentInCollection: PropTypes.func.isRequired,
  isKnowledgeHubsEnabled: PropTypes.bool.isRequired,
  guidesInLearningTabEnabled: PropTypes.bool.isRequired,
  aiContentHelperEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
};

LearningTab.defaultProps = {
  showTeamInfo: true,
  openAddTeamOnMount: false,
};

export default injectIntl(
  injectFeatureFlags(
    withRouter(
      React.memo(CSSModules(LearningTab, styles, {allowMultiple: true}))
    )
  )
);
