import React, {useRef, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {AddFolderIcon, LoadingIndicator} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';
import DuplicateContent from '../../Content/DuplicateContent';
import {ContentList, Hint} from '../../core';
import Course from '../../Course';
import CreateEditCourse from '../../Course/CreateEditCourse';
import GenericEmptyScreen from '../../GenericEmptyScreen';
import EmptyScreenButtons from '../EmptyScreenButtons';

import styles from './styles.module.scss';

const CARD_ITEM_HEIGHT = 300;
const CARD_ITEM_WIDTH = 320;
const CARD_ITEM_PADDING = 52;
const LIST_ITEM_HEIGHT = 130;
const LIST_ITEM_PADDING = 10;

const CourseList = ({
  filteredCourses,
  isImageCourseCardEnabled,
  updateCourseDetails,
  groupId,
  groups,
  selectGroup,
  goToContent,
  copyContent,
  hasCourseDuplicationFailed,
  editCourseInProgress,
  isResourceLibraryTabActive,
  isDemoContentTabActive,
  courseSearchValue,
  setCourseSearchValue,
  setSearchResults,
  openAddCoursePanel,
  hasTemplatesFilter,
  adminLanguage,
  searchCourse,
  lastCopiedCourse,
  intl,
  copyDemoCourseToCustomer,
  isCollectionTabActive,
  setShowContentListSlidePanel,
  guidesInLearningTabEnabled,
  openAddGuideModal,
  openAddCourseModal,
}) => {
  const listRef = useRef(null);
  const rowHeights = useRef({});

  const [editingCourse, setEditingCourse] = useState(null);
  const [copyingCourse, setCopyingCourse] = useState(null);

  const coursesLoaded = useSelector((state) =>
    state.getIn(['course', 'coursesLoaded'])
  );

  const getRowHeight = (index) => {
    if (isImageCourseCardEnabled) {
      return rowHeights.current[index] + CARD_ITEM_PADDING || CARD_ITEM_HEIGHT;
    } else {
      return rowHeights.current[index] + LIST_ITEM_PADDING || LIST_ITEM_HEIGHT;
    }
  };

  const setRowHeight = (index, size) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    rowHeights.current = {...rowHeights.current, [index]: size};
  };

  const getNoCoursesContent = () => {
    if (hasTemplatesFilter) {
      return (
        <GenericEmptyScreen
          imageUrl={'/resources/img/24_mortaboard.svg'}
          title={intl.formatMessage({id: 'Learning.noCourses.templatesTitle'})}
          description={intl.formatMessage({
            id: 'Learning.noCourses.templatesBody',
          })}
          hasPermission={false}
          linkTextKey='Learning.noCourses.helpLink'
          linkHref='https://help.edume.com/creating-and-using-course-templates'
          headerDataAutomation='no-courses-header'
          fullWidthImage={false}
        />
      );
    }

    if (isCollectionTabActive) {
      return (
        <div styleName='empty-collections-screen'>
          <GenericEmptyScreen
            title={intl.formatMessage({id: 'Learning.collections.noCourses'})}
            hasPermission={true}
            buttonText={intl.formatMessage({
              id: 'Learning.collections.addToCollection',
            })}
            buttonIcon={<AddFolderIcon colour='white' />}
            buttonIconPosition='start'
            onButtonClick={setShowContentListSlidePanel}
            headerDataAutomation='no-courses-header'
            fullWidthImage={false}
          />
        </div>
      );
    }

    if (guidesInLearningTabEnabled) {
      const onCreateClick = (contentType) => {
        switch (contentType) {
          case contentTypes.COURSE:
            openAddCourseModal();
            break;
          case contentTypes.GUIDE:
            openAddGuideModal();
            break;
          default:
            break;
        }
      };

      return (
        <GenericEmptyScreen
          title={intl.formatMessage({id: 'Learning.noCourses.header'})}
          description={intl.formatMessage({id: 'Learning.noContents.body'})}
          actions={<EmptyScreenButtons onClick={onCreateClick} />}
          hasPermission={false}
          linkTextKey='Learning.noCourses.helpLink'
          linkHref={
            'https://help.edume.com/creating-content#best-practices-to-get-started'
          }
          headerDataAutomation='no-courses-header'
          fullWidthImage={false}
        />
      );
    }

    return (
      <GenericEmptyScreen
        imageUrl={'/resources/img/24_mortaboard.svg'}
        title={intl.formatMessage({id: 'Learning.noCourses.header'})}
        description={intl.formatMessage({id: 'Learning.noCourses.body'})}
        buttonText={intl.formatMessage({id: 'Learning.noCourses.btn'})}
        onButtonClick={openAddCoursePanel}
        hasPermission={true}
        noPermissionText={intl.formatMessage({
          id: 'Learning.noCourses.checkLater',
        })}
        linkTextKey='Learning.noCourses.helpLink'
        linkHref={'https://help.edume.com/admin-panel/adding-a-course'}
        headerDataAutomation='no-courses-header'
        fullWidthImage={false}
      />
    );
  };

  const sampleCourse = filteredCourses?.filter((course) => course.isSample)[0];
  const sampleCourseId = sampleCourse && sampleCourse.id;

  const searchCourseEvent = (courseId, searchType) =>
    // TODO: add content type?
    searchCourse({
      enteredTerm: courseSearchValue,
      noOfResultsReturned: filteredCourses.length,
      searchType,
      courseId,
    });

  const isFullWidthItem = !isImageCourseCardEnabled;
  const courseCards = filteredCourses.map((course, index) => (
    <Course
      key={course.courseKey}
      course={course}
      updateCourseDetails={updateCourseDetails}
      currentGroupId={groupId}
      groups={groups}
      selectGroup={selectGroup}
      goToContent={goToContent}
      copyContent={copyContent}
      copyDemoCourseToCustomer={copyDemoCourseToCustomer}
      editCourseInProgress={editCourseInProgress}
      hasCourseDuplicationFailed={hasCourseDuplicationFailed}
      searchCourse={searchCourseEvent}
      courseSearchValue={courseSearchValue}
      setCourseSearchValue={setCourseSearchValue}
      setSearchResults={setSearchResults}
      adminLanguage={adminLanguage}
      isResourceLibraryCourse={isResourceLibraryTabActive}
      isDemoContentCourse={isDemoContentTabActive}
      setEditingCourse={setEditingCourse}
      setCopyingCourse={setCopyingCourse}
      {...(course.published && {
        showHint: course.id === sampleCourseId,
      })}
      rowIndex={index}
      setRowHeight={setRowHeight}
      isImageCourseCardEnabled={isImageCourseCardEnabled}
    />
  ));

  const renderContentList = () => {
    if (!coursesLoaded) {
      return (
        <LoadingIndicator
          indicator='hexagons'
          containerStyle='centerAbsolute'
        />
      );
    }
    if (!filteredCourses.length) {
      return <div styleName='empty-screen'>{getNoCoursesContent()}</div>;
    }

    return (
      <>
        {sampleCourse && (
          <div styleName='hint'>
            <Hint
              hintId='SAMPLE_COURSE'
              buttonKey='gotIt'
              trianglePosition='br'
            />
          </div>
        )}
        <ContentList
          isFullWidthItem={isFullWidthItem}
          contentList={courseCards}
          getRowHeight={getRowHeight}
          itemWidth={CARD_ITEM_WIDTH}
          ref={listRef}
        />
      </>
    );
  };

  return (
    <>
      {renderContentList()}
      {editingCourse && (
        <CreateEditCourse
          onClose={() => setEditingCourse(null)}
          originalCourse={editingCourse}
        />
      )}
      {copyingCourse && (
        <DuplicateContent
          content={copyingCourse}
          duplicatedContent={lastCopiedCourse}
          groups={groups.filter((group) => group.id !== groupId)}
          currentGroupId={groupId}
          copyContent={copyContent}
          editContentInProgress={editCourseInProgress}
          hasContentDuplicationFailed={hasCourseDuplicationFailed}
          isResourceLibraryCourse={
            isResourceLibraryTabActive || isDemoContentTabActive
          }
          close={() => setCopyingCourse(null)}
          selectGroup={selectGroup}
          goToContent={goToContent}
          intl={intl}
        />
      )}
    </>
  );
};

CourseList.propTypes = {
  filteredCourses: PropTypes.array.isRequired,
  courseSearchValue: PropTypes.string,
  setCourseSearchValue: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  searchCourse: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  groupId: PropTypes.number,
  selectGroup: PropTypes.func.isRequired,
  updateCourseDetails: PropTypes.func.isRequired,
  hasTemplatesFilter: PropTypes.bool,
  openAddCoursePanel: PropTypes.func,
  goToContent: PropTypes.func.isRequired,
  copyContent: PropTypes.func.isRequired,
  editCourseInProgress: PropTypes.bool.isRequired,
  adminLanguage: PropTypes.string.isRequired,
  hasCourseDuplicationFailed: PropTypes.bool.isRequired,
  isImageCourseCardEnabled: PropTypes.bool,
  isResourceLibraryTabActive: PropTypes.bool,
  isDemoContentTabActive: PropTypes.bool.isRequired,
  lastCopiedCourse: PropTypes.object,
  intl: PropTypes.object.isRequired,
  copyDemoCourseToCustomer: PropTypes.func.isRequired,
  isCollectionTabActive: PropTypes.bool.isRequired,
  setShowContentListSlidePanel: PropTypes.func,
  guidesInLearningTabEnabled: PropTypes.bool,
  openAddGuideModal: PropTypes.func,
  openAddCourseModal: PropTypes.func,
};

export default injectIntl(CSSModules(CourseList, styles));
