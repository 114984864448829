import React, {ReactNode} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import {ClockIcon, PlayIcon} from '../icons';
import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';

import styles from './styles.module.scss';

export type ContentCardProps = {
  title: string;
  image?: string;
  contentType?: 'course' | 'guide';
  contentTypeText?: string;
  activityCountContent?: string;
  courseTimeEstimateText?: string;
  borderRadius?: number;
  fullHeight?: boolean;
  cardFooter?: ReactNode;
  rightPanel?: ReactNode;
  borders?: boolean;
  selected?: boolean;
};

// eslint-disable-next-line complexity
const ContentCard = ({
  title,
  image,
  contentType,
  contentTypeText,
  activityCountContent,
  courseTimeEstimateText,
  borderRadius = 0,
  fullHeight = true,
  cardFooter,
  rightPanel,
  borders = false,
  selected = false,
}: ContentCardProps) => {
  const contentCardStyleNames = classNames('content-card', {
    fullHeight,
    borders,
    selected,
  });
  return (
    <div
      styleName={contentCardStyleNames}
      style={{borderRadius: `${borderRadius}px`}}
    >
      <div
        styleName={`card-image-container${
          contentType === 'guide' ? ' guide' : ''
        }`}
      >
        <img
          styleName='card-image'
          src={image ?? '/resources/img/default_course_image.png'}
          alt='icon'
        />
        <div styleName='play-icon'>
          <PlayIcon size='small' colour='white' />
        </div>
      </div>

      <div styleName='card-content'>
        <div styleName='card-title'>
          <Heading
            size='snail'
            as='h4'
            weight='bold'
            maxLines={2}
            dataAutomation='content-title'
          >
            {title}
          </Heading>
        </div>
        <div styleName='content-info'>
          {contentTypeText && (
            <Text size='s' colour='grey700'>
              {contentTypeText}
            </Text>
          )}
          {activityCountContent && (
            <Text
              size='s'
              colour='textSecondary'
              dataAutomation='course-activities'
            >
              {activityCountContent}
            </Text>
          )}
          {courseTimeEstimateText && (
            <span>
              <ClockIcon size='micro' colour='grey700' />
              <Text size='s' dataAutomation='course-time'>
                {courseTimeEstimateText}
              </Text>
            </span>
          )}
        </div>
        <div styleName='content-footer'>{cardFooter}</div>
      </div>
      {rightPanel}
    </div>
  );
};

export default CSSModules(ContentCard, styles, {allowMultiple: true});
