import variables from '../../../resources/styles/colours.module.scss';

const orientations = ['left', 'right', 'up', 'down'] as const;
export type OrientationOption = (typeof orientations)[number];

const directions = ['right', 'left'] as const;
export type DirectionOption = (typeof directions)[number];

export const sizesMap = {
  micro: 12,
  tiny: 16,
  xsmall: 20,
  small: 24,
  medium: 32,
  big: 40,
  huge: 48,
} as const;

export const colourMap = {
  successPrimary: variables.successPrimary,
  errorPrimary: variables.errorPrimary,
  brandPrimary: variables.brandPrimary,
  brandPrimaryDark: variables.brandPrimaryDark,
  purple700: variables.purple700,
  purple300: variables.purple300,
  grey700: variables.grey700,
  grey300: variables.grey300,
  blue700: variables.blue700,
  white: variables.white,
  textPrimary: variables.textPrimary,
  textSecondary: variables.textSecondary,
  grey050: variables.grey050,
  primary: variables.brandPrimary,
  red: variables.errorPrimary,
  black: variables.textPrimary,
  green: variables.successPrimary,
  blue: variables.blue700,
  purple500: variables.brandPrimary,
  currentColor: 'currentColor',
} as const;
