import * as integrations from '@edume/bento/integrations';

export {integrations};

// Integration config types
export const BAMBOO = 'bamboo';
export const BRAZE = 'braze';
export const FOUNTAIN = 'fountain';
export const ITERABLE = 'iterable';
export const WORKDAY = 'workday';
export const ZAPIER = 'zapier';
export const SKEDULO = 'skedulo';
export const MS_TEAMS = integrations.MS_TEAMS;
export const BEEKEEPER = integrations.BEEKEEPER;
export const SPEAKAP = integrations.SPEAKAP;
export const STAFFBASE = 'staffbase';
export const SERVICEMAX = 'servicemax';

export const EDUME = 'edume';

export const integrationDisplayNames = {
  [BAMBOO]: 'BambooHR',
  [integrations.BEEKEEPER]: 'Beekeeper',
  [BRAZE]: 'Braze',
  [FOUNTAIN]: 'Fountain',
  [ITERABLE]: 'Iterable',
  [WORKDAY]: 'Workday',
  [ZAPIER]: 'Zapier',
  [integrations.MS_TEAMS]: 'Microsoft Teams',
  [SKEDULO]: 'Skedulo',
  [integrations.SPEAKAP]: 'Speakap',
  [STAFFBASE]: 'Staffbase',
  [SERVICEMAX]: 'ServiceMax',
};

export const availableSelfServeIntegrations = [
  BRAZE,
  ITERABLE,
  BAMBOO,
  FOUNTAIN,
  MS_TEAMS,
  BEEKEEPER,
];

// set isHidden to true for integrations that should not be shown in the UI
export const integrationsDetails = [
  {
    name: integrationDisplayNames[BAMBOO],
    logo: '/resources/img/bamboohr.svg',
    descriptionKey: 'humanCapital',
    type: BAMBOO,
    link: 'https://help.edume.com/getting-started/jz2ebsnb8xal4nk5r8l7ntb3fghx9d',
  },
  {
    name: integrationDisplayNames[integrations.BEEKEEPER],
    logo: '/resources/img/beekeeper.svg',
    descriptionKey: 'communication',
    type: integrations.BEEKEEPER,
    link: 'https://help.edume.com/beekeep-edume-integration',
  },
  {
    name: integrationDisplayNames[BRAZE],
    logo: '/resources/img/braze.svg',
    descriptionKey: 'communication',
    type: BRAZE,
    link: 'https://help.edume.com/sending-completion-events-from-edume-to-braze',
  },
  {
    name: integrationDisplayNames[FOUNTAIN],
    logo: '/resources/img/fountain.svg',
    descriptionKey: 'ATS',
    type: FOUNTAIN,
    link: 'https://help.edume.com/getting-started/integrating-your-account-with-fountain',
  },
  {
    name: integrationDisplayNames[ITERABLE],
    logo: '/resources/img/iterable.svg',
    descriptionKey: 'communication',
    type: ITERABLE,
    link: 'https://help.edume.com/sending-completion-events-from-edume-to-iterable',
  },
  {
    name: integrationDisplayNames[MS_TEAMS],
    logo: '/resources/img/msTeams.svg',
    descriptionKey: 'communication',
    type: MS_TEAMS,
    link: 'https://help.edume.com/integrations',
  },
  {
    name: integrationDisplayNames[SKEDULO],
    logo: '/resources/img/skedulo.svg',
    descriptionKey: 'scheduling',
    type: SKEDULO,
    link: 'https://www.edume.com/integrations/skedulo',
  },
  {
    name: integrationDisplayNames[integrations.SPEAKAP],
    logo: '/resources/img/speakap.svg',
    descriptionKey: 'scheduling',
    type: integrations.SPEAKAP,
    link: 'https://www.edume.com/integrations/speakap',
    isHidden: true,
  },
  {
    name: integrationDisplayNames[WORKDAY],
    logo: '/resources/img/workday.svg',
    descriptionKey: 'humanCapital',
    type: WORKDAY,
    link: 'https://help.edume.com/how-to-set-up-a-workday-integration',
  },
];

// Legacy - used only for old notifications modal. Use contentDelivery in Bento instead.
export const integrationsWithNotifications = [
  integrations.SPEAKAP,
  integrations.MS_TEAMS,
];

export const integrationsWithNotificationReminders = [integrations.SPEAKAP];

//Integrations user identification keys
export const EMAIL = 'email';
export const USERID = 'userId';
export const EXTERNAL_ID = 'external_id';
export const USER_ALIAS = 'user_alias';
export const BRAZE_ID = 'braze_id';
