import URLSearchParams from '@ungap/url-search-params';
import {push} from 'connected-react-router';

import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import {RESET_GROUP_OVERVIEW} from '../overview/overviewActionTypes';
import * as peopleActions from '../people/peopleActions';
import {RESET_SELECTED_TEAM, SELECT_GROUP} from '../people/peopleActionTypes';
import {getAllUsersSegment} from '../segments/segmentSelector';
import {updateOtherTabs} from '../stateUtil';
import * as teamActionCreators from './teamActionCreators';
import {
  ADD_TEAM_SUCCESS,
  CHANGED_STATIC_TEAM_NAME,
  CLICKED_CREATE_TEAM,
  DELETED_TEAM,
  DISMISS_CSV_UPLOAD_ERROR,
  EDIT_SMART_TEAM,
  GET_ADDITIONAL_MS_TEAMS_TEAMS,
  INITIAL_LOAD_TEAMS,
  SAVED_TEAM_UPDATE,
  SEARCH_MS_TEAMS_TEAMS,
  SELECT_TEAM,
  SELECTED_TEAM_TYPE,
  SET_REFRESH_GROUP_ID,
  SET_SHOW_ADD_TEAM_MODAL,
  SHOW_USERS_IN_TEAM,
  VIEWED_SMART_TEAM_DETAILS,
  VIEWED_TEAM_ACTION,
} from './teamActionTypes';

const getUpdatedURLWithGroupId = (loc, groupId) => {
  const params = new URLSearchParams(loc.search);
  params.set('g', groupId);

  const getSafeRedirectUrl = (url) => {
    if (url.includes('/reports/course')) {
      return '/reports';
    }
    return url;
  };

  return `${getSafeRedirectUrl(loc.pathname)}?${params}`;
};

const getGroupIdFromUrl = (search) => {
  const params = new URLSearchParams(search);
  return params.get('g');
};

export const dismissCsvUploadError = () => (dispatch) => {
  dispatch({
    type: DISMISS_CSV_UPLOAD_ERROR,
  });
};

export const addGroup = (name, shouldRedirect) => (dispatch, getState) => {
  dispatch(teamActionCreators.addGroup(name))
    .then((response) => {
      const newGroupId = response.payload.data;
      loadTeams({otherTabsRequireUpdate: false})(dispatch, getState).then(() =>
        selectGroup(newGroupId)(dispatch, getState)
      );
      const messageKey = 'Groups.addGroupSuccess';
      notificationActions.createSuccessNotification(messageKey, {name})(
        dispatch
      );
      if (shouldRedirect) {
        dispatch(push('/people-and-teams'));
      }
    })
    .then(() => formActions.closeForm()(dispatch))
    .catch((error) => {
      formActions.addFormErrorFromResponse(error)(dispatch);
    });
};

export const selectGroup = (groupId) => (dispatch, getState) => {
  const url = getUpdatedURLWithGroupId(location, groupId);
  dispatch(push(url));

  dispatch({type: SELECT_GROUP, payload: groupId});

  dispatch({type: RESET_GROUP_OVERVIEW});
  dispatch({type: RESET_SELECTED_TEAM});

  peopleActions.loadUsers(groupId)(dispatch, getState);
  updateOtherTabs(dispatch, getState);
};

export const addTeam = (name) => (dispatch, getState) => {
  const selectedGroupId = getState().getIn(['team', 'groupId']);

  dispatch(teamActionCreators.addTeam(name, selectedGroupId))
    .then(() => {
      loadTeams()(dispatch, getState);
      const messageKey = 'Teams.addTeamSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .then(() => formActions.closeForm()(dispatch))
    .catch((error) => {
      formActions.addFormErrorFromResponse(error)(dispatch);
    });
};

export const editGroup = (name) => (dispatch, getState) => {
  const allUsersSegment = getAllUsersSegment(getState());
  const selectedGroupId = getState().getIn(['team', 'groupId']);
  dispatch(
    teamActionCreators.editGroup(selectedGroupId, name, {
      allUsersSegment: allUsersSegment?.toJS(),
    })
  )
    .then(() => {
      const successKey = 'GroupSettings.editGroupSuccess';
      notificationActions.createSuccessNotification(successKey)(dispatch);
    })
    .then(() => formActions.closeForm()(dispatch))
    .catch((error) => {
      formActions.addFormErrorFromResponse(error)(dispatch);
    });
};

export const editTeam = (id, name) => (dispatch) =>
  dispatch(teamActionCreators.editTeam(id, name))
    .then(() => {
      const successKey = 'Teams.editTeamSuccess';
      notificationActions.createSuccessNotification(successKey)(dispatch);
    })
    .then(() => formActions.closeForm()(dispatch))
    .catch((error) => {
      formActions.addFormErrorFromResponse(error)(dispatch);
    });

//TODO possibly rename to loadGroups + extract to a group service (..._maybe_ reducer)
export const loadTeams =
  (params = {}) =>
  // eslint-disable-next-line complexity
  async (dispatch, getState) => {
    const {
      redirectIfGroups = false,
      otherTabsRequireUpdate = true,
      includeUserCount = true,
      fetchExternalTeams = false,
      initialLoad = false,
    } = params;
    const groupId = getGroupIdFromUrl(location.search);
    if (groupId) {
      dispatch({
        type: SET_REFRESH_GROUP_ID,
        payload: Number(groupId),
      });
    }

    try {
      if (fetchExternalTeams) {
        await dispatch(teamActionCreators.getExternalTeams());
      }

      if (initialLoad) {
        dispatch({
          ...teamActionCreators.getTeams(includeUserCount),
          type: INITIAL_LOAD_TEAMS,
        });

        if (otherTabsRequireUpdate) {
          updateOtherTabs(dispatch, getState);
        }
      } else {
        const {payload} = await dispatch(
          teamActionCreators.getTeams(includeUserCount)
        );
        const groups = payload.data;

        if (groups.length === 0) {
          await dispatch(push('/no-groups' + location.search));
        } else if (redirectIfGroups) {
          await dispatch(push('/' + location.search));
        }

        if (otherTabsRequireUpdate) {
          updateOtherTabs(dispatch, getState);
        }
      }
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const loadExternalTeams = () => (dispatch) => {
  dispatch(teamActionCreators.getExternalTeams());
};

export const selectTeam = (teamId) => (dispatch, getState) => {
  dispatch({
    type: SELECT_TEAM,
    payload: teamId,
  });
  updateOtherTabs(dispatch, getState, false);
};

export const deleteTeam = (team) => (dispatch, getState) => {
  const {id, externalIntegrationType} = team;
  dispatch(teamActionCreators.deleteTeam(id))
    .then(() => {
      dispatch({type: DELETED_TEAM, payload: id});

      const loadTeamsOptions = externalIntegrationType
        ? {fetchExternalTeams: true}
        : {};
      loadTeams(loadTeamsOptions)(dispatch, getState);

      notificationActions.createSuccessNotification('Teams.deleteTeamSuccess')(
        dispatch
      );
    })
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
};

export const deleteGroup = (groupId) => (dispatch, getState) => {
  dispatch(teamActionCreators.deleteGroup(groupId))
    .then(() => {
      if (getState().getIn(['team', 'groups']).size === 0) {
        dispatch(push('/no-groups'));
      } else {
        //teams don't need to be reloaded, everything else does
        updateOtherTabs(dispatch, getState);
      }

      notificationActions.createSuccessNotification(
        'Groups.deleteGroup.success'
      )(dispatch);
    })
    .then(() => formActions.closeForm()(dispatch))
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
};

export const setShowAddTeamModal = (showAddTeamModal) => (dispatch) =>
  dispatch({
    type: SET_SHOW_ADD_TEAM_MODAL,
    payload: {
      showAddTeamModal,
    },
  });

export const clickedCreateTeam = () => (dispatch) => {
  dispatch({type: CLICKED_CREATE_TEAM});
};

export const goToAddTeamModal = () => (dispatch) => {
  dispatch({
    type: SET_SHOW_ADD_TEAM_MODAL,
    payload: {
      showAddTeamModal: true,
    },
  });
  dispatch(push(`/people${location.search}`));
};

export const searchMsTeamsTeams = (searchTerm) => (dispatch) => {
  dispatch(
    teamActionCreators.searchMsTeamsTeams(SEARCH_MS_TEAMS_TEAMS, searchTerm)
  );
};

export const getAdditionalMsTeamsTeams = (searchTerm) => (dispatch) => {
  dispatch(
    teamActionCreators.searchMsTeamsTeams(
      GET_ADDITIONAL_MS_TEAMS_TEAMS,
      searchTerm
    )
  );
};

export const selectTeamType = (teamType) => (dispatch) => {
  dispatch({type: SELECTED_TEAM_TYPE, payload: teamType});
};

export const viewSmartTeamDetails =
  ({teamId, teamName}) =>
  (dispatch) => {
    dispatch({type: VIEWED_SMART_TEAM_DETAILS, payload: {teamId, teamName}});
  };

export const viewTeamAction = (teamType) => (dispatch) => {
  dispatch({type: VIEWED_TEAM_ACTION, payload: teamType});
};

export const showUsersInTeam =
  ({teamType, teamName, teamId}) =>
  (dispatch) => {
    dispatch({type: SHOW_USERS_IN_TEAM, payload: {teamId, teamName, teamType}});
  };

export const changedStaticTeamName =
  ({teamName, teamId}) =>
  (dispatch) => {
    dispatch({
      type: CHANGED_STATIC_TEAM_NAME,
      payload: {teamId, teamName},
    });
  };

export const editSmartTeam =
  ({teamId, teamName}) =>
  (dispatch) => {
    dispatch({type: EDIT_SMART_TEAM, payload: {teamId, teamName}});
  };

export const savedTeamUpdate =
  ({
    nameChanged,
    tagsChanged,
    edumeEventsChanged,
    logicOperatorChanged,
    teamLogic,
    ruleLogic,
    logicLayer,
  }) =>
  (dispatch) => {
    dispatch({
      type: SAVED_TEAM_UPDATE,
      payload: {
        nameChanged,
        tagsChanged,
        edumeEventsChanged,
        logicOperatorChanged,
        teamLogic,
        ruleLogic,
        logicLayer,
      },
    });
  };

export const addSmartTeam =
  ({
    id,
    teamName,
    tags,
    edumeEvents,
    teamLogic,
    autoGeneratedName,
    isSmartTeam = true,
    rules,
    logicLayer,
    ruleLogic,
  }) =>
  (dispatch) => {
    dispatch({
      type: ADD_TEAM_SUCCESS,
      payload: {
        id,
        teamName,
        tags,
        edumeEvents,
        autoGeneratedName,
        teamLogic,
        isSmartTeam,
        rules,
        logicLayer,
        ruleLogic,
      },
    });
  };
