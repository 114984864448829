import {SUPPORTED_INTEGRATIONS_WITH_NOTIFICATION} from '@edume/bento/contentDelivery';

import {
  availableSelfServeIntegrations,
  integrationsDetails,
  MS_TEAMS,
} from '../enums/integrations';

/**
 * @typedef NotificationDetail
 * @property {string} integrationType
 * @property {number[]} teamIds
 * @property {string[]} externalGroupIds
 * @property {string | null} reminderInterval
 *
 */

export const getIntegrationDetails = (type) =>
  integrationsDetails.find((integration) => integration.type === type);

export const filterForIntegrationsWithNotifications = (integration) =>
  SUPPORTED_INTEGRATIONS_WITH_NOTIFICATION.includes(
    integration.externalIntegrationType || integration.integrationType
  );

/**
 * Get notifications object required by NotificationsModal
 * @param {{id: number, externalIntegrationType: string}[]} externalTeams
 * @param {number?} reminderInterval
 * @returns {NotificationDetail[]}
 */
export const getNotificationDetails = (externalTeams, reminderInterval) =>
  externalTeams.reduce((details, team) => {
    const existingDetails = details.find(
      (detail) => detail.integrationType === team.externalIntegrationType
    );

    if (existingDetails) {
      existingDetails.teamIds.push(team.id);
      existingDetails.reminderInterval = reminderInterval;
    } else {
      details.push({
        integrationType: team.externalIntegrationType,
        reminderInterval,
        teamIds: [team.id],
      });
    }

    return details;
  }, []);

export const filterValidIntegrations = (customerIntegrations) => {
  if (customerIntegrations) {
    return customerIntegrations.filter((customerIntegration) =>
      integrationsDetails.find(
        (validIntegration) => validIntegration.type === customerIntegration.type
      )
    );
  } else {
    return [];
  }
};

export const filterAvailableIntegrations = (customerIntegrations) => {
  if (customerIntegrations) {
    return customerIntegrations.filter((customerIntegration) =>
      availableSelfServeIntegrations.find(
        (availableIntegration) =>
          availableIntegration === customerIntegration.type
      )
    );
  } else {
    return [];
  }
};

/**
 * @param {{id: number, integrationType: string}[]} integratedTeams
 * @param {number} teamId
 * @returns {string | null} integration type
 */
export const getTeamIntegrationType = (integratedTeams, teamId) => {
  const integratedTeam = integratedTeams.find(({id}) => id === teamId);
  return integratedTeam ? integratedTeam.integrationType : null;
};

export const notificationReminderIntervals = [
  {id: 'NotificationsModal.reminder.interval.days', value: 3, days: 3},
  {id: 'NotificationsModal.reminder.interval.days', value: 5, days: 5},
  {id: 'NotificationsModal.reminder.interval.weeks', value: 1, days: 7},
  {id: 'NotificationsModal.reminder.interval.weeks', value: 2, days: 14},
  {id: 'NotificationsModal.reminder.interval.none', value: null, days: null},
];

export const showExternalLinkIcon = (accessMethod) => accessMethod === MS_TEAMS;

export const areMstPermissionsGranted = (integration) =>
  integration.groupIntegrationCredentials.permissionsGranted;
