import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import requiredIf from 'react-required-if';

import {IconButton, LinkIcon, TickIcon} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';

const CopyLinkButton = ({
  title,
  options,
  displayedText,
  icon = <LinkIcon colour='brandPrimary' />,
  iconUrl = '/resources/img/24_link.svg',
  hoverIconUrl = '/resources/img/24_link_white.svg',
  showBorder,
  dataAutomation,
  trackIdCopied,
  createErrorNotification,
  isExpandable = true,
  isAlwaysExpanded = false,
  isBgTransparent = false,
  isTrial = false,
}) => {
  const dispatch = useDispatch();
  const [isSuccessShown, setIsSuccessShown] = useState(false);

  const copyLink = (option) => {
    try {
      navigator.clipboard.writeText(option.link);
      const courseId =
        option.contentType === contentTypes.COURSE ? option.contentId : null;
      const guideId =
        option.contentType === contentTypes.GUIDE ? option.contentId : null;
      setIsSuccessShown(true);
      setTimeout(() => setIsSuccessShown(false), 1500);
      if (option.amplitudeType) {
        if (isTrial) {
          dispatch({
            type: option.amplitudeType,
            payload: {
              source: option.amplitudeSource,
              courseId,
              guideId,
            },
          });
        } else {
          // TODO: should we add contentType and contentId here as well?
          trackIdCopied({
            type: option.amplitudeType,
            courseId,
            surveyId: option.surveyId,
            guideId,
          });
        }
      }
    } catch (e) {
      createErrorNotification('Error.generic');
    }
  };

  const updateLinkOptions = (linkOptions) =>
    linkOptions.map((option) => ({
      ...option,
      callback: option.callback || (() => copyLink(option)),
    }));

  const handleClick = options.length === 1 ? () => copyLink(options[0]) : null;

  return (
    <IconButton
      dataAutomation={dataAutomation}
      isExpandable={isExpandable && displayedText}
      isAlwaysExpanded={isAlwaysExpanded && displayedText}
      popoverProps={{
        title,
        options: updateLinkOptions(options),
      }}
      isSuccessShown={isSuccessShown}
      handleClick={handleClick}
      icon={icon}
      iconUrl={iconUrl}
      hoverIconUrl={hoverIconUrl}
      displayedText={displayedText}
      successIconLink='/resources/img/24_correct_white.svg'
      successIcon={<TickIcon colour='white' />}
      showBorder={showBorder}
      isBgTransparent={isBgTransparent}
      noMarginStart
    />
  );
};

CopyLinkButton.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayedText: requiredIf(
    PropTypes.string,
    (props) => props.isExpandable || props.isAlwaysExpanded
  ),
  icon: PropTypes.node,
  iconUrl: PropTypes.string,
  hoverIconUrl: PropTypes.string,
  showBorder: PropTypes.bool,
  dataAutomation: PropTypes.string,
  trackIdCopied: PropTypes.func,
  createErrorNotification: PropTypes.func.isRequired,
  isExpandable: PropTypes.bool,
  isAlwaysExpanded: PropTypes.bool,
  isBgTransparent: PropTypes.bool,
  isTrial: PropTypes.bool,
};

export default CopyLinkButton;
