import moment from 'moment';

import {validateCompletionScreen} from '@edume/bento/courseValidation';

import {COMPLETED, IN_PROGRESS, NOT_STARTED} from '../enums/courses';

export const getCourseStatus = (
  startDate,
  completionDate,
  retakeAssignmentDate
) => {
  if (retakeAssignmentDate && startDate > retakeAssignmentDate) {
    return completionDate > retakeAssignmentDate ? COMPLETED : IN_PROGRESS;
  } else if (startDate) {
    return completionDate ? COMPLETED : IN_PROGRESS;
  }
  return NOT_STARTED;
};

export const getCourseStartDate = (
  startDate,
  latestRetakeCompletionDate,
  retakeAssignmentDate,
  latestRetakeStartDate
) => {
  if (new Date(latestRetakeCompletionDate) > new Date(retakeAssignmentDate)) {
    // retake completed
    return latestRetakeStartDate;
  } else if (retakeAssignmentDate) {
    return new Date(latestRetakeStartDate) > new Date(retakeAssignmentDate)
      ? latestRetakeStartDate // retake in progress
      : null; // retake not started
  } else {
    return startDate; // no retake assigned
  }
};

export const getCourseCompletionValidity = ({
  completionTitle,
  completionSubtitle,
  completionMessage,
  completionButtonText,
  completionButtonUrl,
  completionShowDashboardLink,
  completionCustomTextOptions,
}) => {
  const propertiesToSend = {
    title: completionTitle,
    subtitle: completionSubtitle,
    message: completionMessage,
    buttonText: completionButtonText,
    buttonUrl: completionButtonUrl,
    showDashboardLink: completionShowDashboardLink,
  };

  return (
    !completionCustomTextOptions || validateCompletionScreen(propertiesToSend)
  );
};

export const sortContentByCreationDate = (contents, secondaryField) => {
  // deal with injected content with no createdAt date
  const createdAt = (content) =>
    content.createdAt ? moment(content.createdAt) : moment().year(2000);

  return contents.sort((a, b) => {
    //if dates the same (i.e. injected) use secondary field instead (usually title or name)
    //stops semi-random order changing every time tab loads
    const isTitleBefore =
      a[secondaryField] < b[secondaryField]
        ? -1
        : a[secondaryField] > b[secondaryField];
    const createdBefore = createdAt(a).isBefore(createdAt(b)) ? 1 : -1;
    const sameCreation = createdAt(a).isSame(createdAt(b));

    return sameCreation ? isTitleBefore : createdBefore;
  });
};

export const checkIsCourseInDemoLibrary = (course) =>
  !!course.resourceTypes?.find((r) => r.type === 'demo') && course.published;
