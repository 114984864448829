import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import requiredIf from 'react-required-if';

import {
  Button,
  ExternalLinkIcon,
  LegacyHeading,
  Link,
} from '@edume/magnificent';

import styles from './styles.module.scss';

const GenericEmptyScreen = ({
  imageUrl,
  fullWidthImage = true,
  title,
  description,
  buttonText,
  onButtonClick,
  hasPermission,
  noPermissionText,
  linkTextKey,
  linkHref,
  intl,
  headerDataAutomation,
  buttonDataAutomation,
  actions,
  buttonIcon,
  buttonIconPosition,
}) => (
  <div styleName='parentContainer'>
    <div styleName='contentContainer'>
      <div styleName='imgContainer'>
        {imageUrl ? (
          <img
            src={imageUrl}
            styleName={fullWidthImage ? 'fullWidthImage' : ''}
          />
        ) : null}
      </div>
      <div>
        <LegacyHeading
          size='small'
          weight='medium'
          dataAutomation={headerDataAutomation}
        >
          {title}
        </LegacyHeading>
      </div>
      <div styleName='description'>
        <LegacyHeading size='micro'>{description}</LegacyHeading>
      </div>
      {actions && <div styleName='buttonContainer'>{actions}</div>}
      {hasPermission && (
        <div styleName='buttonContainer'>
          <Button
            type='primary'
            onClick={onButtonClick}
            noMarginTop
            noMarginLeft
            dataAutomation={buttonDataAutomation}
            icon={buttonIcon}
            iconPosition={buttonIconPosition}
          >
            {buttonText}
          </Button>
        </div>
      )}
      {!hasPermission && noPermissionText && (
        <div styleName='noPermissionContainer'>
          <LegacyHeading size='micro'>{noPermissionText}</LegacyHeading>
        </div>
      )}
      {linkTextKey && (
        <div styleName='linkContainer'>
          <Link
            icon={<ExternalLinkIcon colour='primary' size='tiny' />}
            iconPosition='end'
            href={linkHref}
            linkSize='snail'
            text={intl.formatMessage({id: linkTextKey})}
          />
        </div>
      )}
    </div>
  </div>
);

GenericEmptyScreen.propTypes = {
  imageUrl: PropTypes.string,
  fullWidthImage: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: requiredIf(PropTypes.string, (props) => props.hasPermission),
  onButtonClick: requiredIf(PropTypes.func, (props) => props.hasPermission),
  actions: PropTypes.node,

  hasPermission: PropTypes.bool.isRequired, //i.e. can the admin see the button
  noPermissionText: PropTypes.string,

  linkTextKey: PropTypes.string,
  linkHref: requiredIf(PropTypes.string, (props) => props.linkTextKey),

  intl: PropTypes.object.isRequired,
  headerDataAutomation: PropTypes.string,
  buttonDataAutomation: PropTypes.string,
  buttonIcon: PropTypes.node,
  buttonIconPosition: PropTypes.string,
};

export default injectIntl(CSSModules(GenericEmptyScreen, styles));
