import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {NavLink, withRouter} from 'react-router-dom';

import {LegacyIntlHeading} from '../../../core';

import styles from './styles.module.scss';

const HeaderLink = ({
  url,
  visibleTabs,
  keys,
  icons,
  currentPath,
  onClick,
  location,
  dataAutomation,
}) => {
  const activeLinkColor = {borderBottomColor: '#0D003A'};
  const tabName = url === '/' ? 'overview' : url.replace('/', '');
  const isActiveUrl = currentPath === url;
  const activeStyle = isActiveUrl ? 'active' : '';
  const showBetaBadge = false; // Set to true to show a Beta label in the header

  let styleForItem =
    visibleTabs.indexOf(tabName) > -1 ? 'item' : 'invisible-item';

  if (showBetaBadge) {
    styleForItem += ' beta';
  }

  return (
    <NavLink
      to={{pathname: url, search: location.search}}
      styleName={styleForItem}
      onClick={onClick}
      exact={url === '/'}
      activeStyle={activeLinkColor}
      data-automation={`header-item-${dataAutomation}`}
    >
      <div styleName={`iconContainer ${activeStyle}`}>
        <img
          styleName='unhoveredIcon'
          src={`/resources/img/${icons[url]}_grey.svg`}
        />
        <img styleName='hoveredIcon' src={`/resources/img/${icons[url]}.svg`} />
      </div>
      <div styleName={`tabName ${activeStyle}`}>
        <LegacyIntlHeading
          textKey={keys[url]}
          weight='medium'
          size='micro'
          colour={isActiveUrl ? 'textPrimary' : 'grey700'}
        />
      </div>
      {showBetaBadge && (
        <div styleName='beta-badge'>
          <LegacyIntlHeading
            textKey='Header.betaBadge'
            size='mini'
            weight='bold'
            colour='white'
          />
        </div>
      )}
    </NavLink>
  );
};

HeaderLink.propTypes = {
  url: PropTypes.string.isRequired,
  visibleTabs: PropTypes.array.isRequired,
  keys: PropTypes.object,
  icons: PropTypes.object,
  currentPath: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  dataAutomation: PropTypes.string,
};

export default withRouter(
  CSSModules(HeaderLink, styles, {allowMultiple: true})
);
