/* eslint-disable max-nested-callbacks */

/* eslint-disable max-statements */

/* eslint-disable  complexity */
import {fromJS} from 'immutable';
import _ from 'lodash';

import {MS_TEAMS} from '../../enums/integrations';
import {SELECT_GROUP} from '../people/peopleActionTypes';
import {
  ADD_GROUP,
  ADD_GROUP_FAIL,
  ADD_GROUP_SUCCESS,
  ADD_TEAM,
  ADD_TEAM_FAIL,
  ADD_TEAM_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETED_TEAM,
  DISMISS_CSV_UPLOAD_ERROR,
  EDIT_GROUP,
  EDIT_GROUP_FAIL,
  EDIT_GROUP_SUCCESS,
  EDIT_TEAM,
  EDIT_TEAM_FAIL,
  EDIT_TEAM_SUCCESS,
  GET_ADDITIONAL_MS_TEAMS_TEAMS,
  GET_ADDITIONAL_MS_TEAMS_TEAMS_FAIL,
  GET_ADDITIONAL_MS_TEAMS_TEAMS_SUCCESS,
  INITIAL_LOAD_TEAMS,
  INITIAL_LOAD_TEAMS_SUCCESS,
  LOAD_EXTERNAL_TEAMS,
  LOAD_EXTERNAL_TEAMS_SUCCESS,
  LOAD_TEAMS,
  LOAD_TEAMS_SUCCESS,
  SEARCH_MS_TEAMS_TEAMS_SUCCESS,
  SELECT_TEAM,
  SET_REFRESH_GROUP_ID,
  SET_SHOW_ADD_TEAM_MODAL,
  UPDATE_TEAMS_COUNT,
  UPLOAD_CSV,
  UPLOAD_CSV_FAIL,
  UPLOAD_CSV_SUCCESS,
} from './teamActionTypes';

export const initialState = fromJS({
  groups: [],
  groupId: null,
  teamId: null,
  teamsLoading: false,
  teamsLoaded: false,
  initialTeamsLoading: false,
  initialTeamsLoaded: false,
  integrations: [],
  integrationsLoaded: false,
  userCountLoaded: false,
  uploadingCsv: false,
  uploadCsvError: null,
  editTeamInProgress: false,
  deleteGroupInProgress: false,
  refreshGroupId: 0,
  showAddTeamModal: false,
  msTeamsSearchedTeams: [],
  msTeamsSearchedLoaded: false,
  hasRequestedNewTeam: false,
});

const findGroupIndexForId = (groups, id) =>
  groups.findIndex((group) => group.get('id') === id);
const getGroupById = (groups, id) =>
  groups.get(findGroupIndexForId(groups, id));

const findSelectedGroupIndex = (state) =>
  findGroupIndexForId(state.get('groups'), state.get('groupId'));
const findTeamIndexById = (state, teamId) =>
  state
    .getIn(['groups', findSelectedGroupIndex(state), 'children'])
    .findIndex((oldTeam) => oldTeam.get('id') === teamId);

const selectTeamForGroup = (oldState, group) => {
  const oldSelectedTeamId = oldState.get('teamId');

  if (!group) {
    return oldState.set('teamId', null);
  }

  const teams = group.get('children');
  const oldSelectedTeam = group
    .get('children')
    .find((team) => team.get('id') === oldSelectedTeamId);

  if (!oldSelectedTeam) {
    return oldState.set(
      'teamId',
      teams.size > 0 ? teams.getIn([0, 'id']) : null
    );
  } else {
    return oldState;
  }
};

export const reducer = (state = initialState, action) => {
  let updated = csvActionsReducer(state, action);
  updated = teamActionsReducer(updated, action);
  updated = groupActionsReducer(updated, action);
  return updated;
};

const csvActionsReducer = (state, action) => {
  switch (action.type) {
    case DISMISS_CSV_UPLOAD_ERROR: {
      return state.set('uploadCsvError', null);
    }
    case UPLOAD_CSV: {
      return state.set('uploadingCsv', true);
    }
    case UPLOAD_CSV_SUCCESS: {
      return state.set('uploadingCsv', false).set('uploadCsvError', null);
    }
    case UPLOAD_CSV_FAIL: {
      return (
        state
          .set('uploadingCsv', false)
          //NB this fails if error wasn't due to server error (e.g.  no network) (but fails on QA already)
          .set('uploadCsvError', action.payload.error.response.data)
      );
    }
    default:
      return state;
  }
};

const handleLoadTeamsAction = (state, action, loadingType = 'normal') => {
  const sortedGroups = _.orderBy(action.payload.data, 'id', 'desc');
  const newGroups = fromJS(
    sortedGroups.map((group) => ({
      ...group,
      children: _.orderBy(group.children, 'id', 'desc'),
      users: {},
    }))
  );
  let selectedGroupId = state.get('groupId');
  let oldSelectedGroup = getGroupById(state.get('groups'), selectedGroupId);
  const userCountLoaded =
    sortedGroups.length === 0 ||
    (sortedGroups.length > 0 &&
      !!Object.getOwnPropertyDescriptor(sortedGroups[0], 'numUsers')) ||
    (sortedGroups.length > 0 &&
      typeof sortedGroups[0].children?.[0]?.numUsers === 'number');
  let newState =
    loadingType === 'initial'
      ? state
          .set('groups', newGroups)
          .set('teamsLoaded', true)
          .set('initialTeamsLoaded', true)
          .set('userCountLoaded', userCountLoaded)
          .set('initialTeamsLoading', false)
      : state
          .set('groups', newGroups)
          .set('teamsLoaded', true)
          .set('userCountLoaded', userCountLoaded)
          .set('teamsLoading', false);

  if (!oldSelectedGroup && newGroups.size > 0) {
    const refreshGroupId = state.get('refreshGroupId');
    selectedGroupId =
      refreshGroupId && newGroups.find((g) => g.get('id') === refreshGroupId)
        ? state.get('refreshGroupId')
        : newGroups.get(0).get('id');
    newState = newState.set('groupId', selectedGroupId);
  }

  const newSelectedGroup = getGroupById(newGroups, selectedGroupId);
  newState = selectTeamForGroup(newState, newSelectedGroup);
  return newState;
};

// eslint-disable-next-line max-statements, complexity
const groupActionsReducer = (state, action) => {
  switch (action.type) {
    case ADD_GROUP:
    case EDIT_GROUP: {
      return state.set('editTeamInProgress', true);
    }
    case ADD_GROUP_SUCCESS:
    case ADD_GROUP_FAIL:
    case EDIT_GROUP_FAIL: {
      return state.set('editTeamInProgress', false);
    }
    case EDIT_GROUP_SUCCESS: {
      const {id, name, allUsersTeamName} = action.payload.data;
      const {allUsersSegment} = action.meta;

      const groupIndex = findGroupIndexForId(state.get('groups'), id);

      const newState = state
        .set('editTeamInProgress', false)
        .setIn(['groups', groupIndex, 'name'], name);

      if (!allUsersSegment) {
        return newState;
      }

      const teams = state.getIn(['groups', groupIndex, 'children']);
      const allUsersTeamIndex = teams.findIndex(
        (child) => child.get('id') === allUsersSegment.underlyingTeamId
      );

      return newState.setIn(
        ['groups', groupIndex, 'children', allUsersTeamIndex, 'name'],
        allUsersTeamName
      );
    }
    case SET_REFRESH_GROUP_ID:
      return state.set('refreshGroupId', action.payload);
    case INITIAL_LOAD_TEAMS:
      return state.set('initialTeamsLoading', true);
    case LOAD_TEAMS:
      return state.set('teamsLoading', true);
    case LOAD_TEAMS_SUCCESS: {
      return handleLoadTeamsAction(state, action);
    }
    case INITIAL_LOAD_TEAMS_SUCCESS: {
      return handleLoadTeamsAction(state, action, 'initial');
    }

    case LOAD_EXTERNAL_TEAMS:
      return state.set('integrationsLoaded', false).set('teamsLoading', true);

    case GET_ADDITIONAL_MS_TEAMS_TEAMS: {
      return state.set('msTeamsSearchedLoaded', false);
    }

    case GET_ADDITIONAL_MS_TEAMS_TEAMS_SUCCESS: {
      const existingIntegrations = state.get('integrations').toJS();
      const msTeamsIndex = existingIntegrations.findIndex(
        (team) => team.type === MS_TEAMS
      );
      if (msTeamsIndex < 0) {
        return state;
      }
      const msTeamsIntegration = existingIntegrations[msTeamsIndex];
      const linkedMsTeamsTeams = msTeamsIntegration.linkedTeams;
      const unlinkedMsTeamsTeams = msTeamsIntegration.unlinkedTeams;
      const msTeamsSearchResults = action.payload.data;
      const newSearchedTeamsToAdd = msTeamsSearchResults
        .filter(
          (item) =>
            linkedMsTeamsTeams.findIndex(
              (team) => team.externalGroupId === item.id
            ) < 0
        )
        .filter(
          (item) =>
            unlinkedMsTeamsTeams.findIndex((team) => team.id === item.id) < 0
        );
      const unlinkedTeamsWithSearchResults = unlinkedMsTeamsTeams.concat(
        newSearchedTeamsToAdd
      );

      return state
        .setIn(
          ['integrations', msTeamsIndex, 'unlinkedTeams'],
          unlinkedTeamsWithSearchResults
        )
        .set('msTeamsSearchedLoaded', true);
    }

    case GET_ADDITIONAL_MS_TEAMS_TEAMS_FAIL: {
      return state.set('msTeamsSearchedLoaded', false);
    }

    case SEARCH_MS_TEAMS_TEAMS_SUCCESS: {
      const existingTeams = state.get('msTeamsSearchedTeams');
      const newTeams = fromJS(action.payload.data);
      const mergedTeams = existingTeams.concat(
        newTeams.filter((item) => existingTeams.indexOf(item) < 0)
      );

      return state
        .set('msTeamsSearchedTeams', mergedTeams)
        .set('msTeamsSearchedLoaded', true);
    }

    case LOAD_EXTERNAL_TEAMS_SUCCESS:
      return state
        .set('integrations', fromJS(action.payload.data))
        .set('teamsLoading', false)
        .set('integrationsLoaded', true);

    case SELECT_GROUP: {
      const selectedGroupId = action.payload;
      const selectedGroup = getGroupById(state.get('groups'), selectedGroupId);
      return selectTeamForGroup(
        state.set('groupId', selectedGroupId),
        selectedGroup
      );
    }
    case DELETE_GROUP: {
      return state.set('deleteGroupInProgress', true);
    }
    case DELETE_GROUP_FAIL: {
      return state.set('deleteGroupInProgress', false);
    }
    case DELETE_GROUP_SUCCESS: {
      const {groupId} = action.meta;
      const remainingGroups = state
        .get('groups')
        .filter((g) => g.get('id') !== groupId);

      let newState = state
        .set('groups', remainingGroups)
        .set('deleteGroupInProgress', false);

      if (remainingGroups.size === 0) {
        newState = newState
          .set('groupId', initialState.groupId)
          .set('teamId', initialState.teamId);
      } else {
        const newGroupId = remainingGroups.get(0).get('id');
        const newSelectedGroup = getGroupById(remainingGroups, newGroupId);
        newState = selectTeamForGroup(newState, newSelectedGroup).set(
          'groupId',
          newGroupId
        );
      }
      return newState;
    }
    default:
      return state;
  }
};

const teamActionsReducer = (state, action) => {
  switch (action.type) {
    case ADD_TEAM:
    case EDIT_TEAM: {
      return state.set('editTeamInProgress', true);
    }
    case ADD_TEAM_SUCCESS:
    case ADD_TEAM_FAIL:
    case EDIT_TEAM_FAIL: {
      return state.set('editTeamInProgress', false);
    }
    case EDIT_TEAM_SUCCESS: {
      const {id, name} = action.payload.data;
      const selectedGroupIndex = findSelectedGroupIndex(state);
      const updatedTeamIndex = findTeamIndexById(state, id);

      return state
        .set('editTeamInProgress', false)
        .setIn(
          ['groups', selectedGroupIndex, 'children', updatedTeamIndex, 'name'],
          name
        );
    }

    case UPDATE_TEAMS_COUNT: {
      if (!state.get('groupId')) {
        return state;
      }

      return state.updateIn(
        ['groups', findSelectedGroupIndex(state), 'children'],
        (teams) =>
          teams.map((team) => {
            const teamCount = action.payload.data.find(
              (t) => t.id === team.get('id')
            );
            return teamCount
              ? team
                  .set('numUsers', teamCount.numUsers)
                  .set('numAdminUsers', teamCount.numAdminUsers)
                  .set('numActivatedUsers', teamCount.numActivatedUsers)
              : team;
          })
      );
    }

    case SELECT_TEAM: {
      return state.set('teamId', action.payload);
    }

    case DELETED_TEAM: {
      const selectedGroupIndex = findSelectedGroupIndex(state);
      const teamIndex = findTeamIndexById(state, action.payload);

      const newState = state.deleteIn([
        'groups',
        selectedGroupIndex,
        'children',
        teamIndex,
      ]);
      if (state.get('teamId') === action.payload) {
        //TODO should probably select another team here?
        return newState.set('teamId', null);
      } else {
        return newState;
      }
    }

    case SET_SHOW_ADD_TEAM_MODAL: {
      return state.set('showAddTeamModal', action.payload.showAddTeamModal);
    }

    default:
      return state;
  }
};
