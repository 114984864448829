import {fromJS} from 'immutable';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import {
  EMPTY_AI_LESSON_DESCRIPTION,
  validateLessonOrAssessment,
} from '@edume/bento/lessonValidation';

import {
  ADD_LESSON,
  ADD_LESSON_FAIL,
  ADD_LESSON_SUCCESS,
  CHANGE_ORDER,
  CHANGE_ORDER_SUCCESS,
  COPY_LESSON,
  COPY_LESSON_SUCCESS,
  CREATE_MODULE_SURVEY_SUCCESS,
  DELETE_LESSON_SUCCESS,
  DELETE_MODULE_SURVEY_SUCCESS,
  DELETE_TRANSLATION,
  IMPORT_COURSE_FROM_GSHEET_SUCCESS,
  PUBLISH_COURSE_SUCCESS,
  PUBLISH_LESSON_SUCCESS,
  UNPUBLISH_COURSE_SUCCESS,
  UPDATE_DEFAULT_LANGUAGE_SUCCESS,
} from '../course/courseActionTypes';
import {getCourseByModuleId} from '../course/courseSelector';
import {store} from '../store';
import lessonTemplates from './lessonTemplates';
import {
  GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT,
  GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_FAIL,
  GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_SUCCESS,
  GENERATE_LESSONS_FROM_TOPICS,
  GENERATE_LESSONS_FROM_TOPICS_FAIL,
  GENERATE_LESSONS_FROM_TOPICS_SUCCESS,
  GET_COURSE_SURVEYS,
  GET_COURSE_SURVEYS_SUCCESS,
  GET_EDURATING,
  GET_EDURATING_FAIL,
  GET_EDURATING_SUCCESS,
  GET_LESSON,
  LOAD_LESSONS,
  RESET_GENERATED_SUGGESTIONS,
  RESET_MODULE_ACTIVITY,
  START_EDITING_ACTIVITY,
  START_EDITING_END,
  STOP_EDITING_ACTIVITY,
  STOP_EDITING_END,
  UPDATE_ESON,
  UPDATE_LESSON,
  UPDATE_LESSON_FAIL,
  UPDATE_LESSON_SUCCESS,
} from './moduleActivityActionTypes';

const isEndOfActivityEnabled = (moduleId) => {
  const state = store.getState();
  const {customerFlags} = state.get('featureFlags').toJS();
  const course = getCourseByModuleId(state, moduleId);
  return customerFlags?.customEndOfActivityEnabled && !course?.isFountainOnly;
};

const addValidityToLesson = (lesson, defaultCourseLanguage) => {
  const endOfActivityEnabled = isEndOfActivityEnabled(lesson.moduleId);
  const valid = validateLessonOrAssessment(
    lesson.eson,
    false,
    endOfActivityEnabled
  ).result;

  const languages = lesson.languages || {};

  if (defaultCourseLanguage) {
    languages[defaultCourseLanguage] = {
      hasIncompleteActivities: !valid,
    };
  }

  return {
    ...lesson,
    languages,
    valid,
  };
};

const shiftPosition = (activity, moduleId, deletedPosition) => {
  const position = activity.get('position');
  const activityModuleId = activity.get('moduleId');
  return position > deletedPosition && activityModuleId === moduleId
    ? activity.set('position', position - 1)
    : activity;
};

const shiftPositions = (state, lessons, surveys, moduleId, deletedPosition) =>
  state
    .set(
      'lessons',
      lessons.map((lesson) => shiftPosition(lesson, moduleId, deletedPosition))
    )
    .set(
      'surveys',
      surveys.map((survey) => shiftPosition(survey, moduleId, deletedPosition))
    );

export const initialState = fromJS({
  courseId: null,
  lessons: [],
  surveys: [],
  lessonsLoading: false,
  surveysLoading: false,
  lessonsLoaded: false,
  editLessonInProgress: false,
  copyLessonInProgress: false,
  activityIndex: -1,
  editingEndBubble: false,
  lastLessonCreatedType: null,
  aiChatCompletion: null,
  selectedLanguage: null,
  eduRating: null,
  isEduratingLoading: false,
  isGeneratingSuggestions: false,
  generatedSuggestions: null,
});

// eslint-disable-next-line max-statements, complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ESON: {
      const {lessonKey, data} = action.payload;
      const {content: eson, duration, language} = data;
      const lessons = state.get('lessons');
      const lessonIndex = lessons.findIndex(
        (lesson) => lesson.get('key') === lessonKey
      );
      const moduleId = lessons.toJS()[lessonIndex].moduleId;
      const endOfActivityEnabled = isEndOfActivityEnabled(moduleId);

      // eslint-disable-next-line no-undefined
      const valid = validateLessonOrAssessment(
        eson,
        undefined,
        endOfActivityEnabled
      ).result;

      const selectedLanguage = state.get('selectedLanguage');
      const newLessonsBase = lessons.setIn(
        [lessonIndex, 'languages', language, 'hasIncompleteActivities'],
        !valid
      );

      if (!!selectedLanguage && selectedLanguage !== language) {
        return state.set('lessons', newLessonsBase);
      }

      const newLessons = newLessonsBase
        .setIn([lessonIndex, 'eson'], fromJS(eson))
        .setIn([lessonIndex, 'valid'], valid)
        .setIn([lessonIndex, 'minutes'], duration);

      return state.set('lessons', newLessons);
    }
    case GET_LESSON: {
      return state.set('lessonsLoading', true);
    }

    //doesn't use GET_LESSON_SUCCESS as requires lesson key augmenting atm
    case LOAD_LESSONS: {
      const {lessons: payloadLessons, courseId, language} = action.payload;
      const lessons = _.sortBy(payloadLessons, (lesson) => lesson.position);
      return state
        .set(
          'lessons',
          fromJS(
            lessons
              .map((lesson) => addValidityToLesson(lesson, language))
              .map((lesson) => ({
                ...lesson,
                hasAiError:
                  lesson.eson.params.intro?.title ===
                  EMPTY_AI_LESSON_DESCRIPTION,
              }))
          )
        )
        .set('lessonsLoading', false)
        .set('lessonsLoaded', true)
        .set('courseId', courseId)
        .set('selectedLanguage', language);
    }
    case GET_COURSE_SURVEYS:
      return state.set('surveys', fromJS([])).set('surveysLoading', true);
    case GET_COURSE_SURVEYS_SUCCESS:
      return state
        .set('surveys', fromJS(action.payload.data))
        .set('surveysLoading', false);
    case ADD_LESSON:
    case CHANGE_ORDER:
    case UPDATE_LESSON: {
      return state.set('editLessonInProgress', true);
    }
    case COPY_LESSON: {
      return state.set('copyLessonInProgress', true);
    }
    case ADD_LESSON_SUCCESS: {
      const lessons = state.get('lessons');
      const {
        id,
        moduleId,
        lessonKey,
        content,
        duration: minutes,
        position,
      } = action.payload.data;

      const {courseDefaultLanguage} = action.meta;

      const lesson = {
        id,
        moduleId,
        eson: content,
        key: lessonKey,
        position,
        minutes,
        published: false,
        valid: false,
      };

      return state
        .set('editLessonInProgress', false)
        .set(
          'lessons',
          lessons.push(
            fromJS(addValidityToLesson(lesson, courseDefaultLanguage))
          )
        );
    }
    case CREATE_MODULE_SURVEY_SUCCESS: {
      const surveys = state.get('surveys');
      const survey = action.payload.data;
      const {courseDefaultLanguage} = action.meta;

      return state
        .set('surveys', surveys.push(fromJS(survey)))
        .set('selectedLanguage', courseDefaultLanguage);
    }
    case UPDATE_LESSON_FAIL:
    case ADD_LESSON_FAIL: {
      return state.set('editLessonInProgress', false);
    }
    case UPDATE_LESSON_SUCCESS: {
      return state
        .set('editLessonInProgress', false)
        .set('lastLessonCreatedType', null);
    }
    case DELETE_LESSON_SUCCESS: {
      const {lessonKey, moduleId, position} = action.meta;
      const lessons = state.get('lessons');
      const surveys = state.get('surveys');
      const filteredLessons = lessons.filter(
        (lesson) => lesson.get('key') !== lessonKey
      );
      //Adjusts positions of lessons past the deleted one
      return shiftPositions(
        state,
        filteredLessons,
        surveys,
        moduleId,
        position
      );
    }
    case COPY_LESSON_SUCCESS: {
      const {moduleId} = action.meta;
      if (moduleId) {
        const lessons = state.get('lessons');
        const {
          id,
          content,
          position,
          lessonKey,
          duration: minutes,
          languages,
        } = action.payload.data;
        const lesson = {
          id,
          moduleId,
          eson: content,
          key: lessonKey,
          position,
          minutes,
          published: false,
          valid: false,
          languages,
        };
        return state
          .set('lessons', lessons.push(fromJS(addValidityToLesson(lesson))))
          .set('copyLessonInProgress', false);
      } else {
        return state.set('copyLessonInProgress', false);
      }
    }

    case DELETE_MODULE_SURVEY_SUCCESS: {
      const {surveyId, moduleId, position} = action.meta;
      const surveys = state.get('surveys');
      const lessons = state.get('lessons');
      const filteredSurveys = surveys.filter(
        (survey) => survey.get('id') !== surveyId
      );
      return shiftPositions(
        state,
        lessons,
        filteredSurveys,
        moduleId,
        position
      );
    }

    case CHANGE_ORDER_SUCCESS: {
      const moduleActivities = action.payload.data;
      const lessons = state.get('lessons').toJS();
      const surveys = state.get('surveys').toJS();
      moduleActivities.forEach((activity) => {
        if (activity.lessonId) {
          const lesson = lessons.find((l) => l.id === activity.lessonId);
          lesson.position = activity.position;
          lesson.moduleId = activity.moduleId;
        } else if (activity.surveyId) {
          const survey = surveys.find((s) => s.id === activity.surveyId);
          survey.position = activity.position;
          survey.moduleId = activity.moduleId;
        }
      });
      const newState = state
        .set('lessons', fromJS(lessons))
        .set('surveys', fromJS(surveys))
        .set('editLessonInProgress', false);
      return newState;
    }

    case PUBLISH_COURSE_SUCCESS:
    case UNPUBLISH_COURSE_SUCCESS: {
      const published = action.type === PUBLISH_COURSE_SUCCESS;
      const lessons = state.get('lessons');
      return state.set(
        'lessons',
        lessons.map((l) => l.set('published', published))
      );
    }

    case PUBLISH_LESSON_SUCCESS: {
      const lessons = state.get('lessons');
      //only one lesson can be unpublished, just update them all for simplicity
      return state.set(
        'lessons',
        lessons.map((l) => l.set('published', true))
      );
    }

    case START_EDITING_ACTIVITY:
      return state.set('activityIndex', action.payload);

    case STOP_EDITING_ACTIVITY:
      return state.set('activityIndex', -1);

    case START_EDITING_END:
      return state.set('editingEndBubble', true);
    case STOP_EDITING_END:
      return state.set('editingEndBubble', false);

    case RESET_MODULE_ACTIVITY: {
      if (action.payload !== state.get('courseId')) {
        return initialState;
      }

      const newState = state
        .set('lessonsLoading', false)
        .set('lessonsLoaded', false)
        .set('surveysLoading', false)
        .set('editLessonInProgress', false)
        .set('copyLessonInProgress', false)
        .set('activityIndex', -1)
        .set('editingEndBubble', false);

      return newState;
    }

    case GET_EDURATING: {
      return state.set('isEduratingLoading', true);
    }

    case GET_EDURATING_SUCCESS: {
      const data = action.payload.data;
      return state.set('eduRating', data).set('isEduratingLoading', false);
    }

    case GET_EDURATING_FAIL: {
      return state.set('isEduratingLoading', false);
    }

    case DELETE_TRANSLATION: {
      const deletedLanguage = action.meta.language;
      const selectedLanguage = state.get('selectedLanguage');

      const lessons = state.get('lessons');

      if (lessons.length === 0) {
        return state;
      }

      const newState = state.set(
        'lessons',
        lessons.map((lesson) => {
          const languages = lesson.get('languages').delete(deletedLanguage);
          return lesson.set('languages', languages);
        })
      );

      if (deletedLanguage === selectedLanguage) {
        return newState.set('selectedLanguage', initialState.selectedLanguage);
      }
      return newState;
    }

    case UPDATE_DEFAULT_LANGUAGE_SUCCESS: {
      const {defaultLanguage} = action.meta;

      return state.set('selectedLanguage', defaultLanguage);
    }

    case IMPORT_COURSE_FROM_GSHEET_SUCCESS: {
      return state.set('selectedLanguage', initialState.selectedLanguage);
    }

    case GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT: {
      return state
        .set('isGeneratingSuggestions', true)
        .set('generatedSuggestions', null);
    }

    case GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_SUCCESS: {
      const topics = action.payload.data?.topics;
      const generatedSuggestions =
        Array.isArray(topics) && topics.length ? topics : null;
      return state
        .set('isGeneratingSuggestions', false)
        .set('generatedSuggestions', generatedSuggestions);
    }
    case GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT_FAIL: {
      return state
        .set('isGeneratingSuggestions', false)
        .set('generatedSuggestions', null);
    }
    case RESET_GENERATED_SUGGESTIONS: {
      return state.set('generatedSuggestions', null);
    }

    case GENERATE_LESSONS_FROM_TOPICS: {
      const {topics, moduleId} = action.meta;
      const lessons = state.get('lessons');
      const surveys = state.get('surveys');

      const numberOfActivities = lessons.count() + surveys.count();

      const tempLessons = topics.map((topic, index) => {
        const templateLesson = cloneDeep(lessonTemplates.slideshow.blank);
        templateLesson.params.title = topic.title;

        return {
          id: `@temp-lesson-${topic.title}`,
          moduleId,
          eson: templateLesson,
          key: null,
          position: numberOfActivities + index + 1,
          minutes: null,
          published: false,
          valid: false,
          isGenerating: true,
        };
      });

      return state.set('lessons', lessons.push(...tempLessons));
    }

    case GENERATE_LESSONS_FROM_TOPICS_SUCCESS:
    case GENERATE_LESSONS_FROM_TOPICS_FAIL: {
      const lessons = state.get('lessons');

      return state.set(
        'lessons',
        lessons.filter((l) => !l.isGenerating)
      );
    }

    default: {
      return state;
    }
  }
};
