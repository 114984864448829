import React, {useEffect, useRef, useState} from 'react';

import axios from 'axios';
import {mapValues} from 'lodash';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';

import {Button, HintBox, Link, LoadingIndicator} from '@edume/magnificent';

import {StyledModal} from '../../../containers/StyledModals';
import * as imageLibraryActions from '../../../state/imageLibrary/imageLibraryActions';
import * as notificationActions from '../../../state/notification/notificationActions';
import {contain, intersect2D} from '../../../utils/crop.js';
import {
  CANCELLED_COMPONENT_UNMOUNTED,
  getImageDimensions,
  handleImageUpload,
  readURL,
  shouldErrorFileTooBig,
  shouldWarnFileTooBig,
} from '../../../utils/fileUpload.js';
import LegacyIntlHeading from '../LegacyIntlHeading';
import FilePicker from './FilePicker';
import ImageLibrary from './ImageLibrary';
import ImagePreviewer from './ImagePreviewer';
import ToolbarButtons from './ToolbarButtons';

import styles from './styles.module.scss';
import 'cropperjs/dist/cropper.css';

const UploadImageModal = ({
  imageUrl,
  croppedImageUrl,
  cropAspectRatio,
  showOriginalImage,
  hintTextKey,
  onSetImage,
  onSetCroppedImage,
  onSaveChanges,
  onRemove,
  onClose,
  addImageToLibrary,
  groupId,
  getImagesFromLibrary,
  isLibraryLoading,
  groupImages,
  edumeImages,
  resetImageLibraryIsLoading,
  deleteImageFromLibrary,
  trackImageUse,
  createErrorNotification,
  isRemovingEnabled,
}) => {
  const initialStep = 'library';
  const intl = useIntl();
  const cropper = useRef(null);
  const axiosCancelSource = useRef(null);
  const [step, setStep] = useState(() =>
    imageUrl || croppedImageUrl ? 'initial-seeded' : initialStep
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCropperLoading, setIsCropperLoading] = useState(true);
  const [isEduMeLibrary, setIsEduMeLibrary] = useState(false);
  const [lastPickedFile, setLastPickedFile] = useState({
    content: null,
    url: null,
    width: null,
    height: null,
    imageId: null,
    groupId: null,
  });

  useEffect(() => {
    axiosCancelSource.current = axios.CancelToken.source();
    return () => {
      axiosCancelSource.current.cancel(CANCELLED_COMPONENT_UNMOUNTED);
    };
  }, []);

  useEffect(() => {
    getImagesFromLibrary(groupId);

    return () => resetImageLibraryIsLoading();
  }, [getImagesFromLibrary, groupId, resetImageLibraryIsLoading]);

  const startUpload = async (files, {ignoreWarning = false} = {}) => {
    if (files.length) {
      setStep('uploading');
      const file = files[0];
      const url = await readURL(file);
      const fileName = file.name;
      const {width, height} = await getImageDimensions(url);

      setLastPickedFile({
        content: file,
        url,
        width,
        height,
        imageId: null,
        groupId: groupId,
      });

      if (shouldErrorFileTooBig(file, 'image')) {
        setStep('file-size-error');
      } else if (shouldWarnFileTooBig(file, 'image') && !ignoreWarning) {
        setStep('file-size-warning');
      } else {
        handleImageUpload(file, axiosCancelSource.current)
          .then((uploadedImage) => {
            onSetImage(uploadedImage.url, {fileName, width, height});
            setIsCropperLoading(true);
            setStep('crop');
          })
          .catch((err) => {
            if (err.message !== CANCELLED_COMPONENT_UNMOUNTED) {
              setStep('upload-error');
              throw err;
            }
          });
      }
    } else {
      createErrorNotification('UploadModal.error.fileType');
    }
  };

  const chooseImage = async (image) => {
    const {width, height} = await getImageDimensions(image.url);

    setLastPickedFile({
      content: {name: image.filename},
      url: image.url,
      width,
      height,
      imageId: image.id,
      groupId: image.groupId,
    });
    onSetImage(image.url, {fileName: image.filename, width, height});
    setIsCropperLoading(true);
    setStep('crop');
  };

  const deleteImage = (id) => {
    deleteImageFromLibrary(groupId, id);
  };

  const retryUpload = () => {
    startUpload([lastPickedFile.content], {ignoreWarning: true});
  };

  const goToLibrary = () => {
    setStep(initialStep);
  };

  const confirmCrop = () => {
    let {
      x: cropX,
      y: cropY,
      width: cropWidth,
      height: cropHeight,
    } = cropper.current.getData(true);
    const {x, y, width, height} = intersect2D(
      {
        x: parseInt(cropX),
        y: parseInt(cropY),
        width: parseInt(cropWidth),
        height: parseInt(cropHeight),
      },
      {x: 0, y: 0, width: lastPickedFile.width, height: lastPickedFile.height}
    );
    const container = mapValues(contain(width, height, cropAspectRatio), (n) =>
      parseInt(n)
    );
    const resizedUrl = imageUrl.replace(
      '/image/upload/',
      // eslint-disable-next-line max-len
      `/image/upload/x_${x},y_${y},w_${width},h_${height},c_crop/w_${container.width},h_${container.height},c_pad/`
    );
    onSetCroppedImage(resizedUrl, {
      fileName: lastPickedFile.content.name,
      width: container.width,
      height: container.height,
    });
    setStep('success');
  };

  const onConfirm = () => {
    if (lastPickedFile.imageId === null) {
      addImageToLibrary(
        imageUrl,
        lastPickedFile.content.name,
        groupId,
        lastPickedFile.width,
        lastPickedFile.height
      );
    } else if (lastPickedFile.groupId === null) {
      trackImageUse(groupId, lastPickedFile.imageId);
    }

    onSaveChanges();
  };

  const steps = {
    initial: () => ({
      title: intl.formatMessage({id: 'UploadModal.title'}, {type: 'image'}),
      body: (
        <FilePicker
          type='image'
          onFilePick={startUpload}
          isUploading={false}
          hintTextKey={hintTextKey}
        />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
          dataAutomation: 'cancel-button',
        },
      ],
    }),
    library: () => ({
      title: (
        <div style={{paddingLeft: 24}}>
          {intl.formatMessage({id: 'UploadModal.title'}, {type: 'image'})}
        </div>
      ),
      body: (
        <ImageLibrary
          isLibraryLoading={isLibraryLoading}
          imagesFromLibrary={isEduMeLibrary ? edumeImages : groupImages}
          startUpload={startUpload}
          hintTextKey={hintTextKey}
          chooseImage={chooseImage}
          deleteImage={deleteImage}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isEduMeLibrary={isEduMeLibrary}
        />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
          dataAutomation: 'cancel-button',
        },
      ],
      sidebarConfig: {
        title: intl.formatMessage({id: 'ImageLibrary.tab.title'}),
        options: [
          {
            text: intl.formatMessage({id: 'ImageLibrary.tab.group'}),
            onClick: () => {
              setIsEduMeLibrary(false);
            },
            iconUrl: '/resources/img/24_monitor.svg',
            active: !isEduMeLibrary,
          },
          {
            text: intl.formatMessage({id: 'ImageLibrary.tab.edume'}),
            onClick: () => {
              setIsEduMeLibrary(true);
            },
            iconUrl: '/resources/img/24_box.svg',
            active: isEduMeLibrary,
            dataAutomation: 'edume-library-option',
          },
        ],
        bottomContent: {
          text: intl.formatMessage({id: 'ImageLibrary.tab.unsplash'}),
          linkText: intl.formatMessage({id: 'ImageLibrary.unsplashLink'}),
          linkHref: 'https://unsplash.com/',
          typeOfLink: '',
        },
      },
    }),
    'initial-seeded': () => ({
      title: intl.formatMessage(
        {id: 'UploadModal.changeMediaTitle'},
        {type: 'image'}
      ),
      body: (
        <ImagePreviewer
          imageUrl={imageUrl}
          croppedImageUrl={croppedImageUrl}
          showOriginalImage={showOriginalImage}
        />
      ),
      buttons: [
        {
          type: 'primary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.okay'}),
          dataAutomation: 'done-button',
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <ToolbarButtons
            type='image'
            onReplace={startUpload}
            onRemove={onRemove}
            isRemovingEnabled={isRemovingEnabled}
            goToLibrary={goToLibrary}
          />
        ),
      },
    }),
    'file-size-warning': () => ({
      iconUrl: '/resources/img/38_alert.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.warningTitle'},
        {type: 'image'}
      ),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.imageWarningHint' />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: retryUpload,
          text: intl.formatMessage({id: 'UploadModal.warningProceed'}),
          dataAutomation: 'upload-anyway-button',
        },
        {
          type: 'primary',
          onClick: goToLibrary,
          text: intl.formatMessage({id: 'UploadModal.warningStop'}),
          dataAutomation: 'dont-upload-button',
        },
      ],
    }),
    'file-size-error': () => ({
      iconUrl: '/resources/img/38_cross.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.errorTitle'},
        {type: 'image'}
      ),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.imageErrorHint' />
      ),
      buttons: [
        {
          type: 'primary',
          onClick: goToLibrary,
          text: intl.formatMessage({id: 'Button.goBack'}),
          dataAutomation: 'try-again-button',
        },
      ],
    }),
    uploading: () => ({
      title: intl.formatMessage({id: 'UploadModal.title'}, {type: 'image'}),
      body: (
        <FilePicker
          type='image'
          onFilePick={() => {}}
          isUploading={true}
          hintTextKey={hintTextKey}
        />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
          dataAutomation: 'cancel-button',
        },
      ],
    }),
    'upload-error': () => ({
      iconUrl: '/resources/img/38_cross.svg',
      title: intl.formatMessage({id: 'UploadModal.uploadErrorTitle'}),
      body: (
        <LegacyIntlHeading size='mini' textKey='UploadModal.uploadErrorHint' />
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: goToLibrary,
          text: intl.formatMessage({id: 'UploadModal.cancelUploadButton'}),
          dataAutomation: 'cancel-upload-button',
        },
        {
          type: 'primary',
          onClick: retryUpload,
          text: intl.formatMessage({id: 'Button.tryAgain'}),
          dataAutomation: 'try-again-button',
        },
      ],
    }),
    crop: () => ({
      title: intl.formatMessage({id: 'UploadModal.cropTitle'}),
      body: (
        <div className={styles['cropper-container']}>
          {isCropperLoading && (
            <LoadingIndicator
              indicator='hexagons'
              containerStyle='centerAbsolute'
            />
          )}
          <Cropper
            style={{height: '60vh'}}
            ready={() => setIsCropperLoading(false)}
            dragMode='move'
            modal={false}
            zoomOnWheel={false}
            src={lastPickedFile.url}
            aspectRatio={cropAspectRatio}
            ref={(node) => {
              cropper.current = node;
            }}
          />
          {showOriginalImage && (
            <HintBox
              title={intl.formatMessage({id: 'UploadModal.popoutHintTitle'})}
              text={intl.formatMessage({
                id: 'UploadModal.popoutHintDescription',
              })}
            />
          )}
          <div className={styles['zoom-buttons-container']}>
            <Button
              type='thirdary'
              darkIconUrl='/resources/img/24_add.svg'
              iconUrl='/resources/img/24_add.svg'
              noMarginLeft={true}
              noMarginTop={true}
              onClick={() => cropper.current.zoom(0.1)}
            />
            <Button
              type='thirdary'
              darkIconUrl='/resources/img/24_minus.svg'
              iconUrl='/resources/img/24_minus.svg'
              noMarginLeft={true}
              noMarginTop={true}
              onClick={() => cropper.current.zoom(-0.1)}
            />
          </div>
        </div>
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
          dataAutomation: 'cropper-cancel-button',
        },
        {
          type: 'primary',
          onClick: confirmCrop,
          text: intl.formatMessage({id: 'Button.okay'}),
          dataAutomation: 'cropper-done-button',
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <div>
            <Link
              text={intl.formatMessage({id: 'UploadModal.differentImageLink'})}
              onClick={goToLibrary}
            />
          </div>
        ),
      },
    }),
    success: () => ({
      iconUrl: '/resources/img/38_tick.svg',
      title: intl.formatMessage(
        {id: 'UploadModal.successTitle'},
        {type: 'image'}
      ),
      body: (
        <ImagePreviewer
          imageUrl={imageUrl}
          croppedImageUrl={croppedImageUrl}
          showOriginalImage={showOriginalImage}
        />
      ),
      buttons: [
        {
          type: 'primary',
          onClick: onConfirm,
          text: intl.formatMessage({id: 'Button.okay'}),
          dataAutomation: 'upload-done-button',
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <ToolbarButtons
            type='image'
            onReplace={startUpload}
            onRemove={onRemove}
            isRemovingEnabled={isRemovingEnabled}
            goToLibrary={goToLibrary}
          />
        ),
      },
    }),
  };

  return (
    <StyledModal
      onClose={onClose}
      content={steps[step]()}
      underlayClickExits={!isDeleteModalOpen}
      compact
    />
  );
};

UploadImageModal.propTypes = {
  imageUrl: PropTypes.string,
  croppedImageUrl: PropTypes.string,
  cropAspectRatio: PropTypes.number,
  showOriginalImage: PropTypes.bool.isRequired,
  hintTextKey: PropTypes.string.isRequired,
  onSetImage: PropTypes.func.isRequired,
  onSetCroppedImage: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  addImageToLibrary: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  getImagesFromLibrary: PropTypes.func.isRequired,
  isLibraryLoading: PropTypes.bool.isRequired,
  groupImages: PropTypes.array.isRequired,
  edumeImages: PropTypes.array.isRequired,
  resetImageLibraryIsLoading: PropTypes.func.isRequired,
  deleteImageFromLibrary: PropTypes.func.isRequired,
  trackImageUse: PropTypes.func.isRequired,
  createErrorNotification: PropTypes.func.isRequired,
  isRemovingEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  groupId: state.getIn(['team', 'groupId']),
  isLibraryLoading: state.getIn(['imageLibrary', 'isLibraryLoading']),
  groupImages: state.getIn(['imageLibrary', 'groupImages']).toJS(),
  edumeImages: state.getIn(['imageLibrary', 'edumeImages']).toJS(),
});

const mapDispatchToProps = {
  addImageToLibrary: imageLibraryActions.addImageToLibrary,
  getImagesFromLibrary: imageLibraryActions.getImagesFromLibrary,
  resetImageLibraryIsLoading: imageLibraryActions.resetImageLibraryIsLoading,
  deleteImageFromLibrary: imageLibraryActions.deleteImageFromLibrary,
  trackImageUse: imageLibraryActions.trackImageUse,
  createErrorNotification: notificationActions.createErrorNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImageModal);
