import {push} from 'connected-react-router';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import * as authActions from '../auth/authActions';
import * as contentActions from '../content/contentActions';
import {getCourseById, getCourseByKey} from '../course/courseSelector';
import * as formActions from '../form/formActions';
import * as moduleActivityActions from '../moduleActivity/moduleActivityActions';
import * as navigationActions from '../navigation/navigationActions';
import * as notificationActions from '../notification/notificationActions';
import {getStatsForGroup} from '../reporting/reportingActions';
import * as teamActions from '../team/teamActions';
import {getSelectedGroupId} from '../team/teamSelectors';
import * as courseActionCreators from './courseActionCreators';
import {
  ACCESSED_COURSE_REPORT_LINK,
  CHANGE_COURSE_TAB,
  COURSE_TABS,
  DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR,
  REDIRECT_COMPLETED,
  REDIRECT_IN_PROGRESS,
  RESET_COURSE_LOADED,
  SEARCH_COURSE,
  SELECTED_LEARNING_TAB_FILTER,
  SET_ACTIVE_SIDEBAR_ITEM,
  SET_CONTENT_FILTER,
  SET_COURSE_CARD_DESIGN,
  SET_IS_USER_ADDING_CONTENT,
  SET_IS_USER_ADDING_COURSE,
  SIDEBAR_TABS,
  TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL,
  TOGGLE_COURSE_OTP_SLIDEPANEL,
  TOGGLE_SIDEBAR_CREATION_EXPANSION,
  VIEWED_PREVIEW_LINK,
} from './courseActionTypes';

export const resetCourseLoaded = () => (dispatch) =>
  dispatch({type: RESET_COURSE_LOADED});

export const loadCourse = (courseKey, language) => (dispatch, getState) => {
  const contentLanguage =
    language || getState().getIn(['moduleActivity', 'selectedLanguage']);
  return dispatch(courseActionCreators.getCourse(courseKey, contentLanguage));
};

export const goToCourse = (courseId, groupId) => async (dispatch, getState) => {
  dispatch({type: REDIRECT_IN_PROGRESS});
  const currentGroup = getSelectedGroupId(getState());
  if (groupId !== currentGroup) {
    teamActions.selectGroup(groupId)(dispatch, getState);
  }
  dispatch({type: RESET_COURSE_LOADED});
  dispatch(moduleActivityActions.resetModuleActivity());
  await dispatch(push(`/learning/course/${courseId}${location.search}`));
  dispatch({type: REDIRECT_COMPLETED});
};

export const addCourse =
  (courseContent, groupId, meta) => (dispatch, getState) => {
    dispatch(courseActionCreators.addCourse(courseContent, groupId, meta))
      .then((action) => action.payload.data)
      .then((course) => {
        dispatch(push(`/learning/course/${course.id}${location.search}`));
      })
      .then(() => contentActions.loadContents()(dispatch, getState))
      .then(() => {
        const messageKey = 'Learning.createCourseSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .then(() => formActions.closeForm()(dispatch))
      .then(() => authActions.recordCreateCourse()(dispatch))
      .catch((error) => {
        formActions.addFormErrorFromResponse(error)(dispatch);
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };

export const updateTeamsInCourse =
  (courseKey, teamIdsToAdd, teamIdsToRemove) => (dispatch, getState) => {
    dispatch(
      courseActionCreators.updateTeamsInCourse(
        courseKey,
        teamIdsToAdd,
        teamIdsToRemove
      )
    )
      .then(() => contentActions.loadContents()(dispatch, getState))
      .then(() => {
        const messageKey = 'Learning.updateTeamsSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const updateTeamsInCourseForIntegrations =
  (courseKey, teamIdsToAdd, teamIdsToRemove, externalTeamIntegrations) =>
  async (dispatch, getState) => {
    const parentGroupId = getSelectedGroupId(getState());
    if (externalTeamIntegrations && externalTeamIntegrations.length > 0) {
      try {
        const {payload} = await dispatch(
          courseActionCreators.updateExternalTeams(
            parentGroupId,
            externalTeamIntegrations
          )
        );
        dispatch(
          teamActions.loadTeams({
            otherTabsRequireUpdate: false,
            fetchExternalTeams: true,
          })
        );
        const newTeamIds = payload.data;
        updateTeamsInCourse(
          courseKey,
          [...teamIdsToAdd, ...newTeamIds],
          teamIdsToRemove
        )(dispatch, getState);
      } catch (err) {
        notificationActions.createErrorNotificationFromResponse(err)(dispatch);
      }
    } else {
      updateTeamsInCourse(
        courseKey,
        teamIdsToAdd,
        teamIdsToRemove
      )(dispatch, getState);
    }
  };

export const deleteCourse = (courseKey) => (dispatch) => {
  dispatch(courseActionCreators.deleteCourse(courseKey))
    .then(() => {
      const messageKey = 'Learning.deleteCourseSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .then(() => {
      dispatch(navigationActions.goToLearningTab());
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const copyDemoCourseToCustomer =
  ({courseId, groupId}) =>
  (dispatch, getState) => {
    dispatch(courseActionCreators.copyDemoCourseToCustomer(courseId, groupId))
      .then(() => {
        contentActions.loadContents()(dispatch, getState);
      })
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

const updateCourse =
  ({courseKey, update, earlyUpdate, closeForm, successKey, lessons}) =>
  (dispatch, getState) => {
    const course = getCourseByKey(getState(), courseKey);
    const propertiesToSend = [
      'courseImageUrl',
      'title',
      'icon',
      'colour',
      'type',
      'requiresEnrolment',
      'isFountainOnly',
      'waitSendCompletionData',
      'isAutostartEnabled',
      'isSequentialCompletionsEnabled',
    ];
    const previousDetails = pick(course, propertiesToSend);
    const newDetails = {...previousDetails, ...update};

    dispatch(
      courseActionCreators.updateCourse(
        courseKey,
        newDetails,
        earlyUpdate || false,
        previousDetails,
        lessons
      )
    )
      .then(() => {
        if (closeForm) {
          formActions.closeForm()(dispatch);
        }
      })
      .then(() => {
        if (successKey) {
          notificationActions.createSuccessNotification(successKey)(dispatch);
        }
      })
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const updateDefaultLanguage =
  (courseId, defaultLanguage, previousDefaultLanguage) => (dispatch) => {
    dispatch(
      courseActionCreators.updateDefaultLanguage(
        courseId,
        defaultLanguage,
        previousDefaultLanguage
      )
    ).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  };

export const translateCourse = (course, language) => (dispatch, getState) => {
  const {id, courseKey} = course;
  const lessonKeys = getState()
    .getIn(['moduleActivity', 'lessons'])
    .map((lesson) => lesson.get('key'))
    .toJS();
  dispatch(courseActionCreators.translateCourse(id, language))
    .then(() => {
      dispatch(moduleActivityActions.loadLessons(lessonKeys, id, language));
      dispatch(loadCourse(courseKey, language));
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const deleteTranslation =
  (courseId, language, successKey) => (dispatch) => {
    dispatch(courseActionCreators.deleteTranslation(courseId, language))
      .then(() =>
        notificationActions.createSuccessNotification(successKey)(dispatch)
      )
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const updateCourseHideTitleSlide =
  ({courseKey, hideTitleSlide}) =>
  (dispatch) => {
    dispatch(
      courseActionCreators.updateCourseHideTitleSlide(courseKey, hideTitleSlide)
    ).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  };

export const updateCourseTitle = (courseKey, title) =>
  updateCourse({
    courseKey,
    update: {title},
    closeForm: true,
    successKey: 'Learning.updateCourseSuccess',
  });

export const updateCourseDetails = (courseKey, title, icon, colour) =>
  updateCourse({
    courseKey,
    update: {title, icon, colour},
    closeForm: true,
    successKey: 'Learning.updateCourseSuccess',
  });

export const updateCourseImage = (courseKey, courseImageUrl) =>
  updateCourse({
    courseKey,
    update: {courseImageUrl: courseImageUrl || null},
    successKey: 'Learning.updateCourseImageSuccess',
  });

export const updateCourseEnrolment = (courseKey, requiresEnrolment) =>
  updateCourse({
    courseKey,
    update: {requiresEnrolment},
    earlyUpdate: true,
  });

export const updateCourseWaitSendCompletionData = (
  courseKey,
  waitSendCompletionData
) =>
  updateCourse({
    courseKey,
    update: {waitSendCompletionData},
    earlyUpdate: true,
  });

export const updateCourseType = (courseKey, type) =>
  updateCourse({
    courseKey,
    update: {type},
    earlyUpdate: true,
  });

export const updateFountainStatus = (courseKey, isFountainOnly, lessons) =>
  updateCourse({
    courseKey,
    update: {isFountainOnly},
    lessons,
  });

export const updateCourseAutostart = (courseKey, isAutostartEnabled) =>
  updateCourse({
    courseKey,
    update: {isAutostartEnabled},
    earlyUpdate: true,
  });

export const updateCourseSequentialCompletions = (
  courseKey,
  isSequentialCompletionsEnabled
) =>
  updateCourse({
    courseKey,
    update: {isSequentialCompletionsEnabled},
    earlyUpdate: true,
  });

export const publishCourse = (courseKey) => (dispatch) => {
  dispatch(courseActionCreators.publishCourse(courseKey))
    .then(() => {
      notificationActions.createSuccessNotification(
        'PublishModal.coursePublishedSuccessfully'
      )(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const unpublishCourse = (courseKey) => (dispatch, getState) => {
  dispatch(courseActionCreators.unpublishCourse(courseKey))
    .then(() => {
      notificationActions.createSuccessNotification(
        'UnpublishModal.courseUnPublishedSuccessfully'
      )(dispatch);
    })
    .then(() => contentActions.loadContents()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const publishLesson =
  (courseKey, courseId, lessonKey, eson) => async (dispatch) => {
    try {
      if (eson) {
        await dispatch(moduleActivityActions.saveLesson(lessonKey, eson));
      }
      await dispatch(courseActionCreators.publishLesson(courseKey, lessonKey));
      notificationActions.createSuccessNotification(
        'PublishLessonModal.successNotification'
      )(dispatch);
      return dispatch(push(`/learning/course/${courseId}${location.search}`));
    } catch (error) {
      return notificationActions.createErrorNotificationFromResponse(error)(
        dispatch
      );
    }
  };

export const changeModuleActivityOrder =
  (courseId, targetModuleId, lessonId, surveyId, position) => (dispatch) =>
    dispatch(
      courseActionCreators.changeModuleActivityOrder(
        courseId,
        targetModuleId,
        lessonId,
        surveyId,
        position
      )
    ).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );

export const addModule = (courseId, title) => (dispatch, getState) => {
  dispatch(courseActionCreators.addModule(courseId, title))
    .then(() => {
      contentActions.loadContents()(dispatch, getState);
      formActions.closeForm()(dispatch);
      const messageKey = 'Learning.addModuleSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const editModule = (moduleId, title) => (dispatch, getState) => {
  dispatch(courseActionCreators.editModule(moduleId, title))
    .then(() => {
      contentActions.loadContents()(dispatch, getState);
      formActions.closeForm()(dispatch);
      const messageKey = 'Learning.updateModuleSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const deleteModule = (moduleId) => (dispatch, getState) => {
  dispatch(courseActionCreators.deleteModule(moduleId))
    .then(() => {
      contentActions.loadContents()(dispatch, getState);
      formActions.closeForm()(dispatch);
      const messageKey = 'Learning.deleteModuleSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const reassignUsersToCourse =
  (groupId, courseId, users) => (dispatch) => {
    dispatch(
      courseActionCreators.reassignUsersToCourse(groupId, courseId, users)
    );
  };

export const dismissReassignCourseCsvUploadError = () => (dispatch) => {
  dispatch({
    type: DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR,
  });
};

export const getBambooTrainings = (courseKey) => async (dispatch) => {
  await dispatch(courseActionCreators.getBambooTrainings(courseKey));
};

export const linkCourseToIntegration =
  (courseKey, payload) => (dispatch, getState) => {
    dispatch(courseActionCreators.linkIntegrationToCourse(courseKey, payload))
      .then(() => contentActions.loadContents()(dispatch, getState))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const updateCourseIntegration =
  (courseKey, courseIntegrationId, payload) => (dispatch, getState) => {
    dispatch(
      courseActionCreators.updateCourseIntegration(
        courseKey,
        courseIntegrationId,
        payload
      )
    )
      .then(() => contentActions.loadContents()(dispatch, getState))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const unlinkCourseFromIntegration =
  (courseKey, integrationType) => (dispatch, getState) => {
    dispatch(
      courseActionCreators.unlinkIntegrationFromCourse(
        courseKey,
        integrationType
      )
    ).then(() => {
      contentActions.loadContents()(dispatch, getState);
      getBambooTrainings(courseKey)(dispatch);
    });
  };

export const editCourseEndScreen =
  (courseId, courseCompletionFields, courseKey) => (dispatch, getState) => {
    const state = getState();
    const course = getCourseByKey(state, courseKey);
    const propertiesToSend = [
      'completionTitle',
      'completionMessage',
      'completionSubtitle',
      'completionButtonText',
    ];
    const previousDetails = pick(course, propertiesToSend);
    const hasTextChanged = Object.keys(previousDetails).some(
      (key) => previousDetails[key] !== courseCompletionFields[key]
    );
    const isUrlAdded = !course.buttonUrl && !!courseCompletionFields.buttonUrl;

    const completionFields = omit(courseCompletionFields, 'useCustomLink');
    const language = state.getIn(['moduleActivity', 'selectedLanguage']);

    return dispatch(
      courseActionCreators.editCourseEndScreen(
        completionFields,
        courseKey,
        language,
        {
          isUrlAdded,
          hasTextChanged,
        }
      )
    ).then(() => {
      if (!course.published && !course.isMultiLanguage) {
        contentActions.loadContents()(dispatch, getState);
        dispatch(push(`/learning/course/${courseId}${location.search}`));
      }
    });
  };

export const changeCourseTab = (payload) => (dispatch, getState) => {
  if (
    [
      COURSE_TABS.VISIBILITY,
      COURSE_TABS.LEGACY_DELIVERY,
      COURSE_TABS.DELIVERY,
    ].includes(payload.tab)
  ) {
    const groupId = getSelectedGroupId(getState());
    dispatch(getStatsForGroup(groupId));
  }
  dispatch({
    type: CHANGE_COURSE_TAB,
    payload,
  });
};

export const selectCourseLanguage = (payload) => (dispatch) => {
  dispatch({
    type: 'SELECT_COURSE_LANGUAGE',
    payload,
  });
};

export const exportCourseToGSheet = (courseId) => async (dispatch) => {
  try {
    await dispatch(courseActionCreators.exportCourseToGSheet(courseId)).then(
      () =>
        notificationActions.createSuccessNotification(
          'TranslationExportSuccess'
        )(dispatch)
    );
  } catch (error) {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  }
};

export const importCourseFromGSheet =
  (courseId) => async (dispatch, getState) => {
    try {
      await dispatch(courseActionCreators.importCourseFromGSheet(courseId));

      notificationActions.createSuccessNotification('TranslationImportSuccess')(
        dispatch
      );

      const state = getState();
      const course = getCourseById(state, courseId);
      const language =
        state.getIn(['moduleActivity', 'selectedLanguage']) ||
        course.defaultLanguage;
      const lessonKeys = state
        .getIn(['moduleActivity', 'lessons'])
        .map((lesson) => lesson.get('key'))
        .toJS();

      await dispatch(
        moduleActivityActions.loadLessons(lessonKeys, courseId, language)
      );
    } catch (error) {
      console.error(error);
    }
  };

export const storeCoursePreviewToken = (courseId, token) => (dispatch) =>
  dispatch(courseActionCreators.storeCoursePreviewToken(courseId, token))
    .then(
      dispatch({
        type: VIEWED_PREVIEW_LINK,
        payload: {
          id: courseId,
          type: 'course',
        },
      })
    )
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const accessedCourseReportLink = (payload) => (dispatch) =>
  dispatch({
    type: ACCESSED_COURSE_REPORT_LINK,
    payload,
  });

export const updateCoursePriority =
  (courseKey, priority) => async (dispatch) => {
    try {
      await dispatch(
        courseActionCreators.updateCoursePriority(courseKey, priority)
      );
      notificationActions.createSuccessNotification(
        'Learning.coursePriority.notification.updated'
      )(dispatch);
      await dispatch(loadCourse(courseKey));
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const searchCourse = (payload) => (dispatch) =>
  dispatch({
    type: SEARCH_COURSE,
    payload,
  });

export const getResourceLibraryCourses = () => (dispatch) =>
  dispatch(courseActionCreators.getResourceLibraryCourses());

export const getDemoContentCourses = () => (dispatch) =>
  dispatch(courseActionCreators.getDemoContentCourses());

export const addCourseToDemoContent =
  (courseId, courseKey) => async (dispatch) => {
    try {
      await dispatch(courseActionCreators.addCourseToDemoContent(courseId));
      notificationActions.createSuccessNotification(
        'Learning.demo.notification.added'
      )(dispatch);
      await dispatch(loadCourse(courseKey));
      await dispatch(getDemoContentCourses());
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const removeCourseFromDemoContent =
  (courseId, courseKey) => async (dispatch) => {
    try {
      await dispatch(
        courseActionCreators.removeCourseFromDemoContent(courseId)
      );
      notificationActions.createSuccessNotification(
        'Learning.demo.notification.removed'
      )(dispatch);
      await dispatch(loadCourse(courseKey));
      await dispatch(getDemoContentCourses());
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const selectedLearningTabFilter = (payload) => (dispatch) =>
  dispatch({
    type: SELECTED_LEARNING_TAB_FILTER,
    payload,
  });

export const createTemplateFromCourse =
  (courseKey, groupId, courseTitle) => async (dispatch, getState) => {
    try {
      await dispatch(
        courseActionCreators.createTemplateFromCourse(
          courseKey,
          groupId,
          courseTitle
        )
      );
      notificationActions.createSuccessNotification(
        'Learning.createAsTemplate.notification'
      )(dispatch);
      await contentActions.loadContents()(dispatch, getState);
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const setActiveSidebarItem = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SIDEBAR_ITEM,
    payload,
  });
};

export const setActiveContentFilter = (payload) => (dispatch) => {
  dispatch({
    type: SET_CONTENT_FILTER,
    payload,
  });
};

export const toggleSidebarCreationExpansion = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR_CREATION_EXPANSION,
  });
};

export const sendSetCourseCardDesignEvent = (payload) => (dispatch) =>
  dispatch({
    type: SET_COURSE_CARD_DESIGN,
    payload,
  });

export const goToResources = (state) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SIDEBAR_ITEM,
    payload: SIDEBAR_TABS.RESOURCE_LIBRARY,
  });
  dispatch(push(`/learning${location.search}`, state));
};

export const setIsUserAddingContent = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_USER_ADDING_CONTENT,
    payload,
  });
};

export const setIsUserAddingCourse = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_USER_ADDING_COURSE,
    payload,
  });
};

export const goToCreateCourseModal = () => (dispatch) => {
  dispatch({
    type: SET_IS_USER_ADDING_COURSE,
    payload: true,
  });
  dispatch(push(`/learning${location.search}`));
};

export const toggleCourseNotificationsSlidePanel = (payload) => (dispatch) =>
  dispatch({
    type: TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL,
    payload,
  });

export const toggleCourseContentLinksSlidePanel = (payload) => (dispatch) =>
  dispatch({
    type: TOGGLE_COURSE_OTP_SLIDEPANEL,
    payload,
  });

export const generateCoursesWithActivities =
  (courses) => (dispatch, getState) => {
    const groupId = getSelectedGroupId(getState());
    return dispatch(
      courseActionCreators.generateCoursesWithActivities(courses, groupId)
    )
      .then(() => dispatch(push('/learning')))
      .then(() => contentActions.loadContents()(dispatch, getState))
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };
