import {MS_TEAMS} from '../enums/integrations';
import {getAdminRoles} from './auth/authSelector';
import * as collectionActions from './collections/collectionsActions';
import * as contentActions from './content/contentActions';
import {
  setActiveContentFilter,
  setActiveSidebarItem,
  toggleSidebarCreationExpansion,
} from './course/courseActions';
import {CONTENT_FILTERS, SIDEBAR_TABS} from './course/courseActionTypes';
import * as feedActions from './feed/feedActions';
import * as knowledgeHubActions from './knowledgeHub/knowledgeHubActions';
import * as onboardingActions from './onboarding/onboardingActions';
import * as segmentActions from './segments/segmentActions';
import * as surveyActions from './survey/surveyActions';

const getClientType = () => {
  if (sessionStorage.getItem('accessMethod') === MS_TEAMS) {
    return 'mst';
  } else {
    return 'default';
  }
};

export const buildRequest = (type, method, url, data, client) => ({
  type,
  payload: {
    client: client ?? getClientType(),
    request: {url, method, data},
  },
});

export const addMeta = (request, meta) => ({
  type: request.type,
  payload: request.payload,
  meta,
});

const resetLearningTabState = (dispatch, state) => {
  const activeSidebarItem = state.getIn(['course', 'activeSidebarItem']);
  const activeContentFilter = state.getIn(['course', 'activeContentFilter']);
  const isSidebarCreationExpanded = state.getIn([
    'course',
    'isSidebarCreationExpanded',
  ]);
  if (activeSidebarItem !== SIDEBAR_TABS.STANDARD_CONTENT) {
    dispatch(setActiveSidebarItem(SIDEBAR_TABS.STANDARD_CONTENT));
  }
  if (activeContentFilter !== CONTENT_FILTERS.ALL) {
    dispatch(setActiveContentFilter(CONTENT_FILTERS.ALL));
  }
  if (!isSidebarCreationExpanded) {
    dispatch(toggleSidebarCreationExpansion());
  }
};

export const updateOtherTabs = (dispatch, getState, groupChanged = true) => {
  const state = getState();
  const roles = getAdminRoles(state);

  if (roles.isEditor || roles.isManager) {
    feedActions.loadFeedItems()(dispatch, getState);

    const isOnboardingEnabled = state.getIn([
      'featureFlags',
      'customerFlags',
    ]).onboardingEnabled;
    if (isOnboardingEnabled) {
      onboardingActions.loadOnboardingItems()(dispatch, getState);
    }
  }
  //set groupChanged = false if only selecting new team; saves updating courses, which are unchanged
  if (roles.isEditor && groupChanged) {
    resetLearningTabState(dispatch, state);
    contentActions.loadContents()(dispatch, getState);
    knowledgeHubActions.loadKnowledgeHubs()(dispatch, getState);
    surveyActions.getGroupSurveys()(dispatch, getState);
    collectionActions.getCollectionsForGroup()(dispatch, getState);
    segmentActions.loadGroupSegments()(dispatch, getState);
  }
};
