import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import {SlidePanel} from '@edume/magnificent';

import GuideBanner from '../../../components/Guide/Banners';
import * as contentTypes from '../../../enums/contentTypes';
import {ActivityCard} from '../../core';

import styles from './styles.module.scss';

const AddNewGuideSlidePanel = ({
  onClosePopover,
  aiGuideGenerationEnabled,
  intl,
  history,
}) => {
  const [hoveredOption, setHoveredOption] = useState(null);

  const aiGuideTitle = () => (
    <div styleName='activity-title-with-badge'>
      {intl.formatMessage({
        id: 'Guides.addNewGuideSlidePanel.aiGuide',
      })}
      <span>
        {intl.formatMessage({
          id: 'LessonCreation.modal.ai.beta',
        })}
      </span>
    </div>
  );

  const options = [
    {
      id: 1,
      activityTitle: intl.formatMessage({
        id: 'Guides.addNewGuideSlidePanel.blankGuide',
      }),
      activitySubtitle:
        'Create a Guide using text, images. videos questions and polls',
      imageUrl: '/resources/img/lesson/lesson-blank-guide-icon.svg',
      hoveredImageUrl:
        '/resources/img/lesson/lesson-blank-guide-icon-hover.svg',
      onSelect: () => {
        onClosePopover();
        history.push({
          pathname: `/guides/new`,
          search: location.search,
        });
      },
      dataAutomation: 'blank-guide-option',
    },
  ];

  if (aiGuideGenerationEnabled) {
    options.push({
      id: 2,
      activityTitle: CSSModules(aiGuideTitle, styles)(),
      activitySubtitle: intl.formatMessage({
        id: 'Guides.addNewGuideSlidePanel.aiGuideDescription',
      }),
      imageUrl: '/resources/img/lesson/lesson-guide-ai-icon.svg',
      hoveredImageUrl: '/resources/img/lesson/lesson-guide-ai-icon-hover.svg',
      onSelect: () => {
        onClosePopover();
        history.push({
          pathname: `/guides/new`,
          search: location.search,
          state: contentTypes.AI,
        });
      },
      dataAutomation: 'ai-guide-option',
    });
  }

  return (
    <SlidePanel
      title={intl.formatMessage({id: 'Guides.listView.noGuides.btn'})}
      onClose={onClosePopover}
    >
      <div styleName='options-container'>
        {options.map((option, index) => (
          <ActivityCard
            key={index}
            option={option}
            isHovered={hoveredOption === option.id}
            setIsHovered={(value) => {
              if (value) {
                setHoveredOption(option.id);
              } else {
                setHoveredOption(null);
              }
            }}
          />
        ))}
      </div>
      <div styleName='slide-pannel-banner'>
        <GuideBanner isPanelView={true} />
      </div>
    </SlidePanel>
  );
};

AddNewGuideSlidePanel.propTypes = {
  onClosePopover: PropTypes.func.isRequired,
  aiGuideGenerationEnabled: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default injectIntl(
  withRouter(CSSModules(AddNewGuideSlidePanel, styles, {allowMultiple: true}))
);
