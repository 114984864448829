import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import styles from './styles.module.scss';

const DeleteFieldIcon = ({
  disabled,
  fieldHasValue,
  onClick,
  tooltipKey,
  disabledTooltipKey,
  intl,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  let finalTooltipKey = tooltipKey;
  const iconBase = '/resources/img/24_remove_field';
  let iconSrc = `${iconBase}.svg`;
  if (disabled) {
    iconSrc = `${iconBase}_faded.svg`;
    finalTooltipKey = 'LessonCanvas.Edit.cantDeleteLastField';
  } else if (isHovered) {
    iconSrc = `${iconBase}_purple.svg`;
  } else if (fieldHasValue) {
    iconSrc = `${iconBase}_grey.svg`;
  }

  return (
    <span
      styleName={`iconWrapper ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img data-tip data-for={tooltipKey} styleName='icon' src={iconSrc} />
      <ReactTooltip
        id={tooltipKey}
        className='overlay-tooltip'
        place='top'
        effect='solid'
      >
        {intl.formatMessage({
          id: disabled ? disabledTooltipKey : finalTooltipKey,
        })}
      </ReactTooltip>
    </span>
  );
};

DeleteFieldIcon.propTypes = {
  disabled: PropTypes.bool,
  fieldHasValue: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipKey: PropTypes.string.isRequired,
  disabledTooltipKey: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  CSSModules(DeleteFieldIcon, styles, {allowMultiple: true})
);
