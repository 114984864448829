import {addMeta, buildRequest} from '../stateUtil';
import {
  CREATE_INTEGRATION,
  CREATE_WEBHOOK,
  DELETE_API_KEY,
  DELETE_INTEGRATION,
  DELETE_WEBHOOK,
  GENERATE_API_KEY,
  GENERATE_FOUNTAIN_KEYS,
  GENERATE_SIGNING_SECRET,
  GET_API_KEYS,
  GET_CUSTOMER_BRANDING,
  GET_GROUP_BRANDING,
  GET_INTEGRATION_CREDENTIALS,
  GET_SIGNING_SECRETS,
  GET_WEBHOOK_CONFIGURATIONS,
  GET_WEBHOOK_EVENTS,
  TEST_WEBHOOK,
  UPDATE_CUSTOMER_BRANDING,
  UPDATE_FOUNTAIN_KEYS,
  UPDATE_GROUP_BRANDING,
  UPDATE_INTEGRATION,
  UPDATE_WEBHOOK,
  UPDATE_WEBHOOK_EVENTS,
} from './settingsActionTypes';

export const getApiKeys = () =>
  buildRequest(GET_API_KEYS, 'GET', '/settings/apiKeys');

export const generateApiKey = (groupId = null) =>
  buildRequest(GENERATE_API_KEY, 'POST', '/settings/apiKeys', {
    groupId: groupId,
  });

export const deleteApiKey = ({id, groupId = null, groupName = null}) =>
  addMeta(buildRequest(DELETE_API_KEY, 'DELETE', `/settings/apiKeys/${id}`), {
    id,
    groupId,
    groupName,
  });

export const getSigningSecrets = () =>
  buildRequest(GET_SIGNING_SECRETS, 'GET', '/settings/signingSecrets');

export const generateSigningSecret = () =>
  buildRequest(GENERATE_SIGNING_SECRET, 'POST', '/settings/signingSecrets');

export const getWebhookConfigurations = () =>
  buildRequest(
    GET_WEBHOOK_CONFIGURATIONS,
    'GET',
    '/settings/webhookConfigurations'
  );

export const getWebhookEventTypes = () =>
  buildRequest(GET_WEBHOOK_EVENTS, 'GET', '/settings/webhookEvents');

export const createWebhook = ({
  name,
  url,
  authType,
  secret,
  token,
  groupId,
  enabled,
}) =>
  buildRequest(CREATE_WEBHOOK, 'POST', '/settings/webhookConfigurations', {
    name,
    url,
    authType,
    secret,
    token,
    groupId,
    enabled,
  });

export const updateWebhook = (
  webhookId,
  {name, url, authType, secret, token, groupId, enabled}
) =>
  addMeta(
    buildRequest(
      UPDATE_WEBHOOK,
      'PUT',
      `/settings/webhookConfigurations/${webhookId}`,
      {name, url, authType, secret, token, groupId, enabled}
    ),
    {webhookId}
  );

export const updateWebhookEvents = (webhookId, events) =>
  addMeta(
    buildRequest(
      UPDATE_WEBHOOK_EVENTS,
      'PUT',
      `/settings/webhookConfigurations/${webhookId}/subscribedEvents`,
      {events}
    ),
    {webhookId}
  );

export const deleteWebhook = (webhookId) =>
  buildRequest(
    DELETE_WEBHOOK,
    'DELETE',
    `/settings/webhookConfigurations/${webhookId}`
  );

export const testWebhook = (webhookId, eventType) =>
  buildRequest(TEST_WEBHOOK, 'POST', `/settings/testWebhook/${webhookId}`, {
    eventType,
  });

export const createIntegration = (payload) =>
  buildRequest(CREATE_INTEGRATION, 'POST', '/integrations', payload);

export const updateIntegration = (id, payload) =>
  buildRequest(UPDATE_INTEGRATION, 'PUT', `/integrations/${id}`, payload);

export const deleteIntegration = (id, type) =>
  buildRequest(DELETE_INTEGRATION, 'POST', `/integrations/${id}/delete`, {
    type,
  });

export const getIntegrationCredentials = (id) =>
  buildRequest(
    GET_INTEGRATION_CREDENTIALS,
    'GET',
    `/integrations/${id}/details`
  );

export const generateFountainKeys = () =>
  buildRequest(GENERATE_FOUNTAIN_KEYS, 'POST', '/fountain/generateKeys');

export const updateFountainKeys = () =>
  buildRequest(UPDATE_FOUNTAIN_KEYS, 'PUT', '/fountain/updateKeys');

export const getCustomerBranding = (customerId, groupId) =>
  buildRequest(
    GET_CUSTOMER_BRANDING,
    'GET',
    `/settings/branding/${customerId}/groupId/${groupId}`
  );

export const updateCustomerBranding = (customerId, branding) =>
  addMeta(
    buildRequest(
      UPDATE_CUSTOMER_BRANDING,
      'PUT',
      `/settings/branding/${customerId}`,
      {...branding}
    ),
    {customerId, branding}
  );

export const getGroupBranding = (groupId) =>
  buildRequest(
    GET_GROUP_BRANDING,
    'GET',
    `/settings/customisations/${groupId}`
  );

export const updateGroupBranding = (groupId, customisations) =>
  addMeta(
    buildRequest(
      UPDATE_GROUP_BRANDING,
      'PUT',
      `/settings/customisations/${groupId}`,
      {customisations}
    ),
    {groupId, branding: customisations}
  );
