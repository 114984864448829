import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Avatar, LegacyHeading, Link} from '@edume/magnificent';

import SelectGroup from '../../../../containers/SelectGroup';
import HeaderHelpCentre from '../HeaderItems/HeaderHelpCentre';
import HeaderSettings from '../HeaderItems/HeaderSettings';

import styles from './styles.module.scss';

const MobileMenu = ({
  active,
  hideMenu,
  settingsProps,
  links,
  logoUrl,
  email,
  iconText,
  logout,
  isLogoutVisible = true,
}) => (
  <div styleName={`mobile-menu${active ? ' active' : ''}`}>
    <div styleName='scrollable'>
      <div styleName='top'>
        <div styleName='top-content'>
          <SelectGroup />
          <div styleName='buttons'>
            <HeaderHelpCentre {...settingsProps} />
            <HeaderSettings {...settingsProps} />
            <button styleName='close-btn' onClick={hideMenu}>
              <img src='/resources/img/24_cross_white.svg' alt='Close menu' />
            </button>
          </div>
        </div>
      </div>
      <div styleName='bottom'>
        <div styleName='links'>{links}</div>
        <div styleName='identity'>
          <div styleName='icon'>
            <Avatar name={iconText} size='small' />
          </div>
          <LegacyHeading size='mini'>{email}</LegacyHeading>
        </div>
        {isLogoutVisible && (
          <Link onClick={logout} linkSize='snail' text='Sign out' />
        )}
        <img src={logoUrl} alt='Company logo' styleName='logo' />
      </div>
    </div>
  </div>
);

MobileMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired,
  settingsProps: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.element).isRequired,
  logoUrl: PropTypes.string,
  email: PropTypes.string.isRequired,
  iconText: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  isLogoutVisible: PropTypes.bool,
};

export default CSSModules(MobileMenu, styles, {allowMultiple: true});
