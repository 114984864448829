import {connect} from 'react-redux';

import {BellIcon} from '@edume/magnificent';

import Hint from '../components/core/Hint';
import {dismissHint, undismissHint} from '../state/auth/authActions';

const mapStateToProps = (state, ownProps) => {
  const hints = state.getIn(['auth', 'hints']);
  let visible = ownProps.visible !== false && !hints.includes(ownProps.hintId);
  if (ownProps.prerequisite && visible) {
    visible = hints.includes(ownProps.prerequisite);
  }

  return {
    visible,
    icon: ownProps.icon || <BellIcon />,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dismissHint: (hintId, followUp) => dispatch(dismissHint(hintId, followUp)),
  undismissPrerequisite: () => dispatch(undismissHint(ownProps.prerequisite)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Hint);
