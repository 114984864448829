import * as languageUtil from '@edume/bento/languageUtil';

import deData from '../../resources/locales/de.json';
import draftEnData from '../../resources/locales/draft-en.json';
import enData from '../../resources/locales/en.json';
import esData from '../../resources/locales/es.json';
import frData from '../../resources/locales/fr.json';
import ptData from '../../resources/locales/pt.json';

const NODE_ENV = process.env.NODE_ENV;
const isDevelopmentEnv = !NODE_ENV || NODE_ENV === 'development';

export const {adminLanguageNameMap: languageNameMap} = languageUtil;

const languageCodes = [
  {
    code: 'en',
    googleTranslateSupported: true,
  },
  {
    code: 'es',
    googleTranslateSupported: true,
  },
  {
    code: 'pt',
    googleTranslateSupported: true,
  },
  {
    code: 'fi',
    googleTranslateSupported: true,
  },
  {
    code: 'sv',
    googleTranslateSupported: true,
  },
  {
    code: 'sw',
    googleTranslateSupported: true,
  },
  {
    code: 'it',
    googleTranslateSupported: true,
  },
  {
    code: 'ar',
    googleTranslateSupported: true,
  },
  {
    code: 'my',
    googleTranslateSupported: true,
  },
  {
    code: 'ka',
    googleTranslateSupported: true,
  },
  {
    code: 'de',
    googleTranslateSupported: true,
  },
  {
    code: 'fr',
    googleTranslateSupported: true,
  },
  {
    code: 'hi',
    googleTranslateSupported: true,
  },
  {
    code: 'ur',
    googleTranslateSupported: true,
  },
  {
    code: 'zh',
    googleTranslateSupported: true,
  },
  {
    code: 'hr',
    googleTranslateSupported: true,
  },
  {
    code: 'pl',
    googleTranslateSupported: true,
  },
  {
    code: 'nl',
    googleTranslateSupported: true,
  },
  {
    code: 'he',
    googleTranslateSupported: true,
  },
  {
    code: 'no',
    googleTranslateSupported: true,
  },
  {
    code: 'cs',
    googleTranslateSupported: true,
  },
  {
    code: 'ro',
    googleTranslateSupported: true,
  },
  {
    code: 'uk',
    googleTranslateSupported: true,
  },
  {
    code: 'ko',
    googleTranslateSupported: true,
  },
  {
    code: 'ja',
    googleTranslateSupported: true,
  },
  {
    code: 'si',
    googleTranslateSupported: true,
  },
  {
    code: 'bn',
    googleTranslateSupported: true,
  },
  {
    code: 'lt',
    googleTranslateSupported: true,
  },
  {
    code: 'el',
    googleTranslateSupported: true,
  },
  {
    code: 'ru',
    googleTranslateSupported: true,
  },
  {
    code: 'sr',
    googleTranslateSupported: true,
  },
  {
    code: 'sl',
    googleTranslateSupported: true,
  },
  {
    code: 'sk',
    googleTranslateSupported: true,
  },
  {
    code: 'hu',
    googleTranslateSupported: true,
  },
  {
    code: 'lv',
    googleTranslateSupported: true,
  },
  {
    code: 'az',
    googleTranslateSupported: true,
  },
  {
    code: 'as',
    googleTranslateSupported: true,
  },
  {
    code: 'kn',
    googleTranslateSupported: true,
  },
  {
    code: 'ml',
    googleTranslateSupported: true,
  },
  {
    code: 'ta',
    googleTranslateSupported: true,
  },
  {
    code: 'te',
    googleTranslateSupported: true,
  },
  {
    code: 'et',
    googleTranslateSupported: true,
  },
  {
    code: 'tl',
    googleTranslateSupported: true,
  },
  {
    code: 'so',
    googleTranslateSupported: true,
  },
  {
    code: 'ti',
    googleTranslateSupported: true,
  },
  {
    code: 'ku',
    googleTranslateSupported: true,
  },
  {
    code: 'da',
    googleTranslateSupported: true,
  },
  {
    code: 'is',
    googleTranslateSupported: true,
  },
  {
    code: 'kk',
    googleTranslateSupported: true,
  },
  {
    code: 'vi',
    googleTranslateSupported: true,
  },
  {
    code: 'uz',
    googleTranslateSupported: true,
  },
  {
    code: 'sq',
    googleTranslateSupported: true,
  },
  {
    code: 'pt-br',
    googleTranslateSupported: false,
  },
  {
    code: 'ur-Latn',
    googleTranslateSupported: false,
  },
  {
    code: 'zh-hant',
    googleTranslateSupported: false,
  },
  {
    code: 'zh-hk',
    googleTranslateSupported: false,
  },
  {
    code: 'nl-be',
    googleTranslateSupported: false,
  },
  {
    code: 'es-419',
    googleTranslateSupported: false,
  },
];

const languageNamePrefix = 'Learning.multiLanguage.language.';

export const languageNames = languageCodes.reduce(
  (acc, key) => ({
    ...acc,
    [key.code]: `${languageNamePrefix}${key.code}`,
  }),
  {}
);

export const googleTranslateSupportedLanguageNames = languageCodes
  .filter((lang) => lang.googleTranslateSupported)
  .reduce(
    (acc, key) => ({
      ...acc,
      [key.code]: `${languageNamePrefix}${key.code}`,
    }),
    {}
  );

const getLanguageReadyStates = (getIsReadyInLanguage, languages = []) =>
  languages.reduce((langReadyStates, lang) => {
    const isReady = getIsReadyInLanguage(lang);
    langReadyStates[lang] = isReady;
    return langReadyStates;
  }, {});

const isActivityReadyInLanguage = (activity, lang, selectedLanguage) =>
  lang === selectedLanguage
    ? activity.valid
    : activity.languages?.[lang] &&
      !activity.languages?.[lang]?.hasIncompleteActivities;

const isCompletionScreenReadyInLanguage = (course) => (lang) =>
  !course?.completionScreenLanguageValidity ||
  course.completionScreenLanguageValidity[lang];

const isEveryActivityReadyInLanguage = (activities) => (lang) =>
  activities.every((activity) => isActivityReadyInLanguage(activity, lang));

const isCourseReadyInLanguage = (course, activities) => (lang) =>
  isCompletionScreenReadyInLanguage(course)(lang) &&
  isEveryActivityReadyInLanguage(activities)(lang);

export const getActivityLanguageReadyStates = (
  activity,
  selectedLanguage,
  languages
) =>
  getLanguageReadyStates(
    (lang) => isActivityReadyInLanguage(activity, lang, selectedLanguage),
    languages
  );

export const getCompletionScreenLanguageReadyStates = (course) =>
  getLanguageReadyStates(
    isCompletionScreenReadyInLanguage(course),
    course.languages
  );

export const getCourseLanguageReadyStates = (course, activities) =>
  getLanguageReadyStates(
    isCourseReadyInLanguage(course, activities),
    course.languages
  );

const languageDataMap = {
  en: isDevelopmentEnv ? draftEnData : enData,
  es: esData,
  pt: ptData,
  fr: frData,
  de: deData,
};

export const getLocalisedStringsForLanguage = (language) =>
  languageDataMap[language] || languageDataMap.en;
