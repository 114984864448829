import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useState,
} from 'react';

import CSSModules from 'react-css-modules';

import {EmailIcon, KeyIcon, SearchIcon, TickIcon} from '../icons';
import CrossIcon from '../icons/CrossIcon';
import EyeIcon, {EyeIconType} from '../icons/EyeIcon';
import ReadyIcon from '../ReadyIcon';
import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';
import {TextColours, TextWeights} from '../TextComponents/textUtils';

import styles from './styles.module.scss';

type ValidationStyles = 'valid' | 'invalid' | '';

type InputWidths = 'short' | 'long' | 'fullWidth';

export type CommonProps = {
  title?: string;
  titleWeight?: TextWeights;
  validationStyles?: 'icon' | 'alwaysValid' | 'hide' | 'normal';
  value?: string | number;
  onChangeValue?: (e: string) => void;
  rows?: number;
  placeholder?: string;
  errorText?: string;
  successText?: string;
  optionalText?: string;
  countText?: string;
  isCountWarning?: boolean;
  showText?: string;
  hideText?: string;

  onEnterKey?: (input?: string) => void;
  onFocus?: (e: HTMLInputElement | HTMLTextAreaElement) => void;
  onBlur?: () => void;
  onHover?: () => void;

  /* allows always-green inputs (w/ no smiley)...
  ...and inputs that only appear empty/prefilled/focused (never valid/invalid) */
  isInvalid?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  enableVisibilityToggle?: boolean;
  validationEmotes?: boolean;
  typeIcons?: boolean;
  ignorePrefilled?: boolean;

  width?: InputWidths;
  tabIndex?: number;

  includeBottomMargin?: boolean; //TODO maybe one day should refactor into a form component?
  dataAutomation?: string;
  updateFocus?: (focus: boolean) => void; // used by company picker for phone inputs
  updateValidationStyle?: (style: ValidationStyles) => void; // used by company picker for phone inputs
  removeSpellcheck?: boolean;
  readyStatusDataAutomation?: string;
  searchClearText?: ReactNode;
  onClearInput?: () => void;
  clearInputDataAutomation?: string;
  clearInputType?: 'icon' | 'button' | 'none';
  showValidationTextWhileFocused?: boolean;
  htmlId?: string;
  showCountTextContainer?: boolean;
  pattern?: string;
};

type ConditionalNumberProps =
  | {
      numberProps: {
        min?: number;
        max?: number;
        step?: number;
      };
      type: 'number-increment';
    }
  | {
      numberProps?: never;
      type:
        | 'email'
        | 'password'
        | 'text'
        | 'search'
        | 'prompt'
        | 'number'
        | 'date';
    };

export type InputProps = CommonProps & ConditionalNumberProps;

// eslint-disable-next-line max-statements, complexity
const Input = (
  /* prettier-ignore */ {
    autoFocus, placeholder, errorText, successText, optionalText, countText, isCountWarning, type, rows: propRows = 1,
    title, disabled = false, isInvalid = false, validationStyles = 'normal', value = '', width = 'fullWidth',
    tabIndex, includeBottomMargin = true, numberProps, dataAutomation, enableVisibilityToggle = false,
    validationEmotes = true, typeIcons = true, showText, hideText, ignorePrefilled, readyStatusDataAutomation,
    removeSpellcheck, searchClearText, onClearInput, clearInputDataAutomation, clearInputType = 'button', onFocus,
    updateFocus, onBlur, onEnterKey, updateValidationStyle, showValidationTextWhileFocused, titleWeight = 'medium',
    onChangeValue, htmlId, showCountTextContainer = true, pattern
  }: InputProps
) => {
  const [originalValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [hasInputBeenUnfocused, setHasInputBeenUnfocused] = useState(false);

  const handleFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e.target);
    }
    if (updateFocus) {
      updateFocus(true);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    setHasInputBeenUnfocused(true);
    if (onBlur) {
      onBlur();
    }
    if (updateFocus) {
      updateFocus(false);
    }
  };

  const handleKeyPress: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (e.key === 'Enter' && onEnterKey) {
      onEnterKey();
    }
  };

  const handleOnChangeValue: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  };

  const toggleVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // eslint-disable-next-line complexity
  const getExtraInputStyles = (prefilled: boolean) => {
    const empty = value === '';

    if (disabled) {
      return '';
    } else if (isFocused) {
      return ' focused';
    } else if (validationStyles === 'alwaysValid') {
      return ' valid';
    } else if (prefilled) {
      return ' prefilled';
    } else if (
      validationStyles &&
      ['hide', 'icon'].includes(validationStyles) &&
      !empty
    ) {
      return ' prefilled';
    } else if (!isInvalid && updateValidationStyle) {
      if (!empty) {
        updateValidationStyle('valid');
        return ' valid';
      } else {
        updateValidationStyle('');
        return ' empty';
      }
    } else if (validationStyles !== 'icon' && updateValidationStyle) {
      updateValidationStyle('invalid');
      return ' invalid';
    } else {
      return '';
    }
  };

  const rows = ['number', 'number-increment'].includes(type) ? 1 : propRows;

  const containerStyle =
    'container' + ` ${width}` + (includeBottomMargin ? ' bottomMargin' : '');

  let inputStyle = `input light`;
  let showValidState = false;
  let showInvalidState = false;

  const isPrefilled =
    value === originalValue && originalValue !== '' && !ignorePrefilled;
  const hideValidation =
    validationStyles &&
    ['hide', 'alwaysValid', 'icon'].includes(validationStyles);

  const hasValidationText = errorText || successText;
  const showValidationText =
    (!isFocused || (showValidationTextWhileFocused && hasInputBeenUnfocused)) &&
    hasValidationText;
  const showCountText = isFocused && countText;

  inputStyle += getExtraInputStyles(isPrefilled);
  inputStyle += rows !== 1 ? ' multiLine' : '';

  if (isCountWarning) {
    inputStyle += ' invalid always-show-invalid';
  }

  if (typeIcons) {
    inputStyle += ' type-icons';
  }

  if (enableVisibilityToggle) {
    inputStyle += ' visibility';
  }

  if (type === 'search') {
    inputStyle += ' search';
  }

  if (type === 'prompt') {
    inputStyle += ' prompt';
  }

  if (!disabled && !isPrefilled && !hideValidation) {
    if (value !== '') {
      showValidState = !isInvalid;
      if (showValidationText) {
        inputStyle += isInvalid ? ' invalid' : ' valid';
      }
    }
    if (value === '') {
      showInvalidState = isInvalid;
      if (showValidationText) {
        inputStyle += isInvalid ? ' invalid' : '';
      }
    }
    showInvalidState = isInvalid;
  }

  const renderTypeIcons = (inputType: string) => {
    let icon;
    if (typeIcons && rows === 1) {
      if (inputType === 'email') {
        icon = <EmailIcon colour='textPrimary' />;
      } else if (inputType === 'password') {
        icon = <KeyIcon colour='textPrimary' />;
      } else if (inputType === 'search') {
        icon = <SearchIcon />;
      }
    }
    return icon;
  };

  const renderValidationEmote = () => {
    let validationSrc;
    let emoteType;
    if (validationEmotes && !isFocused) {
      if (showInvalidState) {
        validationSrc = '/resources/img/24_sad.svg';
        emoteType = 'sadIcon';
      } else if (showValidState) {
        validationSrc = '/resources/img/24_happy.svg';
        emoteType = 'happyIcon';
      }
    }
    return <img styleName={emoteType} src={validationSrc} />;
  };

  const renderValidationIcon = () => {
    let icon;
    if (enableVisibilityToggle || !validationEmotes) {
      if (showInvalidState) {
        icon = <CrossIcon colour='errorPrimary' size='xsmall' />;
      } else if (showValidState) {
        icon = <TickIcon colour='successPrimary' size='xsmall' />;
      }
    }
    return icon;
  };

  const renderValidationText = () => {
    let status = '';
    let validationText;
    let colour: TextColours = 'textPrimary';
    if (errorText && showInvalidState) {
      status = 'invalid';
      validationText = errorText;
      colour = 'red';
    } else if (successText && showValidState) {
      status = 'valid';
      validationText = successText;
      colour = 'green';
    }
    return (
      <div
        styleName={`validation-text ${status}`}
        data-automation={`${status}-message`}
      >
        <Text size='s' weight='medium' colour={colour} lineHeight='tight'>
          {validationText}
        </Text>
        {renderValidationIcon()}
      </div>
    );
  };

  const renderCountText = () => (
    <div styleName='counter'>
      <Text
        size='s'
        colour={isCountWarning ? 'red' : 'grey300'}
        lineHeight='tight'
      >
        {countText}
      </Text>
    </div>
  );

  const renderVisibilityIcon = () => {
    let eyeType = '';
    let text;
    if (isPasswordVisible) {
      eyeType = 'closed';
      text = hideText ? hideText : 'Hide';
    } else if (!isPasswordVisible) {
      eyeType = 'open';
      text = showText ? showText : 'Show';
    }

    return (
      <div
        styleName={'inner-icon inner-button light valid'}
        onClick={toggleVisibility}
      >
        <EyeIcon type={eyeType as EyeIconType} />
        <span styleName={'inner-icon-text light'}>{text}</span>
      </div>
    );
  };

  const handleClearButton = () => {
    if (onClearInput) {
      onClearInput();
    }

    if (onChangeValue) {
      onChangeValue('');
    }
  };

  const clearInputIconStyleName = clearInputType === 'icon' ? 'clear-icon' : '';

  const renderClearButton = () => (
    <div
      styleName={`inner-icon inner-button ${clearInputIconStyleName} light valid`}
      data-automation={clearInputDataAutomation}
      onClick={handleClearButton}
    >
      {clearInputType === 'icon' ? (
        <CrossIcon size='small' colour='grey300' />
      ) : (
        <span styleName={'inner-icon-text light'}>{searchClearText}</span>
      )}
    </div>
  );

  const getInputSpecificProps = () => {
    switch (type) {
      case 'number-increment':
        return numberProps;
      case 'number':
        return {
          pattern: '[0-9]*',
          inputMode: 'numeric' as const,
        };
      default:
        return {};
    }
  };

  const passwordVisible = isPasswordVisible ? 'text' : 'password';

  const getInputType = () => {
    if (type === 'password') {
      return passwordVisible;
    } else if (['search', 'prompt', 'number'].includes(type)) {
      return 'text';
    } else {
      return type;
    }
  };

  const inputProps = {
    rows,
    autoFocus,
    placeholder,
    type: getInputType(),
    'data-type': type,
    disabled,
    value,
    styleName: inputStyle,
    onKeyPress: handleKeyPress,
    onChange: handleOnChangeValue,
    onFocus: handleFocus,
    onBlur: handleBlur,
    tabIndex,
    dir: 'auto',
    'data-automation': dataAutomation,
    spellCheck: !removeSpellcheck,
    pattern,
  };

  const isInputContainer = type === 'number-increment';
  if (!!pattern && isInputContainer) {
    inputProps.onChange = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const regex = new RegExp(pattern);
      if (event.target.value === '' || regex.test(event.target.value)) {
        handleOnChangeValue(event);
      }
    };
  }

  return (
    <div styleName={containerStyle}>
      {title && (
        <div styleName='title-block'>
          <div styleName='title'>
            {validationStyles === 'icon' && (
              <ReadyIcon
                isValid={!isInvalid}
                size='small'
                dataAutomation={readyStatusDataAutomation}
              />
            )}
            <Heading
              as='h6'
              size='snail'
              weight={titleWeight}
              colour='textPrimary'
            >
              {title}
            </Heading>
          </div>
          {optionalText && <span styleName='optional'>{optionalText}</span>}
        </div>
      )}
      <div styleName='inputContainer'>
        {rows === 1 ? (
          <input id={htmlId} {...inputProps} {...getInputSpecificProps()} />
        ) : (
          <textarea id={htmlId} {...inputProps} />
        )}
        {renderTypeIcons(type)}
        {!enableVisibilityToggle && renderValidationEmote()}
        {type === 'password' &&
          enableVisibilityToggle &&
          renderVisibilityIcon()}
        {type === 'search' &&
          clearInputType !== 'none' &&
          typeof value === 'string' &&
          value.length > 0 &&
          renderClearButton()}
      </div>
      {(hasValidationText || countText) && showCountTextContainer && (
        <div
          styleName={`bottom-text ${
            countText && showValidationTextWhileFocused ? 'double-height' : ''
          }`}
        >
          {showCountText && renderCountText()}
          {showValidationText && renderValidationText()}
        </div>
      )}
    </div>
  );
};

export default CSSModules(Input, styles, {allowMultiple: true});
