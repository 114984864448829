import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Heading, Text} from '@edume/magnificent';

import {StyledModal} from '../../../containers/StyledModals';
import {InputWithWarning} from '../../core';

import styles from './styles.module.scss';

const NAME_LENGTH = 20;

const AddEditCollectionModal = ({
  onClose,
  onSave,
  isEditing,
  collectionName,
  intl,
}) => {
  const [name, setName] = useState(isEditing ? collectionName : '');

  const invalidCollectionName =
    !name.trim() || name.trim().length > NAME_LENGTH;

  const body = () => (
    <div>
      <div styleName='body-container'>
        <Text size='m' colour='grey700'>
          {intl.formatMessage({id: 'Learning.sidebar.collectionTitle'})}
        </Text>
        <InputWithWarning
          type='normal'
          dataAutomation={'collection-title-input'}
          value={name}
          onChangeValue={(v) => setName(v)}
          isInvalid={invalidCollectionName}
          errorText={intl.formatMessage(
            {
              id: 'Learning.collectionNameErrorText',
            },
            {charCount: NAME_LENGTH}
          )}
          autoFocus={true}
          onEnterKey={() => !invalidCollectionName && onSave(name)}
          isTitle={true}
          includeBottomMargin={false}
          hintPosition='center'
        />
      </div>
    </div>
  );

  const content = {
    title: (
      <Heading as='h1' size='sheep' weight='medium'>
        {intl.formatMessage({
          id: `Learning.sidebar.${isEditing ? 'edit' : 'create'}Collection`,
        })}
      </Heading>
    ),
    body: CSSModules(body, styles, {allowMultiple: true})(),
    buttons: [
      {
        type: 'secondary',
        text: intl.formatMessage({id: 'Button.cancel'}),
        onClick: onClose,
        dataAutomation: 'cancel-button',
      },
      {
        type: 'primary',
        text: intl.formatMessage({
          id: `Learning.sidebar.${
            isEditing ? 'edit' : 'create'
          }CollectionConfirm`,
        }),
        onClick: () => onSave(name),
        dataAutomation: 'confirm-create-collection-button',
        disabled: invalidCollectionName,
      },
    ],
  };

  return <StyledModal onClose={onClose} content={content} />;
};

AddEditCollectionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  collectionName: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AddEditCollectionModal);
