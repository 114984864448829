import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {AlertIcon} from '@edume/magnificent';

import {StyledModal} from '../../../../containers/StyledModals';
import {LegacyIntlHeading} from '../../../core';

import styles from './styles.module.scss';

const DeleteGroupModal = ({onClose, onConfirm}) => {
  const intl = useIntl();

  const body = () => (
    <>
      <div styleName='heading'>
        <LegacyIntlHeading
          size='small'
          weight='medium'
          color='textPrimary'
          textKey='Groups.deleteGroupConfirm.heading'
        />
      </div>
      <LegacyIntlHeading
        size='micro'
        textKey='Groups.deleteGroupConfirm.body'
      />
    </>
  );

  const content = {
    title: intl.formatMessage({id: 'Groups.deleteGroupConfirm.modalHeading'}),
    icon: <AlertIcon size='big' />,
    body: CSSModules(body, styles)(),
    buttons: [
      {
        type: 'secondary',
        text: intl.formatMessage({id: 'Groups.deleteGroupConfirm.cancel'}),
        onClick: onClose,
        dataAutomation: 'cancel-delete-group-button',
      },
      {
        type: 'danger',
        text: intl.formatMessage({id: 'Groups.deleteGroupConfirm.confirm'}),
        onClick: onConfirm,
        dataAutomation: 'confirm-delete-group-button',
      },
    ],
  };

  return (
    <StyledModal
      content={content}
      onClose={onClose}
      dataAutomation=''
      blockHeader='warning'
      compact
    />
  );
};

DeleteGroupModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CSSModules(DeleteGroupModal, styles);
