import {push} from 'connected-react-router';

import {MS_TEAMS} from '../../enums/integrations';
import * as authActions from '../auth/authActions';
import * as notificationActions from '../notification/notificationActions';
import * as settingsActionsCreators from './settingsActionCreators';
import * as settingsActionTypes from './settingsActionTypes';

export const generateCustomerApiKey = () => (dispatch) =>
  dispatch(settingsActionsCreators.generateApiKey()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const generateGroupApiKey = (groupId) => (dispatch) =>
  dispatch(settingsActionsCreators.generateApiKey(groupId)).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const deleteApiKey = (apiKey) => (dispatch) =>
  dispatch(settingsActionsCreators.deleteApiKey(apiKey)).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const getApiKeys = () => (dispatch) =>
  dispatch(settingsActionsCreators.getApiKeys()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const getSigningSecrets = () => (dispatch) =>
  dispatch(settingsActionsCreators.getSigningSecrets()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const generateSigningSecret = () => (dispatch) =>
  dispatch(settingsActionsCreators.generateSigningSecret()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const getWebhookConfigurations = () => (dispatch) =>
  dispatch(settingsActionsCreators.getWebhookConfigurations()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const getWebhookEventTypes = () => (dispatch) =>
  dispatch(settingsActionsCreators.getWebhookEventTypes());

export const createWebhook = (payload) => (dispatch) =>
  dispatch(settingsActionsCreators.createWebhook(payload))
    .then((response) => {
      const webhookId = response.payload.data.id;
      dispatch(
        push(
          `/settings/developer-tools/webhooks/${webhookId}${location.search}`
        )
      );
    })
    .then(() =>
      dispatch(
        notificationActions.createSuccessNotification(
          'Settings.webhook.addWebhook.endpoint.create.success'
        )
      )
    )
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const updateWebhook = (webhookId, payload) => (dispatch) =>
  dispatch(settingsActionsCreators.updateWebhook(webhookId, payload))
    .then(() =>
      dispatch(
        notificationActions.createSuccessNotification(
          'Settings.webhook.manageWebhook.endpoint.update.success'
        )
      )
    )
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const updateWebhookEvents = (webhookId, events) => (dispatch) =>
  dispatch(settingsActionsCreators.updateWebhookEvents(webhookId, events))
    .then(() =>
      dispatch(
        notificationActions.createSuccessNotification(
          'Settings.webhook.manageWebhook.events.update.success'
        )
      )
    )
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const deleteWebhook = (webhookId) => (dispatch) =>
  dispatch(settingsActionsCreators.deleteWebhook(webhookId))
    .then(() => {
      dispatch(push(`/settings/developer-tools/webhooks${location.search}`));
    })
    .then(() =>
      dispatch(
        notificationActions.createSuccessNotification(
          'Settings.webhook.deleteWebhook.delete.success'
        )
      )
    )
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const copyWebhookSecret = (secret, webhookId) => (dispatch) => {
  navigator.clipboard.writeText(secret);
  if (webhookId) {
    dispatch({
      type: settingsActionTypes.COPY_WEBHOOK_SECRET,
      payload: {webhookId},
    });
  }
  dispatch(
    notificationActions.createSuccessNotification(
      'Settings.webhookSecret.copyToClipboardSuccess'
    )
  );
};

export const testWebhook = (webhookId, eventType) => (dispatch) =>
  dispatch(settingsActionsCreators.testWebhook(webhookId, eventType))
    .then(() => {
      dispatch(
        notificationActions.createSuccessNotification(
          'settings.webhook.testWebhook.success'
        )
      );
    })
    .catch(() => {
      dispatch(
        notificationActions.createErrorNotification(
          'settings.webhook.testWebhook.fail'
        )
      );
    });

export const createIntegration = (payload) => (dispatch) =>
  dispatch(settingsActionsCreators.createIntegration(payload))
    .then((response) => {
      if (response.payload.data.integrated) {
        if (response.payload.data.integration.type !== MS_TEAMS) {
          dispatch(
            notificationActions.createSuccessNotification(
              'Settings.integrations.create.success'
            )
          );
        }
        dispatch(
          authActions.addIntegrationToState(response.payload.data.integration)
        );
      } else {
        dispatch(
          notificationActions.createErrorNotification(
            `Error.${response.payload.data.errorCode ?? 'generic'}`
          )
        );
      }
    })
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const updateIntegration = (id, payload) => (dispatch) =>
  dispatch(settingsActionsCreators.updateIntegration(id, payload))
    .then((response) => {
      if (response.payload.data.updated) {
        dispatch(
          notificationActions.createSuccessNotification(
            'Settings.integrations.update.success'
          )
        );
        dispatch(
          authActions.updateIntegrationInState(
            response.payload.data.integration
          )
        );
      } else {
        dispatch(
          notificationActions.createErrorNotification(
            `Error.${response.payload.data.errorCode ?? 'generic'}`
          )
        );
      }
    })
    .catch((e) =>
      dispatch(notificationActions.createErrorNotificationFromResponse(e))
    );

export const deleteIntegration = (id, type) => (dispatch) =>
  dispatch(settingsActionsCreators.deleteIntegration(id, type))
    .then((response) => {
      if (response.payload.data.deleted) {
        dispatch(
          notificationActions.createSuccessNotification(
            'Settings.integrations.delete.success'
          )
        );
        dispatch(authActions.deleteIntegrationFromState(id));
      } else {
        throw new Error();
      }
    })
    .catch((e) => {
      dispatch(notificationActions.createErrorNotificationFromResponse(e));
    });

export const getIntegrationCredentials = (id) => (dispatch) =>
  dispatch(settingsActionsCreators.getIntegrationCredentials(id)).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const generateFountainKeys = () => (dispatch) =>
  dispatch(settingsActionsCreators.generateFountainKeys()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const updateFountainKeys = () => (dispatch) =>
  dispatch(settingsActionsCreators.updateFountainKeys()).catch((e) =>
    dispatch(notificationActions.createErrorNotificationFromResponse(e))
  );

export const getCustomerBranding = (customerId, groupId) => (dispatch) =>
  dispatch(settingsActionsCreators.getCustomerBranding(customerId, groupId));

export const updateCustomerBranding =
  (customerId, branding) => (dispatch, getState) =>
    dispatch(
      settingsActionsCreators.updateCustomerBranding(customerId, branding)
    )
      .then(() => {
        const groupId = getState().getIn(['team', 'groupId']);
        dispatch(
          settingsActionsCreators.getCustomerBranding(customerId, groupId)
        );

        dispatch(
          notificationActions.createSuccessNotification(
            'Settings.branding.update.success'
          )
        );
      })
      .catch(() => {
        dispatch(
          notificationActions.createErrorNotificationFromResponse(
            'Settings.branding.update.error'
          )
        );
      });

export const resetCustomerBranding = () => (dispatch) =>
  dispatch({type: settingsActionTypes.RESET_CUSTOMER_BRANDING});

export const getGroupBranding = (groupId) => (dispatch) =>
  dispatch(settingsActionsCreators.getGroupBranding(groupId));

export const updateGroupBranding = (groupId, branding) => (dispatch) =>
  dispatch(settingsActionsCreators.updateGroupBranding(groupId, branding))
    .then(() => {
      dispatch(settingsActionsCreators.getGroupBranding(groupId));

      dispatch(
        notificationActions.createSuccessNotification(
          'Settings.branding.update.success'
        )
      );
    })
    .catch(() => {
      dispatch(
        notificationActions.createErrorNotificationFromResponse(
          'Settings.branding.update.error'
        )
      );
    });
export const openMsTeamsModal = () => (dispatch) => {
  dispatch({type: settingsActionTypes.OPEN_MS_TEAMS_MODAL});
};

export const closeMsTeamsModal = () => (dispatch) => {
  dispatch({type: settingsActionTypes.CLOSE_MS_TEAMS_MODAL});
};
