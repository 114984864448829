import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import Info from '../Info';
import LegacyIntlHeading from '../LegacyIntlHeading';

import styles from './styles.module.scss';

const MultiLineInfo = ({introKey, items, dataKey, place, size, theme}) => {
  const headingProps = {
    colour: theme === 'dark' ? 'white' : 'textPrimary',
    size: 'micro',
  };
  return (
    <Info dataKey={dataKey} place={place} size={size} theme={theme}>
      <div styleName='tooltipHeaderBox'>
        <LegacyIntlHeading {...headingProps} textKey={introKey} />
        {items.map(({headingKey, descriptionKey}) => (
          <span styleName='item' key={headingKey}>
            <LegacyIntlHeading
              {...headingProps}
              weight='bold'
              textKey={headingKey}
            />
            <LegacyIntlHeading {...headingProps} textKey={descriptionKey} />
          </span>
        ))}
      </div>
    </Info>
  );
};

MultiLineInfo.propTypes = {
  introKey: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headingKey: PropTypes.string,
      descriptionKey: PropTypes.string,
    })
  ).isRequired,
  dataKey: PropTypes.string.isRequired,
  place: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default CSSModules(MultiLineInfo, styles);
