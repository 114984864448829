export const learnerLanguageNameMap = {
  en: 'English',
  es: 'Español',
  pt: 'Português',
  'pt-br': 'Português (Brasil)',
  fi: 'Suomi',
  sv: 'Sverige',
  sw: 'Kiswahili',
  it: 'Italiano',
  ar: 'العربية',
  my: 'Mranmabhasa',
  ka: 'ქართული',
  de: 'Deutsch',
  fr: 'Français',
  hi: 'हिन्दी',
  ur: 'اردو',
  zh: '汉语',
  'zh-hant': '漢語',
  'zh-hk': '漢語 (香港)',
  hr: 'Hrvatski jezik',
  pl: 'Polski',
  nl: 'Nederlands',
  he: 'עִבְרִית‎',
  no: 'Norsk',
  cs: 'Čeština',
  ro: 'Română',
  uk: 'Українська',
  ko: '한국어',
  ja: '日本語',
  si: 'සිංහල',
  bn: 'বাংলা',
  lt: 'Lietuvių kalba',
  el: 'Ελληνικά',
  ru: 'Русский',
  sr: 'Рпски језик',
  sl: 'Slovenščina',
  sk: 'Slovenčina',
  hu: 'Magyar',
  lv: 'Latviešu valoda',
  az: 'Azərbaycan dili',
  as: 'অসমীয়া',
  kn: 'ಕನ್ನಡ',
  ml: 'മലയാളം',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  et: 'Eesti',
  tl: 'Tagalog',
  so: 'Soomaali',
  ti: 'ትግርኛ',
  ku: 'کوردی',
  'ur-Latn': 'Urdu',
  da: 'Dansk',
  is: 'Íslenska',
  kk: 'Қазақша',
  vi: 'Tiếng Việt',
  'nl-be': 'Flemish',
  'es-419': 'Español (Latinoamérica y el Caribe)',
  uz: 'Ўзбек',
  sq: 'Shqip',
};

export const adminLanguages = ['en', 'es', 'pt', 'fr', 'de'] as const;

export const adminLanguageNameMap = Object.fromEntries(
  adminLanguages.map((lang) => [lang, learnerLanguageNameMap[lang]])
) as Record<(typeof adminLanguages)[number], string>;
