import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
  AlertIcon,
  Button,
  DeleteIcon,
  InlineInput,
  ListPopover,
  PlusIcon,
} from '@edume/magnificent';

import EdumePropTypes from '../../../proptypes';
import {
  getHasLeadRightsOnCompany,
  getHasLeadRightsOnGroup,
} from '../../../utils/permissions';
import {LegacyIntlHeading} from '../../core';
import EditTeamOrGroup from '../EditTeamOrGroup';
import DeleteGroupModal from './DeleteGroupModal';
import SelectGroupMenu from './SelectGroupMenu';

import styles from './styles.module.scss';

// eslint-disable-next-line complexity
const SelectGroup = ({
  selected,
  groups,
  permissions,
  isTrial,
  addGroup,
  selectGroup,
  editGroup,
  deleteGroup,
  formError,
  intl,
}) => {
  const [isShowingAddGroupModal, setIsShowingAddGroupModal] = useState(false);

  const [isDeleteIconRendered, setIsDeleteIconRendered] = useState(false);
  const [isDeleteIconHidden, setIsDeleteIconHidden] = useState(false);
  const [
    isShowingDeleteGroupConfirmation,
    setIsShowingDeleteGroupConfirmation,
  ] = useState(false);

  const [isShowingGroupDropdown, setIsShowingGroupDropdown] = useState(false);
  const [isShowingMobileMenu, setIsShowingMobileMenu] = useState(false);

  const hasLeadRightsOnCompany = getHasLeadRightsOnCompany(permissions);
  const hasLeadRightsOnGroup = getHasLeadRightsOnGroup(permissions, selected);

  const onGroupNameInputClick = (isEditing) => {
    if (!isEditing && groups.length > 1) {
      setIsDeleteIconRendered(true);
    }
  };

  const showDeleteGroupConfirmation = () => {
    setIsShowingDeleteGroupConfirmation(true);
  };

  const hideDeleteGroupConfirmation = () => {
    setIsShowingDeleteGroupConfirmation(false);
  };

  const showAddGroupModal = () => {
    setIsShowingAddGroupModal(true);
    setIsShowingGroupDropdown(false);
  };

  const hideAddGroupModal = () => {
    setIsShowingAddGroupModal(false);
    setIsShowingGroupDropdown(false);
  };

  const handleGroupDropdown = () => {
    if (isShowingGroupDropdown) {
      hideGroupDropdown();
    } else {
      showGroupDropdown();
    }
  };

  const showGroupDropdown = () => {
    setIsShowingGroupDropdown(true);
  };
  const hideGroupDropdown = () => {
    setIsShowingGroupDropdown(false);
  };

  const showMobileMenu = () => {
    setIsShowingMobileMenu(true);
  };
  const hideMobileMenu = () => {
    setIsShowingMobileMenu(false);
  };

  const onSelectGroup = (groupId) => {
    setIsShowingMobileMenu(false);
    selectGroup(groupId);
  };

  // Bit messy, but we need to visually hide the delete icon but leave it in the DOM so that we
  // can process the click event that triggers the delete confirmation modal
  const onGroupNameChange = (name) => {
    if (groups.length > 1) {
      setIsDeleteIconHidden(true);
      setTimeout(() => {
        setIsDeleteIconRendered(!isDeleteIconRendered);
        setIsDeleteIconHidden(false);
      }, 750);
    }

    if (name !== selected.name) {
      editGroup(name);
    }
  };

  const onConfirmGroupDelete = () => {
    deleteGroup(selected.id);
    hideDeleteGroupConfirmation();
  };

  const enableGroupDropdown = () => {
    const {company, group} = permissions;
    const isCompanyAdmin = company !== false;
    const isMultipleGroupAdmin = group && group.length > 1;
    return (isCompanyAdmin || isMultipleGroupAdmin) && !isTrial;
  };

  if (!selected) {
    return <div />;
  }

  const groupDropdownEnabled = enableGroupDropdown();

  let dropdownOptions = groups.map((group) => ({
    title: group.name,
    callback: () => selectGroup(group.id),
    dataAutomation: `group-dropdown-option`,
  }));

  const nameTooltipText = intl.formatMessage({
    id: 'Header.editGroupName.tooltip',
  });
  const deleteTooltipText = intl.formatMessage({
    id: 'Header.deleteGroup.tooltip',
  });

  const tooltip = (
    <ReactTooltip
      className='iconButtonTooltip'
      delayShow={300}
      id='deleteTip'
      place='bottom'
      effect='solid'
      backgroundColor='black'
    >
      {deleteTooltipText}
    </ReactTooltip>
  );

  return (
    <div
      styleName={`container ${groupDropdownEnabled ? 'dropdown-enabled' : ''}`}
    >
      <div styleName='group-display-wrapper'>
        {isShowingMobileMenu && (
          <SelectGroupMenu
            groups={groups}
            selectGroup={onSelectGroup}
            active={isShowingMobileMenu}
            onClose={hideMobileMenu}
          />
        )}

        <button
          styleName='dropdown-container'
          data-automation='header-item-group-dropdown'
          onClick={groupDropdownEnabled ? handleGroupDropdown : () => {}}
          disabled={!groupDropdownEnabled ? true : false}
        >
          <div styleName='dropdown'>
            {groupDropdownEnabled && (
              <img src='/resources/img/24_drop_arrow_purple_mid.svg' />
            )}
            <LegacyIntlHeading
              textKey='Header.groupLabel'
              weight='medium'
              size='micro'
              colour={groupDropdownEnabled ? 'purple300' : 'grey050'}
            />
          </div>
        </button>
        {isShowingGroupDropdown && (
          <div styleName='popover-wrapper'>
            <ListPopover
              title={intl.formatMessage({id: 'Groups.selectionPopoverTitle'})}
              close={hideGroupDropdown}
              options={dropdownOptions}
              withSearch={dropdownOptions.length > 5}
              searchPlaceholder={intl.formatMessage({
                id: 'SelectGroup.search.placeholder',
              })}
              searchClearText={intl.formatMessage({
                id: 'SelectGroup.search.clearText',
              })}
              searchNoResultsMessage={intl.formatMessage({
                id: 'SelectGroup.search.noResultsMessage',
              })}
              fixedBottomContent={
                permissions.company && permissions.company.isLead ? (
                  <div>
                    <hr styleName='divider' />
                    <Button
                      type='secondary'
                      dataAutomation='add-group-button'
                      onClick={showAddGroupModal}
                      noMarginLeft
                      noMarginTop
                      icon={<PlusIcon colour='white' />}
                    >
                      {intl.formatMessage({
                        id: 'Groups.selectionPopoverAddLink',
                      })}
                    </Button>
                  </div>
                ) : null
              }
              dataAutomation='group-search-input'
            />
          </div>
        )}

        <div styleName='name-container'>
          <p styleName='name-divider'>|</p>
          <div styleName='header-container'>
            <InlineInput
              value={selected.name}
              onSave={onGroupNameChange}
              onEdit={onGroupNameInputClick}
              size='hamster'
              colour='white'
              weight='medium'
              dataAutomationInput='group-name'
              dataAutomationIcon='edit-group-name-button'
              iconSize='small'
              disabled={!hasLeadRightsOnGroup}
              maxLength={60}
              tooltipProps={{
                text: nameTooltipText,
                place: 'bottom',
                effect: 'solid',
                backgroundColor: 'black',
              }}
            />
            {isDeleteIconRendered && hasLeadRightsOnCompany && (
              <>
                <div
                  styleName={`delete-icon-container ${
                    isDeleteIconHidden ? 'hidden' : ''
                  }`}
                  onClick={showDeleteGroupConfirmation}
                  data-automation='delete-group-button'
                  data-tip
                  data-for='deleteTip'
                >
                  <div styleName='delete-icon'>
                    <DeleteIcon size='small' colour='purple300' />
                  </div>
                </div>
                {tooltip}
              </>
            )}
            {formError && (
              <div styleName='error-container'>
                <AlertIcon size='tiny' colour='white' />
                <LegacyIntlHeading
                  textKey={`Error.${formError}`}
                  size='mini'
                  colour='white'
                />
              </div>
            )}
          </div>

          {groupDropdownEnabled && (
            <button styleName='mobile-switch' onClick={showMobileMenu}>
              <img src='/resources/img/24_switch_white.svg' alt='switch' />
              <LegacyIntlHeading
                textKey='Header.switchGroup'
                weight='medium'
                size='mini'
                colour='white'
              />
            </button>
          )}
        </div>
      </div>

      {isShowingAddGroupModal && (
        <div styleName='add-group-container'>
          <EditTeamOrGroup
            editType='add'
            onSave={addGroup}
            onCancel={hideAddGroupModal}
            isGroup
            isModal
          />
        </div>
      )}

      {isShowingDeleteGroupConfirmation && (
        <DeleteGroupModal
          onClose={hideDeleteGroupConfirmation}
          onConfirm={onConfirmGroupDelete}
        />
      )}
    </div>
  );
};

SelectGroup.propTypes = {
  //generic props
  selected: PropTypes.object, //returns <div/> if no group provided
  groups: PropTypes.array.isRequired,
  permissions: EdumePropTypes.permissions.isRequired,
  isTrial: PropTypes.bool.isRequired,
  formError: PropTypes.string,
  intl: PropTypes.object.isRequired,
  //actions
  addGroup: PropTypes.func.isRequired,
  selectGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

export default injectIntl(
  CSSModules(SelectGroup, styles, {allowMultiple: true})
);
