import React, {useEffect} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {MS_TEAMS_DEFAULT_COMPANY_NAME} from '@edume/bento/integrations';
import {NEW_TRIAL_STRING} from '@edume/bento/trials';
import {AdminBadge, Heading} from '@edume/magnificent';

import EdumePropTypes from '../../../proptypes';
import {setIsTrialSignup} from '../../../state/trial/trialActions';
import FormStepOne from '../../Trial/FormStepOne';

import styles from './styles.module.scss';

const OverviewHeader = ({
  adminLevel,
  adminRoles,
  firstName,
  isTrialSignup,
  standardStyle,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const companyName = useSelector(
    (state) => state.getIn(['auth', 'company'])?.officialName
  );

  useEffect(() => {
    if (
      companyName === MS_TEAMS_DEFAULT_COMPANY_NAME ||
      companyName?.includes(NEW_TRIAL_STRING) ||
      !firstName?.length
    ) {
      dispatch(setIsTrialSignup(true));
    }
  }, [companyName, dispatch, firstName]);

  const getBadges = () => {
    const badges = [];
    if (adminRoles.isLead) {
      badges.push(
        <AdminBadge
          level={adminLevel}
          role='lead'
          key='lead'
          text={intl.formatMessage({id: `Admin.level.${adminLevel}.lead`})}
        />
      );
    } else {
      for (const roleName in adminRoles) {
        if (adminRoles[roleName]) {
          const role = roleName.toLowerCase().replace(/^is/, '');
          badges.push(
            <AdminBadge
              level={adminLevel}
              role={role}
              key={roleName}
              text={intl.formatMessage({
                id: `Admin.level.${adminLevel}.${role}`,
              })}
            />
          );
        }
      }
    }
    return <div styleName='badges'>{badges}</div>;
  };

  const headingTextKey = isTrialSignup
    ? 'Overview.trialSignup.heading'
    : 'Overview.greeting';

  return (
    <div styleName={classNames('big-rectangle', {standard: standardStyle})}>
      <div
        styleName={classNames('column-container', {
          standard: standardStyle,
          compact: isTrialSignup,
        })}
      >
        <Heading
          dataAutomation='welcome-text'
          size='rhino'
          weight='medium'
          as='h1'
        >
          {intl.formatMessage({id: headingTextKey}, {firstName})}
        </Heading>
        {isTrialSignup ? <FormStepOne /> : getBadges()}
      </div>
    </div>
  );
};

OverviewHeader.propTypes = {
  adminLevel: EdumePropTypes.adminLevel.isRequired,
  adminRoles: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  isTrialSignup: PropTypes.bool,
  standardStyle: PropTypes.bool,
};

export default CSSModules(OverviewHeader, styles, {allowMultiple: true});
