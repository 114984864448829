import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {OTP, SSU} from '@edume/bento/auth';
import {LegacyHeading} from '@edume/magnificent';

import * as contentAssignmentTypes from '../../../enums/contentAssignment';
import useFeatureFlag from '../../../hooks/useFeatureFlags';
import {
  getGroupAccessType,
  getIsSsuSsoEnabled,
} from '../../../state/team/teamSelectors';
import {getKnowledgeHubLink} from '../../../utils/links';
import {CopyLinkButton, PreviewLinkButton} from '../../core';

import styles from './styles.module.scss';

// eslint-disable-next-line complexity
const KnowledgeHubCard = ({
  intl,
  knowledgeHub,
  teams,
  isSeamlessEnabled,
  showLinks,
  createErrorNotification,
  trackIdCopied,
  history,
}) => {
  const knowledgeHubsManagementEnabled = useFeatureFlag(
    'knowledgeHubsManagement'
  );
  const groupAccessType = useSelector((state) => getGroupAccessType(state));
  const isSsuSsoEnabled = getIsSsuSsoEnabled(groupAccessType);

  const {
    id,
    name,
    teams: khTeams,
    showsExternalContent,
    contentAssignment,
  } = knowledgeHub;

  const createLinkOption = (text, link, dataAutomation) => ({
    title: intl.formatMessage({id: text}),
    color: 'primary',
    link,
    tooltipProps: {
      text: intl.formatMessage({id: 'Link.tooltip.copyToClipboard'}),
      effect: 'solid',
      place: 'bottom',
      backgroundColor: 'black',
    },
    dataAutomation,
  });

  let linkOptions = [];
  const linkArgs = {
    knowledgeHubId: id,
    groupOrTeamIds: khTeams,
  };

  if (isSsuSsoEnabled) {
    linkOptions.push(
      createLinkOption(
        `Link.type.${groupAccessType}.knowledgeHubTitle`,
        getKnowledgeHubLink({type: SSU, ...linkArgs}),
        `copy-knowledge-hub-${groupAccessType}-link-button`
      )
    );
  }

  if (groupAccessType === OTP) {
    linkOptions.push(
      createLinkOption(
        'Link.type.otp',
        getKnowledgeHubLink({
          type: OTP,
          knowledgeHubId: id,
          groupOrTeamIds: teams[0]?.parentGroupId,
        }),
        'copy-knowledge-hub-otp-link-button'
      )
    );
  }

  if (isSeamlessEnabled) {
    linkOptions.push(
      createLinkOption(
        'Link.type.seamless.knowledgeHubTitle',
        getKnowledgeHubLink({type: 'seamless', ...linkArgs}),
        'copy-knowledge-hub-seamless-link-button'
      )
    );
  }

  const isManualAssignment =
    contentAssignment === contentAssignmentTypes.MANUAL;

  const isManagementViewEnabled =
    knowledgeHubsManagementEnabled && isManualAssignment;

  const handleSelectKnowledgeHub = () =>
    isManagementViewEnabled ? history.push(`/learning/kh/${id}`) : {};

  const tooltipId = `knowledge-hub-card-${id}`;
  return (
    <div
      styleName={`hub-summary${isManagementViewEnabled ? ' manual' : ''}`}
      data-automation='knowledge-hub-card'
      onClick={handleSelectKnowledgeHub}
    >
      <div styleName='summary-title'>
        <LegacyHeading
          dataAutomation='knowledge-hub-name'
          size='tinier'
          weight='bold'
          maxLines={1}
        >
          {name}
        </LegacyHeading>
      </div>

      {!isManagementViewEnabled && (
        <div styleName='summary-info'>
          <LegacyHeading size='micro' weight='medium'>
            {intl.formatMessage({id: 'KnowledgeHub.summary.courseView'})}{' '}
          </LegacyHeading>
          <LegacyHeading dataAutomation='course-view-type' size='micro'>
            {intl.formatMessage({
              id: `KnowledgeHub.courseSelection.${
                showsExternalContent ? 'user' : 'teams'
              }`,
            })}
          </LegacyHeading>
        </div>
      )}
      {!isManualAssignment && (
        <>
          <div styleName='summary-info'>
            <div data-tip data-for={tooltipId}>
              <LegacyHeading
                size='micro'
                weight='medium'
                colour='primary'
                dataAutomation='number-of-teams'
              >
                {intl.formatMessage(
                  {id: 'KnowledgeHub.summary.teamsCount'},
                  {count: khTeams.length}
                )}
              </LegacyHeading>
            </div>
          </div>
          <ReactTooltip id={tooltipId} place='right' effect='solid'>
            <div styleName='teams-tooltip'>
              {khTeams.map((teamId) => (
                <span key={teamId} data-automation='selected-team'>
                  {teams.find((team) => team.id === teamId).name}
                </span>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}

      <div styleName='summary-actions'>
        <PreviewLinkButton
          contentType='knowledgeHub'
          knowledgeHubId={id}
          isIconOnly={showLinks}
          intl={intl}
        />
        {showLinks && !isManualAssignment && (
          <CopyLinkButton
            options={linkOptions}
            displayedText={intl.formatMessage({id: 'Link.copy'})}
            dataAutomation='copy-links-button'
            showBorder={false}
            isExpandable={false}
            trackIdCopied={trackIdCopied}
            createErrorNotification={createErrorNotification}
          />
        )}
      </div>
    </div>
  );
};

KnowledgeHubCard.propTypes = {
  knowledgeHub: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  isSeamlessEnabled: PropTypes.bool,
  showLinks: PropTypes.bool,
  createErrorNotification: PropTypes.func,
  trackIdCopied: PropTypes.func,
  history: PropTypes.object.isRequired,
};

export default withRouter(
  injectIntl(CSSModules(KnowledgeHubCard, styles, {allowMultiple: true}))
);
