import React from 'react';

import * as Sentry from '@sentry/react';
import 'core-js/stable';
import moment from 'moment';
import {createRoot} from 'react-dom/client';
import {hotjar} from 'react-hotjar';

import '@edume/magnificent/dist/resources/styles/fonts.css';

import App from './App';
import {getConfig} from './config';
import {amplitude} from './tracking/index';

import '../resources/styles/common.scss';
import '../resources/styles/mdxeditorStyles.css';
import '../resources/styles/reactTabs.scss';
import './grid.module.scss';

const {version: releaseVersion} = require('../package.json');

//spanish loads "26º" instead of "26 de" for dates, fix that
moment.updateLocale('es', {
  ordinal: (number) => number + ' de',
});

(async () => {
  const config = await getConfig();
  amplitude.initialiseAmplitude(config.amplitudeApiKey);

  if (!window.hj) {
    hotjar.initialize(config.hotjarId, 6);
  }

  if (config.sentryDsnUrl && config.pipelineEnv) {
    Sentry.init({
      dsn: config.sentryDsnUrl,
      release: releaseVersion,
      environment: config.pipelineEnv,
      integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0.2,
      debug: false,
    });
  }
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
})();
