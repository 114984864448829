import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {AlertIcon, HintBox} from '@edume/magnificent';

import AdditionalIntegrationContent from '../AdditionalIntegrationContent';

import styles from './styles.module.scss';

const ErrorMessage = ({teamSource, getHasMsTeamsError, intl}) => {
  const {title, teamDescriptor, type, errorCode} = teamSource;

  let errorTitle = '';
  let errorText = '';

  // We assume that an MS Teams 401 error is because the token has expired, so we direct the user to the MST app
  if (getHasMsTeamsError({errorCode, type})) {
    errorTitle = intl.formatMessage({
      id: 'Learning.assignTeams.msTeamsError.title',
    });
    errorText = intl.formatMessage({
      id: 'Learning.assignTeams.msTeamsError.text',
    });
  } else if (errorCode === 401 || errorCode === 403) {
    errorTitle = intl.formatMessage(
      {id: 'Learning.assignTeams.apiAuthError'},
      {
        integrationName: title,
        teamDescriptor: intl.formatMessage({
          id: `Learning.assignTeams.${teamDescriptor}`,
        }),
      }
    );
    errorText = intl.formatMessage({
      id: 'Learning.assignTeams.apiAuthErrorMsg',
    });
  } else {
    errorTitle = intl.formatMessage(
      {id: 'Learning.assignTeams.apiConnectionError'},
      {integrationName: title}
    );
    errorText = intl.formatMessage(
      {id: 'Learning.assignTeams.apiConnectionErrorMsg'},
      {integrationName: title}
    );
  }

  return (
    <>
      <div styleName={'error-alert'}>
        <HintBox
          type={'error'}
          icon={<AlertIcon />}
          title={errorTitle}
          text={errorText}
        />
      </div>
      <AdditionalIntegrationContent type={type} error={errorCode} />
    </>
  );
};

ErrorMessage.propTypes = {
  teamSource: PropTypes.object.isRequired,
  getHasMsTeamsError: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(ErrorMessage, styles));
