import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Heading, Link} from '@edume/magnificent';

import styles from './styles.module.scss';

const SelectableItemList = ({items, selected, onSelect, dataAutomation}) => (
  <div
    styleName='selectable-item-list'
    data-automation={dataAutomation}
    role='menu'
  >
    {items.map((item, index) => (
      <div key={index}>
        <button
          data-automation={item.dataAutomation}
          styleName={`selectable-item ${index === selected ? ' selected' : ''}`}
          onClick={() => onSelect(index)}
          role='menuitem'
        >
          {item.image ? <img src={item.image} /> : <div styleName='blank' />}
          <div styleName='selectable-item-title'>
            <Heading
              as='h3'
              size={items.length > 3 ? 'hamster' : 'cat'}
              weight='medium'
            >
              {item.title}
            </Heading>
          </div>
        </button>
        {item.link && (
          <div styleName='selectable-item-link'>
            <Link
              onClick={() => item.link.onClick(index)}
              text={item.link.text}
            />
          </div>
        )}
      </div>
    ))}
  </div>
);

SelectableItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string.isRequired,
      dataAutomation: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    })
  ).isRequired,
  selected: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  dataAutomation: PropTypes.string,
};

export default CSSModules(SelectableItemList, styles, {allowMultiple: true});
