import React from 'react';

import {sanitizeUrl} from '@braintree/sanitize-url';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {DefaultPlayer as Video} from 'react-html5video';
import YouTube from 'react-youtube';

import {getVideoUrl} from '@edume/bento/videoUtil';
import {FixedRatioImage} from '@edume/magnificent';

import '../../../../resources/styles/ReactHtml5Video.css';
import styles from './styles.module.scss';

const MediaContainer = ({type, mediaUrl, thumbnailUrl, youtubeId}) => (
  <div styleName='media-container'>
    {type === 'embed' && (
      <YouTube
        containerClassName='youtube-container'
        className='youtube'
        videoId={youtubeId}
      />
    )}
    {type === 'video' && (
      <Video
        controls={['PlayPause', 'Seek', 'Time', 'Volume']}
        poster={thumbnailUrl}
        width='100%'
      >
        <source src={sanitizeUrl(getVideoUrl(mediaUrl))} type='video/mp4' />
      </Video>
    )}
    {type === 'image' && (
      <a href={thumbnailUrl || mediaUrl} target='blank'>
        <FixedRatioImage
          style={{
            paddingBottom: '50%',
            backgroundSize: 'cover',
          }}
          url={sanitizeUrl(thumbnailUrl || mediaUrl)}
          dataAutomation='image-container'
        />
      </a>
    )}
  </div>
);

MediaContainer.propTypes = {
  type: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  youtubeId: PropTypes.string,
};

export default CSSModules(MediaContainer, styles);
