import {isAllUsersSegmentRule} from '@edume/bento/segmentValidation';

export const getAllUsersSegment = (state) =>
  state
    .getIn(['segments', 'segments'])
    .find((segment) => isAllUsersSegmentRule(segment.get('rules').toJS()));

export const getSegmentById = (state, id) => {
  const segments = state.getIn(['segments', 'segments']).toJS();
  return segments.find((segment) => segment.id === id);
};
