import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import styles from '../styles.module.scss';

const SelectIconAndColour = ({type, onSelect, items, selectedItem}) => {
  const intl = useIntl();

  const buildIcon = (item) => (
    <button
      type='button'
      styleName={classNames(`${type}-circle`, {
        selected: item === selectedItem,
      })}
      key={item}
      onClick={() => onSelect(item)}
      data-automation={`select-course-${type}-${item}`}
    >
      {type === 'icon' ? (
        <img
          src={`https://edume-res.cloudinary.com/image/upload/course_icons/transparent/${item}.png`}
          alt={intl.formatMessage({id: `Image.alt.${type}.${item}`})}
        />
      ) : (
        <div style={{background: item}} />
      )}
    </button>
  );

  return <div styleName='dropdown-container'>{items.map(buildIcon)}</div>;
};

SelectIconAndColour.propTypes = {
  type: PropTypes.oneOf(['icon', 'colour']).isRequired,
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.string.isRequired,
};

export default CSSModules(SelectIconAndColour, styles, {allowMultiple: true});
