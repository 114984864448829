import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {MS_TEAMS} from '@edume/bento/integrations';
import {LegacyHeading} from '@edume/magnificent';

import SelectUnselectAllLink from './SelectUnselectAllLink';

import styles from './styles.module.scss';

const SelectableTeamsConditionalHeadings = ({
  accessMethod,
  isMsTeams,
  isViewingWithinMST,
  selectedIdsBySource,
  sourceIndex,
  totalSelectedIds,
  hasSearchResults,
  allSearchedTeamsIds,
  allTeamIds,
  updateSelectedTeams,
  intl,
}) => {
  const numberTeamsAvailableToSelect = hasSearchResults
    ? allSearchedTeamsIds.length
    : allTeamIds.length;

  const preselectedNotInSearchResultsIds = selectedIdsBySource[
    sourceIndex
  ].filter((id) => !allSearchedTeamsIds.includes(id));

  const hasAllTeamsSelected = numberTeamsAvailableToSelect === totalSelectedIds;

  const hasAllSearchedSelected =
    numberTeamsAvailableToSelect + preselectedNotInSearchResultsIds.length ===
    totalSelectedIds;

  const isSelectAll = hasSearchResults
    ? !hasAllSearchedSelected
    : !hasAllTeamsSelected;

  return (
    <div styleName='assignment-headings'>
      {accessMethod !== MS_TEAMS ||
      (isViewingWithinMST && !isMsTeams) ||
      hasSearchResults ||
      totalSelectedIds ? (
        <SelectUnselectAllLink
          selectedIdsBySource={selectedIdsBySource}
          sourceIndex={sourceIndex}
          hasSearchResults={hasSearchResults}
          allSearchedTeamsIds={allSearchedTeamsIds}
          allTeamIds={allTeamIds}
          isSelectAll={isSelectAll}
          updateSelectedTeams={updateSelectedTeams}
        />
      ) : (
        <LegacyHeading size='micro' weight='medium' colour='textPrimary'>
          {intl.formatMessage({
            id: 'Learning.assignTeams.recent',
          })}
        </LegacyHeading>
      )}
      <LegacyHeading size='micro' weight='medium' colour='textPrimary'>
        {intl.formatMessage(
          {id: 'Learning.assignTeams.assigned'},
          {num: totalSelectedIds}
        )}
      </LegacyHeading>
    </div>
  );
};

SelectableTeamsConditionalHeadings.propTypes = {
  accessMethod: PropTypes.string.isRequired,
  isMsTeams: PropTypes.bool.isRequired,
  isViewingWithinMST: PropTypes.bool.isRequired,
  selectedIdsBySource: PropTypes.array.isRequired,
  sourceIndex: PropTypes.number.isRequired,
  totalSelectedIds: PropTypes.number.isRequired,
  hasSearchResults: PropTypes.bool.isRequired,
  allSearchedTeamsIds: PropTypes.array.isRequired,
  allTeamIds: PropTypes.array.isRequired,
  updateSelectedTeams: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  CSSModules(SelectableTeamsConditionalHeadings, styles)
);
