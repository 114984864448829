import React, {MouseEventHandler, ReactNode} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import ReactTooltip from 'react-tooltip';

import {getTooltipDataKey} from '../utils/tooltipUtils';

import styles from './styles.module.scss';

export type LinkColours = (typeof linkColours)[number];
export const linkColours = [
  'primary',
  'red',
  'white',
  'black',
  'green',
  'blue',
  'purple500',
  'purple300',
  'textPrimary',
] as const;

export type LinkSizes = (typeof linkSizes)[number];
export const linkSizes = ['ant', 'snail', 'hamster', 'cat'] as const;

export type IconSizes = 'mini' | 'micro' | 'tinier' | 'tiny' | 'medium';
export type IconPositions = (typeof iconPositions)[number];
export const iconPositions = ['start', 'end'] as const;

type CommonLinkProps = {
  text: string;
  icon?: ReactNode;
  iconLink?: string;
  iconSize?: IconSizes;
  linkColour?: LinkColours;
  linkSize?: LinkSizes;
  disabled?: boolean;
  keepEnabledTextColour?: boolean;
  reskinClassName?: string;
  type?: string;
  dataAutomation?: string;
  shouldDangerouslySetInnerHtml?: boolean;
  iconPosition?: string;
  tooltipProps?: {
    text: string;
    effect: 'float' | 'solid';
    place: 'top' | 'right' | 'bottom' | 'left';
    backgroundColor?: string;
  };
};

type ConditionalOnClickProps =
  | {
      onClick: MouseEventHandler<HTMLButtonElement>;
      href?: never;
    }
  | {
      onClick?: never;
      href: string;
    };

type LinkProps = CommonLinkProps & ConditionalOnClickProps;

// eslint-disable-next-line complexity
const Link = ({
  iconLink,
  icon,
  href,
  onClick,
  text,
  linkColour = 'primary',
  linkSize = 'ant',
  iconSize,
  disabled,
  keepEnabledTextColour,
  reskinClassName = '',
  type = '',
  dataAutomation,
  shouldDangerouslySetInnerHtml,
  iconPosition = 'start',
  tooltipProps,
}: LinkProps): JSX.Element => {
  const iconEl =
    icon ||
    (iconLink && (
      <img styleName={`linkImg ${iconSize || 'default'}-img`} src={iconLink} />
    ));
  const linkText = (
    <span
      styleName={classNames('link', reskinClassName, linkSize, {
        disabled: disabled,
        keepEnabledTextColour: keepEnabledTextColour,
      })}
    >
      {text}
    </span>
  );

  const tooltip = tooltipProps && (
    <ReactTooltip
      className='iconButtonTooltip'
      delayShow={300}
      id={tooltipProps && getTooltipDataKey(tooltipProps.text)}
      {...tooltipProps}
    >
      {tooltipProps && tooltipProps.text}
    </ReactTooltip>
  );

  const targetTooltipAttributes = tooltipProps
    ? {
        'data-tip': true,
        'data-for': getTooltipDataKey(tooltipProps.text),
        'data-tip-disable': false,
      }
    : {};

  if (shouldDangerouslySetInnerHtml) {
    return (
      <>
        <button
          disabled={disabled}
          data-automation={dataAutomation}
          onClick={disabled ? () => {} : onClick}
          styleName='innerHtmlWrapper'
          dangerouslySetInnerHTML={{__html: text}}
          {...targetTooltipAttributes}
        />
        {tooltip}
      </>
    );
  }

  const linkContent = (
    <>
      {iconPosition === 'start' && iconEl}
      {linkText}
      {iconPosition === 'end' && iconEl}
    </>
  );

  if (href) {
    return (
      <>
        <a
          data-automation={dataAutomation}
          href={disabled ? '' : href}
          target='_blank'
          styleName={classNames('linkContainer', type, linkColour)}
          rel='noreferrer'
          {...targetTooltipAttributes}
        >
          {disabled ? (
            <span
              styleName='disabled-span-wrapper'
              {...targetTooltipAttributes}
            >
              {linkContent}
            </span>
          ) : (
            linkContent
          )}
        </a>
        {tooltip}
      </>
    );
  } else {
    return (
      <>
        <button
          data-automation={dataAutomation}
          onClick={disabled ? () => {} : onClick}
          styleName={classNames('linkContainer', type, linkColour)}
          type='button'
          disabled={disabled}
          {...targetTooltipAttributes}
        >
          <span
            styleName={classNames('span-wrapper', {
              'disabled-span-wrapper': disabled,
            })}
            {...(disabled ? targetTooltipAttributes : {})}
          >
            {linkContent}
          </span>
        </button>
        {tooltip}
      </>
    );
  }
};

export default CSSModules(Link, styles, {allowMultiple: true});
