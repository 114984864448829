import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Button} from '@edume/magnificent';

import {LegacyIntlHeading} from '../../core';
import OverviewBlock from '../OverviewBlock';

import styles from './styles.module.scss';

const OverviewPromo = ({
  backgroundImageUrl,
  theme,
  titleKey,
  descriptionKey,
  primaryButtonKey,
  primaryButtonAction,
  primaryButtonIcon,
  secondaryButtonKey,
  secondaryButtonAction,
  secondaryButtonIcon,
  textPosition,
}) => (
  <OverviewBlock>
    <div
      styleName={`promo ${textPosition} ${theme}`}
      style={{'--bg-image': `url(${backgroundImageUrl})`}}
    >
      <div styleName='promo-content'>
        <LegacyIntlHeading
          size='medium'
          weight='medium'
          colour={theme === 'dark' ? 'white' : 'textPrimary'}
          textKey={titleKey}
        />
        <LegacyIntlHeading
          size='tiny'
          colour={theme === 'dark' ? 'grey300' : 'grey700'}
          textKey={descriptionKey}
        />
        <div>
          {primaryButtonKey && (
            <Button type='primary' onClick={primaryButtonAction} noMarginLeft>
              <div styleName='button-content'>
                <LegacyIntlHeading
                  textKey={primaryButtonKey}
                  size='mini'
                  weight='medium'
                />
                {primaryButtonIcon && (
                  <span styleName='icon'>{primaryButtonIcon}</span>
                )}
              </div>
            </Button>
          )}
          {secondaryButtonKey && (
            <Button
              type='secondary'
              onClick={secondaryButtonAction}
              noMarginLeft
            >
              <div styleName='button-content'>
                <LegacyIntlHeading
                  textKey={secondaryButtonKey}
                  size='mini'
                  weight='medium'
                />
                {secondaryButtonIcon && (
                  <span styleName='icon'>{secondaryButtonIcon}</span>
                )}
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  </OverviewBlock>
);

OverviewPromo.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string.isRequired,
  primaryButtonKey: PropTypes.string,
  primaryButtonAction: PropTypes.func,
  primaryButtonIcon: PropTypes.node,
  secondaryButtonKey: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonIcon: PropTypes.node,
  textPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  theme: PropTypes.oneOf(['light', 'dark']).isRequired,
};

export default CSSModules(OverviewPromo, styles, {allowMultiple: true});
