import React from 'react';

import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {
  HappyFilledIcon,
  HintBox,
  InfoIcon,
  LoadingIndicator,
} from '@edume/magnificent';

import {StyledModal} from '../../../../containers/StyledModals';
import {COURSE, GUIDE} from '../../../../enums/contentTypes';
import {LegacyIntlHeading} from '../../../core';

const ConfirmCopyContent = ({
  close,
  contentType,
  sourceContentTitle,
  copiedGroups,
  currentGroupId,
  selectGroup,
  isLoading,
  isFailed,
  intl,
  onBackToContentList,
  isResourceLibraryCourse,
  goToContent,
  duplicatedContent,
}) => {
  const confirmCopyContentTitle = intl.formatMessage({
    id: 'Learning.confirmCopyContentTitle',
  });
  const confirmCopyContentLoadingTitle = intl.formatMessage({
    id: 'Learning.confirmCopyContentLoadingTitle',
  });
  const confirmCopyContentConfirm = intl.formatMessage({
    id: 'Learning.confirmCopyContentConfirm',
  });

  const confirmCopyContentHintBoxTitle = intl.formatMessage({
    id: 'Learning.confirmCopyContentHintBoxTitle',
  });
  const confirmCopyContentHintBoxText = intl.formatMessage({
    id: 'Learning.confirmCopyContentHintBoxText',
  });
  const confirmCopyResourceLibraryContentTitle = intl.formatMessage({
    id: 'Learning.confirmCopyResourceLibraryContentTitle',
  });
  const confirmCopyResourceLibraryContentConfirm = intl.formatMessage({
    id: 'Learning.confirmCopyResourceLibraryContentConfirm',
  });

  const closeButtonText = intl.formatMessage({id: 'Button.close'});

  const body = (
    <div>
      <p>
        <LegacyIntlHeading
          size='mini'
          textKey='Learning.confirmCopyContent'
          values={{
            groupCount: copiedGroups.length,
            sourceContentTitle: <b>{sourceContentTitle}</b>,
          }}
        />
      </p>
      <HintBox
        title={confirmCopyContentHintBoxTitle}
        text={confirmCopyContentHintBoxText}
        showCheckBox={false}
        icon={<InfoIcon />}
      />
    </div>
  );

  const successContent = {
    title: confirmCopyContentTitle,
    body,
    buttons: [
      {
        type: 'primary',
        onClick: close,
        text: confirmCopyContentConfirm,
        dataAutomation: 'ok-button',
      },
    ],
  };

  const loadingContent = {
    title: confirmCopyContentLoadingTitle,
    body: <LoadingIndicator indicator='hexagons' containerStyle='centerFlex' />,
    buttons: [
      {
        type: 'primary',
        onClick: close,
        text: closeButtonText,
      },
    ],
  };

  const resourceLibraryContentBody = (
    <div>
      <LegacyIntlHeading
        size='small'
        textKey='Learning.addedToMyLibraryTitle'
      />
      <p>
        <LegacyIntlHeading
          size='mini'
          textKey='Learning.addedToMyLibraryMessage'
          values={{
            contentType,
          }}
        />
      </p>
    </div>
  );

  const resourceLibraryContent = {
    title: confirmCopyResourceLibraryContentTitle,
    icon: <HappyFilledIcon size='big' colour='white' />,
    body: resourceLibraryContentBody,
    buttons: [
      {
        type: 'primary',
        onClick: () => goToContent(duplicatedContent),
        text: confirmCopyResourceLibraryContentConfirm,
        dataAutomation: `go-to-${contentType}-button`,
      },
      {
        type: 'secondary',
        onClick: close,
        text: closeButtonText,
        dataAutomation: 'close-button',
      },
    ],
  };

  let content;

  if (isResourceLibraryCourse) {
    content = resourceLibraryContent;
  } else if (isLoading) {
    content = loadingContent;
  } else {
    content = successContent;
  }

  if (copiedGroups.length === 1) {
    const goToContentListButton = intl.formatMessage({
      id: 'Learning.goToContentListButton',
    });
    if (copiedGroups[0].id === currentGroupId) {
      content.bottomLeftContent = {
        text: goToContentListButton,
        onClick: () => {
          onBackToContentList();
        },
        type: 'link',
      };
    } else {
      content.bottomLeftContent = {
        text: goToContentListButton,
        onClick: () => {
          selectGroup(copiedGroups[0].id);
        },
        type: 'link',
      };
    }
  }

  if (isFailed) {
    close();
  }

  return (
    <StyledModal
      onClose={close}
      content={content}
      blockHeader='success'
      linkDataAutomation='link-to-content'
    />
  );
};

ConfirmCopyContent.propTypes = {
  close: PropTypes.func.isRequired,
  contentType: PropTypes.oneOf([COURSE, GUIDE]).isRequired,
  sourceContentTitle: PropTypes.string.isRequired,
  copiedGroups: PropTypes.array.isRequired,
  currentGroupId: PropTypes.number,
  duplicatedContent: PropTypes.object,
  goToContent: PropTypes.func.isRequired,

  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  onBackToContentList: PropTypes.func,
  isResourceLibraryCourse: PropTypes.bool,

  selectGroup: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
};

export default withRouter(ConfirmCopyContent);
