import {getExtensionFromFileName} from '@edume/bento/fileUtil';

import {
  ADD_LESSON,
  COPY_LESSON,
  CREATE_MODULE_SURVEY,
  DELETE_LESSON,
  DELETE_MODULE_SURVEY,
} from '../course/courseActionTypes';
import {addMeta, buildRequest} from '../stateUtil';
import {
  GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT,
  GENERATE_LESSONS_FROM_TOPICS,
  GET_COURSE_SURVEYS,
  GET_EDURATING,
  GET_LESSON,
  STORE_LESSON_PREVIEW_TOKEN,
  UPDATE_LESSON,
} from './moduleActivityActionTypes';

export const getLesson = (lessonKey, language) => {
  const query = language ? `?language=${language}` : '';
  return buildRequest(GET_LESSON, 'GET', `/lessons/${lessonKey}${query}`);
};

export const getCourseSurveys = (courseId, language) => {
  const query = language ? `?language=${language}` : '';
  return buildRequest(
    GET_COURSE_SURVEYS,
    'GET',
    `/courses/${courseId}/surveys${query}`
  );
};

export const addLesson = (
  moduleId,
  templateType,
  lessonEson,
  courseId,
  lessonType,
  fileUploadedType,
  courseDefaultLanguage
) =>
  addMeta(
    buildRequest(ADD_LESSON, 'POST', '/lessons', {moduleId, lessonEson}),
    {
      templateType,
      lessonEson,
      courseId,
      lessonType,
      fileUploadedType,
      courseDefaultLanguage,
    }
  );

export const createModuleSurvey = (moduleId, survey, courseDefaultLanguage) =>
  addMeta(
    buildRequest(
      CREATE_MODULE_SURVEY,
      'POST',
      `/module/${moduleId}/surveys`,
      survey
    ),
    {moduleId, courseDefaultLanguage}
  );

export const copyLessonToCourse = (lessonId, courseId, moduleId) =>
  moduleId
    ? addMeta(
        buildRequest(COPY_LESSON, 'POST', `/lessons/${lessonId}/duplicate`, {
          targetModuleId: moduleId,
        }),
        {courseId, moduleId}
      )
    : addMeta(
        buildRequest(COPY_LESSON, 'POST', `/lessons/${lessonId}/duplicate`, {
          targetCourseId: courseId,
        }),
        {courseId}
      );

export const updateLesson = (
  lessonKey,
  lessonEson,
  language,
  metaData,
  originalLessonEson
) => {
  const query = language ? `?language=${language}` : '';
  return addMeta(
    buildRequest(UPDATE_LESSON, 'PUT', `/lessons/${lessonKey}${query}`, {
      lessonEson,
      originalLessonEson,
    }),
    metaData
  );
};

export const deleteLesson = (lessonKey, moduleId, position) =>
  addMeta(buildRequest(DELETE_LESSON, 'DELETE', `/lessons/${lessonKey}`), {
    lessonKey,
    moduleId,
    position,
  });

export const deleteModuleSurvey = (surveyId, moduleId, position) =>
  addMeta(buildRequest(DELETE_MODULE_SURVEY, 'DELETE', `/survey/${surveyId}`), {
    surveyId,
    moduleId,
    position,
  });

export const storeLessonPreviewToken = (lessonId, previewToken) =>
  buildRequest(
    STORE_LESSON_PREVIEW_TOKEN,
    'POST',
    `/lessons/preview/${lessonId}`,
    {previewToken}
  );

export const getEduRating = (lesson, language) =>
  addMeta(
    buildRequest(GET_EDURATING, 'POST', `/getEduRating`, {
      lesson,
      language,
    }),
    {
      lessonId: lesson.id,
    }
  );

export const generateLessonSuggestionsFromDocument = ({courseTitle, file}) => {
  let payload = new FormData();
  payload.append('courseTitle', courseTitle);
  if (file) {
    payload.append('file', file);
  }

  return buildRequest(
    GENERATE_LESSON_SUGGESTIONS_FROM_DOCUMENT,
    'POST',
    `/generateLessonSuggestionsFromDocument`,
    payload
  );
};

export const generateLessonsFromTopics = ({
  topics,
  moduleId,
  courseId,
  file,
  queryFilter,
}) => {
  let payload = new FormData();
  payload.append('topics', JSON.stringify(topics));
  payload.append('moduleId', moduleId);
  if (file) {
    payload.append('file', file);
    if (queryFilter) {
      payload.append('queryFilter', JSON.stringify(queryFilter));
    }
  }
  const fileUploadedType = getExtensionFromFileName(file?.name);

  return addMeta(
    buildRequest(
      GENERATE_LESSONS_FROM_TOPICS,
      'POST',
      `/generateLessonsFromTopics`,
      payload
    ),
    {
      topics,
      moduleId,
      courseId,
      fileUploadedType,
    }
  );
};
