import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Button, LegacyHeading, Link} from '@edume/magnificent';

import styles from './styles.module.scss';

const IntroItem = ({
  heading,
  text,
  subText,
  linkText,
  onLinkClick,
  buttonText,
  onButtonClick,
}) => (
  <div styleName='container'>
    <LegacyHeading weight='medium' size='tiny'>
      {heading}
    </LegacyHeading>
    <div styleName='text-container'>
      <LegacyHeading size='micro' colour='grey700'>
        {text}
      </LegacyHeading>
      {subText && (
        <div styleName='sub-text'>
          <LegacyHeading size='micro' colour='grey700'>
            {subText}
          </LegacyHeading>
        </div>
      )}
    </div>
    <div styleName='link-container'>
      <Link text={linkText} onClick={onLinkClick} />
    </div>
    {buttonText && (
      <div styleName='button'>
        <Button
          type='primary'
          onClick={onButtonClick}
          darkIconUrl='/resources/img/24_mortaboard.svg'
          iconUrl='/resources/img/24_mortaboard_white.svg'
        >
          {buttonText}
        </Button>
      </div>
    )}
  </div>
);

IntroItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.string,
};

export default CSSModules(IntroItem, styles);
