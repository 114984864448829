import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import requiredIf from 'react-required-if';

import {Button} from '@edume/magnificent';

import {LegacyIntlHeading, RelativeTime} from '../../core';
import OverviewBlock from '../OverviewBlock';

import styles from './styles.module.scss';

const StatBlock = ({
  statValue,
  statKey,
  cacheDate,
  showButton,
  buttonKey,
  onClick,
  intl,
}) => {
  const formatNumberOptions = {
    significantDigits: 3,
    notation: 'compact',
    compactDisplay: 'short',
  };
  return (
    <OverviewBlock>
      <div styleName='container' data-automation='stat-block'>
        <LegacyIntlHeading
          size='large'
          weight='medium'
          text={intl.formatNumber(statValue, formatNumberOptions)}
        />
        <LegacyIntlHeading
          size='mini'
          textKey={statKey}
          values={{number: statValue}}
        />

        {showButton && (
          <Button type='thirdary' noMarginLeft onClick={onClick}>
            {intl.formatMessage({id: buttonKey})}
          </Button>
        )}
        {cacheDate && (
          <div styleName='cache-date'>
            <RelativeTime timestamp={cacheDate} colour='grey700' size='mini' />
          </div>
        )}
      </div>
    </OverviewBlock>
  );
};

StatBlock.propTypes = {
  statValue: PropTypes.number.isRequired,
  statKey: PropTypes.string.isRequired,
  cacheDate: PropTypes.string,
  showButton: PropTypes.bool.isRequired,
  buttonKey: requiredIf(PropTypes.string, (props) => props.showButton),
  onClick: requiredIf(PropTypes.func, (props) => props.showButton),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(StatBlock, styles, {allowMultiple: true}));
