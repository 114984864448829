import {smartTeamsEnums} from '@edume/bento';

import * as notificationActions from '../notification/notificationActions';
import * as teamActionCreators from '../team/teamActionCreators';
import {UPDATE_TEAMS_COUNT} from '../team/teamActionTypes';
import {getSelectedGroupId} from '../team/teamSelectors';
import * as segmentActionCreators from './segmentActionCreators';
import {
  STOP_POLL_SEGMENT_STATUS,
  UPDATE_SEGMENT_STATUS,
} from './segmentActionTypes';
import {getSegmentById} from './segmentSelector';

export const loadGroupSegments = () => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());

  dispatch(segmentActionCreators.loadGroupSegments(selectedGroupId));
  dispatch(teamActionCreators.getTeams(true));
};

export const createGroupSegment = (payload) => (dispatch) =>
  dispatch(segmentActionCreators.createGroupSegment(payload))
    .then((response) => {
      dispatch(loadGroupSegments());
      notificationActions.createSuccessNotification(
        'Teams.createSmartTeam.pending',
        {teamName: payload.name}
      )(dispatch);
      return response;
    })
    .catch((e) => {
      const errorCode = e.response?.data?.errorCode;
      const supportedErrors = [
        'smart_team_name_already_exists',
        'static_team_name_already_exists',
        'smart_team_name_too_long',
      ];
      if (supportedErrors.includes(errorCode)) {
        notificationActions.createErrorNotification(
          `Teams.createSmartTeam.error.${errorCode}`,
          {teamName: payload.name}
        )(dispatch);
      } else {
        notificationActions.createErrorNotification(
          'Teams.SmartTeam.createUpdate.error.generic',
          {teamName: payload.name}
        )(dispatch);
      }
    });

export const editGroupSegment = (segmentId, payload) => (dispatch) =>
  dispatch(segmentActionCreators.editGroupSegment(segmentId, payload))
    .then(() => {
      dispatch(loadGroupSegments());
      notificationActions.createSuccessNotification(
        'Teams.editSmartTeam.pending',
        {teamName: payload.name}
      )(dispatch);
    })
    .catch((e) => {
      const errorCode = e.response?.data?.errorCode;
      const supportedErrors = [
        'smart_team_name_already_exists',
        'static_team_name_already_exists',
        'smart_team_name_too_long',
      ];
      if (supportedErrors.includes(errorCode)) {
        notificationActions.createErrorNotification(
          `Teams.editSmartTeam.error.${errorCode}`,
          {teamName: payload.name}
        )(dispatch);
      } else {
        notificationActions.createErrorNotification(
          'Teams.editSmartTeam.error.generic',
          {teamName: payload.name}
        )(dispatch);
      }
    });

export const assignCourseToSegment = (courseKey, payload) => (dispatch) =>
  dispatch(segmentActionCreators.assignCourseToSegment(courseKey, payload))
    .then(() => {
      notificationActions.createSuccessNotification(
        'Learning.assignSmartTeams.success'
      )(dispatch);
    })
    .catch(() => {
      notificationActions.createErrorNotification('genericError')(dispatch);
    });

/**
 * @param {{id: number, status: string, currentStep: number | null, totalSteps: number | null}[]} segments
 */
export const updateSegmentStatus =
  ({segments, groupsCount}) =>
  (dispatch, getState) => {
    const state = getState();
    for (const segment of segments) {
      const {status} = segment;
      const savedSegment = getSegmentById(state, segment.id);

      const isChangingStatus = savedSegment && savedSegment?.status !== status;

      if (
        isChangingStatus &&
        status === smartTeamsEnums.SMART_TEAM_UPDATE_STATUS.COMPLETED
      ) {
        notificationActions.createSuccessNotification(
          'Teams.SmartTeam.createUpdate.success',
          {teamName: savedSegment.name}
        )(dispatch);
      }

      if (
        isChangingStatus &&
        status === smartTeamsEnums.SMART_TEAM_UPDATE_STATUS.ERROR
      ) {
        notificationActions.createErrorNotification(
          'Teams.SmartTeam.createUpdate.error.generic',
          {teamName: savedSegment.name}
        )(dispatch);
      }
    }

    dispatch({type: UPDATE_SEGMENT_STATUS, payload: {data: segments}});
    dispatch({type: UPDATE_TEAMS_COUNT, payload: {data: groupsCount}});
  };

export const stopPollSegmentStatus = () => (dispatch) => {
  dispatch({
    type: STOP_POLL_SEGMENT_STATUS,
  });
};
