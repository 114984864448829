import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.module.scss';

const Table = ({
  header,
  rows,
  emptyText,
  compact,
  outline,
  alternateStyling,
  rowDataAutomation,
  ...props
}) => {
  if (rows.length === 0 && emptyText) {
    return (
      <div styleName='empty-table' {...props}>
        <LegacyHeading size='micro'>{emptyText}</LegacyHeading>
      </div>
    );
  }

  const getCellContent = (cell, isHead = false) => {
    const cellText = cell.text ? cell.text : cell;
    const heading = (
      <LegacyHeading size={isHead ? 'tinier' : 'micro'}>
        {cellText}
      </LegacyHeading>
    );

    return cell.icon ? (
      <div styleName='text-icon-cell'>
        <img src={cell.icon} />
        &nbsp;{heading}
      </div>
    ) : (
      heading
    );
  };

  const stylesNames = ['table'];

  if (compact) {
    stylesNames.push('compact');
  }

  if (outline) {
    stylesNames.push('outline');
  }

  if (alternateStyling) {
    stylesNames.push('alternate');
  }

  return (
    <table styleName={stylesNames.join(' ')} {...props}>
      {header && (
        <thead>
          <tr>
            {header.map((cell, i) => (
              <th key={i}>{getCellContent(cell, true)}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, i) => (
          <tr key={i} data-automation={rowDataAutomation}>
            {row.map((cell, j) => (
              <td key={j}>{getCellContent(cell)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  compact: PropTypes.bool,
  emptyText: PropTypes.string,
  outline: PropTypes.bool,
  header: PropTypes.array,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          icon: PropTypes.string,
        }),
      ])
    )
  ).isRequired,
  alternateStyling: PropTypes.bool,
  rowDataAutomation: PropTypes.string,
};

export default CSSModules(Table, styles, {allowMultiple: true});
