import React, {useState} from 'react';

import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {COMPANY} from '../../../enums/adminLevels';
import {addAdmin} from '../../../state/admin/adminActions';
import {
  goToCreateCourseModal,
  goToResources,
} from '../../../state/course/courseActions';
import {goToAddTeamModal} from '../../../state/team/teamActions';
import {LegacyIntlHeading} from '../../core';
import AddEditAdminOld from '../../peopleAndTeams/AddEditAdminOld';
import OverviewBlock from '../OverviewBlock';
import IntroItem from './IntroItem';

import styles from './styles.module.scss';

const OverviewIntro = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isAddEditAdminVisible, setIsAddEditAdminVisible] = useState(false);

  const formatKey = (id) => intl.formatMessage({id});
  const content = [
    {
      heading: formatKey('Overview.intro.content.heading'),
      text: formatKey('Overview.intro.content.text'),
      subText: formatKey('Overview.intro.content.subText'),
      linkText: formatKey('Overview.intro.content.linkText'),
      onLinkClick: () => dispatch(goToResources()),
      buttonText: formatKey('Overview.intro.content.buttonText'),
      onButtonClick: () => dispatch(goToCreateCourseModal()),
    },
    {
      heading: formatKey('Overview.intro.teams.heading'),
      text: formatKey('Overview.intro.teams.text'),
      linkText: formatKey('Overview.intro.teams.linkText'),
      onLinkClick: () => dispatch(goToAddTeamModal()),
    },
    {
      heading: formatKey('Overview.intro.admins.heading'),
      text: formatKey('Overview.intro.admins.text'),
      linkText: formatKey('Overview.intro.admins.linkText'),
      onLinkClick: () => setIsAddEditAdminVisible(true),
    },
  ];

  const handleOnCloseAdmin = () => setIsAddEditAdminVisible(false);
  const handleOnSaveAdmin = (admin) => {
    dispatch(addAdmin(admin));
    handleOnCloseAdmin();
  };

  const renderAddAdmin = () =>
    isAddEditAdminVisible && (
      <AddEditAdminOld
        onSave={handleOnSaveAdmin}
        onClose={handleOnCloseAdmin}
        level={COMPANY}
        hideRoleSelection={true}
      />
    );

  return (
    <OverviewBlock>
      <div styleName='container'>
        <LegacyIntlHeading
          size='medium'
          weight='medium'
          textKey='Overview.intro.title'
        />
        <div styleName='content-grid'>
          {content.map((section, index) => (
            <IntroItem key={index} {...section} />
          ))}
        </div>
      </div>
      {renderAddAdmin()}
    </OverviewBlock>
  );
};

export default CSSModules(OverviewIntro, styles);
