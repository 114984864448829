import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {Input, LegacyHeading} from '@edume/magnificent';

import {searchMsTeamsTeams} from '../../../../state/team/teamActions';

import styles from './styles.module.scss';

const MsTeamsSearchBar = ({searchTerm, setSearchTerm, intl}) => {
  const dispatch = useDispatch();
  let timeOut = null;
  useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    if (searchTerm.length) {
      timeOut = setTimeout(() => dispatch(searchMsTeamsTeams(searchTerm)), 200);
    }
  }, [searchTerm]);

  return (
    <div styleName='search-teams'>
      <LegacyHeading size='micro' colour='textPrimary'>
        {intl.formatMessage({
          id: 'Learning.assignTeams.search.description',
        })}
      </LegacyHeading>
      <Input
        type='search'
        placeholder={intl.formatMessage({
          id: 'Learning.assignTeams.search.placeholder',
        })}
        value={searchTerm}
        onChangeValue={setSearchTerm}
        validationEmotes={false}
        clearInputType='icon'
        onClearInput={() => setSearchTerm('')}
      />
    </div>
  );
};

MsTeamsSearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(MsTeamsSearchBar, styles));
