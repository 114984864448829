import React, {useState} from 'react';

import {Set} from 'immutable';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {LegacyHeading} from '@edume/magnificent';

import {StyledModal} from '../../../containers/StyledModals';
import {MS_TEAMS} from '../../../enums/integrations';
import {sortObjectsDescendingByKey} from '../../../utils/sort';
import AdditionalIntegrationContent from './AdditionalIntegrationContent';
import ErrorMessage from './ErrorMessage';
import MsTeamsSearchBar from './MsTeamsSearchBar';
import MultiSourceTabs from './MultiSourceTabs';
import SelectableTeamsComponent from './SelectableTeamsComponent';

import styles from './styles.module.scss';

// eslint-disable-next-line max-statements
const SelectTeamsFromSourcesDialog = ({
  cancel,
  headingKey,
  buttonKey,
  save,
  teamSources,
  seedSelectedTeamIds,
  showHint,
  intl,
  returnBodyOnly,
}) => {
  const msTeamsSearchedTeams = useSelector((state) =>
    state.getIn(['team', 'msTeamsSearchedTeams']).toJS()
  );
  const msTeamsSearchedLoaded = useSelector((state) =>
    state.getIn(['team', 'msTeamsSearchedLoaded'])
  );
  const accessMethod = useSelector((state) =>
    state.getIn(['auth', 'accessMethod'])
  );
  const isViewingWithinMST = accessMethod === MS_TEAMS;

  const initialTeamSelection = teamSources.map(({teams}) =>
    teams
      .filter((team) => seedSelectedTeamIds.includes(team.id))
      .map((team) => team.id)
  );

  const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);
  const [selectedIdsBySource, setSelectedIdsBySource] =
    useState(initialTeamSelection);
  const [searchTerm, setSearchTerm] = useState('');

  const getExternalTeamsToLink = () => {
    const externalTeamsToLink = [];

    teamSources.forEach((source, index) => {
      const externalTeamIds = selectedIdsBySource[index].filter(
        (id) => typeof id !== 'number'
      );
      const externalTeams = source.teams.filter((team) =>
        externalTeamIds.includes(team.id)
      );
      const searchedTeams = msTeamsSearchedTeams.filter((team) =>
        externalTeamIds.includes(team.id)
      );

      if (externalTeams.length > 0 || searchedTeams.length > 0) {
        externalTeamsToLink.push({
          integrationType: source.type,
          teams: [...externalTeams, ...searchedTeams],
        });
      }
    });

    return externalTeamsToLink;
  };

  const initialExternalTeamSelection = teamSources.map(({teams}) =>
    teams
      .filter((team) => seedSelectedTeamIds.includes(team.id))
      .map((team) => team.externalGroupId)
  );

  const isTeamSelected = (id, sourceIndex) =>
    selectedIdsBySource[sourceIndex].includes(id) ||
    initialExternalTeamSelection[sourceIndex].includes(id)
      ? true
      : false;

  const renderPrioritisedTeamsList = (teams, sourceIndex) => {
    const selectedTeams = [];
    const remainingTeams = [];
    const disabledTeams = [];
    teams.forEach((team) => {
      if (team.disabled) {
        disabledTeams.push(team);
      } else if (selectedIdsBySource[sourceIndex].includes(team.id)) {
        selectedTeams.push(team);
      } else {
        remainingTeams.push(team);
      }
    });
    return [...selectedTeams, ...remainingTeams, ...disabledTeams];
  };

  const renderNewlySelectedFirst = (teams) => {
    const newlySelected = [];
    const preSelected = [];
    teams.forEach((t) => {
      if (!seedSelectedTeamIds.includes(t?.id)) {
        newlySelected.push(t);
      } else {
        preSelected.push(t);
      }
    });
    return [
      ...newlySelected,
      ...preSelected.sort(sortObjectsDescendingByKey('updatedAt')),
    ];
  };

  const saveDifference = () => {
    const allSelectedIds = selectedIdsBySource.flat();
    const teamIdsToSave = allSelectedIds.filter(Number.isInteger); // external team ids are always strings
    const idsToAdd = Array.from(
      Set(teamIdsToSave).subtract(seedSelectedTeamIds)
    );
    const idsToRemove = Array.from(
      Set(seedSelectedTeamIds).subtract(teamIdsToSave)
    );
    const externalTeamsToLink = getExternalTeamsToLink();
    save(idsToAdd, idsToRemove, externalTeamsToLink);
    cancel();
  };

  const getHasMsTeamsError = (source) =>
    source &&
    source.type === MS_TEAMS &&
    isViewingWithinMST &&
    source.errorCode === 401;

  const renderTeamList = (teamSource, sourceIndex) => {
    const {teams: sourceTeams, type} = teamSource;
    const isMsTeamsWithinMsTeams = isViewingWithinMST && type === MS_TEAMS;
    const hasSearchResults = !!(msTeamsSearchedLoaded && searchTerm.length);
    const searchResultTeamsWithExistingData = msTeamsSearchedTeams
      .filter((team) =>
        team.name.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
      .map((team) => {
        const existingTeam = sourceTeams.find(
          (t) => t.externalGroupId === team.id
        );
        if (existingTeam) {
          return existingTeam;
        } else {
          return team;
        }
      });

    const mstTeams = selectedIdsBySource[sourceIndex]?.length
      ? renderNewlySelectedFirst(
          selectedIdsBySource[sourceIndex].map((id) => {
            let team = sourceTeams.find((t) => t.id === id);
            if (!team) {
              team = msTeamsSearchedTeams.find((t) => t.id === id);
            }
            return team;
          })
        )
      : sourceTeams
          .filter((t) => t.externalGroupId)
          .sort(sortObjectsDescendingByKey('updatedAt'));

    const teams = isMsTeamsWithinMsTeams
      ? renderPrioritisedTeamsList(mstTeams, sourceIndex)
      : sourceTeams;

    // Teams assigned to other groups are flagged as disabled
    const allTeamIds = teams
      .filter((team) => !team.disabled)
      .map((team) => team.id);

    return (
      <>
        {isMsTeamsWithinMsTeams && (
          <MsTeamsSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        )}

        {hasSearchResults && !msTeamsSearchedTeams.length && (
          <div styleName='no-results'>
            <LegacyHeading size='micro' weight='medium' colour='textPrimary'>
              {intl.formatMessage({
                id: 'Learning.assignTeams.search.noTeams.heading',
              })}
            </LegacyHeading>
            <LegacyHeading size='micro' colour='textPrimary'>
              {intl.formatMessage({
                id: 'Learning.assignTeams.search.noTeams.info',
              })}
            </LegacyHeading>
          </div>
        )}

        <SelectableTeamsComponent
          accessMethod={accessMethod}
          isMsTeams={type === MS_TEAMS}
          isViewingWithinMST={isViewingWithinMST}
          selectedIdsBySource={selectedIdsBySource}
          setSelectedIdsBySource={setSelectedIdsBySource}
          sourceIndex={sourceIndex}
          teams={teams}
          isTeamSelected={isTeamSelected}
          hasSearchResults={hasSearchResults}
          searchResultTeamsWithExistingData={searchResultTeamsWithExistingData}
          allTeamIds={allTeamIds}
          returnBodyOnly={returnBodyOnly}
          save={save}
        />

        <AdditionalIntegrationContent type={type} />
      </>
    );
  };

  const renderTabContents = (teamSource, sourceIndex = 0) =>
    teamSource.errorCode ? (
      <ErrorMessage
        teamSource={teamSource}
        getHasMsTeamsError={getHasMsTeamsError}
      />
    ) : (
      renderTeamList(teamSource, sourceIndex)
    );

  const modalSize = teamSources.length > 3 ? 'large' : null;

  const isSaveButtonDisabled = () => {
    const areSelectedTeamsEqual =
      selectedIdsBySource
        .flat()
        .every((id) => seedSelectedTeamIds.includes(id)) &&
      selectedIdsBySource.flat().length === seedSelectedTeamIds.length;

    return areSelectedTeamsEqual && !getHasMsTeamsError(teamSources[0])
      ? true
      : false;
  };

  const modalBody = () =>
    teamSources.length === 1 ? (
      renderTabContents(teamSources[0])
    ) : (
      <MultiSourceTabs
        teamSources={teamSources}
        selectedIdsBySource={selectedIdsBySource}
        selectedSourceIndex={selectedSourceIndex}
        setSelectedSourceIndex={setSelectedSourceIndex}
        showHint={showHint}
        renderTabContents={renderTabContents}
      />
    );

  const modalContent = {
    title: intl.formatMessage({id: headingKey}),
    body: CSSModules(modalBody, styles, {allowMultiple: true})(),
    buttons: [
      {
        type: 'primary',
        text: intl.formatMessage({id: buttonKey}),
        onClick: saveDifference,
        disabled: isSaveButtonDisabled(),
        dataAutomation: 'confirm-teams-button',
      },
    ],
  };

  return returnBodyOnly ? (
    CSSModules(modalBody, styles, {allowMultiple: true})()
  ) : (
    <StyledModal
      onClose={cancel}
      size={modalSize}
      compact={true}
      content={modalContent}
      closeIcon={true}
      fitContentHeightForDesktop
    />
  );
};

SelectTeamsFromSourcesDialog.propTypes = {
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  seedSelectedTeamIds: PropTypes.array,
  headingKey: PropTypes.string.isRequired,
  buttonKey: PropTypes.string.isRequired,
  showHint: PropTypes.bool,
  teamSources: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  returnBodyOnly: PropTypes.bool,
};

export default injectIntl(SelectTeamsFromSourcesDialog);
