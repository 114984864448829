/* eslint-disable no-nested-ternary */

/* eslint-disable complexity */
import moment from 'moment';

import {ampProperties} from '@edume/bento/amplitude';
import {
  getAdminLevel,
  getPermissionsList,
  getRolesFromPermissions,
} from '@edume/bento/permissionsUtil';
import {SMART, STATIC} from '@edume/bento/smartTeamsEnums';

import * as contentTypes from '../enums/contentTypes';
import {BEEKEEPER, MS_TEAMS, SPEAKAP} from '../enums/integrations';
import {INSPIRATION, RECENT} from '../enums/overview';
import {
  ASSIGN_COLLECTION_TO_CONTENT_SUCCESS,
  UPDATE_CONTENT_IN_COLLECTION_SUCCESS,
} from '../state/collections/collectionsActionTypes';
import {COPY_CONTENT_SUCCESS} from '../state/content/contentActionTypes';
import {ACCESSED_COURSE_REPORT_LINK} from '../state/course/courseActionTypes';
import {getCourseById, getCourseByKey} from '../state/course/courseSelector';
import {getGuideById} from '../state/guide/guideSelector';
import {getLessonByKey} from '../state/moduleActivity/moduleActivitySelector';
import {getSelectedGroupId, getTeams} from '../state/team/teamSelectors';
import {amplitude} from './index';
import {
  ACTIVATED,
  ACTIVE,
  ADD_ADMIN,
  ADD_GROUP,
  ADD_USER,
  ADDED_COLLECTION_CONTENT,
  ADMIN,
  ADMIN_SETTINGS,
  ALL_USERS,
  ALPHABETICALLY,
  ASSIGN_TEAMS_TO_COURSE,
  ASSIGN_TEAMS_TO_GUIDE,
  ASSIGNED_TEAMS_TO_COURSE_VIA_NOTIFICATION_SLIDEOUT,
  ASSIGNED_TEAMS_TO_GUIDE_VIA_NOTIFICATION_SLIDEOUT,
  CHANGED_STATIC_TEAM_NAME,
  CHRONOLOGICALLY,
  CLICKED_CREATE_COURSE,
  CLICKED_CREATE_GUIDE,
  CLICKED_CREATE_TEAM,
  CLICKED_ON_SUPPORT_ARTICLE,
  COMPANY,
  COMPLETED,
  COPIED_PUBLISHED_LINK,
  COPIED_WEBHOOK_SECRET,
  COURSE,
  CREATE_COMPONENT,
  CREATE_COURSE,
  CREATE_GUIDE,
  CREATE_INTEGRATION,
  CREATE_LESSON,
  CREATE_MESSAGE,
  CREATE_MODULE,
  CREATE_SURVEY,
  CREATE_TEAM,
  CREATED_ACCOUNT,
  CREATED_AI_LESSON,
  CREATED_API_KEY,
  CREATED_COLLECTION,
  CREATED_KNOWLEDGE_HUB,
  CREATED_SIGNING_SECRET,
  CUSTOM,
  CUSTOMISED,
  DASHBOARD,
  DATE_RANGE_SELECTED,
  DEFAULT,
  DELETE_ADMIN,
  DELETE_COMPONENT,
  DELETE_COURSE,
  DELETE_GROUP,
  DELETE_GUIDE,
  DELETE_INTEGRATION,
  DELETE_MESSAGE,
  DELETE_SURVEY,
  DELETE_TEAM,
  DELETE_USER,
  DELETED_API_KEY,
  DETAILED,
  DOWNLOAD_REPORT,
  DOWNLOAD_USER_REPORT,
  DOWNLOAD_USERS,
  EDIT_COMPONENT,
  EDIT_SMART_TEAM,
  EDIT_USER,
  EDITED_BUTTON,
  EDITED_END_SCREEN,
  EDITED_SLIDE_COLOR,
  EMPTY,
  EVENT_REPORTING_CHANGED,
  EVENT_REPORTING_NAME_CHANGED,
  EVENT_REPORTING_STATUS_CHANGED,
  GENERATE_MLS_SPREADSHEET,
  GROUP,
  HOME,
  ID_COPIED,
  KNOWLEDGE_HUB_ALL_CONTENT,
  KNOWLEDGE_HUB_VIEW_ONLY,
  MODIFIED_COMPANY_LEVEL_REPORT,
  MULTIPLE,
  MULTIPLE_CHOICE,
  NONE,
  NOT_ACTIVATED,
  NPS,
  NUMBER_OF_BEEKEEPER_TEAMS_ASSIGNED,
  NUMBER_OF_BEEKEEPER_TEAMS_NOTIFIED,
  NUMBER_OF_MS_TEAMS_TEAMS_ASSIGNED,
  NUMBER_OF_MS_TEAMS_TEAMS_NOTIFIED,
  NUMBER_OF_SPEAKAP_TEAMS_ASSIGNED,
  NUMBER_OF_SPEAKAP_TEAMS_NOTIFIED,
  NUMBER_OF_TEAMS_NOTIFIED,
  OPENED_COURSE_NOTIFICATIONS_SLIDEPANEL,
  OPENED_GUIDE_NOTIFICATIONS_SLIDEPANEL,
  OPENED_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL,
  OVERALL,
  OVERVIEW,
  PUBLISH_COURSE,
  PUBLISH_GUIDE,
  PUBLISHED,
  REACTION,
  REPORTS,
  REVOKE_ADMIN_PERMISSIONS,
  SAVED_COMPANY_BRANDING,
  SAVED_GROUP_BRANDING,
  SAVED_TEAM_UPDATE,
  SAVED_TEMPLATE,
  SEARCH,
  SELECTED_COURSE_TAB,
  SELECTED_FILTER,
  SELECTED_SEARCH_RESULT,
  SELECTED_TEAM_TYPE,
  SENT_COURSE_NOTIFICATIONS,
  SENT_GUIDE_NOTIFICATIONS,
  SENT_IMMEDIATELY,
  SENT_KNOWLEDGE_HUB_NOTIFICATIONS,
  SET_COURSE_CARD_DESIGN,
  SHOW_USERS_IN_TEAM,
  SINGLE,
  SURVEYS,
  TEAM,
  TEAM_LIST,
  UNPUBLISH_GUIDE,
  UNPUBLISHED,
  UPDATE_COURSE_SETTINGS,
  UPDATE_GUIDE,
  UPDATE_INTEGRATION,
  UPDATE_KNOWLEDGE_HUB_CUSTOMISATIONS,
  UPDATE_USERS_ASSIGNED_TEAMS,
  UPDATED_COURSE_PRIORITY,
  UPDATED_LANGUAGE,
  UPDATED_WEBHOOK_CONFIG,
  UPDATED_WEBHOOK_SUBSCRIBED_EVENTS,
  UPLOAD_MEDIA,
  USER,
  USER_LIST,
  VIEW_HELP_CENTER,
  VIEW_MESSAGES,
  VIEW_REPORT,
  VIEW_SURVEY_LIST,
  VIEW_TEAM_LIST,
  VIEW_USER_LIST,
  VIEWED_ACCOUNT_UPGRADE,
  VIEWED_API_KEYS,
  VIEWED_COMPANY_LEVEL_REPORT,
  VIEWED_COMPANY_SETTINGS,
  VIEWED_CONTENT_CREATION_GUIDE,
  VIEWED_COURSE_DELIVERIES,
  VIEWED_COURSE_SCREEN,
  VIEWED_DASHBOARD,
  VIEWED_GUIDE_DELIVERIES,
  VIEWED_INDIVIDUAL_INTEGRATION_PAGE,
  VIEWED_INTEGRATIONS,
  VIEWED_KNOWLEDGE_HUB_DELIVERIES,
  VIEWED_LEARNING_PAGE,
  VIEWED_PREVIEW_LINK,
  VIEWED_SEARCH_RESULTS_PAGE,
  VIEWED_SIGNING_SECRETS,
  VIEWED_SMART_TEAM_DETAILS,
  VIEWED_TEAM_ACTION,
  VIEWED_USAGE_PAGE,
  VIEWED_WEBHOOK_CONFIG,
} from './trackingProps';

const {
  ACTIVATION_DATE,
  INVITATION_DATE,
  USER_TYPE,
  ADMIN_LEVEL,
  PERMISSIONS,
  EMAIL,
  PHONE,
  EMPLOYEE_ID,
  GROUP_ID,
  GROUP_NAME,
  COURSE_ID,
  COURSE_TITLE,
  GUIDE_ID,
  GUIDE_TITLE,
  NUMBER_OF_MODULES,
  COURSE_LESSON_FORMAT,
  NUMBER_OF_ASSESSMENTS,
  NUMBER_OF_LESSONS,
  NUMBER_OF_SURVEYS,
  TEAM_ID,
  TEAM_NAME,
  MODULE_ID,
  COURSE_IMAGE_ADDED,
  DUPLICATED_COURSE,
  SELECTED_COLOUR,
  SELECTED_ICON,
  SELECTED_DEFAULT_COLOUR,
  SELECTED_DEFAULT_ICON,
  FILTER_TYPE,
  NUMBER_OF_USERS_DOWNLOADED,
  SORT_TYPE,
  SEARCH_TERM,
  SOURCE,
  ADD_USER_METHOD,
  NUMBER_OF_USERS_ADDED,
  USER_ID,
  LOGIN_TYPE_CHANGED,
  OTHER_INFO_UPDATED,
  LOGIN_TYPE_VALUE_UPDATED,
  LAST_NAME_UPDATED,
  FIRST_NAME_UPDATED,
  VIEW_TYPE,
  SURVEY_ID,
  SURVEY_NAME,
  SURVEY_TYPE,
  NUMBER_OF_SURVEY_OPTIONS,
  ADDED_SURVEY_FOLLOW_UP,
  NUMBER_OF_TEAMS_ASSIGNED,
  NUMBER_OF_TEAMS_UNASSIGNED,
  MESSAGE_ID,
  NUMBER_OF_USERS_ASSIGNED,
  NUMBER_OF_ACTIVATED_USERS_ASSIGNED,
  NUMBER_OF_NON_ACTIVATED_USERS_ASSIGNED,
  COURSES_ASSIGNED,
  LESSON_ID,
  LESSON_TYPE,
  FILE_UPLOAD_TYPE,
  COMPONENT_TYPE,
  IMAGE_UPLOADED,
  VIDEO_UPLOADED,
  IMAGE_REPLACED,
  VIDEO_REPLACED,
  REPORT_USER_SEGMENT,
  TITLE,
  MEDIA_TYPE,
  SCHEDULED,
  PINNED,
  EXPIRATION,
  TEMPLATE_TYPE,
  NET_CHANGE_TEAMS_ASSIGNED,
  TOTAL_TEAMS_ASSIGNED,
  USER_STATUS,
  REPORT_LEVEL,
  REPORT_TYPE,
  PAGE_TITLE,
  ACTIVITY_TYPE,
  ACTIVITY_ID,
  EDITED_TEXT,
  LINK_ADDED,
  MULTIVARIANT_TESTS,
  MULTIVARIANT_TEST_VARIANTS,
  COLOUR_TYPE,
  HIDE_TITLE_SLIDE,
  CERTIFICATE,
  VIEWPORT_HEIGHT,
  VIEWPORT_WIDTH,
  COURSE_LANGUAGES,
  CUSTOMER,
  INTEGRATION_NAME,
  STATUS,
  CUSTOM_NAME,
  WEBHOOK_NAME,
  DAY_RANGE,
  COURSE_REPORT_LINK_TYPE,
  THIRD_PARTY_LINK_TYPE,
  SEARCH_TYPE,
  NUMBER_OF_RESULTS_RETURNED,
  PERMISSION_LEVEL,
  SECRET_ID,
  KEY_ID,
  WEBHOOK_ID,
  LOGIN_TYPE,
  PREVIEW_ID,
  LINK_TYPE,
  DUPLICATED_FROM,
  OPTION_SELECTED,
  TYPE_OF_ID,
  SELECTED_LANGUAGE,
  PRIORITY_STATUS,
  ENABLED,
  ORIGINAL_COURSE_ID,
  KNOWLEDGE_HUB_NAME,
  KNOWLEDGE_HUB_CONFIGURATION,
  HAS_TABS,
  LAYOUT_VERSION,
  HAS_SEARCH_BAR,
  HAS_COURSE_TIME_ESTIMATE,
  HAS_COURSE_ACTIVITIES,
  BORDER_RADIUS,
  PROGRESS_BAR_COLOR,
  DASHBOARD_HEADER_COLOR,
  DASHBOARD_HEADER_TEXT_COLOR,
  DASHBOARD_NAME,
  LOGO_URL,
  DARK_LOGO_URL,
  COLOUR_PRIMARY,
  COLOUR_PRIMARY_DARK,
  COLOUR_ACCENT_1,
  COLOUR_ACCENT_2,
  START_DATE,
  END_DATE,
  DAYS_BETWEEN_DATES,
  ACTION_TYPE,
  COLUMN_NUMBER,
  NUMBER_OF_COLUMNS,
  GROUP_IDS,
  COURSE_IDS,
  ARE_ALL_GROUPS_UNIQUE,
  ARE_ALL_COURSES_UNIQUE,
  ASSIGNED_TO_TEAMS,
  IS_ADMIN_AND_LEARNER,
  IS_ADMIN,
  COLLECTION_NAME,
  COLLECTION_IDS,
  CONTENT_ID,
  CONTENT_TYPE,
  COLLECTION_SOURCE,
  AI_PROMPT,
  AI_EDITOR_ACTION,
  AI_EDITOR_TONE,
  KNOWLEDGE_HUB_ID,
  COURSE_STATUS,
  ACTIVITY_STATUS,
  DELIVERY_PREFERENCE,
  TIME_SETTING,
  HOME_DASHBOARD,
  NAME_OF_ARTICLE,
  DASHBOARD_COURSES,
  DASHBOARD_COURSES_LINK,
  DASHBOARD_RESOURCES,
  DASHBOARD_RESOURCES_LINK,
  OTHER,
  MESSAGE_TEXT,
  ENROLMENT,
  HIDE_ACTIVITY_LIST,
  TEAM_TYPE,
  NAME_CHANGED,
  TAGS_CHANGED,
  EDUME_EVENTS_CHANGED,
  LOGIC_OPERATOR_CHANGED,
  TEAM_LOGIC,
  LOGIC_LAYER,
  RULE_LOGIC,
  TAGS,
  EDUME_EVENTS,
} = ampProperties;

const courseSettingsPropertyMap = {
  isAutostartEnabled: HIDE_ACTIVITY_LIST,
  requiresEnrolment: ENROLMENT,
};

const getPermissions = ({isEditor, isManager, isAnalyst}) => ({
  lead: isEditor && isAnalyst && isManager,
  manager: isManager,
  analyst: isAnalyst,
  editor: isEditor,
});

const getPublishedStatus = (published) => (published ? PUBLISHED : UNPUBLISHED);

const logThirdPartyEvent = (eventName, eventProps) => {
  amplitude.logAmplitudeEvent(eventName, eventProps);

  // Segment
  if (window?.analytics) {
    window.analytics.track(eventName, eventProps);
  }
};

export const setInitialUserProps = (state) => {
  const {
    id,
    company,
    permissions,
    activationDate,
    createdAt,
    email,
    phone,
    employeeId,
    learnerGroups,
  } = state.get('auth').toJS();
  const adminLevel = getAdminLevel(permissions);
  const roles = getRolesFromPermissions(permissions);
  const permissionsList = getPermissionsList(roles);
  const learnerTeams = (learnerGroups || []).filter((g) => g.parentGroupId);
  const assignedToTeams = learnerTeams.length > 0;
  const isAdminAndLearner = (learnerGroups || []).length > 0;

  const userProps = {
    [ACTIVATION_DATE]: activationDate,
    [INVITATION_DATE]: createdAt,
    [USER_TYPE]: ADMIN,
    [ADMIN_LEVEL]: adminLevel,
    [PERMISSIONS]: permissionsList,
    [EMAIL]: email,
    [PHONE]: phone,
    [EMPLOYEE_ID]: employeeId,
    [ASSIGNED_TO_TEAMS]: assignedToTeams,
    [IS_ADMIN_AND_LEARNER]: isAdminAndLearner,
  };

  setUserViewportSize();
  amplitude.setAmplitudeUserId(id);
  amplitude.setAmplitudeUserProps(userProps);
  amplitude.setAmplitudeGroup(company.id);

  // Segment
  if (window?.analytics) {
    window.analytics.identify(id, userProps);
    window.analytics.group(company.id, {groupType: 'customer'});
  }
};

export const setAppcuesUserProps = (state) => {
  const {
    id,
    company,
    permissions,
    activationDate,
    createdAt,
    employeeId,
    accessMethod,
  } = state.get('auth').toJS();
  const integrationsUsed = state.getIn(['trial', 'integrationsUsed']);
  if (!id) {
    return;
  }
  const resourceType = state.getIn(['trial', 'resourceType'], null);
  const resourceKey = state.getIn(['trial', 'resourceKey'], null);
  const firstResource = state.getIn(['trial', 'firstResource'], null);

  const adminLevel = getAdminLevel(permissions);
  const roles = getRolesFromPermissions(permissions);
  const permissionsList = getPermissionsList(roles);

  const appcuesUserProps = {
    activationDate: activationDate,
    invitationDate: createdAt,
    adminLevel: adminLevel,
    permissions: permissionsList,
    employeeId: employeeId,
    company: company.name,
    accountCreationDate: company.createdAt,
    isTrial: company.isTrial,
    integrationsUsed,
    resourceType,
    resourceKey,
    accessMethod,
  };

  if (firstResource.isResourceNotFound) {
    appcuesUserProps.resourceKey = 'failed';
  } else if (!resourceKey) {
    appcuesUserProps.resourceKey = 'no resource key';
  }

  if (window && window.Appcues) {
    window.Appcues.track(CREATED_ACCOUNT);
    window.Appcues.identify(id, appcuesUserProps);
  }
};

export const setUserGroup = (gId, state) => {
  const groupId = gId ? gId : state.getIn(['team', 'groupId']);
  const groups = state.getIn(['team', 'groups']).toJS();
  const groupName = groups
    .filter((g) => g.id === groupId)
    .map((g) => g.name)[0];

  amplitude.setAmplitudeUserProps({
    [GROUP_ID]: groupId,
    [GROUP_NAME]: groupName,
  });
};

export const setUserViewportSize = () => {
  if (window) {
    amplitude.setAmplitudeUserProps({
      [VIEWPORT_WIDTH]: window.innerWidth,
      [VIEWPORT_HEIGHT]: window.innerHeight,
    });
  }
};

export const setUserLoginType = (loginType) =>
  amplitude.setAmplitudeUserProps({[LOGIN_TYPE]: loginType});

export const sendPublishCourseEvent = (courseKey, state) => {
  const courses = state.getIn(['course', 'courses']).toJS();
  const course = courses.filter((c) => c.courseKey === courseKey)[0];
  const moduleActivity = state.get('moduleActivity').toJS();
  const {countAssessments, countLessons, countSurveys} =
    getCourseActivityCount(moduleActivity);
  if (window && window.Appcues) {
    window.Appcues.track(PUBLISH_COURSE);
  }

  logThirdPartyEvent(PUBLISH_COURSE, {
    [COURSE_ID]: course.id,
    [COURSE_TITLE]: course.title,
    [NUMBER_OF_MODULES]: course.modules.length,
    [NUMBER_OF_ASSESSMENTS]: countAssessments,
    [NUMBER_OF_LESSONS]: countLessons,
    [NUMBER_OF_SURVEYS]: countSurveys,
    [COURSE_LESSON_FORMAT]: course.lessonsType ? course.lessonsType : DEFAULT,
  });
};

export const getCourseActivityCount = (moduleActivity) => {
  const {lessons, surveys} = moduleActivity;
  let countAssessments = 0;
  let countLessons = 0;
  const countSurveys = surveys.length;

  lessons.forEach((lesson) => {
    if (lesson.eson.type === 'lesson') {
      countLessons++;
    } else if (lesson.eson.type === 'assessment') {
      countAssessments++;
    }
  });

  return {countAssessments, countLessons, countSurveys};
};

export const sendAssignCourseToTeamEvent = (action) => {
  const {groupIdsToAdd, groupIdsToRemove} =
    action.meta.previousAction.payload.request.data;
  const countTeamsAssigned = groupIdsToAdd.length;
  const countTeamsUnassigned = groupIdsToRemove.length;
  const urlPath = window.location.pathname;
  const [courseId] = urlPath.split('/').slice(-1);

  logThirdPartyEvent(ASSIGN_TEAMS_TO_COURSE, {
    [COURSE_ID]: courseId,
    [NUMBER_OF_TEAMS_ASSIGNED]: countTeamsAssigned,
    [NUMBER_OF_TEAMS_UNASSIGNED]: countTeamsUnassigned,
  });
};

export const sendDeleteMessageEvent = (action) => {
  const {id, teams} = action.payload.data;
  logThirdPartyEvent(DELETE_MESSAGE, {
    [MESSAGE_ID]: id,
    [NUMBER_OF_TEAMS_ASSIGNED]: teams.length,
  });
};

export const sendViewSurveyListEvent = (tabIndex, state) => {
  const surveysForGroup = state.getIn(['survey', 'surveysForGroup']);
  const visibleSurveys =
    tabIndex === 0
      ? surveysForGroup.filter((data) => data.finishDate === null) // active tab
      : surveysForGroup.filter((data) => data.finishDate !== null); // completed tab

  let viewType = EMPTY;
  if (surveysForGroup.length > 0) {
    viewType = tabIndex === 0 ? ACTIVE : COMPLETED;
  }

  logThirdPartyEvent(VIEW_SURVEY_LIST, {
    [NUMBER_OF_SURVEYS]: visibleSurveys.length,
    [VIEW_TYPE]: viewType,
    [SOURCE]: SURVEYS,
  });
};

export const sendAddUserEvent = (action) => {
  const {countUsersAdded} = action.payload.data;
  const {source, countTeamsAssigned} = action.meta;

  logThirdPartyEvent(ADD_USER, {
    [SOURCE]: source,
    [ADD_USER_METHOD]: countUsersAdded ? MULTIPLE : SINGLE,
    [NUMBER_OF_TEAMS_ASSIGNED]: countTeamsAssigned,
    [NUMBER_OF_USERS_ADDED]: countUsersAdded || 1, // CSV || single user
  });
};

export const sendEditUserEvent = (action, state) => {
  const {id} = action.payload.data;
  const {data} = action.meta.previousAction.payload.request;
  const {source, teamId} = action.meta;
  const groupId = state.getIn(['team', 'groupId']);
  // Use team-level user data if available
  const users =
    state.getIn(['people', 'groupUsers', teamId, 'users']).toJS() ||
    state.getIn(['people', 'groupUsers', groupId, 'users']).toJS();
  const user = Object.values(users)
    .flat()
    .find((u) => u.id === id);

  // Deliberately using loose equality (==) to return true for null == undefined when comparing email/phone
  const changedProps = [
    'firstName',
    'lastName',
    'employeeId',
    'email',
    'phone',
  ].filter((userProp) => user[userProp] != data[userProp]); // eslint-disable-line eqeqeq
  const hasChangedLoginType = !!user.email !== !!data.email;
  const hasChangedLoginValue =
    !hasChangedLoginType &&
    (changedProps.includes('email') || changedProps.includes('phone'));

  logThirdPartyEvent(EDIT_USER, {
    [USER_ID]: id,
    [SOURCE]: source,
    [FIRST_NAME_UPDATED]: changedProps.includes('firstName'),
    [LAST_NAME_UPDATED]: changedProps.includes('lastName'),
    [OTHER_INFO_UPDATED]: changedProps.includes('employeeId'),
    [LOGIN_TYPE_VALUE_UPDATED]: hasChangedLoginValue,
    [LOGIN_TYPE_CHANGED]: hasChangedLoginType,
  });
};

export const sendDeleteUserEvent = (action) => {
  const [userId] = action.meta.previousAction.payload.request.url
    .split('/')
    .slice(-1);
  const {source} = action.meta;

  logThirdPartyEvent(DELETE_USER, {
    [USER_ID]: +userId,
    [SOURCE]: source,
  });
};

export const sendCreateLessonEvent = (action) => {
  const {
    meta: {
      templateType,
      courseId,
      lessonType,
      fileUploadedType,
      lessonEson: {type},
      multiple,
    },
    payload: {
      data: {id},
    },
  } = action;

  if (type !== 'assessment') {
    const eventPayload = {
      [LESSON_ID]: id,
      [COURSE_ID]: courseId,
      [LESSON_TYPE]: lessonType,
      [TEMPLATE_TYPE]: templateType,
    };
    if (fileUploadedType) {
      eventPayload[FILE_UPLOAD_TYPE] = fileUploadedType;
    }
    if (templateType === 'ai') {
      eventPayload[MULTIPLE] = multiple;
    }
    logThirdPartyEvent(CREATE_LESSON, eventPayload);
  }
};

export const sendCreateCourseEvent = (action, state) => {
  const {
    meta: {courseKey, originalIcon, originalColour, copiedFrom, source},
    payload: {data},
    type,
  } = action;
  const {id, icon, title, colour, courseImageUrl} = data[0] || data;
  const courses = state.getIn(['course', 'courses']).toJS();
  const resourceLibraryCourses = state
    .getIn(['course', 'resourceLibraryCourses'])
    .toJS();

  let courseIdDuplicatedFrom = '';

  if (type === COPY_CONTENT_SUCCESS) {
    if (copiedFrom === 'duplicated' || copiedFrom === 'templates') {
      courseIdDuplicatedFrom = courses.find(
        (c) => c.courseKey === courseKey
      ).id;
    } else {
      courseIdDuplicatedFrom = resourceLibraryCourses.find(
        (c) => c.courseKey === courseKey
      ).id;
    }
  }

  if (window && window.Appcues) {
    window.Appcues.track(CREATE_COURSE);
  }

  let createdFrom = OTHER;
  if (source === DASHBOARD) {
    createdFrom = HOME_DASHBOARD;
  } else if (source === INSPIRATION) {
    createdFrom = DASHBOARD_RESOURCES;
  }

  logThirdPartyEvent(CREATE_COURSE, {
    [COURSE_ID]: id,
    [COURSE_TITLE]: title,
    [DUPLICATED_COURSE]: courseIdDuplicatedFrom,
    [COURSE_IMAGE_ADDED]: courseImageUrl ? 'yes' : 'no',
    [SELECTED_COLOUR]: colour,
    [SELECTED_ICON]: icon,
    [SELECTED_DEFAULT_COLOUR]:
      colour !== originalColour || !originalColour ? 'no' : 'yes',
    [SELECTED_DEFAULT_ICON]:
      icon !== originalIcon || !originalIcon ? 'no' : 'yes',
    [DUPLICATED_FROM]: copiedFrom || '',
    [SOURCE]: createdFrom,
  });
};

export const sendUpdateLessonEvent = (action, state) => {
  const ampEvents = {
    DELETE: DELETE_COMPONENT,
    CREATE: CREATE_COMPONENT,
    EDIT: EDIT_COMPONENT,
    UPLOAD: UPLOAD_MEDIA,
  };
  const {
    meta: {
      previousAction: {meta},
    },
    payload: {
      data: {lessonKey, id, content},
    },
  } = action;

  if (meta && meta[0] && Object.keys(meta[0]).length !== 0) {
    meta.forEach((event) => {
      const {
        updateType,
        componentType,
        mediaFormat,
        thirdPartyLinkType,
        hasButtonTextChanged,
        hasSlideBgChanged,
        slideBgColour,
      } = event;
      const courses = state.getIn(['course', 'courses']).toJS();
      const course = courses.filter((c) => c.lessonKeys.includes(lessonKey))[0];
      const lesson = getLessonByKey(state, lessonKey);
      const isImageRelatedComponent = [
        'imageQuiz',
        'image bubble',
        'intro',
        'slide',
      ].includes(componentType);

      const eventProps = {
        [LESSON_ID]: id,
        [COURSE_ID]: course.id,
      };
      if (updateType === 'UPLOAD') {
        eventProps[isImageRelatedComponent ? IMAGE_UPLOADED : VIDEO_UPLOADED] =
          mediaFormat;
      } else {
        eventProps[LESSON_TYPE] = course.lessonsType;
        eventProps[COMPONENT_TYPE] = componentType;
        eventProps[COURSE_STATUS] = getPublishedStatus(course.published);
        eventProps[ACTIVITY_STATUS] = getPublishedStatus(lesson.published);
        if (updateType === 'EDIT' && mediaFormat) {
          eventProps[
            isImageRelatedComponent ? IMAGE_REPLACED : VIDEO_REPLACED
          ] = mediaFormat;
        } else if (
          updateType === 'EDIT' &&
          (thirdPartyLinkType || thirdPartyLinkType === null)
        ) {
          eventProps[THIRD_PARTY_LINK_TYPE] =
            thirdPartyLinkType === null ? OTHER : thirdPartyLinkType;
        }
      }

      const aiState = state.getIn(['ai']).toJS();
      if (aiState.rewordAction) {
        eventProps[AI_EDITOR_ACTION] = aiState.rewordAction;
        if (aiState.rewordTone) {
          eventProps[AI_EDITOR_TONE] = aiState.rewordTone;
        }
      }

      logThirdPartyEvent(ampEvents[updateType], eventProps);

      if (hasButtonTextChanged) {
        logThirdPartyEvent(EDITED_BUTTON, {
          [COURSE_ID]: course.id,
          [COURSE_LESSON_FORMAT]: course.lessonsType ?? DEFAULT,
          [COMPONENT_TYPE]: componentType,
          [ACTIVITY_ID]: id,
          [ACTIVITY_TYPE]: content.type,
          [COURSE_STATUS]: getPublishedStatus(course.published),
          [ACTIVITY_STATUS]: getPublishedStatus(lesson.published),
        });
      }

      if (hasSlideBgChanged) {
        logThirdPartyEvent(EDITED_SLIDE_COLOR, {
          [COURSE_ID]: course.id,
          [COMPONENT_TYPE]: componentType,
          [ACTIVITY_ID]: id,
          [ACTIVITY_TYPE]: content.type,
          [COLOUR_TYPE]: slideBgColour,
          [COURSE_STATUS]: getPublishedStatus(course.published),
          [ACTIVITY_STATUS]: getPublishedStatus(lesson.published),
        });
      }
    });
  }
};

export const sendAddGroupEvent = (action) => {
  const {data} = action.payload;
  const {name} = action.meta.previousAction.payload.request.data;

  logThirdPartyEvent(ADD_GROUP, {
    [GROUP_ID]: data,
    [GROUP_NAME]: name,
  });
};

const coursesAssignedToDeletedGroup = [];

// Courses have been deleted when `DELETE_GROUP_SUCCESS` action is fired, so need to save here
export const saveDeletedGroupData = (state) => {
  const courses = state.getIn(['course', 'courses']).toJS();
  coursesAssignedToDeletedGroup.push(...courses);
};

export const sendDeleteGroupEvent = async (action) => {
  const {id, name, numUsers, numActivatedUsers, numTeams} = action.payload.data;

  logThirdPartyEvent(DELETE_GROUP, {
    [GROUP_ID]: id,
    [GROUP_NAME]: name,
    [NUMBER_OF_TEAMS_ASSIGNED]: numTeams,
    [COURSES_ASSIGNED]: coursesAssignedToDeletedGroup.length,
    [NUMBER_OF_USERS_ASSIGNED]: numUsers,
    [NUMBER_OF_ACTIVATED_USERS_ASSIGNED]: numActivatedUsers,
    [NUMBER_OF_NON_ACTIVATED_USERS_ASSIGNED]: numUsers - numActivatedUsers,
  });

  coursesAssignedToDeletedGroup.length = 0;
};

export const sendCreateTeamEvent = (action) => {
  const {isSmartTeam} = action.payload;

  if (isSmartTeam) {
    const hasMoreThanOneTagOrEvent = action.payload.rules.length > 1;
    return logThirdPartyEvent(CREATE_TEAM, {
      [TEAM_ID]: action.payload.id,
      [TEAM_NAME]: action.payload.teamName,
      [TAGS]: action.payload.tags,
      [EDUME_EVENTS]: action.payload.edumeEvents,
      [TEAM_LOGIC]: hasMoreThanOneTagOrEvent ? action.payload.teamLogic : '',
      [LOGIC_LAYER]: action.payload.logicLayer,
      [RULE_LOGIC]: action.payload.ruleLogic,
    });
  }

  const {data} = action.payload;
  const {name} = action.meta.previousAction.payload.request.data;

  return logThirdPartyEvent(CREATE_TEAM, {[TEAM_ID]: data, [TEAM_NAME]: name});
};

export const sendDeleteTeamEvent = (url, state) => {
  const {groups, groupId} = state.get('team').toJS();
  const id = Number(url.split('/')[2]);
  const team = groups
    .find((group) => group.id === groupId)
    .children.find((t) => t.id === id);
  const {name, isSmartTeam} = team;

  logThirdPartyEvent(DELETE_TEAM, {
    [TEAM_ID]: id,
    [TEAM_NAME]: name,
    [TEAM_TYPE]: isSmartTeam ? SMART : STATIC,
  });
};

const processAddAdminEvent = (
  isCompanyAdmin,
  isTeamAdmin,
  perms,
  numTeamsAssigned,
  source
) => {
  const adminLevel = isCompanyAdmin ? COMPANY : isTeamAdmin ? TEAM : GROUP;
  const permissions = getPermissions(perms);
  const permissionsList = getPermissionsList(permissions);

  logThirdPartyEvent(ADD_ADMIN, {
    [ADMIN_LEVEL]: adminLevel,
    [PERMISSIONS]: permissionsList,
    [NUMBER_OF_TEAMS_ASSIGNED]: numTeamsAssigned,
    [SOURCE]: source,
  });
};

export const sendAddAdminEvent = (payload) => {
  const {
    isCompanyAdmin,
    isTeamAdmin,
    isEditor,
    isAnalyst,
    isManager,
    learnerGroups,
    source,
  } = payload;
  const numTeamsAssigned = learnerGroups.filter((g) => g.parentGroupId).length;
  processAddAdminEvent(
    isCompanyAdmin,
    isTeamAdmin,
    {isEditor, isAnalyst, isManager},
    numTeamsAssigned,
    source
  );
};

export const sendDeleteAdminEvent = (url, state) => {
  const adminId = Number(url.split('/')[2]);
  const {companyAdmins, groupAdmins, teamAdmins} = state.get('admin').toJS();
  const {isCompanyAdmin, isTeamAdmin, isEditor, isAnalyst, isManager} = [
    ...companyAdmins,
    ...groupAdmins,
    ...teamAdmins,
  ].find((admin) => admin.id === adminId);
  // eslint-disable-next-line no-nested-ternary
  const adminLevel = isCompanyAdmin ? COMPANY : isTeamAdmin ? TEAM : GROUP;
  const permissions = getPermissions({isManager, isAnalyst, isEditor});
  const permissionsList = getPermissionsList(permissions);

  logThirdPartyEvent(DELETE_ADMIN, {
    [ADMIN_LEVEL]: adminLevel,
    [PERMISSIONS]: permissionsList,
  });
};

let teamUserTabIndex = 0;

export const handleViewTeamOrUserList = (action, state) => {
  const index = action.payload;
  teamUserTabIndex = index;
  if (index === 0) {
    sendViewTeamListEvent(state);
  }
};

const sendViewTeamListEvent = (state) => {
  const {groups, groupId} = state.get('team').toJS();
  const currentGroup = groups.find((group) => group.id === groupId);
  const numTeams = currentGroup.children.length;

  logThirdPartyEvent(VIEW_TEAM_LIST, {
    [NUMBER_OF_TEAMS_ASSIGNED]: numTeams,
  });
};

const getFilterString = (str) => {
  if (str === 'notActivated') {
    return NOT_ACTIVATED;
  } else if (str === 'activated') {
    return ACTIVATED;
  } else {
    return ALL_USERS;
  }
};

// eslint-disable-next-line complexity
export const sendViewUserListEvent = (action, state) => {
  const groupId = state.getIn(['team', 'groupId']);
  const pathName = state.getIn(['router', 'location', 'pathname']);
  if (
    action.meta.groupId === groupId &&
    pathName === '/people' &&
    teamUserTabIndex === 1
  ) {
    const {
      searchQuery,
      groupUsers: {
        [groupId]: {
          users,
          usersPageFilters: {filterOption, sortOption},
        },
      },
    } = state.get('people').toJS();

    const viewType =
      sortOption !== 'mostRecent' ||
      filterOption !== 'all' ||
      searchQuery !== ''
        ? CUSTOM
        : DEFAULT;
    const isEmpty = users[0].length === 0;
    const filter = searchQuery === '' ? getFilterString(filterOption) : SEARCH;
    const sort = sortOption === 'mostRecent' ? CHRONOLOGICALLY : ALPHABETICALLY;

    logThirdPartyEvent(VIEW_USER_LIST, {
      [FILTER_TYPE]: isEmpty && viewType === DEFAULT ? NONE : filter,
      [SORT_TYPE]: isEmpty || searchQuery ? NONE : sort,
      [VIEW_TYPE]: isEmpty ? EMPTY : viewType,
      [SEARCH_TERM]: searchQuery ? searchQuery : NONE,
    });
  }
};

export const sendCreateModuleEvent = (action) => {
  const {
    payload: {
      data: {id, courseId},
    },
  } = action;

  logThirdPartyEvent(CREATE_MODULE, {
    [MODULE_ID]: id,
    [COURSE_ID]: courseId,
  });
};

export const sendUpdateUserTeamsEvent = (action, state) => {
  if (!action.meta.isNewUser) {
    const {groupId} = state.get('team').toJS();
    const {users} = state.getIn(['people', 'groupUsers', groupId]).toJS();
    const {
      url,
      data: {groupIdsToAdd, groupIdsToRemove},
    } = action.meta.previousAction.payload.request;
    const userId = Number(url.split('/')[2]);
    const user = Object.values(users)
      .flat(1)
      .find((u) => u.id === userId);
    const numUserTeams = user?.teams?.length || 0;
    const numTeamsAssigned = groupIdsToAdd.length;
    const numTeamsUnassigned = groupIdsToRemove.length;
    const netChange = numTeamsAssigned - numTeamsUnassigned;

    logThirdPartyEvent(UPDATE_USERS_ASSIGNED_TEAMS, {
      [SOURCE]: USER_LIST,
      [NUMBER_OF_TEAMS_ASSIGNED]: numTeamsAssigned,
      [NUMBER_OF_TEAMS_UNASSIGNED]: numTeamsUnassigned,
      [NET_CHANGE_TEAMS_ASSIGNED]: netChange,
      [TOTAL_TEAMS_ASSIGNED]: numUserTeams + netChange,
      [IS_ADMIN]: user?.isAdmin,
    });
  }
};

export const sendUpdateAdminTeamsEvent = (action) => {
  const {groupIdsToAdd, groupIdsToRemove} =
    action.meta.previousAction.payload.request.data;
  const {user} = action.meta;
  const numTeamsBefore = user.learnerGroups.flatMap((g) => g.teamIds).length;
  const numTeamsAssigned = groupIdsToAdd.length;
  const numTeamsUnassigned = groupIdsToRemove.length;
  const netChange = numTeamsAssigned - numTeamsUnassigned;

  logThirdPartyEvent(UPDATE_USERS_ASSIGNED_TEAMS, {
    [SOURCE]: ADMIN_SETTINGS,
    [NUMBER_OF_TEAMS_ASSIGNED]: numTeamsAssigned,
    [NUMBER_OF_TEAMS_UNASSIGNED]: numTeamsUnassigned,
    [NET_CHANGE_TEAMS_ASSIGNED]: netChange,
    [TOTAL_TEAMS_ASSIGNED]: numTeamsBefore + netChange,
    [IS_ADMIN]: true,
  });
};

export const sendRemoveUserTeamsEvent = (action) => {
  const {userGroups} = action.payload.data;
  const teams = userGroups.filter((group) => group.parent_group_id);
  const {user} = action.meta;

  logThirdPartyEvent(UPDATE_USERS_ASSIGNED_TEAMS, {
    [SOURCE]: TEAM_LIST,
    [NUMBER_OF_TEAMS_ASSIGNED]: 0,
    [NUMBER_OF_TEAMS_UNASSIGNED]: 1,
    [NET_CHANGE_TEAMS_ASSIGNED]: -1,
    [TOTAL_TEAMS_ASSIGNED]: teams.length,
    [IS_ADMIN]: user.isAdmin,
  });
};

export const sendDownloadUsersInGroupEvent = (action, state) => {
  const {groupId, teamId} = state.get('team').toJS();

  // Use team-level user data if available
  const usersPageFilters =
    state.getIn(['people', 'groupUsers', teamId, 'usersPageFilters']) ||
    state.getIn(['people', 'groupUsers', groupId, 'usersPageFilters']);

  const {numMembers, numActivatedMembers} = action.payload.data;
  const filter = getFilterString(usersPageFilters.filterOption);
  const userNumbers = {
    [NOT_ACTIVATED]: numMembers - numActivatedMembers,
    [ACTIVATED]: numActivatedMembers,
    [ALL_USERS]: numMembers,
  };
  const numUsersDownloaded = userNumbers[filter];

  logThirdPartyEvent(DOWNLOAD_USERS, {
    [FILTER_TYPE]: filter,
    [NUMBER_OF_USERS_DOWNLOADED]: numUsersDownloaded,
  });
};

export const sendViewMessagesEvent = (action) => {
  const {teamId, teamName} = action.payload;

  logThirdPartyEvent(VIEW_MESSAGES, {
    [TEAM_ID]: teamId,
    [TEAM_NAME]: teamName,
  });
};

export const sendDeleteSurveyEvent = (action, state) => {
  const [id] = action.payload.config.url.split('/').slice(-1);
  const surveys = state.getIn(['survey', 'surveysForGroup']);
  const {type, name} = surveys.find((s) => s.id === +id);

  let eventProps = {
    [SURVEY_ID]: Number(id),
    [SURVEY_TYPE]: type === 'enps' ? 'nps' : type,
    [SURVEY_NAME]: name,
  };

  const {countAssignedTeams} = action.payload.data;
  if (countAssignedTeams) {
    eventProps = {
      ...eventProps,
      [NUMBER_OF_TEAMS_ASSIGNED]: countAssignedTeams,
    };
  }

  logThirdPartyEvent(DELETE_SURVEY, eventProps);
};

export const sendCreateSurveyEvent = (action) => {
  const {id, name, questions} = action.payload.data;
  const teamsAssigned = action.meta.previousAction.payload.request.data.teamIds;
  let eventProps = {
    [SURVEY_ID]: id,
    [SURVEY_NAME]: name,
  };

  if (questions[0].type === 'multipleChoice') {
    const {options} = questions[0].params;
    const isReactionSurvey = options.every(
      (option) => option === '👍' || option === '👎'
    );
    eventProps = isReactionSurvey
      ? {
          ...eventProps,
          [SURVEY_TYPE]: REACTION,
          [NUMBER_OF_TEAMS_ASSIGNED]: teamsAssigned.length,
        }
      : {
          ...eventProps,
          [SURVEY_TYPE]: MULTIPLE_CHOICE,
          [NUMBER_OF_TEAMS_ASSIGNED]: teamsAssigned.length,
          [NUMBER_OF_SURVEY_OPTIONS]: options.length,
        };
  } else {
    eventProps = {
      ...eventProps,
      [SURVEY_TYPE]: NPS,
    };
  }

  logThirdPartyEvent(CREATE_SURVEY, eventProps);
};

export const sendCreateModuleSurveyEvent = (action) => {
  const {id, name, questions, moduleId} = action.payload.data;
  let eventProps = {
    [SURVEY_ID]: id,
    [SURVEY_NAME]: name,
    [MODULE_ID]: moduleId,
    [ADDED_SURVEY_FOLLOW_UP]: false,
  };

  questions.forEach((question) => {
    switch (question.type) {
      case 'freeText': {
        eventProps = {
          ...eventProps,
          [ADDED_SURVEY_FOLLOW_UP]: true,
        };
        return null;
      }
      case 'multipleChoice': {
        const {options} = question.params;
        const isReactionSurvey = options.every(
          (option) => option === '👍' || option === '👎'
        );
        eventProps = isReactionSurvey
          ? {
              ...eventProps,
              [SURVEY_TYPE]: REACTION,
            }
          : {
              ...eventProps,
              [SURVEY_TYPE]: MULTIPLE_CHOICE,
              [NUMBER_OF_SURVEY_OPTIONS]: options.length,
            };
        return null;
      }
      default:
        return null;
    }
  });

  logThirdPartyEvent(CREATE_SURVEY, eventProps);
};
const DOWNLOAD_REPORT_EVENT_PROPS = {
  level: REPORT_LEVEL,
  type: REPORT_TYPE,
  teamId: TEAM_ID,
  teamName: TEAM_NAME,
  courseId: COURSE_ID,
  filterType: FILTER_TYPE,
  dayRange: DAY_RANGE,
  userStatus: USER_STATUS,
};

export const sendDownloadReportEvent = (action) => {
  const data = action.payload;
  const eventProps = {};
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      eventProps[[DOWNLOAD_REPORT_EVENT_PROPS[key]]] = data[key];
    }
  });
  logThirdPartyEvent(DOWNLOAD_REPORT, eventProps);
};

let viewReportEventProps = {};

// eslint-disable-next-line complexity
export const sendViewReportEvent = (action, state, source) => {
  const {groupId, groups} = state.get('team').toJS();
  const search = action.payload.location.search;
  const searchGroupId = +search
    .slice(1)
    .split('&')
    .find((str) => str.includes('g='))
    ?.slice(2);

  if (!searchGroupId || searchGroupId === groupId) {
    viewReportEventProps = {};
    const {pathname} = action.payload.location;
    viewReportEventProps[REPORT_LEVEL] = pathname.includes('team')
      ? TEAM
      : COURSE;
    viewReportEventProps[REPORT_TYPE] =
      pathname === '/reports' || pathname === '/reports/teams'
        ? OVERVIEW
        : DETAILED;
    viewReportEventProps[SOURCE] = source || REPORTS;

    if (pathname.includes('reports/course/')) {
      viewReportEventProps[COURSE_ID] = Number([pathname.split('/').slice(-1)]);
      viewReportEventProps[REPORT_USER_SEGMENT] = OVERALL;
    }

    if (pathname.includes('reports/team/')) {
      viewReportEventProps[TEAM_ID] = Number([pathname.split('/').slice(-1)]);
      viewReportEventProps[TEAM_NAME] = groups
        .find((group) => group.id === groupId)
        .children.find(
          (team) => team.id === Number([pathname.split('/').slice(-1)])
        ).name;
    }
    logThirdPartyEvent(VIEW_REPORT, viewReportEventProps);
  }
};

export const handleReportToggle = (action) => {
  viewReportEventProps[REPORT_USER_SEGMENT] = action.payload.view;
  logThirdPartyEvent(VIEW_REPORT, viewReportEventProps);
};

export const handleUserToggle = (action) => {
  const {isOpen, userId} = action.payload;
  if (isOpen) {
    viewReportEventProps[USER_ID] = userId;
    viewReportEventProps[REPORT_LEVEL] = USER;
    logThirdPartyEvent(VIEW_REPORT, viewReportEventProps);
  }
};

export const sendCreateMessageEvent = (action) => {
  const {id, teams} = action.payload.data;
  const {data} = action.meta.previousAction.payload.request;
  const {publishDate, expiryDate, pinned, content} = data;
  const {type} = content.media || {};

  const isScheduled = !moment(publishDate).isBetween(
    moment().subtract('minutes', 1),
    moment().add('minutes', 1)
  );

  logThirdPartyEvent(CREATE_MESSAGE, {
    [MESSAGE_ID]: id,
    [TITLE]: !!content.title,
    [MEDIA_TYPE]: type || NONE,
    [PINNED]: !!pinned,
    [NUMBER_OF_TEAMS_ASSIGNED]: teams.length,
    [SCHEDULED]: isScheduled,
    [EXPIRATION]: !!expiryDate,
  });
};

export const sendGuideCreated = (action, state) => {
  const {data: guide, source} = action.payload;
  const groupId = getSelectedGroupId(state);
  const eventProps = {
    [GUIDE_ID]: guide.id,
    [GUIDE_TITLE]: guide.title,
    [GROUP_ID]: groupId,
    [SOURCE]: source === DASHBOARD ? HOME_DASHBOARD : OTHER,
  };

  if (guide.aiPrompt) {
    eventProps[AI_PROMPT] = guide.aiPrompt;
  }

  logThirdPartyEvent(CREATE_GUIDE, eventProps);
};

export const sendGuideUpdated = (action, state) => {
  const {data: guide} = action.payload;
  const groupId = getSelectedGroupId(state);

  logThirdPartyEvent(UPDATE_GUIDE, {
    [GUIDE_ID]: guide.id,
    [GUIDE_TITLE]: guide.title,
    [GROUP_ID]: groupId,
  });
};

export const sendGuidePublishEvent = (action, state) => {
  const {data: guide} = action.payload;
  const groupId = getSelectedGroupId(state);

  const eventName =
    guide.status === 'published' ? PUBLISH_GUIDE : UNPUBLISH_GUIDE;

  logThirdPartyEvent(eventName, {
    [GUIDE_ID]: guide.id,
    [GUIDE_TITLE]: guide.title,
    [GROUP_ID]: groupId,
  });
};

export const sendGuideDeleted = (action) => {
  const {guideId, groupId} = action.meta;

  logThirdPartyEvent(DELETE_GUIDE, {
    [GUIDE_ID]: guideId,
    [GROUP_ID]: groupId,
  });
};

export const sendGuideGroupAssigned = (action, state) => {
  const {guideId} = action.meta;
  const groupId = getSelectedGroupId(state);
  const guide = getGuideById(state, guideId);

  logThirdPartyEvent(ASSIGN_TEAMS_TO_GUIDE, {
    [GUIDE_ID]: guide.id,
    [GUIDE_TITLE]: guide.title,
    [GROUP_ID]: groupId,
  });
};

export const sendViewHelpCenterEvent = () => {
  const tab = window.location.pathname.split('/')[1];

  logThirdPartyEvent(VIEW_HELP_CENTER, {
    [PAGE_TITLE]: tab ? tab : HOME,
  });
};

let courseToDelete;

export const saveDeleteCourseData = (action, state) => {
  const {courseKey} = action.meta;
  const course = getCourseByKey(state, courseKey);
  courseToDelete = course;
};

export const sendDeleteCourseEvent = () => {
  logThirdPartyEvent(DELETE_COURSE, {
    [COURSE_ID]: courseToDelete.id,
    [COURSE_TITLE]: courseToDelete.title,
  });
};

export const sendEditedEndScreenEvent = (action, state) => {
  const courseKey = action.meta.courseKey;
  const courses = state.getIn(['course', 'courses']).toJS();
  const course = courses.find((c) => c.courseKey === courseKey);
  logThirdPartyEvent(EDITED_END_SCREEN, {
    [EDITED_TEXT]: action.meta.hasTextChanged ? 'yes' : 'no',
    [LINK_ADDED]: action.meta.isUrlAdded ? 'yes' : 'no',
    [COURSE_STATUS]: getPublishedStatus(course.published),
  });
};

export const sendUserTests = (tests, variants) =>
  amplitude.setAmplitudeUserProps({
    [MULTIVARIANT_TESTS]: tests,
    [MULTIVARIANT_TEST_VARIANTS]: variants,
  });

export const sendEditCourseTitleSlideEvent = (action, state) => {
  const {courseKey, hideTitleSlide} = action.meta;
  const course = getCourseByKey(state, courseKey);

  logThirdPartyEvent(UPDATE_COURSE_SETTINGS, {
    [COURSE_ID]: course.id,
    [HIDE_TITLE_SLIDE]: hideTitleSlide ? 'on' : 'off',
  });
};

export const sendEditCourseSettingsEvent = (action, state) => {
  const {course: newDetails, previousDetails, courseKey} = action.meta;
  const {id} = getCourseByKey(state, courseKey);

  const changes = Object.keys(previousDetails).reduce((acc, key) => {
    if (previousDetails[key] !== newDetails[key]) {
      acc[key] = newDetails[key];
    }
    return acc;
  }, {});

  const [changedProperty, newValue] = Object.entries(changes)[0];
  if (!changedProperty) {
    return;
  }

  if (changedProperty === 'type') {
    logThirdPartyEvent(UPDATE_COURSE_SETTINGS, {
      [COURSE_ID]: id,
      [CERTIFICATE]: newValue === 'certificate' ? 'on' : 'off',
    });
    return;
  }

  logThirdPartyEvent(UPDATE_COURSE_SETTINGS, {
    [COURSE_ID]: id,
    [courseSettingsPropertyMap[changedProperty]]: newValue ? 'on' : 'off',
  });
};

export const sendGenerateMLSSheetEvent = (action, state) => {
  const {courseId} = action.meta;
  const course = getCourseById(state, courseId);

  logThirdPartyEvent(GENERATE_MLS_SPREADSHEET, {
    [COURSE_ID]: courseId,
    [COURSE_TITLE]: course.title,
    [COURSE_LANGUAGES]: course.languages,
  });
};

export const toggleContentEventIntegration = (action, state) => {
  const contentType =
    action.meta.previousAction.payload.request.url.split('/')[1];
  const contentID =
    action.meta.previousAction.payload.request.url.split('/')[2];

  const groupId = state.getIn(['team', 'groupId']);
  const {id: customerId} = state.getIn(['auth', 'company']);
  const {type, configType, credentials} =
    action.meta.previousAction.payload.request.data;

  const payload = {
    [GROUP_ID]: groupId,
    [CUSTOMER]: customerId,
    [INTEGRATION_NAME]: configType,
    [WEBHOOK_NAME]: type,
    [STATUS]: credentials.enabled ? 'on' : 'off',
    [CUSTOM_NAME]: credentials.eventName,
  };

  switch (contentType) {
    case contentTypes.GUIDE:
      payload[GUIDE_ID] = contentID;
      break;
    case contentTypes.COURSE: {
      const courses = state.getIn(['course', 'courses']).toJS();
      const {id: courseId} = courses.find((c) => c.courseKey === contentID);

      payload[COURSE_ID] = courseId;
      break;
    }
    default:
      break;
  }

  logThirdPartyEvent(EVENT_REPORTING_CHANGED, payload);
};

export const toggleContentIntegration = (action, state) => {
  const contentType =
    action.meta.previousAction.payload.request.url.split('/')[1];
  const contentID =
    action.meta.previousAction.payload.request.url.split('/')[2];

  const groupId = state.getIn(['team', 'groupId']);
  const {id: customerId} = state.getIn(['auth', 'company']);
  const {configType, credentials} =
    action.meta.previousAction.payload.request.data;

  const payload = {
    [GROUP_ID]: groupId,
    [CUSTOMER]: customerId,
    [INTEGRATION_NAME]: configType,
    [STATUS]: credentials.enabled ? 'on' : 'off',
  };

  switch (contentType) {
    case contentTypes.GUIDE:
      payload[GUIDE_ID] = contentID;
      break;
    case contentTypes.COURSE: {
      const courses = state.getIn(['course', 'courses']).toJS();
      const {id: courseId} = courses.find((c) => c.courseKey === contentID);

      payload[COURSE_ID] = courseId;
      break;
    }
    default:
      break;
  }

  logThirdPartyEvent(EVENT_REPORTING_STATUS_CHANGED, payload);
};

export const editContentEventIntegrationName = (action, state) => {
  const contentType =
    action.meta.previousAction.payload.request.url.split('/')[1];
  const contentID =
    action.meta.previousAction.payload.request.url.split('/')[2];

  const groupId = state.getIn(['team', 'groupId']);
  const {id: customerId} = state.getIn(['auth', 'company']);
  const {type, configType, credentials} =
    action.meta.previousAction.payload.request.data;

  const payload = {
    [GROUP_ID]: groupId,
    [CUSTOMER]: customerId,
    [INTEGRATION_NAME]: configType,
    [WEBHOOK_NAME]: type,
    [STATUS]: credentials.enabled ? 'on' : 'off',
    [CUSTOM_NAME]: credentials.eventName,
  };

  switch (contentType) {
    case contentTypes.GUIDE:
      payload[GUIDE_ID] = contentID;
      break;
    case contentTypes.COURSE: {
      const courses = state.getIn(['course', 'courses']).toJS();
      const {id: courseId} = courses.find((c) => c.courseKey === contentID);

      payload[COURSE_ID] = courseId;
      break;
    }
    default:
      break;
  }

  logThirdPartyEvent(EVENT_REPORTING_NAME_CHANGED, payload);
};

export const accessedCourseReportLink = (action) => {
  const {courseId, linkType} = action.payload;
  logThirdPartyEvent(ACCESSED_COURSE_REPORT_LINK, {
    [COURSE_ID]: courseId,
    [COURSE_REPORT_LINK_TYPE]: linkType,
  });
};

export const sendCourseSearchEvent = (action) => {
  // TODO: add content type?
  const {enteredTerm, noOfResultsReturned, searchType, courseId} =
    action.payload;
  const eventName = courseId
    ? SELECTED_SEARCH_RESULT
    : VIEWED_SEARCH_RESULTS_PAGE;

  logThirdPartyEvent(eventName, {
    [SEARCH_TERM]: enteredTerm,
    [NUMBER_OF_RESULTS_RETURNED]: noOfResultsReturned,
    ...(courseId && {
      [COURSE_ID]: courseId,
      [SEARCH_TYPE]: searchType,
    }),
  });
};

export const sendViewApiKeysEvent = () =>
  logThirdPartyEvent(VIEWED_API_KEYS, {});

export const sendCreateApiKeyEvent = (action) => {
  const {id, groupId, groupName} = action.payload.data;

  let eventProps = {[KEY_ID]: id};
  if (groupId) {
    eventProps[PERMISSION_LEVEL] = GROUP;
    eventProps[GROUP_ID] = groupId;
    eventProps[GROUP_NAME] = groupName;
  } else {
    eventProps[PERMISSION_LEVEL] = COMPANY;
  }
  logThirdPartyEvent(CREATED_API_KEY, eventProps);
};

export const sendDeleteApiKeyEvent = (action) => {
  const {id, groupId, groupName} = action.meta;

  let eventProps = {[KEY_ID]: id};
  if (groupId) {
    eventProps[PERMISSION_LEVEL] = GROUP;
    eventProps[GROUP_ID] = groupId;
    eventProps[GROUP_NAME] = groupName;
  } else {
    eventProps[PERMISSION_LEVEL] = COMPANY;
  }
  logThirdPartyEvent(DELETED_API_KEY, eventProps);
};

export const sendViewedSigningSecretsEvent = () =>
  logThirdPartyEvent(VIEWED_SIGNING_SECRETS, {});

export const sendCreatedSigningSecretEvent = (action) =>
  logThirdPartyEvent(CREATED_SIGNING_SECRET, {
    [SECRET_ID]: action.payload.data.id,
  });

export const sendViewedWebhookConfigEvent = (webhookId) =>
  logThirdPartyEvent(VIEWED_WEBHOOK_CONFIG, {
    [WEBHOOK_ID]: webhookId,
  });

export const sendUpdatedWebhookConfigEvent = (action) =>
  logThirdPartyEvent(UPDATED_WEBHOOK_CONFIG, {
    [WEBHOOK_ID]: action.meta.webhookId,
  });

export const sendUpdatedWebhookSubscribedEventsEvent = (action) =>
  logThirdPartyEvent(UPDATED_WEBHOOK_SUBSCRIBED_EVENTS, {
    [WEBHOOK_ID]: action.meta.webhookId,
  });

export const sendCopiedWebhookSecretEvent = (action) =>
  logThirdPartyEvent(COPIED_WEBHOOK_SECRET, {
    [WEBHOOK_ID]: action.payload.webhookId,
  });

export const sendUserReportDownloadedEvent = (action) =>
  logThirdPartyEvent(DOWNLOAD_USER_REPORT, {
    [USER_ID]: action.payload.userId,
  });

export const selectedLearningTabFilter = (action) =>
  logThirdPartyEvent(SELECTED_FILTER, {
    [OPTION_SELECTED]: action.payload,
  });

export const sendViewedPreviewLink = (action) => {
  const {
    payload: {id, type},
  } = action;
  logThirdPartyEvent(VIEWED_PREVIEW_LINK, {
    [PREVIEW_ID]: id,
    [LINK_TYPE]: type,
  });
};

export const sendIdCopiedEvent = (action) => {
  const {
    payload: {type, courseId, surveyId, guideId},
  } = action;
  logThirdPartyEvent(ID_COPIED, {
    [TYPE_OF_ID]: type,
    [COURSE_ID]: courseId,
    [SURVEY_ID]: surveyId,
    [GUIDE_ID]: guideId,
  });
};

export const selectedCourseTab = (action) =>
  logThirdPartyEvent(SELECTED_COURSE_TAB, {
    [OPTION_SELECTED]: action.payload.tab,
  });

export const sendViewedLearningPageEvent = (action) => {
  let source = OTHER;
  const {state} = action.payload.location;
  if (state === INSPIRATION) {
    source = DASHBOARD_RESOURCES_LINK;
  } else if (state === RECENT) {
    source = DASHBOARD_COURSES_LINK;
  } else if (state === DASHBOARD) {
    source = HOME_DASHBOARD;
  }
  logThirdPartyEvent(VIEWED_LEARNING_PAGE, {
    [SOURCE]: source,
  });
};

export const sendViewedIntegrationsEvent = () =>
  logThirdPartyEvent(VIEWED_INTEGRATIONS, {});

export const sendUpdatedLanguageEvent = (action) =>
  logThirdPartyEvent(UPDATED_LANGUAGE, {
    [SELECTED_LANGUAGE]: action.meta.languageCode,
  });

export const sendUpdateCoursePriorityEvent = (action) =>
  logThirdPartyEvent(UPDATED_COURSE_PRIORITY, {
    [PRIORITY_STATUS]:
      action.meta.previousAction.payload.request.data.priority ?? 'none',
  });

export const sendViewedCompanySettingsEvent = () =>
  logThirdPartyEvent(VIEWED_COMPANY_SETTINGS, {});

export const sendViewedContentCreationGuide = () =>
  logThirdPartyEvent(VIEWED_CONTENT_CREATION_GUIDE, {});

export const sendViewedCourseScreenEvent = (courseId, action) => {
  const {state} = action.payload.location;
  let source = OTHER;
  if (state === RECENT) {
    source = DASHBOARD_COURSES;
  }
  if (state === INSPIRATION) {
    source = DASHBOARD_RESOURCES;
  }
  logThirdPartyEvent(VIEWED_COURSE_SCREEN, {
    [COURSE_ID]: courseId,
    [SOURCE]: source,
  });
};

export const sendViewedDashboardEvent = () =>
  logThirdPartyEvent(VIEWED_DASHBOARD, {});

export const sendViewedIntegrationPageEvent = (type) => {
  logThirdPartyEvent(VIEWED_INDIVIDUAL_INTEGRATION_PAGE, {
    [INTEGRATION_NAME]: type,
  });
};

export const sendIntegrationUpdatedEvent = (action) => {
  const {type, credentials} = action.payload.data.integration;
  logThirdPartyEvent(UPDATE_INTEGRATION, {
    [INTEGRATION_NAME]: type,
    [ENABLED]: credentials.enabled,
  });
};

export const sendIntegrationDeletedEvent = (action) => {
  const credentialsObject = JSON.parse(action.payload.config.data);
  const {type} = credentialsObject;
  logThirdPartyEvent(DELETE_INTEGRATION, {
    [INTEGRATION_NAME]: type,
  });
};

export const sendIntegrationCreatedEvent = (action) => {
  const {type, credentials} = action.payload.data.integration;
  logThirdPartyEvent(CREATE_INTEGRATION, {
    [INTEGRATION_NAME]: type,
    [ENABLED]: credentials.enabled,
  });
};

export const sendSetCourseCardDesignEvent = (action, state) => {
  const {isImageCourseCardEnabled} = action.payload;
  const {id: customerId} = state.getIn(['auth', 'company']);
  amplitude.logAmplitudeEvent(SET_COURSE_CARD_DESIGN, {
    [CUSTOMER]: customerId,
    [VIEW_TYPE]: isImageCourseCardEnabled ? 'CARD' : 'LIST',
  });
};

export const sendKnowledgeHubCreatedEvent = (action) => {
  const {name, showsExternalContent} = action.payload.data;
  const {groupId, teamIds} = action.meta;

  const configuration = showsExternalContent
    ? KNOWLEDGE_HUB_ALL_CONTENT
    : KNOWLEDGE_HUB_VIEW_ONLY;

  logThirdPartyEvent(CREATED_KNOWLEDGE_HUB, {
    [GROUP_ID]: groupId,
    [NUMBER_OF_TEAMS_ASSIGNED]: teamIds.length,
    [KNOWLEDGE_HUB_NAME]: name,
    [KNOWLEDGE_HUB_CONFIGURATION]: configuration,
  });
};

export const sendCreatedAccountEvent = (state) => {
  const resourceKey = state.getIn(['trial', 'resourceKey'], null);
  const resourceType = state.getIn(['trial', 'resourceType'], null);

  if (resourceKey) {
    amplitude.setAmplitudeGroup(resourceType, 'trialResourceType');
    amplitude.setAmplitudeGroup(resourceKey, 'trialResource');
  }
  logThirdPartyEvent(CREATED_ACCOUNT, {});
};

export const sendCreateTemplateFromCourseEvent = (action, state) => {
  const {courseKey, groupId} = action.meta;
  const courses = state.getIn(['course', 'courses']).toJS();
  const {id} = courses.filter((c) => c.courseKey === courseKey)[0];
  logThirdPartyEvent(SAVED_TEMPLATE, {
    [GROUP_ID]: groupId,
    [ORIGINAL_COURSE_ID]: id,
  });
};

export const sendUpdateBrandingEvent = (action) => {
  const {customerId, groupId, branding} = action.meta;
  const {
    logoUrl,
    darkLogoUrl,
    colorPrimary,
    colorPrimaryDark,
    colorAccent1,
    colorAccent2,
    hasTabs,
    layoutVersion,
    hasSearchBar,
    hasCourseTimeEstimate,
    hasCourseActivities,
    borderRadius,
    progressBarColor,
    dashboardHeaderColor,
    dashboardHeaderTextColor,
    dashboardName,
  } = branding;
  if (layoutVersion === 'images') {
    logThirdPartyEvent(UPDATE_KNOWLEDGE_HUB_CUSTOMISATIONS, {
      [GROUP_ID]: groupId,
      [HAS_TABS]: hasTabs,
      [LAYOUT_VERSION]: layoutVersion,
      [HAS_SEARCH_BAR]: hasSearchBar,
      [HAS_COURSE_TIME_ESTIMATE]: hasCourseTimeEstimate,
      [HAS_COURSE_ACTIVITIES]: hasCourseActivities,
      [BORDER_RADIUS]: borderRadius,
      [PROGRESS_BAR_COLOR]: progressBarColor,
      [DASHBOARD_HEADER_COLOR]: dashboardHeaderColor,
      [DASHBOARD_HEADER_TEXT_COLOR]: dashboardHeaderTextColor,
      [DASHBOARD_NAME]: dashboardName,
    });
  } else {
    logThirdPartyEvent(
      customerId ? SAVED_COMPANY_BRANDING : SAVED_GROUP_BRANDING,
      {
        ...(groupId && {[GROUP_ID]: groupId}),
        [LOGO_URL]: logoUrl,
        [DARK_LOGO_URL]: darkLogoUrl,
        [COLOUR_PRIMARY]: colorPrimary,
        [COLOUR_PRIMARY_DARK]: colorPrimaryDark,
        [COLOUR_ACCENT_1]: colorAccent1,
        [COLOUR_ACCENT_2]: colorAccent2,
      }
    );
  }
};

export const sendTalkToSalesEvent = (action) => {
  const {source} = action.payload;
  logThirdPartyEvent(VIEWED_ACCOUNT_UPGRADE, {
    [SOURCE]: source,
  });
};

export const sendCopyAnonCourseLinkEvent = (action) => {
  const {source, courseId} = action.payload;
  logThirdPartyEvent(COPIED_PUBLISHED_LINK, {
    [SOURCE]: source,
    [COURSE_ID]: courseId,
  });
};

export const sendCreateAICourseEvent = () => {
  window.Appcues.track(CREATED_AI_LESSON);
};

export const sendOpenedCourseNotificationsSlidepanelEvent = (action) => {
  const {visible, courseId} = action.payload;
  if (visible) {
    logThirdPartyEvent(OPENED_COURSE_NOTIFICATIONS_SLIDEPANEL, {
      [COURSE_ID]: courseId,
    });
  }
};

export const sendOpenedGuideNotificationsSlidepanelEvent = (action) => {
  const {visible, guideId} = action.payload;
  if (visible) {
    logThirdPartyEvent(OPENED_GUIDE_NOTIFICATIONS_SLIDEPANEL, {
      [GUIDE_ID]: guideId,
    });
  }
};

export const sendOpenedKnowledgeHubNotificationsSlidepanelEvent = (action) => {
  const {knowledgeHubId} = action.payload;
  logThirdPartyEvent(OPENED_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL, {
    [KNOWLEDGE_HUB_ID]: knowledgeHubId,
  });
};

const getIntegrationTeamCounts = ({
  teamIntegrations,
  integratedTeamIds,
  unlinkedTeams,
}) => {
  const integrationsToProcess = [MS_TEAMS, SPEAKAP, BEEKEEPER];
  const counts = {};
  for (const integration of integrationsToProcess) {
    const integrationData = teamIntegrations.find(
      (i) => i.type === integration
    );
    if (integrationData) {
      const linkedCount = integratedTeamIds.filter((id) =>
        integrationData.linkedTeams.find((t) => t.id === id)
      ).length;
      const unlinkedCount = unlinkedTeams.filter(
        (team) => team.integration === integration
      ).length;
      counts[integration] = linkedCount + unlinkedCount;
    }
  }
  return counts;
};

export const sendLearnerNotificationsEvents = (action, state) => {
  const {courseId, guideId, knowledgeHubId} = action.meta;
  const {
    eduMeTeamIdsToAssign,
    eduMeTeamIdsToNotify,
    integratedTeamIdsToAssign,
    unlinkedTeamsToAssign,
    deliveryPreference,
    schedule,
    customSmsText,
  } = action.meta.previousAction.payload.request.data;
  const sentNotificationData = action.payload.data;
  const teamIntegrations = state.getIn(['team', 'integrations']).toJS();
  const timeSetting = schedule ? SCHEDULED : SENT_IMMEDIATELY;
  const messageTextType = customSmsText ? CUSTOMISED : DEFAULT;

  const eduMeTeamsAssigned = eduMeTeamIdsToAssign?.length;
  const integrationTeamsAssigned =
    integratedTeamIdsToAssign?.length || unlinkedTeamsToAssign?.length;

  if (eduMeTeamsAssigned || integrationTeamsAssigned) {
    const integrationTeamCounts = getIntegrationTeamCounts({
      teamIntegrations,
      integratedTeamIds: integratedTeamIdsToAssign,
      unlinkedTeams: unlinkedTeamsToAssign,
    });
    if (courseId) {
      logThirdPartyEvent(ASSIGNED_TEAMS_TO_COURSE_VIA_NOTIFICATION_SLIDEOUT, {
        [COURSE_ID]: courseId,
        [NUMBER_OF_TEAMS_ASSIGNED]: eduMeTeamsAssigned,
        [NUMBER_OF_BEEKEEPER_TEAMS_ASSIGNED]: integrationTeamCounts[BEEKEEPER],
        [NUMBER_OF_MS_TEAMS_TEAMS_ASSIGNED]: integrationTeamCounts[MS_TEAMS],
        [NUMBER_OF_SPEAKAP_TEAMS_ASSIGNED]: integrationTeamCounts[SPEAKAP],
      });
    } else if (guideId) {
      logThirdPartyEvent(ASSIGNED_TEAMS_TO_GUIDE_VIA_NOTIFICATION_SLIDEOUT, {
        [GUIDE_ID]: guideId,
        [NUMBER_OF_TEAMS_ASSIGNED]: eduMeTeamsAssigned,
        [NUMBER_OF_BEEKEEPER_TEAMS_ASSIGNED]: integrationTeamCounts[BEEKEEPER],
        [NUMBER_OF_MS_TEAMS_TEAMS_ASSIGNED]: integrationTeamCounts[MS_TEAMS],
        [NUMBER_OF_SPEAKAP_TEAMS_ASSIGNED]: integrationTeamCounts[SPEAKAP],
      });
    }
  }

  const eduMeTeamsNotified = eduMeTeamIdsToNotify?.length;

  if (courseId) {
    logThirdPartyEvent(SENT_COURSE_NOTIFICATIONS, {
      [COURSE_ID]: courseId,
      [DELIVERY_PREFERENCE]: deliveryPreference,
      [TIME_SETTING]: timeSetting,
      [MESSAGE_TEXT]: messageTextType,
      [NUMBER_OF_TEAMS_NOTIFIED]: eduMeTeamsNotified,
      [NUMBER_OF_BEEKEEPER_TEAMS_NOTIFIED]: sentNotificationData[BEEKEEPER],
      [NUMBER_OF_MS_TEAMS_TEAMS_NOTIFIED]: sentNotificationData[MS_TEAMS],
      [NUMBER_OF_SPEAKAP_TEAMS_NOTIFIED]: sentNotificationData[SPEAKAP],
    });
  } else if (guideId) {
    logThirdPartyEvent(SENT_GUIDE_NOTIFICATIONS, {
      [GUIDE_ID]: guideId,
      [DELIVERY_PREFERENCE]: deliveryPreference,
      [TIME_SETTING]: timeSetting,
      [MESSAGE_TEXT]: messageTextType,
      [NUMBER_OF_TEAMS_NOTIFIED]: eduMeTeamsNotified,
      [NUMBER_OF_BEEKEEPER_TEAMS_NOTIFIED]: sentNotificationData[BEEKEEPER],
      [NUMBER_OF_MS_TEAMS_TEAMS_NOTIFIED]: sentNotificationData[MS_TEAMS],
      [NUMBER_OF_SPEAKAP_TEAMS_NOTIFIED]: sentNotificationData[SPEAKAP],
    });
  } else if (knowledgeHubId) {
    logThirdPartyEvent(SENT_KNOWLEDGE_HUB_NOTIFICATIONS, {
      [KNOWLEDGE_HUB_ID]: knowledgeHubId,
      [DELIVERY_PREFERENCE]: deliveryPreference,
      [TIME_SETTING]: timeSetting,
      [NUMBER_OF_TEAMS_NOTIFIED]: eduMeTeamsNotified,
      [NUMBER_OF_BEEKEEPER_TEAMS_NOTIFIED]: sentNotificationData[BEEKEEPER],
      [NUMBER_OF_MS_TEAMS_TEAMS_NOTIFIED]: sentNotificationData[MS_TEAMS],
      [NUMBER_OF_SPEAKAP_TEAMS_NOTIFIED]: sentNotificationData[SPEAKAP],
    });
  }
};

export const sendViewedCompanyLevelReportEvent = () => {
  logThirdPartyEvent(VIEWED_COMPANY_LEVEL_REPORT, {});
};

export const sendDateRangeSelectedEvent = (action) => {
  const {startDate, endDate, daysBetweenDates} = action.payload;
  logThirdPartyEvent(DATE_RANGE_SELECTED, {
    [START_DATE]: startDate,
    [END_DATE]: endDate,
    [DAYS_BETWEEN_DATES]: daysBetweenDates,
  });
};

export const sendModifiedCompanyLevelReportEvent = (action) => {
  const {type, columnNumber, courseIds, groupIds} = action.payload;
  const numberOfColumns = courseIds.length;
  const areGroupsUnique = groupIds.length === new Set(groupIds).size;
  const areCoursesUnique = courseIds.length === new Set(courseIds).size;

  logThirdPartyEvent(MODIFIED_COMPANY_LEVEL_REPORT, {
    [ACTION_TYPE]: type,
    [NUMBER_OF_COLUMNS]: numberOfColumns,
    [COLUMN_NUMBER]: columnNumber,
    [COURSE_IDS]: courseIds.join(','),
    [GROUP_IDS]: groupIds.join(','),
    [ARE_ALL_GROUPS_UNIQUE]: areGroupsUnique,
    [ARE_ALL_COURSES_UNIQUE]: areCoursesUnique,
  });
};

export const sendViewedUsagePageEvent = () => {
  logThirdPartyEvent(VIEWED_USAGE_PAGE, {});
};

export const sendCollectionCreatedEvent = (action) => {
  const {name, groupId} = action.payload.data;

  logThirdPartyEvent(CREATED_COLLECTION, {
    [COLLECTION_NAME]: name,
    [GROUP_ID]: groupId,
  });
};

export const sendCollectionContentAddedEvent = (action) => {
  switch (action.type) {
    case UPDATE_CONTENT_IN_COLLECTION_SUCCESS: {
      const {collectionId, source} = action.meta;

      // only send the event on adding content
      if (!action.meta.contentToAdd) {
        return null;
      }

      const {contentId, contentType} = action.meta.contentToAdd;

      return logThirdPartyEvent(ADDED_COLLECTION_CONTENT, {
        [COLLECTION_IDS]: collectionId,
        [CONTENT_ID]: contentId,
        [CONTENT_TYPE]: contentType,
        [COLLECTION_SOURCE]: source,
      });
    }

    case ASSIGN_COLLECTION_TO_CONTENT_SUCCESS: {
      const {collectionIds, contentId, contentType, source} = action.meta;

      // only send the event on adding content
      if (!collectionIds.length) {
        return null;
      }

      return logThirdPartyEvent(ADDED_COLLECTION_CONTENT, {
        [COLLECTION_IDS]: collectionIds.join(','),
        [CONTENT_ID]: contentId,
        [CONTENT_TYPE]: contentType,
        [COLLECTION_SOURCE]: source,
      });
    }
    default:
      return null;
  }
};

export const sendAdminPermissionsRevokedEvent = (action, state) => {
  const adminDemoted = !action.payload.data.isAdmin;
  if (adminDemoted) {
    const {id} = action.payload.data;
    const {companyAdmins, groupAdmins, teamAdmins} = state.get('admin').toJS();
    const {isCompanyAdmin, isTeamAdmin, isEditor, isAnalyst, isManager} = [
      ...companyAdmins,
      ...groupAdmins,
      ...teamAdmins,
    ].find((admin) => admin.id === id);
    const adminLevel = isCompanyAdmin ? COMPANY : isTeamAdmin ? TEAM : GROUP;
    const permissions = getPermissions({isManager, isAnalyst, isEditor});
    const permissionsList = getPermissionsList(permissions);

    logThirdPartyEvent(REVOKE_ADMIN_PERMISSIONS, {
      [USER_ID]: id,
      [ADMIN_LEVEL]: adminLevel,
      [PERMISSIONS]: permissionsList,
    });
  }
};

export const sendLearnerUpgradedToAdminEvent = (action, state) => {
  const {id, isCompanyAdmin, isEditor, isAnalyst, isManager} =
    action.payload.data;
  const {companyAdmins, groupAdmins, teamAdmins} = state.get('admin').toJS();
  const wasAdmin = !![...companyAdmins, ...groupAdmins, ...teamAdmins].find(
    (admin) => admin.id === id
  );
  if (!wasAdmin) {
    const isTeamAdmin = !!teamAdmins.find((admin) => admin.id === id);
    processAddAdminEvent(
      isCompanyAdmin,
      isTeamAdmin,
      {isEditor, isAnalyst, isManager},
      0,
      'upgraded from learner'
    );
  }
};

export const sendClickedCreateContentButtonEvent = (action) => {
  const {contentType} = action;
  if (contentType === contentTypes.GUIDE) {
    logThirdPartyEvent(CLICKED_CREATE_GUIDE);
  }
  if (contentType === contentTypes.COURSE) {
    logThirdPartyEvent(CLICKED_CREATE_COURSE);
  }
};

export const sendViewedHelpCenterArticleEvent = (action) => {
  logThirdPartyEvent(CLICKED_ON_SUPPORT_ARTICLE, {
    [NAME_OF_ARTICLE]: action.articleName,
  });
};

export const sendCreateAILessonsEvent = (action) => {
  const lessons = action.payload.data;
  const {courseId, fileUploadedType} = action.meta;

  lessons.forEach((lesson) => {
    let lessonData = {
      meta: {
        templateType: 'ai',
        courseId,
        lessonType: 'slideshow',
        fileUploadedType,
        lessonEson: {type: 'lesson'},
        multiple: lessons.length > 1,
      },
      payload: {
        data: {
          id: lesson.id,
        },
      },
    };
    sendCreateLessonEvent(lessonData);
  });
};

export const sendViewedScheduledNotificationsEvent = (action) => {
  const {contentType, source} = action.payload;
  const eventNameMap = {
    [contentTypes.COURSE]: VIEWED_COURSE_DELIVERIES,
    [contentTypes.GUIDE]: VIEWED_GUIDE_DELIVERIES,
    [contentTypes.KNOWLEDGE_HUB]: VIEWED_KNOWLEDGE_HUB_DELIVERIES,
  };
  const eventName = eventNameMap[contentType];
  if (eventName) {
    logThirdPartyEvent(eventName, {
      [SOURCE]: source,
    });
  }
};

export const sendClickedCreateTeamEvent = () => {
  logThirdPartyEvent(CLICKED_CREATE_TEAM, {});
};

export const sendSelectedTeamTypeEvent = (action) => {
  logThirdPartyEvent(SELECTED_TEAM_TYPE, {
    [TEAM_TYPE]: action.payload,
  });
};

export const sendViewedSmartTeamDetailsEvent = (action) => {
  logThirdPartyEvent(VIEWED_SMART_TEAM_DETAILS, {
    [TEAM_ID]: action.payload.teamId,
    [TEAM_NAME]: action.payload.teamName,
  });
};

export const sendViewedTeamActionEvent = (action) => {
  logThirdPartyEvent(VIEWED_TEAM_ACTION, {[TEAM_TYPE]: action.payload});
};

export const sendShowUsersInTeamEvent = (action) => {
  logThirdPartyEvent(SHOW_USERS_IN_TEAM, {
    [TEAM_TYPE]: action.payload.teamType,
    [TEAM_ID]: action.payload.teamId,
    [TEAM_NAME]: action.payload.teamName,
  });
};

export const sendChangeStaticTeamNameEvent = (action) => {
  logThirdPartyEvent(CHANGED_STATIC_TEAM_NAME, {
    [TEAM_ID]: action.payload.teamId,
    [TEAM_NAME]: action.payload.teamName,
  });
};

export const sendEditSmartTeamEvent = ({teamId, state}) => {
  const teams = getTeams(state);
  const {name} = teams.filter((t) => t.id === Number(teamId))[0];

  logThirdPartyEvent(EDIT_SMART_TEAM, {
    [TEAM_ID]: teamId,
    [TEAM_NAME]: name,
  });
};

export const sendSavedTeamUpdateEvent = (action) => {
  logThirdPartyEvent(SAVED_TEAM_UPDATE, {
    [NAME_CHANGED]: action.payload.nameChanged,
    [TAGS_CHANGED]: action.payload.tagsChanged,
    [EDUME_EVENTS_CHANGED]: action.payload.edumeEventsChanged,
    [LOGIC_OPERATOR_CHANGED]: action.payload.logicOperatorChanged,
    [TEAM_LOGIC]: action.payload.teamLogic,
    [RULE_LOGIC]: action.payload.ruleLogic,
    [LOGIC_LAYER]: action.payload.logicLayer,
  });
};
