export const LOAD_GROUP_SEGMENTS = 'LOAD_GROUP_SEGMENTS';
export const LOAD_GROUP_SEGMENTS_FAIL = 'LOAD_GROUP_SEGMENTS_FAIL';
export const LOAD_GROUP_SEGMENTS_SUCCESS = 'LOAD_GROUP_SEGMENTS_SUCCESS';
export const CREATE_GROUP_SEGMENT = 'CREATE_GROUP_SEGMENT';
export const CREATE_GROUP_SEGMENT_FAIL = 'CREATE_GROUP_SEGMENT_FAIL';
export const CREATE_GROUP_SEGMENT_SUCCESS = 'CREATE_GROUP_SEGMENT_SUCCESS';
export const ASSIGN_COURSE_TO_SEGMENT = 'ASSIGN_COURSE_TO_SEGMENT';
export const UPDATE_SEGMENT_STATUS = 'UPDATE_SEGMENT_STATUS';
export const STOP_POLL_SEGMENT_STATUS = 'STOP_POLL_SEGMENT_STATUS';
export const EDIT_GROUP_SEGMENT = 'EDIT_GROUP_SEGMENT';
export const EDIT_GROUP_SEGMENT_FAIL = 'EDIT_GROUP_SEGMENT_FAIL';
export const EDIT_GROUP_SEGMENT_SUCCESS = 'EDIT_GROUP_SEGMENT_SUCCESS';
