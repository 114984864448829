import React, {useEffect, useRef, useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Button, Input} from '@edume/magnificent';

import LegacyIntlHeading from '../LegacyIntlHeading';

import colourStyles from '../../../../resources/styles/colours.module.scss';
import styles from './styles.module.scss';

const CopyLink = ({
  link,
  disabled,
  inputDisabled,
  placeholder,
  inputDataAutomation,
  copyButtonDataAutomation,
  isViewLink,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const timer = () => setTimeout(setTimeout(() => setShowSuccess(false), 2000));

  const viewLink = () => {
    window.open(link, '_blank');
    setShowSuccess(true);
    timer();
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setShowSuccess(true);
    timer();
  };
  useEffect(() => () => {
    // necessary to avoid memory leak if modal closed whilst timer running
    clearTimeout(timer);
  });

  // Maintain button width when clicked
  const ref = useRef(null);

  const defaultButtonIconUrl = disabled
    ? '/resources/img/24_link_light_grey.svg'
    : '/resources/img/24_link_white.svg';

  const defaultButton = (
    <span ref={ref} data-tip data-for='previewTip'>
      <Button
        dataAutomation={copyButtonDataAutomation}
        type={disabled ? 'thirdary' : 'active'}
        disabled={disabled}
        cancelDisabledStyle={disabled}
        onClick={() => (isViewLink ? viewLink() : copyLink())}
        iconUrl={!isViewLink && defaultButtonIconUrl}
        darkIconUrl={!isViewLink && defaultButtonIconUrl}
        height='48px'
        noMarginTop
        noMarginLeft
      >
        <LegacyIntlHeading
          textKey={`Authoring.label.${isViewLink ? 'view' : 'copy'}EnpsLink`}
          weight='bold'
          size='mini'
        />
      </Button>
    </span>
  );

  const successButton = (
    <Button
      type='custom'
      onClick={() => {}}
      customColour={colourStyles.successPrimary}
      customDarkColour={colourStyles.successPrimary}
      iconUrl='/resources/img/24_correct_white.svg'
      darkIconUrl='/resources/img/24_correct_white.svg'
      height='48px'
      noMarginTop
      noMarginLeft
    />
  );

  return (
    <div
      styleName={classNames('copy-link-wrapper', {
        disabled,
      })}
    >
      <Input
        dataAutomation={inputDataAutomation}
        type='text'
        value={link}
        onChangeValue={() => {}}
        placeholder={placeholder}
        validationStyles='hide'
        disabled={disabled || inputDisabled}
        onFocus={(e) => {
          e.select();
          copyLink();
        }}
      />
      <div
        styleName='copy-link-button'
        style={{minWidth: ref.current?.offsetWidth}}
      >
        {showSuccess ? successButton : defaultButton}
      </div>
    </div>
  );
};

CopyLink.propTypes = {
  link: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  inputDataAutomation: PropTypes.string,
  copyButtonDataAutomation: PropTypes.string,
  isViewLink: PropTypes.bool,
};

CopyLink.defaultProps = {
  disabled: false,
  placeholder: '',
};

export default injectIntl(CSSModules(CopyLink, styles, {allowMultiple: true}));
