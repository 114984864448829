import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {Heading, Link, Text} from '@edume/magnificent';

import {
  ACCEPTED_LABELS,
  READY_TO_PUBLISH,
  UNPUBLISHED,
} from '../../../enums/courses';
import {INSPIRATION, RECENT} from '../../../enums/overview';
import {getAdminLanguage} from '../../../state/auth/languageSelector';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import ImageCourse from '../../Course/ImageCourse';

import styles from './styles.module.scss';

const OverviewCourseList = ({
  courses = [],
  type,
  onLinkClick = () => {},
  onCourseCardClick,
  featureFlags,
}) => {
  const intl = useIntl();
  const adminLanguage = useSelector((state) => getAdminLanguage(state));

  if (courses.length === 0) {
    return null;
  }

  const {priorityCoursesEnabled} = featureFlags;

  const renderCourse = (course) => {
    const publishStatus = course.ready ? READY_TO_PUBLISH : UNPUBLISHED;
    const publishStatusText = intl.formatMessage({
      id: `Learning.${publishStatus}`,
    });
    const acceptedLabel = ACCEPTED_LABELS.includes(course.label);
    const showCourseLabel = type === INSPIRATION;
    return (
      <div styleName='course-wrapper'>
        <ImageCourse
          course={course}
          publishStatus={publishStatus}
          publishStatusText={publishStatusText}
          isResourceLibraryCourse={false}
          isDemoContentCourse={false}
          priorityCoursesEnabled={priorityCoursesEnabled}
          acceptedLabel={acceptedLabel}
          adminLanguage={adminLanguage}
          copyResourceLibraryCourse={() => {}}
          duplicateOptions={[]}
          isTemplate={false}
          hasNoHoverState={true}
          goToCourse={() => onCourseCardClick(course)}
          showCourseLabel={showCourseLabel}
        />
      </div>
    );
  };

  const title = intl.formatMessage({id: `Overview.${type}.title`});
  const subtitle = intl.formatMessage({
    id: `Overview.${type}.subtitle`,
  });
  const linkText = intl.formatMessage({id: `Overview.${type}.link`});

  return (
    <>
      <div styleName='courses-heading'>
        <div>
          <Heading size='leopard' as='h2' weight='semiBold'>
            {title}
          </Heading>
          <Text size='s' colour='grey700'>
            {subtitle}
          </Text>
        </div>
        <Link onClick={onLinkClick} text={linkText} linkSize='snail' />
      </div>
      <div styleName='courses'>{courses.map(renderCourse)}</div>
    </>
  );
};

OverviewCourseList.propTypes = {
  courses: PropTypes.array,
  type: PropTypes.oneOf([RECENT, INSPIRATION]),
  onLinkClick: PropTypes.func.isRequired,
  onCourseCardClick: PropTypes.func.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

export default injectFeatureFlags(CSSModules(OverviewCourseList, styles));
