import {validateLessonOrAssessment} from '@edume/bento/lessonValidation';

import {getCourseCompletionValidity} from '../../utils/courseUtil';
import {getCourseLanguageReadyStates} from '../../utils/language';

export const getCourseByModuleId = (state, moduleId) => {
  const course = state
    .getIn(['course', 'courses'])
    .find((c) => c.getIn(['modules']).find((m) => m.get('id') === moduleId));
  return course ? course.toJS() : null;
};

export const getCourseById = (state, courseId) => {
  const course = state
    .getIn(['course', 'courses'])
    .find((c) => c.get('id') === courseId);
  return course ? course.toJS() : null;
};

export const getCourseByKey = (state, courseKey) => {
  const course = state
    .getIn(['course', 'courses'])
    .find((c) => c.get('courseKey') === courseKey);
  return course ? course.toJS() : null;
};

// eslint-disable-next-line complexity
export const isCourseValid = (state, courseId) => {
  const course = getCourseById(state, courseId);
  if (!course) {
    return false;
  }

  const lessons = state.getIn(['moduleActivity', 'lessons']).toJS();
  const surveys = state.getIn(['moduleActivity', 'surveys']).toJS();
  const {customCompletionScreenEnabled, customEndOfActivityEnabled} =
    state.getIn(['featureFlags', 'customerFlags']);
  const endOfActivityEnabled =
    customEndOfActivityEnabled && !course.isFountainOnly;
  const courseIsNonEmpty = lessons.length > 0 || surveys.length > 0;
  const everyModuleIsNonEmpty = course.modules.every(
    (module) =>
      lessons.some((lesson) => lesson.moduleId === module.id) ||
      surveys.some((survey) => survey.moduleId === module.id)
  );
  const everyLessonIsValid = lessons.every(
    (lesson) =>
      validateLessonOrAssessment(lesson.eson, false, endOfActivityEnabled)
        .result
  );

  const languageReadyStates = getCourseLanguageReadyStates(course, [
    ...lessons,
    ...surveys,
  ]);

  const everyLanguageIsValid = Object.values(languageReadyStates).every(
    (language) => language
  );

  const enabledCustomCompletionValidation =
    !course.completionCustomTextOptions ||
    course.isFountainOnly ||
    getCourseCompletionValidity(course);
  const validEndScreen =
    !customCompletionScreenEnabled || enabledCustomCompletionValidation;

  return (
    courseIsNonEmpty &&
    everyModuleIsNonEmpty &&
    everyLessonIsValid &&
    validEndScreen &&
    everyLanguageIsValid
  );
};

export const getReassignedResultsStatus = (state) => {
  const results = state.getIn(['course', 'reassignCourseResults']);

  if (!results.length || results.length === 0) {
    return {
      reassignedSuccess: 'NONE',
      unsuccessfulResults: [],
      successfulResults: [],
    };
  }

  let successfulResults = [];
  let unsuccessfulResults = [];
  results.forEach((res) => {
    if (res.success) {
      successfulResults.push(res);
    } else {
      unsuccessfulResults.push(res);
    }
  });

  if (unsuccessfulResults.length === results.length) {
    return {
      reassignedSuccess: 'NONE',
      successfulResults,
      unsuccessfulResults,
    };
  } else if (successfulResults.length === results.length) {
    return {
      reassignedSuccess: 'ALL',
      successfulResults,
      unsuccessfulResults,
    };
  } else {
    return {
      reassignedSuccess: 'SOME',
      successfulResults,
      unsuccessfulResults,
    };
  }
};

export const hideCourseTitleSlide = (state, courseKey) => {
  const course = getCourseByKey(state, courseKey);
  return course.hideTitleSlide;
};
