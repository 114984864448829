import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

const OverviewBlock = ({children}) => <div styleName='block'>{children}</div>;

OverviewBlock.propTypes = {
  children: PropTypes.any,
};

export default CSSModules(OverviewBlock, styles, {allowMultiple: true});
