import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {LegacyHeading, PlusIcon} from '@edume/magnificent';

import styles from './styles.module.scss';

const AddPlusButton = ({onClick, courseModuleType, intl}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      styleName='add-container-interactive'
      onClick={onClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div styleName='add-inner-container'>
        <div styleName='plus-sign'>
          <PlusIcon colour={isHovered ? 'white' : 'brandPrimary'} />
          <div styleName='add-activity-title'>
            <LegacyHeading
              size='tinier'
              weight='medium'
              // colour={isHovered ? 'purpleDark' : 'primary'}
              text={
                courseModuleType === 'activity'
                  ? intl.formatMessage({id: 'Learning.addALesson'})
                  : intl.formatMessage({id: 'Learning.addACard'})
              }
              maxLines={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddPlusButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  courseModuleType: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
};

export default CSSModules(AddPlusButton, styles);
