import {buildRequest} from '../stateUtil';
import {
  ASSIGN_COURSE_TO_SEGMENT,
  CREATE_GROUP_SEGMENT,
  EDIT_GROUP_SEGMENT,
  LOAD_GROUP_SEGMENTS,
} from './segmentActionTypes';

export const loadGroupSegments = (groupId) =>
  buildRequest(LOAD_GROUP_SEGMENTS, 'GET', `/segments/${groupId}`);

export const createGroupSegment = (payload) =>
  buildRequest(CREATE_GROUP_SEGMENT, 'POST', '/segments', payload);

export const editGroupSegment = (segmentId, payload) =>
  buildRequest(EDIT_GROUP_SEGMENT, 'PUT', `/segments/${segmentId}`, payload);

export const assignCourseToSegment = (courseKey, payload) =>
  buildRequest(
    ASSIGN_COURSE_TO_SEGMENT,
    'POST',
    `/course/${courseKey}/assignSegment`,
    payload
  );
