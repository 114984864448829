export const LOAD_GUIDE = 'LOAD_GUIDE';
export const LOAD_GUIDE_SUCCESS = 'LOAD_GUIDE_SUCCESS';
export const LOAD_GUIDES = 'LOAD_GUIDES';

export const UPDATE_GUIDE = 'UPDATE_GUIDE';
export const UPDATE_GUIDE_SUCCESS = 'UPDATE_GUIDE_SUCCESS';

export const DELETE_GUIDE = 'DELETE_GUIDE';
export const DELETE_GUIDE_SUCCESS = 'DELETE_GUIDE_SUCCESS';
export const DELETE_GUIDE_FAIL = 'DELETE_GUIDE_FAIL';

export const RECORD_GUIDE_CREATION = 'RECORD_GUIDE_CREATION';

export const PUBLISH_GUIDE = 'PUBLISH_GUIDE';
export const PUBLISH_GUIDE_SUCCESS = 'PUBLISH_GUIDE_SUCCESS';
export const UNPUBLISH_GUIDE = 'UNPUBLISH_GUIDE';
export const UNPUBLISH_GUIDE_SUCCESS = 'UNPUBLISH_GUIDE_SUCCESS';

export const CHANGE_GUIDE_TAB = 'CHANGE_GUIDE_TAB';
export const GUIDE_TABS = {
  PREVIEW: 'PREVIEW',
  LEGACY_DELIVERY: 'LEGACY_DELIVERY',
  DELIVERY: 'DELIVERY',
  REPORTS: 'REPORTS',
  VISIBILITY: 'VISIBILITY',
};

export const ASSIGN_GUIDE_TO_GROUPS = 'ASSIGN_GUIDE_TO_GROUPS';
export const ASSIGN_GUIDE_TO_GROUPS_SUCCESS = 'ASSIGN_GUIDE_TO_GROUPS_SUCCESS';
export const GET_GUIDE_ASSIGNED_TEAMS = 'GET_GUIDE_ASSIGNED_TEAMS';
export const GET_GUIDE_ASSIGNED_TEAMS_SUCCESS =
  'GET_GUIDE_ASSIGNED_TEAMS_SUCCESS';
export const RESET_GUIDE_ASSIGNED_TEAMS = 'RESET_GUIDE_ASSIGNED_TEAMS';

export const GET_PREVIEW_TOKEN = 'GET_PREVIEW_TOKEN';
export const GET_PREVIEW_TOKEN_SUCCESS = 'GET_PREVIEW_TOKEN_SUCCESS';
export const RESET_PREVIEW_TOKEN = 'RESET_PREVIEW_TOKEN';

export const TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL =
  'TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL';
export const TOGGLE_GUIDE_OTP_SLIDEPANEL = 'TOGGLE_GUIDE_OTP_SLIDEPANEL';
export const GET_GUIDES_NOTIFICATION_DATA = 'GET_GUIDES_NOTIFICATION_DATA';
export const GET_GUIDES_NOTIFICATION_DATA_SUCCESS =
  'GET_GUIDES_NOTIFICATION_DATA_SUCCESS';

export const SET_IS_USER_ADDING_GUIDE = 'SET_IS_USER_ADDING_GUIDE';

export const LINK_INTEGRATED_GUIDE = 'LINK_INTEGRATED_GUIDE';
export const LINK_INTEGRATED_GUIDE_SUCCESS = 'LINK_INTEGRATED_GUIDE_SUCCESS';
export const UPDATE_INTEGRATED_GUIDE = 'UPDATE_INTEGRATED_GUIDE';
export const UPDATE_INTEGRATED_GUIDE_SUCCESS =
  'UPDATE_INTEGRATED_GUIDE_SUCCESS';
