import React, {MouseEvent, ReactNode, useState} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';
import ReactTooltip from 'react-tooltip';

import Button, {ButtonTypes} from '../Button';
import {ChevronIcon} from '../icons';
import ListPopover from '../Popover/ListPopover';
import {PopoverCustomWidth, PopoverFullWidth} from '../Popover/Popover';
import Text, {TextSizes} from '../TextComponents/Text';

import styles from './styles.module.scss';

type Option =
  | {
      title: string;
      icon?: string;
      color?: string;
      callback: () => void;
      dataAutomation?: string;
    }
  | {
      subtitle: string;
    };

type CommonProps = {
  options: Option[];
  dropdownKey?: string; //needed for tooltips
  type?: ButtonTypes;
  title?: string;
  showInvalidState?: boolean;
  errorText?: string;
  selectedIndex?: number;
  defaultText?: string;
  textSize?: TextSizes;
  buttonProps?: {[key: string]: string | boolean};
  buttonContent?: ReactNode;
  popoverContainerStyles?: {[key: string]: string | number};
  changeOptionText?: boolean;
  disabledTooltipClassname?: string;
  disabledTooltipText?: string;
  reskinClassName?: string;
  dataAutomation?: string;
  iconPosition?: 'start' | 'end';
  hasPopoverBelow?: boolean;
  hideSelectedOption?: boolean;
  withSearch?: boolean;
  searchPlaceholder?: string;
  searchClearText?: string;
  searchNoResultsMessage?: string;
  clearInputType?: 'icon' | 'button' | 'none';
  searchInputDataAutomation?: string;
};

type ConditionalButtonProps =
  | {
      buttonProps: {[key: string]: string | boolean};
      textSize?: never;
    }
  | {
      buttonProps?: never;
      textSize?: TextSizes;
    };

type ConditionalWidthProps =
  | {
      isFullWidthOnMobile?: boolean;
      customWidth?: never;
    }
  | {
      isFullWidthOnMobile?: never;
      customWidth?: PopoverCustomWidth;
    };

type DropdownProps = CommonProps &
  ConditionalButtonProps &
  ConditionalWidthProps;

// eslint-disable-next-line complexity
const Dropdown = ({
  options,
  dropdownKey = 'dropdownKey',
  type = 'secondary',
  title,
  showInvalidState,
  errorText,
  selectedIndex,
  defaultText,
  textSize = 'm',
  buttonProps,
  buttonContent,
  popoverContainerStyles,
  changeOptionText = false,
  disabledTooltipClassname = '',
  disabledTooltipText,
  reskinClassName = '',
  dataAutomation,
  iconPosition = 'start',
  hasPopoverBelow,
  hideSelectedOption,
  withSearch,
  searchPlaceholder,
  searchClearText,
  searchNoResultsMessage,
  customWidth,
  isFullWidthOnMobile,
  clearInputType,
  searchInputDataAutomation,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = (event: MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    setIsOpen((prevState) => !prevState);
  };
  const optionSelected = typeof selectedIndex !== 'undefined';
  const selectedOption = optionSelected ? options[selectedIndex] : null;

  let buttonText = '';

  if (!buttonContent) {
    if ((!changeOptionText || !optionSelected) && defaultText) {
      buttonText = defaultText;
    } else if (selectedOption && 'title' in selectedOption) {
      buttonText = selectedOption.title;
    }
  }

  const getPopoverFullWidthProp = (): PopoverFullWidth | null => {
    if (isFullWidthOnMobile) {
      return 'mobile-only';
    }
    if (hasPopoverBelow && !withSearch && buttonProps?.fullWidth) {
      return 'container';
    }

    return null;
  };
  const popoverFullWidthProp = getPopoverFullWidthProp();

  const listPopoverWidthProps = popoverFullWidthProp
    ? {fullWidth: popoverFullWidthProp}
    : {customWidth};

  return (
    <div
      styleName={classNames('dropdown-wrapper', {opened: isOpen})}
      data-tip
      data-for={dropdownKey}
    >
      <Button
        type={isOpen ? 'active' : type}
        onClick={toggleDropdown}
        icon={<ChevronIcon colour='grey700' orientation='down' />}
        dropdownButton={true}
        noMarginLeft
        noMarginTop
        reskinClassName={reskinClassName}
        iconPosition={iconPosition}
        {...buttonProps}
        dataAutomation={dataAutomation}
      >
        {buttonContent || (
          <Text size={textSize} weight='medium'>
            {buttonText}
          </Text>
        )}
      </Button>
      {isOpen && (
        <div
          styleName={classNames('popoverWrapper', {
            below: hasPopoverBelow,
          })}
          style={popoverContainerStyles}
        >
          <ListPopover
            dataAutomation={searchInputDataAutomation}
            title={title}
            options={
              hideSelectedOption
                ? options.filter((val) => val !== selectedOption)
                : options
            }
            close={toggleDropdown}
            withSearch={withSearch}
            searchPlaceholder={searchPlaceholder}
            searchClearText={searchClearText}
            searchNoResultsMessage={searchNoResultsMessage}
            clearInputType={clearInputType}
            {...listPopoverWidthProps}
          />
        </div>
      )}
      {errorText && showInvalidState && (
        <div styleName='error'>
          <Text size='s' colour='red'>
            {errorText}
          </Text>
        </div>
      )}
      {buttonProps && buttonProps.disabled && disabledTooltipText && (
        <ReactTooltip
          id={dropdownKey}
          className={disabledTooltipClassname}
          place='top'
          effect='solid'
        >
          <div className='box'>{disabledTooltipText}</div>
        </ReactTooltip>
      )}
    </div>
  );
};

export default CSSModules(Dropdown, styles, {allowMultiple: true});
