import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useDropzone} from 'react-dropzone';
import {FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  DownloadIcon,
  LegacyHeading,
  LoadingIndicator,
} from '@edume/magnificent';

import styles from './styles.module.scss';

const FilePicker = ({type, isUploading, onFilePick, hintTextKey}) => {
  const intl = useIntl();
  const {getRootProps, getInputProps} = useDropzone({
    accept: type === 'image' ? 'image/*' : 'video/*',
    multiple: false,
    onDrop: onFilePick,
    noDragEventsBubbling: true,
  });

  const uploadButtonText = intl.formatMessage(
    {id: 'UploadModal.selectFileButton'},
    {type}
  );

  return (
    <div>
      {hintTextKey && <FormattedMessage id={hintTextKey} />}
      <div
        {...getRootProps({className: 'dropzone'})}
        styleName={`upload-area ${hintTextKey ? '' : 'no-hint-text'}`}
      >
        <input data-automation={`${type}-input`} {...getInputProps()} />
        {isUploading ? (
          <LoadingIndicator
            indicator='hexagons'
            size='small'
            containerStyle='centerFlex'
          />
        ) : (
          <>
            <div>
              <DownloadIcon size='small' colour='brandPrimary' />
              <LegacyHeading
                size='micro'
                colour='primary'
                weight='bold'
                text={intl.formatMessage({id: 'UploadModal.dragFile'})}
              />
            </div>

            <LegacyHeading
              size='micro'
              colour='primary'
              text={intl.formatMessage({id: 'UploadModal.dragFile.or'})}
            />
            <Button
              type='primary'
              onClick={() => null}
              noMarginTop
              noMarginLeft
              dataAutomation={`upload-${type}-button`}
            >
              {uploadButtonText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

FilePicker.propTypes = {
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  isUploading: PropTypes.bool.isRequired,
  onFilePick: PropTypes.func.isRequired,
  hintTextKey: PropTypes.string.isRequired,
};

export default CSSModules(FilePicker, styles, {allowMultiple: true});
