import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Text} from '@edume/magnificent';

import styles from './styles.module.scss';

const SidebarItem = ({
  title,
  onClick,
  isTabbable,
  isActive,
  dragHandle = null,
  endContent,
  automationTag,
  isDragging,
  dragInProgress,
  hideEndContentIfInactive,
  tabTextDataAutomation,
}) => (
  <button
    styleName={classNames('sidebar-button', {
      active: isActive,
      dragging: isDragging,
      dragInProgress,
      'with-handle': !!dragHandle,
    })}
    onClick={onClick}
    data-automation={automationTag}
    tabIndex={isTabbable ? undefined : -1}
  >
    {dragHandle}

    <div styleName='title-container'>
      <Text
        size='m'
        weight={isActive ? 'medium' : 'regular'}
        inline
        dataAutomation={tabTextDataAutomation}
      >
        {title}
      </Text>
    </div>

    {!!endContent && (
      <div styleName={classNames({hideInactive: hideEndContentIfInactive})}>
        {endContent}
      </div>
    )}
  </button>
);

SidebarItem.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isTabbable: PropTypes.bool,
  isActive: PropTypes.bool,
  dragHandle: PropTypes.node,
  endContent: PropTypes.node,
  automationTag: PropTypes.string,
  isDragging: PropTypes.bool,
  dragInProgress: PropTypes.bool,
  hideEndContentIfInactive: PropTypes.bool,
  tabTextDataAutomation: PropTypes.string,
};

export default CSSModules(SidebarItem, styles, {allowMultiple: true});
