import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

import {integrations, MS_TEAMS} from '../../../../enums/integrations';

import styles from './styles.module.scss';

const AdditionalIntegrationContent = ({type, error, intl}) => {
  let content = null;
  const beekeeperContent = (
    <div styleName='additional-content'>
      <LegacyHeading size='micro' colour='textPrimary'>
        {!error ? (
          <div>
            {intl.formatMessage({id: 'Learning.assignTeams.copy.beekeeper1'})}
          </div>
        ) : null}
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'Learning.assignTeams.copy.beekeeper2',
            }),
          }}
        />
      </LegacyHeading>
    </div>
  );

  const msTeamsContent = (
    <div styleName='additional-content'>
      <LegacyHeading size='micro' colour='textPrimary'>
        {!error ? ( //eslint-disable-line
          <div>
            {intl.formatMessage({id: 'Learning.assignTeams.copy.msTeams1'})}
          </div>
        ) : null}
        <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'Learning.assignTeams.copy.msTeams2',
            }),
          }}
        />
      </LegacyHeading>
    </div>
  );

  switch (type) {
    case integrations.BEEKEEPER:
      content = beekeeperContent;
      break;

    case MS_TEAMS:
      content = msTeamsContent;
      break;

    default:
      break;
  }

  return content;
};

AdditionalIntegrationContent.propTypes = {
  type: PropTypes.string.isRequired,
  error: PropTypes.number,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(AdditionalIntegrationContent, styles));
