import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Input, LegacyHeading, Link, SlidePanel} from '@edume/magnificent';

import useFeatureFlag from '../../../hooks/useFeatureFlags';
import {
  LegacyIntlHeading,
  PreviewLinkButton,
  SelectTeamsList,
} from '../../core';
import HubLinks from '../HubLinks';

import styles from '../styles.module.scss';

// eslint-disable-next-line complexity
const AddKnowledgeHub = ({
  onClose,
  onBack,
  intl,
  teams,
  addKnowledgeHub,
  groupId,
  isSeamlessEnabled,
  knowledgeHubId,
}) => {
  const [addingKnowledgeHub, setAddingKnowledgeHub] = useState(true);
  const [knowledgeHubName, setKnowledgeHubName] = useState('');
  const [selectedCourseType, setSelectedCourseType] = useState('');
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);

  const knowledgeHubsManagementEnabled = useFeatureFlag(
    'knowledgeHubsManagement'
  );

  useEffect(() => {
    if (knowledgeHubId) {
      setAddingKnowledgeHub(false);
    }
  }, [knowledgeHubId]);

  const onSubmit = () => {
    const showsExternalContent = selectedCourseType === 'user';
    addKnowledgeHub(
      groupId,
      selectedTeamIds,
      knowledgeHubName,
      showsExternalContent
    );
    onClose();
  };

  const courseTypes = ['user', 'teams'];

  const panelDescription = intl.formatMessage({
    id: knowledgeHubsManagementEnabled
      ? 'KnowledgeHub.panelDescription.sections'
      : 'KnowledgeHub.panelDescription',
  });

  const linkText = intl.formatMessage({id: 'KnowledgeHub.linkText'});

  const getCourseSelectionOptions = courseTypes.map((type) => {
    const showsExternalContent = type === 'user';
    const dataAutomation = showsExternalContent
      ? 'all-courses-option'
      : 'team-only-option';
    return (
      <div
        key={type}
        styleName={`course-option ${
          selectedCourseType === type ? 'selected' : ''
        }`}
        onClick={() => setSelectedCourseType(type)}
        data-automation={dataAutomation}
      >
        <LegacyHeading size='mini' weight='medium'>
          {intl.formatMessage({
            id: `Button.${showsExternalContent ? 'yes' : 'no'}`,
          })}
        </LegacyHeading>
      </div>
    );
  });

  const addingHubContent = (
    <>
      <div styleName='secondary-text'>
        <LegacyHeading size='micro'>{panelDescription}</LegacyHeading>
        <div styleName='link'>
          <Link text={linkText} href='https://help.edume.com/knowledge-hubs' />
        </div>
      </div>

      <Input
        type='text'
        value={knowledgeHubName}
        title={intl.formatMessage({id: 'KnowledgeHub.nameInputTitle'})}
        onChangeValue={setKnowledgeHubName}
        dataAutomation='knowledge-hub-name-input'
      />

      {!knowledgeHubsManagementEnabled && (
        <>
          <SelectTeamsList
            teams={teams}
            titleKey='KnowledgeHub.teamSelectionInputTitle'
            setSelectedTeamIds={setSelectedTeamIds}
            selectedTeamIds={selectedTeamIds}
          />
          <div styleName='course-selection'>
            <LegacyIntlHeading
              size='micro'
              weight='medium'
              textKey='KnowledgeHub.courseSelectionInputTitle'
            />
            <div styleName='options-container'>{getCourseSelectionOptions}</div>
          </div>
        </>
      )}
    </>
  );

  const summaryInfo = [
    {
      type: 'courseView',
      value: selectedCourseType
        ? intl.formatMessage({
            id: `KnowledgeHub.courseSelection.${selectedCourseType}`,
          })
        : '',
      tag: 'course-view-type',
    },
    {
      type: 'teams',
      value: selectedTeamIds
        .map((teamId) => teams.find((team) => team.id === teamId).name)
        .join(', '),
      tag: 'selected-teams',
    },
  ];

  const summary = summaryInfo.map(({type, value, tag}) => (
    <div styleName='summary-info' key={type}>
      <LegacyHeading size='mini' weight='medium'>
        {intl.formatMessage({id: `KnowledgeHub.summary.${type}`})}
      </LegacyHeading>
      <LegacyHeading dataAutomation={tag} size='mini'>
        {value}
      </LegacyHeading>
    </div>
  ));

  const summaryContent = (
    <>
      <div styleName='hub-summary'>
        <LegacyHeading
          dataAutomation='knowledge-hub-name'
          size='tiny'
          weight='medium'
        >
          {knowledgeHubName}
        </LegacyHeading>
        {summary}

        <PreviewLinkButton
          contentType='knowledgeHub'
          knowledgeHubId={knowledgeHubId}
        />
      </div>
      <HubLinks
        teamIds={selectedTeamIds}
        knowledgeHubId={knowledgeHubId}
        isSeamlessEnabled={isSeamlessEnabled}
        titleKey='KnowledgeHub.summary.description'
      />
    </>
  );

  const isSubmitButtonDisabled = knowledgeHubsManagementEnabled
    ? !knowledgeHubName
    : !knowledgeHubName || !selectedCourseType || selectedTeamIds.length === 0;

  let actions = [];
  if (addingKnowledgeHub) {
    actions = [
      {
        type: 'primary',
        onClick: onSubmit,
        disabled: isSubmitButtonDisabled,
        dataAutomation: 'confirm-create-knowledge-hub-button',
        text: intl.formatMessage({id: 'KnowledgeHub.buttonText'}),
      },
      {
        type: 'secondary',
        onClick: onBack,
        dataAutomation: 'create-knowledge-hub-back-button',
        text: intl.formatMessage({id: 'Button.back'}),
      },
    ];
  }

  const titleKey = `KnowledgeHub.${
    addingKnowledgeHub ? 'createText' : 'summaryText'
  }`;
  const title = intl.formatMessage({id: titleKey});

  return (
    <SlidePanel onClose={onClose} buttons={actions} title={title}>
      {addingKnowledgeHub ? addingHubContent : summaryContent}
    </SlidePanel>
  );
};

AddKnowledgeHub.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  addKnowledgeHub: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  isSeamlessEnabled: PropTypes.bool.isRequired,
  knowledgeHubId: PropTypes.number,
};

export default injectIntl(
  CSSModules(AddKnowledgeHub, styles, {allowMultiple: true})
);
