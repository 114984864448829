import * as contentActions from '../content/contentActions';
import * as notificationActions from '../notification/notificationActions';
import {getStatsForGroup} from '../reporting/reportingActions';
import * as settingsActions from '../settings/settingsActions';
import {getAllUsersTeam, getSelectedGroupId} from '../team/teamSelectors';
import * as guideActionCreators from './guideActionCreators';
import {
  CHANGE_GUIDE_TAB,
  GUIDE_TABS,
  RECORD_GUIDE_CREATION,
  RESET_GUIDE_ASSIGNED_TEAMS,
  RESET_PREVIEW_TOKEN,
  SET_IS_USER_ADDING_GUIDE,
  TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL,
  TOGGLE_GUIDE_OTP_SLIDEPANEL,
  UPDATE_GUIDE_SUCCESS,
} from './guideActionTypes';

export const loadGuide = (guideId) => async (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  if (selectedGroupId) {
    await dispatch(
      guideActionCreators.getGuide(guideId, selectedGroupId)
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
  }
};

export const loadGuides = (filters) => async (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  if (selectedGroupId) {
    await dispatch(
      guideActionCreators.getGuides(selectedGroupId, filters || {})
    )
      .then(() =>
        dispatch(
          guideActionCreators.getGuideNotificationDataByGroup(selectedGroupId)
        )
      )
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  }
};

export const updateTeamsInGuide =
  (guideId, teamIdsToAdd, teamIdsToRemove) => (dispatch, getState) => {
    const groupId = getSelectedGroupId(getState());
    dispatch(
      guideActionCreators.updateTeamsInGuide(
        guideId,
        groupId,
        teamIdsToAdd,
        teamIdsToRemove
      )
    )
      .then(() => getGuideAssignedTeams(guideId)(dispatch, getState))
      .then(() => {
        notificationActions.createSuccessNotification(
          'Guides.updateTeamsSuccess'
        )(dispatch);
      })
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const getGuideAssignedTeams = (guideId) => (dispatch) => {
  dispatch(guideActionCreators.getGuideAssignedTeams(guideId)).catch((error) =>
    notificationActions.createErrorNotificationFromResponse(error)(dispatch)
  );
};

export const resetGuideAssignedTeams = () => (dispatch) => {
  dispatch({
    type: RESET_GUIDE_ASSIGNED_TEAMS,
  });
};

export const updateGuide = (guideId, payload) => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  return dispatch(
    guideActionCreators.updateGuide(guideId, selectedGroupId, payload)
  )
    .then(() => {
      notificationActions.createSuccessNotification(
        'Guides.updatedSuccesfully'
      )(dispatch);
    })
    .then(() => contentActions.loadContents()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const publishGuide = (guideId) => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  return dispatch(guideActionCreators.publishGuide(guideId, selectedGroupId))
    .then(() => {
      notificationActions.createSuccessNotification(
        'Guides.publishModal.publishedSuccesfully'
      )(dispatch);
    })
    .then(() => contentActions.loadContents()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const unpublishGuide = (guideId) => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  return dispatch(guideActionCreators.unpublishGuide(guideId, selectedGroupId))
    .then(() => {
      notificationActions.createSuccessNotification(
        'Guides.unpublishModal.unpublishedSuccesfully'
      )(dispatch);
    })
    .then(() => contentActions.loadContents()(dispatch, getState))
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const deleteGuide = (guideId) => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  return dispatch(guideActionCreators.deleteGuide(guideId, selectedGroupId))
    .then(() => {
      notificationActions.createSuccessNotification(
        'Guides.deleteGuideSuccess'
      )(dispatch);
      contentActions.loadContents()(dispatch, getState);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const patchLocalGuide = (guide) => (dispatch) => {
  dispatch({
    type: UPDATE_GUIDE_SUCCESS,
    payload: {data: guide},
  });
};

export const guideCreated = (guide, source) => async (dispatch) => {
  await dispatch({
    type: RECORD_GUIDE_CREATION,
    payload: {data: guide, source},
  });
};

export const getPreviewToken = (guideId) => (dispatch, getState) => {
  const selectedGroupId = getSelectedGroupId(getState());
  return dispatch(
    guideActionCreators.getPreviewToken(guideId, selectedGroupId)
  );
};

export const resetPreviewToken = () => (dispatch) => {
  dispatch({
    type: RESET_PREVIEW_TOKEN,
  });
};

export const changeGuideTab = (payload) => (dispatch, getState) => {
  if (
    GUIDE_TABS.VISIBILITY === payload.tab ||
    GUIDE_TABS.DELIVERY === payload.tab
  ) {
    const groupId = getSelectedGroupId(getState());
    dispatch(getStatsForGroup(groupId));

    const signingSecrets = getState()
      .getIn(['settings', 'signingSecret'])
      .toJS();

    if (!signingSecrets.key && !signingSecrets.hasLoaded) {
      dispatch(settingsActions.getSigningSecrets());
    }
  }
  dispatch({
    type: CHANGE_GUIDE_TAB,
    payload,
  });
};

export const toggleGuideNotificationsSlidePanel = (payload) => (dispatch) => {
  dispatch({
    type: TOGGLE_GUIDE_NOTIFICATIONS_SLIDEPANEL,
    payload,
  });
};

export const toggleGuideContentLinksSlidePanel = (payload) => (dispatch) => {
  dispatch({
    type: TOGGLE_GUIDE_OTP_SLIDEPANEL,
    payload,
  });
};

export const setIsUserAddingGuide = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_USER_ADDING_GUIDE,
    payload,
  });
};

export const linkGuideToIntegration =
  (guideId, payload) => (dispatch, getState) => {
    dispatch(guideActionCreators.linkIntegrationToGuide(guideId, payload))
      .then(() => contentActions.loadContents()(dispatch, getState))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const updateGuideIntegration =
  (guideId, courseIntegrationId, payload) => (dispatch, getState) => {
    dispatch(
      guideActionCreators.updateGuideIntegration(
        guideId,
        courseIntegrationId,
        payload
      )
    )
      .then(() => contentActions.loadContents()(dispatch, getState))
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const makeGuideVisibleToAllUsers = (guideId) => (dispatch, getState) => {
  const allUsersTeam = getAllUsersTeam(getState());

  if (!allUsersTeam) {
    return;
  }

  updateTeamsInGuide(guideId, [allUsersTeam.id], [])(dispatch, getState);
};
