import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {InfoIcon, QuestionMarkIcon} from '@edume/magnificent';

import {svgColorMap} from '../../../utils/colours';

import styles from './styles.module.scss';

const Info = ({
  tooltipKey,
  tooltipTitleKey,
  children,
  dataKey,
  place,
  size,
  iconLeft,
  intl,
  hideArrow = true,
  iconColor = 'purple300',
  tooltipIconColor = 'purple300',
  iconType = '!',
  theme = 'light',
}) => {
  const tooltip = tooltipKey ? intl.formatMessage({id: tooltipKey}) : '';
  const tooltipTitle = tooltipTitleKey
    ? intl.formatMessage({id: tooltipTitleKey})
    : '';

  const className = () => {
    let styleName = `infoTooltip ${theme}`;
    if (hideArrow) {
      styleName += ' hideArrow';
    }
    if (size) {
      styleName += ` ${size}`;
    }

    return styleName;
  };

  return (
    <span styleName='container'>
      {iconType === '?' ? (
        <QuestionMarkIcon colour={iconColor} dataTip dataFor={dataKey} />
      ) : (
        <InfoIcon colour={iconColor} dataTip dataFor={dataKey} />
      )}
      <ReactTooltip
        type={theme}
        id={dataKey}
        className={className()}
        place={place}
      >
        {tooltipKey ? (
          <div styleName={iconLeft ? 'iconLeft' : ''}>
            <div>
              <InfoIcon
                colour={tooltipIconColor}
                {...(iconLeft && {size: 'big'})}
              />
            </div>
            <div>
              {tooltipTitleKey && <h4>{tooltipTitle}</h4>}
              <p>{tooltip}</p>
            </div>
          </div>
        ) : (
          children
        )}
      </ReactTooltip>
    </span>
  );
};

Info.propTypes = {
  //one of tooltipkey *or* children is required
  tooltipKey: PropTypes.string,
  tooltipTitleKey: PropTypes.string,
  children: PropTypes.any,

  dataKey: PropTypes.string.isRequired,
  place: PropTypes.string,
  size: PropTypes.oneOf(['large']),
  iconLeft: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  hideArrow: PropTypes.bool,
  iconColor: PropTypes.oneOf(svgColorMap),
  tooltipIconColor: PropTypes.oneOf(svgColorMap),
  iconType: PropTypes.oneOf(['!', '?']),
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default injectIntl(CSSModules(Info, styles));
