import React from 'react';

import PropTypes from 'prop-types';
import {Draggable} from 'react-beautiful-dnd';
import CSSModules from 'react-css-modules';

import {getItemStyle} from '..';
import AdminContentCard from '../../../core/AdminContentCard';

import styles from './styles.module.scss';

const Row = ({data, index, style}) => {
  const {contentList, droppableId, addContent, removeContent} = data;
  const contentItem = contentList[index];

  if (!contentItem) {
    return null;
  }

  const key = `${contentItem.contentType}-${contentItem.id}`;
  return (
    <div style={getItemStyle({style})}>
      <div styleName='draggable-card-container'>
        <Draggable
          key={key}
          draggableId={`${droppableId}-${key}`}
          index={index}
          isDragDisabled={contentItem.isAssignedContent}
        >
          {(provided) => (
            <AdminContentCard
              key={key}
              content={contentItem}
              provided={provided}
              addContent={addContent}
              removeContent={removeContent}
            />
          )}
        </Draggable>
      </div>
    </div>
  );
};

Row.propTypes = {
  data: PropTypes.shape({
    contentList: PropTypes.array.isRequired,
    droppableId: PropTypes.string.isRequired,
    addContent: PropTypes.func,
    removeContent: PropTypes.func,
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default CSSModules(Row, styles);
