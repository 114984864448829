import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {AdminBadge} from '@edume/magnificent';

import {StyledModal} from '../../containers/StyledModals';
import {TEAM} from '../../enums/adminLevels';
import * as authActions from '../../state/auth/authActions';
import {
  getAdminLevel,
  getAdminRoles,
  getVisibleTabs,
} from '../../state/auth/authSelector';
import * as trackingActions from '../../state/tracking/trackingActions';
import {trackingActionTypes} from '../../state/tracking/trackingActions';
import {LegacyIntlHeading} from '../core';
import * as contentBuilder from './contentBuilder';

import styles from './styles.module.scss';

const connector = connect(
  (state) => {
    const company = state.getIn(['auth', 'company']);
    return {
      firstName: state.getIn(['auth', 'firstName']),
      companyName: company.name,
      isTrial: company.isTrial,
      adminLevel: company.isTrial ? 'trial' : getAdminLevel(state),
      roles: getAdminRoles(state),
      tabs: getVisibleTabs(state),
      hasCourses: state.getIn(['course', 'courses']).size > 0,
      //this only checks there's a team in the newest group - probably fine?
      hasTeams: state.getIn(['team', 'teamId']) !== null,
    };
  },
  (dispatch) => ({
    ...bindActionCreators(authActions, dispatch),
    ...bindActionCreators(trackingActions, dispatch),
  })
);

const OnboardingModal = ({
  firstName,
  companyName,
  isTrial,
  adminLevel,
  roles,
  tabs,
  hasCourses,
  hasTeams,
  closeOnboarding,
  intl,
  track,
  history,
}) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [showFinal, setShowFinal] = useState(false);

  const getStepCount = () => {
    const introCount = 1; //always one intro slide, whether full or trial company
    const tabCount = getContentSteps().length;
    return introCount + tabCount;
  };

  const closeMainOnboarding = () => {
    if (isTrial) {
      // Closing main onboarding, so we can start tracking Hotjar here. When we do, the checklist should be visible by default
      if (window.hj) {
        window.hj('stateChange', '/checklistVisible');
      }
      close();
    } else {
      setShowFinal(true);
    }
  };

  const onNextStep = () => {
    //if on the final step, hide this modal and show the standalone "first things first" modal
    if (stepIndex === getStepCount() - 1) {
      closeMainOnboarding();
      if (isTrial) {
        track(trackingActionTypes.TRIAL_ONBOARD_END);
      }
    } else {
      setStepIndex((i) => i + 1);
      if (isTrial) {
        track(trackingActionTypes.TRIAL_ONBOARD, {slideNumber: stepIndex + 2});
      }
    }
  };

  const onStepChange = (newIndex) => {
    const safeIndex = Math.min(Math.max(0, newIndex), getStepCount() - 1);
    setStepIndex(safeIndex);
    if (isTrial) {
      track(trackingActionTypes.TRIAL_ONBOARD, {slideNumber: safeIndex + 1});
    }
  };

  const onSkip = () => {
    if (isTrial) {
      track(trackingActionTypes.TRIAL_ONBOARD_SKIP, {
        slideNumber: stepIndex + 1,
      });
    }
    closeMainOnboarding();
  };

  const close = () => {
    closeOnboarding();
  };

  const bottomLeftContent = () => ({
    text: intl.formatMessage({id: 'Onboarding.skip'}),
    type: 'button',
    onClick: onSkip,
    dataAutomation: 'modal-skip-button',
  });

  const getWelcomeStep = () => {
    let badges = [];
    if (roles.isLead) {
      badges.push(
        <AdminBadge
          key={0}
          level={adminLevel}
          role='lead'
          text={intl.formatMessage({id: `Admin.level.${adminLevel}.lead`})}
        />
      );
    } else {
      //map from e.g. `isAnalyst` -> `analyst` for all my roles
      const myRoleNames = Object.keys(roles)
        .filter((roleName) => roles[roleName])
        .map((roleName) => roleName.split('is')[1].toLowerCase());
      badges = badges.concat(
        myRoleNames.map((roleName, index) => (
          <AdminBadge
            key={index}
            level={adminLevel}
            role={roleName}
            text={intl.formatMessage({
              id: `Admin.level.${adminLevel}.${roleName}`,
            })}
          />
        ))
      );
    }
    return {
      imageUrl: '/resources/img/onboarding/onboard-welcome.png',
      title: intl.formatMessage(
        {id: 'Onboarding.Welcome.title'},
        {name: firstName}
      ),
      body: CSSModules(
        () => (
          <div styleName='modalContent'>
            <FormattedMessage
              id='Onboarding.Welcome.intro'
              values={{companyName}}
            />
            <div styleName='badgeContainer'>{badges}</div>
            <FormattedMessage id='Onboarding.Welcome.body' />
          </div>
        ),
        styles
      )(),
      buttons: [
        {
          type: 'primary',
          text: intl.formatMessage({id: 'Onboarding.next'}),
          onClick: () => onStepChange(1),
        },
      ],
      bottomLeftContent: bottomLeftContent(),
    };
  };

  const getTrialWelcomeStep = () => ({
    imageUrl: '/resources/img/onboarding/onboard-trial-welcome.png',
    title: intl.formatMessage({id: 'Onboarding.TrialWelcome.title'}),
    body: (
      <LegacyIntlHeading size='micro' textKey='Onboarding.TrialWelcome.body' />
    ),
    buttons: [
      {
        type: 'primary',
        text: intl.formatMessage({id: 'Onboarding.next'}),
        onClick: () => onStepChange(1),
      },
    ],
    bottomLeftContent: bottomLeftContent(),
  });

  const getUpdatesStep = () =>
    contentBuilder.getUpdatesContent(
      intl,
      adminLevel,
      onNextStep,
      'Onboarding.next',
      bottomLeftContent()
    );

  const getPeopleStep = () =>
    contentBuilder.getPeopleContent(
      intl,
      adminLevel,
      onNextStep,
      'Onboarding.next',
      bottomLeftContent()
    );

  const getContentStep = () =>
    contentBuilder.getLearningContent(
      intl,
      adminLevel,
      onNextStep,
      'Onboarding.next',
      bottomLeftContent()
    );

  const getReportingStep = () =>
    contentBuilder.getReportingContent(
      intl,
      adminLevel,
      onNextStep,
      'Onboarding.next',
      bottomLeftContent()
    );

  // eslint-disable-next-line complexity
  const getFinalStep = () => {
    let bodyText;
    let buttonAction;

    if (roles.isLead && adminLevel !== TEAM) {
      bodyText = intl.formatMessage({
        id: `Onboarding.Final.Lead.${hasCourses ? 'hasContent' : 'noContent'}`,
      });
      buttonAction = () => history.push('/learning');
    } else if (roles.isManager || (roles.isLead && adminLevel === TEAM)) {
      bodyText = intl.formatMessage({
        id: `Onboarding.Final.Manager.${hasTeams ? 'hasContent' : 'noContent'}`,
      });
      buttonAction = () => history.push('/people');
    } else if (roles.isEditor) {
      bodyText = intl.formatMessage({
        id: `Onboarding.Final.Editor.${
          hasCourses ? 'hasContent' : 'noContent'
        }`,
      });
      buttonAction = () => history.push('/learning');
    } else if (roles.isAnalyst) {
      bodyText = intl.formatMessage({id: 'Onboarding.Final.Analyst'});
      buttonAction = () => history.push('/reports');
    }

    return {
      imageUrl: '/resources/img/onboarding/onboard-get-started.png',
      title: intl.formatMessage({id: 'Onboarding.Final.title'}),
      body: CSSModules(
        () => <div styleName='modalContent'>{bodyText}</div>,
        styles
      )(),
      buttons: [
        {
          type: 'secondary',
          text: intl.formatMessage({id: 'Onboarding.Final.secondaryButton'}),
          onClick: () => {
            close();
            track(trackingActionTypes.TRIAL_CREATE_SAMPLE_COURSE_MODAL_NO);
          },
        },
        {
          type: 'primary',
          //TODO replace with different role-specific copy if discussion goes that way
          text: intl.formatMessage({id: 'Onboarding.Final.go'}),
          onClick: () => {
            close();
            buttonAction();
            track(trackingActionTypes.TRIAL_CREATE_SAMPLE_COURSE_MODAL_YES);
          },
        },
      ],
    };
  };

  const getContentSteps = () => {
    const steps = [];
    if (tabs.indexOf('learning') > -1) {
      steps.push(getContentStep());
    }
    if (tabs.indexOf('messages') > -1) {
      steps.push(getUpdatesStep());
    }
    if (tabs.indexOf('people') > -1) {
      steps.push(getPeopleStep());
    }
    if (tabs.indexOf('reports') > -1) {
      steps.push(getReportingStep());
    }
    return steps;
  };

  const welcomeStep = isTrial ? getTrialWelcomeStep() : getWelcomeStep();

  const content = [welcomeStep, ...getContentSteps()];

  return showFinal ? (
    <StyledModal onClose={() => {}} content={getFinalStep()} />
  ) : (
    <StyledModal
      onClose={() => {
        /*no-op, so can't close via overlay*/
      }}
      content={content}
      stepIndex={stepIndex}
      onStepChange={onStepChange}
    />
  );
};

OnboardingModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  isTrial: PropTypes.bool.isRequired,
  adminLevel: PropTypes.string.isRequired,
  roles: PropTypes.any.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasCourses: PropTypes.bool.isRequired,
  hasTeams: PropTypes.bool.isRequired,

  closeOnboarding: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
  track: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(
  connector(injectIntl(CSSModules(OnboardingModal, styles)))
);
