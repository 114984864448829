import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import SelectableTeamList from './SelectableTeamList';
import SelectableTeamsConditionalHeadings from './SelectableTeamsConditionalHeadings';

import styles from './styles.module.scss';

const SelectableTeamsComponent = ({
  accessMethod,
  isMsTeams,
  isViewingWithinMST,
  selectedIdsBySource,
  setSelectedIdsBySource,
  sourceIndex,
  teams,
  isTeamSelected,
  hasSearchResults,
  searchResultTeamsWithExistingData,
  allTeamIds,
  returnBodyOnly,
  save,
}) => {
  const teamsToDisplay =
    isMsTeams && hasSearchResults ? searchResultTeamsWithExistingData : teams;

  const allSearchedTeamsIds = searchResultTeamsWithExistingData
    .filter((t) => !t.disabled)
    .map((t) => t.id);

  const totalSelectedIds = selectedIdsBySource[sourceIndex]?.length;

  const hasTeamsToDisplay = hasSearchResults
    ? searchResultTeamsWithExistingData.length
    : allTeamIds.length;

  const toggleSelection = (selectedIndex, teamId) => {
    const selectedTeamIds = selectedIdsBySource[selectedIndex];
    const selectedTeamIndex = selectedTeamIds.indexOf(teamId);

    const newSelectedTeamIds =
      selectedTeamIndex === -1
        ? [teamId, ...selectedTeamIds]
        : selectedTeamIds.filter((id) => id !== teamId);

    return updateSelectedTeams(selectedIndex, newSelectedTeamIds);
  };

  const updateSelectedTeams = (selectedIndex, newSelectedIds) => {
    setSelectedIdsBySource([
      ...selectedIdsBySource.slice(0, selectedIndex),
      newSelectedIds,
      ...selectedIdsBySource.slice(selectedIndex + 1),
    ]);
    if (returnBodyOnly) {
      save(newSelectedIds);
    }
  };

  return (
    hasTeamsToDisplay && (
      <>
        <SelectableTeamsConditionalHeadings
          accessMethod={accessMethod}
          isMsTeams={isMsTeams}
          isViewingWithinMST={isViewingWithinMST}
          selectedIdsBySource={selectedIdsBySource}
          sourceIndex={sourceIndex}
          totalSelectedIds={totalSelectedIds}
          hasSearchResults={hasSearchResults}
          allSearchedTeamsIds={allSearchedTeamsIds}
          allTeamIds={allTeamIds}
          updateSelectedTeams={updateSelectedTeams}
        />
        <div
          styleName={`teams-list ${
            isViewingWithinMST && teamsToDisplay.length > 4 ? 'scrollable' : ''
          } ${!teamsToDisplay.length ? 'hidden' : ''}`}
        >
          <SelectableTeamList
            sourceIndex={sourceIndex}
            teamsToDisplay={teamsToDisplay}
            toggleSelection={toggleSelection}
            isTeamSelected={isTeamSelected}
          />
        </div>
      </>
    )
  );
};

SelectableTeamsComponent.propTypes = {
  accessMethod: PropTypes.string.isRequired,
  isMsTeams: PropTypes.bool.isRequired,
  isViewingWithinMST: PropTypes.bool.isRequired,
  selectedIdsBySource: PropTypes.array.isRequired,
  setSelectedIdsBySource: PropTypes.func.isRequired,
  sourceIndex: PropTypes.number.isRequired,
  teams: PropTypes.array.isRequired,
  isTeamSelected: PropTypes.func.isRequired,
  hasSearchResults: PropTypes.bool.isRequired,
  searchResultTeamsWithExistingData: PropTypes.array.isRequired,
  allTeamIds: PropTypes.array.isRequired,
  returnBodyOnly: PropTypes.bool,
  save: PropTypes.func,
};

export default CSSModules(SelectableTeamsComponent, styles, {
  allowMultiple: true,
});
