import {addMeta, buildRequest} from '../stateUtil';
import {
  ADD_GROUP,
  ADD_TEAM,
  DELETE_GROUP,
  DELETE_TEAM,
  EDIT_GROUP,
  EDIT_TEAM,
  LOAD_EXTERNAL_TEAMS,
  LOAD_TEAMS,
} from './teamActionTypes';

export const addTeam = (name, parentGroupId) =>
  buildRequest(ADD_TEAM, 'POST', '/group', {name, parentGroupId});

export const deleteTeam = (teamId) =>
  buildRequest(DELETE_TEAM, 'DELETE', `/groups/${teamId}`);

export const addGroup = (name) =>
  buildRequest(ADD_GROUP, 'POST', '/group', {name});

export const editTeam = (id, name) =>
  buildRequest(EDIT_TEAM, 'PUT', `/groups/${id}`, {name});

export const editGroup = (id, name, {allUsersSegment}) =>
  addMeta(buildRequest(EDIT_GROUP, 'PUT', `/groups/${id}`, {name}), {
    allUsersSegment,
  });

export const deleteGroup = (groupId) =>
  addMeta(buildRequest(DELETE_GROUP, 'DELETE', `/groups/${groupId}`), {
    groupId,
  });

export const getTeams = (includeUserCount) => {
  const queryParams = `?includeUserCount=${!!includeUserCount}`;
  return buildRequest(LOAD_TEAMS, 'GET', `/groups${queryParams}`);
};

export const getExternalTeams = () =>
  buildRequest(LOAD_EXTERNAL_TEAMS, 'GET', '/groups/integrations');

export const searchMsTeamsTeams = (actionType, searchTerm) =>
  buildRequest(actionType, 'GET', `/msTeams/searchTeams?query=${searchTerm}`);
