import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Button, ChevronIcon, Heading, HintBox} from '@edume/magnificent';

import useHasChanges from '../../../hooks/useHasChanges';
import usePrevious from '../../../hooks/usePrevious';
import {
  addCourse,
  updateCourseDetails,
} from '../../../state/course/courseActions';
import {getSelectedGroupId} from '../../../state/team/teamSelectors';
import {InputWithWarning, UploadMediaWithThumbnail} from '../../core';
import SlidePanelWithWarning from '../../core/SlidePanelWithWarning';
import {ONE_BY_ONE_CROPPED_IMAGE_ONLY} from '../../core/UploadMediaWithThumbnail/imageTypes';
import SelectIconAndColour from './SelectIconAndColour';

import colourStyles from '../../../../resources/styles/colours.module.scss';
import styles from './styles.module.scss';

const ICON_NAMES = [
  'alex',
  'apple',
  'authentication',
  'bag',
  'bell',
  'blackboard',
  'brain',
  'bulb',
  'burger',
  'car',
  'cardchart',
  'certificate',
  'chart',
  'clock',
  'directions',
  'edume',
  'folders',
  'graph',
  'handshake',
  'hospital',
  'idcard',
  'lamp',
  'lock',
  'loudspeaker',
  'marker',
  'miko',
  'money',
  'moneybag',
  'mortarboard',
  'notebook',
  'nunzia',
  'oswald',
  'package',
  'pencil',
  'percent',
  'pingu',
  'shop',
  'shoppingcart',
  'sparkles',
  'speechbubble',
  'teresa',
  'tickbadge',
  'tools',
  'trophy',
  'truck',
  'unlock',
  'verified',
  'wrench',
];

const COLOURS = [
  '#019083',
  '#DE8F6E',
  '#3F8AB1',
  '#DBD56E',
  '#88AB75',
  '#454448',
  '#FF6B67',
  '#71C3BC',
  '#8CB8D0',
  '#BED1B2',
  '#EEC1AD',
  '#8F5C44',
  '#0D003A',
];

const shuffle = (a) => {
  let j;
  let x;
  let i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

// eslint-disable-next-line complexity, max-statements
const CreateEditCourse = ({onClose, onBack, originalCourse}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isEditCourseInProgress = useSelector((state) =>
    state.getIn(['course', 'editCourseInProgress'])
  );
  const groupId = useSelector((state) => getSelectedGroupId(state));

  const [courseTitle, setCourseTitle] = useState(
    originalCourse ? originalCourse.title : ''
  );
  const [isInvalidCourseTitle, setIsInvalidCourseTitle] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    originalCourse ? originalCourse.courseImageUrl : null
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [hasIconsVisible, setHasIconsVisible] = useState(false);
  const [hasColoursVisible, setHasColoursVisible] = useState(false);
  const [isAbleToClose, setIsAbleToClose] = useState(true);
  const isPrevEditCourseInProgress = usePrevious(isEditCourseInProgress);
  const [isClosing, setIsClosing] = useState(false);

  const [iconsAndColours, setIconsAndColours] = useState(() => {
    const courseIcons = shuffle(ICON_NAMES);
    const courseColours = shuffle(COLOURS);

    const hasIcon = originalCourse && originalCourse.icon;

    if (hasIcon) {
      const index = courseIcons.indexOf(originalCourse.icon);
      if (index >= 0) {
        courseIcons.splice(index, 1);
        courseIcons.unshift(originalCourse.icon);
      }
    }
    if (originalCourse) {
      const colour = originalCourse.colour.toUpperCase();
      const index = courseColours.indexOf(colour);
      if (index >= 0) {
        courseColours.splice(index, 1);
        courseColours.unshift(colour);
      }
    }
    return {
      courseIcons,
      courseColours,
      originalColour: courseColours[0],
      originalIcon: courseIcons[0],
      selectedIcon: courseIcons[0],
      selectedColour: courseColours[0],
    };
  });

  const hasChanges = useHasChanges(
    [
      courseTitle,
      imageUrl,
      iconsAndColours.selectedIcon,
      iconsAndColours.selectedColour,
    ],
    [
      originalCourse?.title || '',
      originalCourse?.courseImageUrl || null,
      iconsAndColours.originalIcon,
      iconsAndColours.originalColour,
    ]
  );

  useEffect(() => {
    if (isPrevEditCourseInProgress && !isEditCourseInProgress) {
      setIsClosing(true);
    }
  }, [isEditCourseInProgress, isPrevEditCourseInProgress]);

  const isInvalidForm =
    isInvalidCourseTitle || isImageUploading || !courseTitle.trim();

  const updateCourseTitle = (newCourseTitle) => {
    setCourseTitle(newCourseTitle);
    setIsInvalidCourseTitle(!newCourseTitle.trim());
  };

  const handleOnSaveCourse = () => {
    const source = history.location.state;
    if (isInvalidForm) {
      return null;
    }

    const {selectedIcon, selectedColour, originalIcon, originalColour} =
      iconsAndColours;

    if (originalCourse) {
      return dispatch(
        updateCourseDetails(
          originalCourse.courseKey,
          courseTitle,
          selectedIcon,
          selectedColour
        )
      );
    }

    const newCourse = {
      title: courseTitle,
      icon: selectedIcon,
      colour: selectedColour,
      courseImageUrl: imageUrl,
      public: false,
    };
    setIsClosing(true);
    return dispatch(
      addCourse(newCourse, groupId, {
        originalColour,
        originalIcon,
        source,
      })
    );
  };

  const selectCourseImage = (image) => {
    setImageUrl(image.url);
  };

  const toggleIconsVisibility = () => {
    setHasIconsVisible((prev) => !prev);
    setHasColoursVisible(false);
  };

  const toggleColoursVisibility = () => {
    setHasColoursVisible((prev) => !prev);
    setHasIconsVisible(false);
  };

  const setCourseIcon = (icon) => {
    setIconsAndColours({...iconsAndColours, selectedIcon: icon});
    setHasIconsVisible(false);
  };

  const setCourseColour = (colour) => {
    setIconsAndColours({...iconsAndColours, selectedColour: colour});
    setHasColoursVisible(false);
  };

  const isAdding = !originalCourse;

  const headerKey = isAdding
    ? 'Learning.createACourse'
    : 'Learning.course.edit';
  const buttonKey = isAdding ? 'Button.createCourse' : 'Button.save';

  const title = intl.formatMessage({id: headerKey});
  const buttons = [
    {
      type: 'primary',
      text: intl.formatMessage({id: buttonKey}),
      dataAutomation: 'create-course-button',
      onClick: (e) => {
        e.stopPropagation();
        handleOnSaveCourse();
      },
      disabled: isInvalidForm || isEditCourseInProgress,
    },
  ];
  if (isAdding) {
    buttons.push({
      type: 'secondary',
      text: intl.formatMessage({id: 'Button.back'}),
      dataAutomation: 'create-course-back-button',
      onClick: onBack,
    });
  }

  const {courseIcons, courseColours, selectedIcon, selectedColour} =
    iconsAndColours;

  const toggleButtonProps = {
    type: 'thirdary',
    iconPosition: 'end',
    noMarginTop: true,
    noMarginLeft: true,
  };

  const iconButtonText = intl.formatMessage({id: 'Learning.courseIconButton'});
  const colourButtonText = intl.formatMessage({
    id: 'Learning.courseColourButton',
  });

  return (
    <SlidePanelWithWarning
      onClose={isAbleToClose ? onClose : () => {}}
      shouldClose={isClosing}
      title={title}
      buttons={buttons}
      showUnsavedChangesWarning={hasChanges}
      disableFocusTrap={true} // needed for Image modal
    >
      <div styleName='body-container'>
        {originalCourse?.isTemplate && (
          <section>
            <HintBox
              text={intl.formatMessage({
                id: 'Learning.publishBar.editTemplateMessage',
              })}
              type='error'
              compact={true}
            />
          </section>
        )}
        <section styleName='reduce-space-below'>
          <Heading as='h4' size='snail' weight='semiBold'>
            {intl.formatMessage({id: 'Learning.chooseCourseTitle'})}
          </Heading>
          <InputWithWarning
            type='normal'
            dataAutomation='course-title-input'
            value={courseTitle}
            inputKey='courseTitle'
            onChangeValue={updateCourseTitle}
            placeholder={intl.formatMessage({
              id: 'Learning.courseTitlePlaceholder',
            })}
            isInvalid={isInvalidCourseTitle}
            errorText={intl.formatMessage({
              id: 'Learning.courseTitleErrorText',
            })}
            autoFocus={true}
            onEnterKey={handleOnSaveCourse}
            isTitle={true}
            includeBottomMargin={false}
            hintPosition='center'
          />
        </section>

        <Heading as='h4' size='snail'>
          {intl.formatMessage({id: 'Learning.courseImageDescription'})}
        </Heading>

        <div styleName='image-icon-container'>
          {isAdding && (
            <section>
              <Heading as='h4' size='snail' weight='semiBold'>
                {intl.formatMessage({id: 'Learning.chooseCourseImage'})}
              </Heading>
              <div styleName='image-wrapper'>
                <UploadMediaWithThumbnail
                  type='image'
                  imageType={ONE_BY_ONE_CROPPED_IMAGE_ONLY}
                  onModalOpen={() => {
                    setIsImageUploading(true);
                    setIsAbleToClose(false);
                  }}
                  onModalClose={() => {
                    setIsImageUploading(false);
                    setIsAbleToClose(true);
                  }}
                  setMedia={selectCourseImage}
                  media={{url: imageUrl}}
                  addButtonLocaleKey='Learning.addCourseImage'
                  changeButtonLocaleKey='Learning.changeCourseImage'
                  hintTextKey='Learning.imageUploadRecommendationCourseImage'
                  imageStyles={{
                    paddingBottom: '100%',
                    backgroundColor: colourStyles.grey050,
                    border: `2px dashed ${colourStyles.grey300}`,
                    borderRadius: '12px',
                  }}
                />
              </div>
            </section>
          )}

          <section>
            <Heading as='h4' size='snail' weight='semiBold'>
              {intl.formatMessage({id: 'Learning.chooseCourseIcon'})}
            </Heading>
            <div styleName='icons-and-colours'>
              <div
                styleName='preview-circle'
                style={{background: selectedColour}}
                data-automation='course-details-preview-circle'
              >
                <img
                  src={`https://edume-res.cloudinary.com/image/upload/course_icons/transparent/${selectedIcon}.png`}
                  alt={selectedIcon}
                />
              </div>

              <div styleName='button-container'>
                <Button
                  {...toggleButtonProps}
                  icon={
                    <ChevronIcon
                      orientation={hasIconsVisible ? 'up' : 'down'}
                    />
                  }
                  onClick={toggleIconsVisibility}
                  dataAutomation='change-icon-expand-button'
                >
                  {iconButtonText}
                </Button>
                {hasIconsVisible && (
                  <SelectIconAndColour
                    type='icon'
                    onSelect={setCourseIcon}
                    items={courseIcons.filter((icon) => icon !== 'sparkles')}
                    selectedItem={selectedIcon}
                  />
                )}
              </div>

              <div styleName='button-container'>
                <Button
                  {...toggleButtonProps}
                  icon={
                    <ChevronIcon
                      orientation={hasColoursVisible ? 'up' : 'down'}
                    />
                  }
                  onClick={toggleColoursVisibility}
                  dataAutomation='change-colour-expand-button'
                >
                  {colourButtonText}
                </Button>
                {hasColoursVisible && (
                  <SelectIconAndColour
                    type='colour'
                    onSelect={setCourseColour}
                    items={courseColours}
                    selectedItem={selectedColour}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </SlidePanelWithWarning>
  );
};

CreateEditCourse.propTypes = {
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  originalCourse: PropTypes.object,
};

export default CSSModules(CreateEditCourse, styles, {allowMultiple: true});
