import amplitudeJs from 'amplitude-js';

import {amplitudeFunctions} from '@edume/bento/amplitude';

import {sendTrackingEvents} from './trackingEvents';
import {sendTrackingUserProps} from './trackingUsers';

export const amplitude = amplitudeFunctions(amplitudeJs);

export const trackReduxAction = (action, state) => {
  sendTrackingUserProps(action, state);
  sendTrackingEvents(action, state);
};
