import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {Badge, ContentCard, Text} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';
import {
  PUBLISHED,
  READY_TO_PUBLISH,
  REVIEW,
  UNPUBLISHED,
} from '../../../enums/courses';
import {getContentBadgeColour} from '../../../utils/badges';
import RightPanel from './RightPanel';

import styles from './styles.module.scss';

const getStatus = (isGuide, content) => {
  if (isGuide ? content.status === PUBLISHED : content.published) {
    return PUBLISHED;
  }
  if (isGuide ? content.status === REVIEW : content.ready) {
    return READY_TO_PUBLISH;
  }
  return UNPUBLISHED;
};

const AdminContentCard = ({
  content,
  provided,
  addContent,
  removeContent,
  isDragging,
  isStatusVisible = true,
}) => {
  const intl = useIntl();

  const {
    contentType,
    isAssignedContent,
    title,
    courseImageUrl,
    cover,
    numModuleActivities,
    steps,
  } = content;

  const isGuide = contentType === contentTypes.GUIDE;
  const activityCountContent = isGuide
    ? intl.formatMessage(
        {
          id: 'Guides.stepCount',
        },
        {number: steps?.length}
      )
    : intl.formatMessage(
        {
          id: 'Courses.preview.lessonCountCap',
        },
        {number: numModuleActivities}
      );

  const status = getStatus(isGuide, content);

  const contentTypeText = intl.formatMessage({
    id: `Learning.contentType.${contentType}`,
  });

  const rightPanel = (
    <RightPanel
      removeContent={removeContent}
      addContent={addContent}
      content={content}
      isAssignedContent={isAssignedContent}
    />
  );

  const badgeColour = getContentBadgeColour(status);

  const cardFooter = isStatusVisible && (
    <Badge
      colour={badgeColour}
      textContent={intl.formatMessage({id: `Learning.${status}`})}
    />
  );

  return (
    <div
      styleName={`card-container${isDragging ? ' dragging' : ''}`}
      data-automation='content-card'
      {...(provided && provided.draggableProps)}
      {...(provided && provided.dragHandleProps)}
      ref={provided && provided.innerRef}
    >
      <ContentCard
        title={title}
        image={isGuide ? cover.thumbnail : courseImageUrl}
        contentType={contentType}
        contentTypeText={contentTypeText}
        activityCountContent={activityCountContent}
        borderRadius={4}
        cardFooter={cardFooter}
        rightPanel={rightPanel}
        fullHeight={false}
        borders={true}
        selected={isAssignedContent}
      />
    </div>
  );
};

AdminContentCard.propTypes = {
  content: PropTypes.object.isRequired,
  provided: PropTypes.object,
  addContent: PropTypes.func,
  removeContent: PropTypes.func,
  isDragging: PropTypes.bool,
  isStatusVisible: PropTypes.bool,
};

export default CSSModules(AdminContentCard, styles, {
  allowMultiple: true,
});
