import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.module.scss';

const getIntlMessage = (intl) => (id) => intl.formatMessage({id});

const SettingsCard = ({children, titleKey, descriptionKey, headerChildren}) => {
  const intlMessage = getIntlMessage(useIntl());

  return (
    <div styleName='card'>
      <div styleName='card-header-container'>
        <div styleName='card-header'>
          <LegacyHeading size='tiny' weight='medium'>
            {intlMessage(titleKey)}
          </LegacyHeading>
        </div>
        {headerChildren}
      </div>
      <div styleName='card-description'>
        {descriptionKey && (
          <LegacyHeading size='micro' weight='regular'>
            <span
              dangerouslySetInnerHTML={{__html: intlMessage(descriptionKey)}}
            />
          </LegacyHeading>
        )}
      </div>
      <div styleName='container'>{children}</div>
    </div>
  );
};

SettingsCard.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string,
  children: PropTypes.node,
  headerChildren: PropTypes.node,
};

export default CSSModules(SettingsCard, styles);
