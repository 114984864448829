import {useEffect, useRef} from 'react';

import {useSelector} from 'react-redux';

import {SSEClient} from '../SSEClient';

/**
 * @typedef {() => void} onCloseCallback Invoked when the SSE connection is closed.
 */

/**
 * @param {string} url
 * @param {object} config
 * @param {boolean} [config.disable]
 * @param {(data?: any) => (void | onCloseCallback)} callback callbacks are _only_ allowed to return either void, or a destructor.
 */
const useServerSentEvents = (url, {disable = false}, callback) => {
  /** @type {React.MutableRefObject<SSEClient>} */
  const sseClient = useRef(null);
  const isAuthenticated = useSelector((state) =>
    state.getIn(['auth', 'isAuthenticated'])
  );

  const close = () => {
    closeSSE();
    if (callback) {
      callback()?.();
    }
  };

  const closeSSE = () => {
    if (sseClient.current) {
      sseClient.current.onMessage = null;
      sseClient.current.onClose = null;
      sseClient.current.close();
      sseClient.current = null;
    }
  };

  useEffect(() => {
    if (disable) {
      closeSSE();
    }

    if (isAuthenticated && !sseClient.current && !disable) {
      const client = new SSEClient(url);
      client.onMessage = callback;
      client.onClose = close;
      sseClient.current = client;
    }

    return () => {
      closeSSE();
    };
  }, [isAuthenticated, url, disable]);
};

export default useServerSentEvents;
