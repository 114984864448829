export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_FAIL = 'ADD_COURSE_FAIL';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const ADD_LESSON = 'ADD_LESSON';
export const ADD_LESSON_FAIL = 'ADD_LESSON_FAIL';
export const ADD_LESSON_SUCCESS = 'ADD_LESSON_SUCCESS';
export const ADD_AI_LESSON_SUCCESS = 'ADD_AI_LESSON_SUCCESS';
export const ADD_MODULE = 'ADD_MODULE';
export const ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS';
export const ASSIGN_COURSE_TO_GROUPS = 'ASSIGN_COURSE_TO_GROUPS';
export const ASSIGN_COURSE_TO_GROUPS_SUCCESS =
  'ASSIGN_COURSE_TO_GROUPS_SUCCESS';
export const CHANGE_COURSE_TAB = 'CHANGE_COURSE_TAB';
export const CHANGE_ORDER = 'CHANGE_ORDER';
export const CHANGE_ORDER_SUCCESS = 'CHANGE_ORDER_SUCCESS';

export const COPY_LESSON = 'COPY_LESSON';
export const COPY_LESSON_SUCCESS = 'COPY_LESSON_SUCCESS';
export const COURSE_TABS = {
  LESSONS: 'LESSONS',
  LEARNING_CARDS: 'LEARNING_CARDS',
  LEGACY_DELIVERY: 'LEGACY_DELIVERY',
  MULTI_LANGUAGE: 'MULTI_LANGUAGE',
  SHARE: 'SHARE',
  VISIBILITY: 'VISIBILITY',
  DELIVERY: 'DELIVERY',
};
export const SIDEBAR_TABS = {
  STANDARD_CONTENT: 'standardContent', // covers 'all content' in sidebar
  TEMPLATES: 'templates',
  COLLECTIONS: 'collections',
  RESOURCE_LIBRARY: 'edumeResources',
  KNOWLEDGE_HUBS: 'knowledgeHubs',
  DEMO_CONTENT: 'demoContent',
};
export const CONTENT_FILTERS = {
  ALL: 'all',
  DRAFT: 'draft',
  PUBLISHED: 'published',
};
export const CREATE_MODULE_SURVEY = 'CREATE_MODULE_SURVEY';
export const CREATE_MODULE_SURVEY_SUCCESS = 'CREATE_MODULE_SURVEY_SUCCESS';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_FAIL = 'DELETE_COURSE_FAIL';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_LESSON = 'DELETE_LESSON';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_SURVEY = 'DELETE_MODULE_SURVEY';
export const DELETE_MODULE_SURVEY_SUCCESS = 'DELETE_MODULE_SURVEY_SUCCESS';
export const DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR =
  'DISMISS_REASSIGN_COURSE_CSV_UPLOAD_ERROR';
export const EDIT_COURSE_COMPLETION = 'EDIT_COURSE_COMPLETION';
export const EDIT_MODULE = 'EDIT_MODULE';
export const GET_BAMBOO_TRAININGS = 'GET_BAMBOO_TRAININGS';
export const GET_BAMBOO_TRAININGS_FAIL = 'GET_BAMBOO_TRAININGS_FAIL';
export const GET_BAMBOO_TRAININGS_SUCCESS = 'GET_BAMBOO_TRAININGS_SUCCESS';
export const ID_COPIED = 'ID_COPIED';
export const LINK_EXTERNAL_TEAMS = 'LINK_EXTERNAL_TEAMS';
export const LINK_INTEGRATED_COURSE = 'LINK_INTEGRATED_COURSE';
export const LINK_INTEGRATED_COURSE_SUCCESS = 'LINK_INTEGRATED_COURSE_SUCCESS';
export const LOAD_COURSE = 'LOAD_COURSE';
export const LOAD_COURSE_SUCCESS = 'LOAD_COURSE_SUCCESS';
export const PUBLISH_COURSE = 'PUBLISH_COURSE';
export const PUBLISH_COURSE_SUCCESS = 'PUBLISH_COURSE_SUCCESS';
export const PUBLISH_LESSON = 'PUBLISH_LESSON';
export const PUBLISH_LESSON_SUCCESS = 'PUBLISH_LESSON_SUCCESS';
export const REASSIGN_COURSE = 'REASSIGN_COURSE';
export const REASSIGN_COURSE_FAIL = 'REASSIGN_COURSE_FAIL';
export const REASSIGN_COURSE_SUCCESS = 'REASSIGN_COURSE_SUCCESS';
export const REDIRECT_IN_PROGRESS = 'REDIRECT_IN_PROGRESS';
export const REDIRECT_COMPLETED = 'REDIRECT_COMPLETED';
export const UNLINK_INTEGRATED_COURSE = 'UNLINK_INTEGRATED_COURSE';
export const UNPUBLISH_COURSE = 'UNPUBLISH_COURSE';
export const UNPUBLISH_COURSE_SUCCESS = 'UNPUBLISH_COURSE_SUCCESS';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_COURSE_FAIL = 'UPDATE_COURSE_FAIL';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_HIDDEN_TITLE_SLIDE =
  'UPDATE_COURSE_HIDDEN_TITLE_SLIDE';
export const UPDATE_COURSE_HIDDEN_TITLE_SLIDE_SUCCESS =
  'UPDATE_COURSE_HIDDEN_TITLE_SLIDE_SUCCESS';
export const UPDATE_COURSE_PRIORITY = 'UPDATE_COURSE_PRIORITY';
export const UPDATE_COURSE_PRIORITY_SUCCESS = 'UPDATE_COURSE_PRIORITY_SUCCESS';
export const UPDATE_INTEGRATED_COURSE = 'UPDATE_INTEGRATED_COURSE';
export const UPDATE_INTEGRATED_COURSE_SUCCESS =
  'UPDATE_INTEGRATED_COURSE_SUCCESS';
export const EXPORT_COURSE_TO_GSHEET = 'EXPORT_COURSE_TO_GSHEET';
export const EXPORT_COURSE_TO_GSHEET_SUCCESS =
  'EXPORT_COURSE_TO_GSHEET_SUCCESS';
export const IMPORT_COURSE_FROM_GSHEET = 'IMPORT_COURSE_FROM_GSHEET';
export const IMPORT_COURSE_FROM_GSHEET_SUCCESS =
  'IMPORT_COURSE_FROM_GSHEET_SUCCESS';
export const RESET_COURSE_LOADED = 'RESET_COURSE_LOADED';
export const STORE_COURSE_PREVIEW_TOKEN = 'STORE_COURSE_PREVIEW_TOKEN';
export const STORE_COURSE_PREVIEW_TOKEN_SUCCESS =
  'STORE_COURSE_PREVIEW_TOKEN_SUCCESS';
export const ACCESSED_COURSE_REPORT_LINK = 'accessed course report link';
export const SEARCH_COURSE = 'SEARCH_COURSE';
export const SET_PAGINATION_TAB = 'SET_PAGINATION_TAB';
export const LOAD_RESOURCE_LIBRARY_COURSES = 'LOAD_RESOURCE_LIBRARY_COURSES';
export const LOAD_DEMO_CONTENT_COURSES = 'LOAD_DEMO_CONTENT_COURSES';
export const LOAD_DEMO_CONTENT_COURSES_SUCCESS =
  'LOAD_DEMO_CONTENT_COURSES_SUCCESS';
export const LOAD_DEMO_CONTENT_COURSES_FAIL = 'LOAD_DEMO_CONTENT_COURSES_FAIL';
export const LOAD_RESOURCE_LIBRARY_COURSES_SUCCESS =
  'LOAD_RESOURCE_LIBRARY_COURSES_SUCCESS';
export const SELECTED_LEARNING_TAB_FILTER = 'SELECTED_LEARNING_TAB_FILTER';
export const VIEWED_PREVIEW_LINK = 'VIEWED_PREVIEW_LINK';
export const CREATE_TEMPLATE_FROM_COURSE = 'CREATE_TEMPLATE_FROM_COURSE';
export const CREATE_TEMPLATE_FROM_COURSE_SUCCESS =
  'CREATE_TEMPLATE_FROM_COURSE_SUCCESS';
export const UPDATE_COURSE_TIMESTAMPS = 'UPDATE_COURSE_TIMESTAMPS';
export const SET_ACTIVE_SIDEBAR_ITEM = 'SET_ACTIVE_SIDEBAR_ITEM';
export const SET_CONTENT_FILTER = 'SET_CONTENT_FILTER';
export const TOGGLE_SIDEBAR_CREATION_EXPANSION =
  'TOGGLE_SIDEBAR_CREATION_EXPANSION';
export const SET_COURSE_CARD_DESIGN = 'SET_COURSE_CARD_DESIGN';
export const ADD_COURSE_TO_DEMO_CONTENT = 'ADD_COURSE_TO_DEMO_CONTENT';
export const ADD_COURSE_TO_DEMO_CONTENT_SUCCESS =
  'ADD_COURSE_TO_DEMO_CONTENT_SUCCESS';
export const REMOVE_COURSE_FROM_DEMO_CONTENT =
  'REMOVE_COURSE_FROM_DEMO_CONTENT';
export const REMOVE_COURSE_FROM_DEMO_CONTENT_SUCCESS =
  'REMOVE_COURSE_FROM_DEMO_CONTENT_SUCCESS';
export const COPY_DEMO_COURSE_TO_CUSTOMER = 'COPY_DEMO_COURSE_TO_CUSTOMER';
export const COPY_DEMO_COURSE_TO_CUSTOMER_SUCCESS =
  'COPY_DEMO_COURSE_TO_CUSTOMER_SUCCESS';
export const SET_IS_USER_ADDING_CONTENT = 'SET_IS_USER_ADDING_CONTENT';
export const SET_IS_USER_ADDING_COURSE = 'SET_IS_USER_ADDING_COURSE';
export const TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL =
  'TOGGLE_COURSE_NOTIFICATIONS_SLIDEPANEL';
export const TOGGLE_COURSE_OTP_SLIDEPANEL = 'TOGGLE_COURSE_OTP_SLIDEPANEL';
export const TRANSLATE_COURSE = 'TRANSLATE_COURSE';
export const TRANSLATE_COURSE_SUCCESS = 'TRANSLATE_COURSE_SUCCESS';
export const TRANSLATE_COURSE_FAIL = 'TRANSLATE_COURSE_FAIL';
export const UPDATE_DEFAULT_LANGUAGE = 'UPDATE_DEFAULT_LANGUAGE';
export const UPDATE_DEFAULT_LANGUAGE_SUCCESS =
  'UPDATE_DEFAULT_LANGUAGE_SUCCESS';
export const UPDATE_DEFAULT_LANGUAGE_FAIL = 'UPDATE_DEFAULT_LANGUAGE_FAIL';
export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const DELETE_TRANSLATION_SUCCESS = 'DELETE_TRANSLATION_SUCCESS';
export const CLEAR_IMPORT_ERROR = 'CLEAR_IMPORT_ERROR';
