import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {SSO} from '@edume/bento/auth';

import styles from './styles.module.scss';

const MessageBubbleHeader = ({ssoConfig, authType}) => {
  const renderLogos = () => {
    const output =
      authType === SSO && ssoConfig.darkLogoUrl ? (
        <div>
          <span styleName='edumeLogo'>
            <img src={'/resources/img/logo-edume-full-colour.svg'} />
          </span>
          <span styleName='companyLogo'>
            <img src={ssoConfig.darkLogoUrl} />
          </span>
        </div>
      ) : (
        <img src={'/resources/img/logo-edume-full-colour.svg'} />
      );

    return output;
  };

  return (
    <div styleName='bubbleHeaderWrapper'>
      <div styleName='companyLogos'>{renderLogos()}</div>
    </div>
  );
};

MessageBubbleHeader.propTypes = {
  ssoConfig: PropTypes.object,
  authType: PropTypes.string,
};

export default CSSModules(MessageBubbleHeader, styles, {allowMultiple: true});
